import axios from 'axios';

export const getSharedClips = async (clipShareId, passPhrase) =>
  (await axios.get(
    `/clips_sharing/${clipShareId}${
      passPhrase ? `?key=${encodeURIComponent(passPhrase)}` : ''
    }`,
  )).data;

export const createSharedClip = async (clipShare) =>
  (await axios.post(`/clips_sharing/`, clipShare)).data;

export default {getSharedClips, createSharedClip};
