import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  success: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.success.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.warning.contrastText,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const VariantSnackbarContent = ({
  classes,
  className,
  icon,
  message,
  onClose,
  variant,
  ...other
}) => (
  <SnackbarContent
    className={classNames(classes[variant], className)}
    data-snackbar-variant={variant}
    aria-describedby="client-snackbar"
    message={
      <span id="client-snackbar" className={classes.message}>
        {icon}
        {message}
      </span>
    }
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={onClose}
        size="large"
      >
        <CloseIcon className={classes.icon} />
      </IconButton>,
    ]}
    {...other}
  />
);

VariantSnackbarContent.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

VariantSnackbarContent.defaultProps = {
  onClose: () => {},
  icon: null,
};

export default withStyles(styles)(VariantSnackbarContent);
