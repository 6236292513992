import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {Paper, Tab, Tabs, Badge, Grid, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import TabPanel from './TabPanel';
import Spinner from '../spinner';

const useStyles = makeStyles((theme) => ({
  tabBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  badgeWrapper: {
    padding: [[0, theme.spacing(2)]],
    [theme.breakpoints.down('sm')]: {
      padding: [[0, theme.spacing(0.5)]],
    },
  },
  badge: {
    backgroundColor: theme.palette.warning.dark,
    color: theme.palette.common.white,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

const TabBar = (props) => {
  const {
    tabs,
    tabProps,
    PaperProps,
    onChange,
    selectedTab: chosenTab,
    tabAction,
    secondaryNode,
    isContentLoading,
    tabGridProps,
    secondaryNodeGridProps,
    ...rest
  } = props;
  const [selectedTab, setSelectedTab] = useState(parseInt(chosenTab, 10) || 0);

  useEffect(
    () => {
      setSelectedTab(parseInt(chosenTab, 10));
    },
    [chosenTab],
  );

  const classes = useStyles(props);
  const a11yProps = (label, panel = false) => {
    return {
      [panel ? 'aria-labelledby' : 'id']: `tab-${kebabCase(label)}`,
      [panel ? 'id' : 'aria-controls']: `tabpanel-${kebabCase(label)}`,
    };
  };

  return (
    <Box {...rest}>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={secondaryNode ? 9 : 12}
          data-cy="tab-bar-grid"
          {...tabGridProps}
        >
          <Paper square {...PaperProps} className={classes.tabBar}>
            <Tabs
              variant="scrollable"
              value={selectedTab}
              indicatorColor="primary"
              onChange={(evt, newTab) => {
                onChange(newTab);
                setSelectedTab(newTab);
              }}
            >
              {tabs.map((t) => (
                <Tab
                  label={
                    <Badge
                      name={`${t.key}-changed-count`}
                      className={classes.badgeWrapper}
                      classes={{
                        badge: classNames(classes.badge, {
                          [classes.error]: t.errors,
                        }),
                      }}
                      badgeContent={t.changes || undefined}
                      overlap="rectangular"
                    >
                      {t.label}
                    </Badge>
                  }
                  key={t.key || t.label}
                  {...a11yProps(t.key || t.label)}
                />
              ))}
            </Tabs>
            {!!tabAction && tabAction}
          </Paper>
        </Grid>
        {secondaryNode && (
          <Grid
            item
            sm={12}
            md={3}
            data-cy="secondary-node-grid"
            {...secondaryNodeGridProps}
          >
            {secondaryNode}
          </Grid>
        )}
      </Grid>

      {!isContentLoading ? (
        tabs.map((t, idx) => {
          return (
            <TabPanel
              value={selectedTab}
              key={kebabCase(t.key || t.label)}
              index={idx}
              {...a11yProps(t.key || t.label, true)}
            >
              {React.createElement(t.component, {
                ...tabProps,
                ...(t.props || {}),
              })}
            </TabPanel>
          );
        })
      ) : (
        <Spinner data-cy="loading-spinner" color="primary" />
      )}
    </Box>
  );
};

TabBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabAction: PropTypes.node,
  /* eslint-disable react/forbid-prop-types */
  PaperProps: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  tabProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
  secondaryNode: PropTypes.node,
  isContentLoading: PropTypes.bool,
  tabGridProps: PropTypes.shape({}),
  secondaryNodeGridProps: PropTypes.shape({}),
};

TabBar.defaultProps = {
  selectedTab: 0,
  PaperProps: {},
  tabProps: {},
  tabAction: null,
  secondaryNode: null,
  isContentLoading: false,
  tabGridProps: null,
  secondaryNodeGridProps: null,
};

export default TabBar;
