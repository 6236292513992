import React, {Fragment} from 'react';
import {PropTypes} from 'prop-types';
import {Typography, Divider} from '@mui/material';

import {
  AlarmModeDisarmed,
  AlarmModeEventsOnly,
  AlarmModeTest,
  AlarmModeEMArmed,
  AlarmModeSelfVerifiedArmed,
} from './AlarmMode';

const findActiveStatus = (statuses) =>
  statuses.find((element) => element.isActive);

const AlarmMode = ({mode, alarmsCount}) => (
  <Fragment>
    {mode}
    <Divider variant="fullWidth" />
    <Typography data-cy="alarm-events-count" sx={{paddingTop: 2}}>
      {alarmsCount} - Events in last 24h
    </Typography>
  </Fragment>
);

const AlarmCurrentMode = ({alarmStatuses, alarmsCount}) => {
  const activeStatus = findActiveStatus(alarmStatuses.statuses);

  switch (activeStatus.status) {
    case 'Test Mode':
      return <AlarmMode mode={<AlarmModeTest />} alarmsCount={alarmsCount} />;

    case 'Events Only':
      return (
        <AlarmMode mode={<AlarmModeEventsOnly />} alarmsCount={alarmsCount} />
      );

    case 'Armed':
      return alarmStatuses.isEnvysionMonitoring ? (
        <AlarmMode mode={<AlarmModeEMArmed />} alarmsCount={alarmsCount} />
      ) : (
        <AlarmMode
          mode={<AlarmModeSelfVerifiedArmed />}
          alarmsCount={alarmsCount}
        />
      );

    default:
      return (
        <AlarmMode mode={<AlarmModeDisarmed />} alarmsCount={alarmsCount} />
      );
  }
};

AlarmCurrentMode.propTypes = {
  alarmStatuses: PropTypes.shape({
    isEnvysionMonitoring: PropTypes.bool,
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        isActive: PropTypes.bool,
      }),
    ),
  }).isRequired,
  alarmsCount: PropTypes.number.isRequired,
};

export default AlarmCurrentMode;
