import React, {Fragment} from 'react';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AlarmIcon from '@mui/icons-material/Alarm';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Icon from '@mui/material/Icon';

import {PropTypes} from 'prop-types';
import classNames from 'classnames';

import alarmModeIconStyles from '../alarmModeStyles';

const useStylesBase = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  extraContent: {
    marginTop: theme.spacing(2),
  },
}));

const useStyleAlarmModeIcon = makeStyles(alarmModeIconStyles);

const AlarmMode = ({
  children,
  label,
  icon,
  level,
  actionContent,
  extraContent,
}) => {
  const baseClasses = useStylesBase();
  const alarmModeClasses = useStyleAlarmModeIcon();
  const classes = {...baseClasses, ...alarmModeClasses};

  return (
    <Fragment>
      <div data-cy="alarm-modes-row-component" className={classes.root}>
        <div className={classes.title}>
          <Icon
            data-cy="color-box"
            className={classNames(
              {
                [classes.colorWarning]: level === 'warn',
                [classes.colorError]: level === 'error',
                [classes.colorSuccess]: level === 'success',
                [classes.colorMain]: level === 'info',
              },
              classes.icon,
            )}
          >
            {icon}
          </Icon>
          <Typography variant="h6">{label}</Typography>
        </div>
        {actionContent}
      </div>

      <Typography variant="body1">{children}</Typography>
      <div className={classes.extraContent}>{extraContent}</div>
    </Fragment>
  );
};

// Common rows
export const AlarmModeDisarmed = ({
  actionContent,
  extraContent,
  label = 'Disarmed',
}) => (
  <AlarmMode
    label={label}
    icon={<AlarmOffIcon />}
    level="error"
    actionContent={actionContent}
    extraContent={extraContent}
  >
    SSP is disabled.
  </AlarmMode>
);

export const AlarmModeEventsOnly = ({actionContent, extraContent}) => (
  <AlarmMode
    label="Events Only"
    icon={<AccessTimeIcon />}
    level="info"
    actionContent={actionContent}
    extraContent={extraContent}
  >
    Events will be logged, but all notifications are suppressed.
  </AlarmMode>
);

// Envysion Monitoring Rows
export const AlarmModeTest = ({actionContent, extraContent}) => (
  <AlarmMode
    label="Test Mode"
    icon={<AlarmIcon />}
    level="warn"
    actionContent={actionContent}
    extraContent={extraContent}
  >
    While in EM Test Mode, emergency services will not be dispatched unless
    confirmed by this locations EM contact(s).
  </AlarmMode>
);

export const AlarmModeEMArmed = ({
  actionContent,
  extraContent,
  label = 'Armed',
}) => (
  <AlarmMode
    label={label}
    icon={<AlarmOnIcon />}
    level="success"
    actionContent={actionContent}
    extraContent={extraContent}
  >
    All events will immediately engage emergency services if a true threat is
    detected, or if there is no response to unverified events.
  </AlarmMode>
);

// Self verification variant
export const AlarmModeSelfVerifiedArmed = ({
  actionContent,
  extraContent,
  label = 'Armed',
}) => (
  <AlarmMode
    label={label}
    icon={<AlarmOnIcon />}
    level="success"
    actionContent={actionContent}
    extraContent={extraContent}
  >
    All events will immediately trigger push or email notifications to the
    subscribed users for verification.
  </AlarmMode>
);

AlarmMode.defaultProps = {
  level: null,
  actionContent: null,
  extraContent: null,
};

AlarmMode.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  level: PropTypes.string,
  actionContent: PropTypes.node,
  extraContent: PropTypes.node,
};

export default AlarmMode;
