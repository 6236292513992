import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, isSupported} from 'firebase/messaging';
import {isMobileApp} from '../../util/general';

const FirebaseClient = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyC7WPWj_NYShqnJJDPcbOyaeI4CKbsckes',
    authDomain: 'envysion-android.firebaseapp.com',
    databaseURL: 'https://envysion-android.firebaseio.com',
    projectId: 'envysion-android',
    storageBucket: 'envysion-android.appspot.com',
    messagingSenderId: '481934663619',
    appId: '1:481934663619:web:d1e4c323009e258e62a776',
    measurementId: 'G-LL3YZ0YX8J',
  };

  const messagingVapidKey =
    'BM0H3NGeM7Jy0B7npEKmd7mimCBEA9uivmZgwtHj5KKatIyboYOaSsxj1aiemKZpOeylMS9M9uNFxD9foVEa3ts';

  const firebaseApp = initializeApp(firebaseConfig);

  let messaging = null;

  const isServiceWorkerSupported = () => {
    if ('serviceWorker' in navigator) {
      return true;
    }
    return false;
  };

  const setupFirebase = async () => {
    try {
      // check if: service worker and firebase messaging is supported in the client browser
      if (
        isServiceWorkerSupported() &&
        ((await isSupported()) && !isMobileApp())
      ) {
        messaging = getMessaging(firebaseApp);
        const workerRegistration = await navigator.serviceWorker.register(
          '/ui/firebase-messaging-sw.js',
        );
        await navigator.serviceWorker.ready;
        let currentToken = null;
        if (workerRegistration) {
          currentToken = await getToken(messaging, {
            vapidKey: messagingVapidKey,
            serviceWorkerRegistration: workerRegistration,
          });
        }
        return currentToken || false;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
    return false;
  };

  // listens on every message including foreground message and background message of type notification-clicked
  let onMessageEventListener = null;
  const onMessageListener = (callback) => {
    if (isServiceWorkerSupported() && !isMobileApp()) {
      onMessageEventListener = (event) => {
        callback(event.data);
      };
      navigator.serviceWorker.addEventListener(
        'message',
        onMessageEventListener,
      );
    }
  };

  const removeOnMessageListener = () => {
    if (
      onMessageEventListener &&
      isServiceWorkerSupported() &&
      !isMobileApp()
    ) {
      navigator.serviceWorker.removeEventListener(
        'message',
        onMessageEventListener,
      );
      onMessageEventListener = null; // Reset the reference
    }
  };

  // listens on foreground messages of type push-received only
  let onForegroundMessageEventListener = null;
  const onForegroundMessageListener = (callback) => {
    if (isServiceWorkerSupported() && !isMobileApp()) {
      onForegroundMessageEventListener = (event) => {
        if (event.data.messageType === 'push-received') {
          callback(event.data);
        }
      };
      navigator.serviceWorker.addEventListener(
        'message',
        onForegroundMessageEventListener,
      );
    }
  };
  const removeOnForegroundMessageListener = () => {
    if (
      onForegroundMessageEventListener &&
      isServiceWorkerSupported() &&
      !isMobileApp()
    ) {
      navigator.serviceWorker.removeEventListener(
        'message',
        onForegroundMessageEventListener,
      );
      onForegroundMessageEventListener = null;
    }
  };

  return {
    setupFirebase,
    onForegroundMessageListener,
    onMessageListener,
    removeOnMessageListener,
    removeOnForegroundMessageListener,
  };
};

export default FirebaseClient;
