import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter, useLocation} from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import {ListItem} from '@mui/material';

import {overrideComponent} from '../../../types/common';

const SecondNavListLink = (props) => {
  const {pathname} = useLocation();
  const {
    to,
    component,
    relative,
    match,
    children,
    bottom,
    onSubNavOpen,
    onMainNavItemClick,
    isHovered,
    onSubNavClose,
    mouseEnter,
    mouseLeave,
    customIsActiveMatcher,
  } = props;
  const formatPath = (path) => (relative ? `${match.url}${path}` : path);
  const hasSubItems = Array.isArray(to);
  let incomingTo = to;
  let actualTo;

  if (hasSubItems) {
    incomingTo = incomingTo.map((path) => {
      if (!isEmpty(path) && !path.startsWith('/')) {
        return `/${path}`;
      }
      return path;
    });
  } else if (!isEmpty(incomingTo) && !incomingTo.startsWith('/')) {
    incomingTo = `/${to}`;
  }

  if (hasSubItems) {
    actualTo = incomingTo.map((path) => {
      return formatPath(path);
    });
  } else {
    actualTo = formatPath(incomingTo);
  }

  const isActive = () => {
    if (hasSubItems) {
      return actualTo.some((v) =>
        customIsActiveMatcher
          ? customIsActiveMatcher(v, pathname)
          : v === pathname,
      );
    }
    const subRouteMatches = () => {
      const subRootPathname = pathname.split('/')[2];
      const subRootActualTo = actualTo.split('/')[2];
      return subRootPathname === subRootActualTo;
    };

    return actualTo === pathname || subRouteMatches(pathname, actualTo);
  };

  const listItemStyles = (theme) => {
    let style = {
      color: theme.palette.grey[800],
      cursor: 'pointer',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: theme.palette.primary.hover,
      },
      ...(isHovered && {
        backgroundColor: theme.palette.primary.hover,
      }),
    };

    if (bottom) {
      style = {
        ...style,
        justifyContent: 'center',
        height: 'inherit',
        borderTop: '3px solid transparent',
        paddingBottom: 0,
        paddingX: 1,
      };

      return isActive()
        ? {
            ...style,
            borderTop: `3px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.primary.lightest,
            '& > div > span': {
              color: theme.palette.primary.main,
              fontWeight: '500',
            },
            '& > div > div': {
              color: theme.palette.primary.main,
            },
          }
        : style;
    }

    return isActive()
      ? {
          ...style,
          borderLeft: `3px solid ${theme.palette.primary.main}`,
          backgroundColor: isHovered
            ? theme.palette.primary.hover
            : theme.palette.primary.lightest,
          height: theme.spacing(7),
          '& > div > div > span': {
            color: theme.palette.primary.main,
            fontWeight: '500',
          },
          '& > div > div ': {
            color: theme.palette.primary.main,
          },
          '& >  div > div > p': {
            color: theme.palette.primary.main,
          },
          '& > svg': {
            color: theme.palette.primary.main,
          },
        }
      : {
          ...style,
          fontWeight: '400',
          borderLeft: '3px solid transparent',
          height: theme.spacing(7),
          '& > svg': {
            color: theme.palette.grey[600],
          },
        };
  };

  const handleOnMouseEnter = () =>
    hasSubItems ? mouseEnter() : onSubNavClose();

  const handleOnClickBottom = () => {
    onSubNavClose();

    if (hasSubItems) {
      onSubNavOpen();
    }
  };

  return (
    <ListItem
      data-cy="second-navigation-item"
      {...!bottom && {
        onMouseOver: onSubNavOpen,
        onMouseEnter: handleOnMouseEnter,
        onMouseLeave: () => hasSubItems && mouseLeave(),
      }}
      onClick={bottom ? handleOnClickBottom : onMainNavItemClick}
      to={hasSubItems ? to.toString() : actualTo}
      component={component}
      sx={listItemStyles}
    >
      {children}
    </ListItem>
  );
};

SecondNavListLink.propTypes = {
  /**
   * Node component to render navigation link
   */
  component: overrideComponent,
  /**
   * Node from parent to render
   */
  children: PropTypes.node.isRequired,
  /**
   * Determines whether the path is relative or not
   */
  relative: PropTypes.bool,
  /**
   * Path to redirect the page.
   * string - is for main item;
   * array of strings - is for item that contains sub items
   */
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  /**
   * Property from withRouter, inform about url
   */
  match: PropTypes.shape({url: PropTypes.string}),
  /**
   * Boolean for positioning menu in bottom, below medium viewport 720px.
   */
  bottom: PropTypes.bool,
  /**
   * Callback function for open menu item when sub items are present
   */
  onSubNavOpen: PropTypes.func,
  /**
   * Callback function handle on clicking the menu item
   */
  onMainNavItemClick: PropTypes.func,
  /**
   * Information if main item is hovered to apply styles
   */
  isHovered: PropTypes.bool,
  /**
   * Callback function for close menu item when sub items are present
   */
  onSubNavClose: PropTypes.func,
  /**
   * Callback function handle on mouse enter event
   */
  mouseEnter: PropTypes.func,
  /**
   * Callback function handle on mouse leave event
   */
  mouseLeave: PropTypes.func,
  /**
   * Callback function handle for custom path match to highlight active item
   */
  customIsActiveMatcher: PropTypes.func,
};

SecondNavListLink.defaultProps = {
  component: Link,
  relative: false,
  match: {url: ''},
  bottom: false,
  onSubNavOpen: null,
  onMainNavItemClick: null,
  isHovered: false,
  onSubNavClose: null,
  mouseEnter: null,
  mouseLeave: null,
  customIsActiveMatcher: null,
};
export default withRouter(SecondNavListLink);
