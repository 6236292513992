import React, {PureComponent} from 'react';
import {Switch, Route} from 'react-router-dom';
import memoize from 'memoize-one';
import {isEqual} from 'lodash';
import get from 'lodash/get';

import PageLoader from '../../shared/components/pageLoader';
import {getSite, getSites} from '../../api/sites';
import {getCameras} from '../../api/sensors';
import {
  getRootCategory,
  flattenSitesListForSearch,
} from '../../shared/util/sites';
import {
  getAppliance,
  getAppliancesByParams,
  getAppliancesForSite,
} from '../../api/appliances';
import {getLoginMessage} from '../../api/loginMessage';
import {
  getSiteCloudArchiveSettings,
  getAllSiteCloudArchiveSettings,
  getSiteCloudArchiveStatus,
} from '../../api/cloudArchive';
import CapabilityEnabledRoute from '../../shared/components/capabilityEnabledRoute';
import GlobalErrorPage from '../globalErrorPage';
import {settingsCapabilities} from './settings/index';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {withLogger} from '../../shared/components/logger';
import {getRegisters} from '../../api/registers';
import allowed, {
  CLOUD_ARCHIVE_ADMIN,
  CLOUD_ARCHIVE_USER,
} from '../../shared/util/allowed';
import Spinner from '../../shared/components/spinner';

const mergeCA2Enabled = (ca2SitesMap, siteTree) =>
  siteTree.map((node) => {
    if (node.children) {
      return {
        ...node,
        children: mergeCA2Enabled(ca2SitesMap, node.children),
      };
    }
    return {
      ...node,
      cloudArchiveEnabled: !!(
        (ca2SitesMap[node.id] && ca2SitesMap[node.id].enabled) ||
        node.cloudArchiveEnabled
      ),
    };
  });

const memoizedMerge = memoize((ca2Sites, siteTree) => {
  const ca2SitesMap = ca2Sites.reduce(
    (acc, {uuid, enabled}) => ({...acc, [uuid]: {enabled}}),
    {},
  );

  return {
    ...siteTree,
    children: mergeCA2Enabled(ca2SitesMap, siteTree.children),
  };
}, isEqual); // deep equality check needed for object parameters

class Sites extends PureComponent {
  state = {
    currentSite: '',
    filterValue: '',
    initialLoad: true,
    loginMessage: [],
    rootCategory: [],
    sites: [],
    siteSearchList: [],
  };

  async componentDidMount() {
    if (this.state.initialLoad) {
      const sites = await getSites();
      let category = sites;
      const ca2User = allowed(this.props.currentUser, [
        'CloudArchiveAdmin',
        'CloudArchiveUser',
      ]);
      if (ca2User && category.children) {
        const ca2Sites = await getAllSiteCloudArchiveSettings();
        category = memoizedMerge(ca2Sites, category);
      }
      const rootCategory = getRootCategory(category);

      this.setState({
        initialLoad: false,
        loginMessage: await getLoginMessage(),
        rootCategory,
        sites,
        siteSearchList: flattenSitesListForSearch(
          rootCategory,
          rootCategory.name,
        ),
      });
    }
  }

  setCurrentSite = (siteId) => {
    this.setState({currentSite: siteId});
  };

  setRouteState = (stateChanges) => {
    this.setState(stateChanges);
  };

  render() {
    const {match, currentUser} = this.props;

    const loaderProps = {
      ...this.state,
      setRouteState: this.setRouteState,
      forceToRoot: !!this.state.filterValue,
      setCurrentSite: this.setCurrentSite,
    };

    return this.state.initialLoad ? (
      <Spinner size={40} color="primary" />
    ) : (
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => (
            <PageLoader
              page={() => import('./list')}
              propsToIgnoreUpdate={['filterValue', 'forceToRoot']}
              {...loaderProps}
            />
          )}
        />
        <Route
          exact
          path={`${match.path}/category/:categoryId`}
          render={() => (
            <PageLoader
              page={() => import('./list')}
              propsToIgnoreUpdate={['filterValue', 'forceToRoot']}
              {...loaderProps}
            />
          )}
        />
        <CapabilityEnabledRoute
          capabilities={settingsCapabilities}
          path={`${match.path}/:siteId/settings`}
          renderDefault={<GlobalErrorPage />}
          render={(renderProps) => {
            const {siteId} = renderProps.match.params;
            return (
              <PageLoader
                page={() => import('./settings')}
                key="settings"
                resources={{
                  site: async () => getSite(siteId),
                  appliances: async () => {
                    const appliances = await getAppliancesForSite(siteId);
                    return Promise.all(
                      appliances.map(async (a) => ({
                        ...(await getAppliance(a.id)),
                        cameraDiscovery: a.cameraDiscovery,
                      })),
                    );
                  },
                }}
                propsToIgnoreUpdate={['match', 'location', 'forceToRoot']}
                {...renderProps}
                {...loaderProps}
                siteId={siteId}
              />
            );
          }}
        />
        <Route
          path={`${match.path}/:siteId`}
          render={(renderProps) => {
            const {siteId} = renderProps.match.params;
            const appliances = async () => {
              const fetchedAppliances = (await getAppliancesByParams({
                siteId,
                active: true,
              })).results;
              return Promise.all(
                fetchedAppliances.map(async (a) => ({
                  ...(await getAppliance(a.id)),
                  cameraDiscovery: a.cameraDiscovery,
                })),
              );
            };
            return (
              <PageLoader
                page={() => import('./details')}
                key="details"
                resources={{
                  site: async () => getSite(siteId),
                  cameras: async () => {
                    const cameras = await getCameras(siteId);
                    if (
                      allowed(currentUser, [
                        CLOUD_ARCHIVE_ADMIN,
                        CLOUD_ARCHIVE_USER,
                      ])
                    ) {
                      const cloudArchiveStatus = await getSiteCloudArchiveStatus(
                        siteId,
                      );
                      return cameras.map((c) => {
                        let cloudArchive = get(
                          cloudArchiveStatus,
                          `cameras.${c.id}`,
                        );
                        if (cloudArchive) {
                          // Get the high watermarks into the correct timezone
                          cloudArchive.highWatermark = cloudArchive.highWatermark.setZone(
                            c.timeZone,
                          );
                        } else {
                          cloudArchive = {};
                        }

                        return {
                          ...c,
                          cloudArchive,
                        };
                      });
                    }
                    return cameras;
                  },
                  registers: async () => getRegisters(),
                  appliances,
                  siteCloudArchiveEnabled: async () =>
                    getSiteCloudArchiveSettings(siteId),
                }}
                propsToIgnoreUpdate={[
                  'filterValue',
                  'match',
                  'location',
                  'forceToRoot',
                ]}
                {...renderProps}
                {...loaderProps}
              />
            );
          }}
        />
      </Switch>
    );
  }
}

export default withLogger(withCurrentUser(Sites));
