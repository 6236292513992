import {useEffect, useState, useCallback} from 'react';
import {getSiteAlarmStatus, updateSiteAlarmMode} from '../../../../api/alarms';

export const createAlarmModeState = {
  LOADING: () => ({state: 'LOADING'}),
  FETCHED: (siteStatus) => ({state: 'FETCHED', siteStatus}),
  SWITCHING: (targetStatus) => ({
    state: 'SWITCHING',
    siteStatus: targetStatus,
  }),
};

export const useAlarmMode = (siteId, alarmModeFetchTrigger) => {
  const [alarmModeState, setAlarmModeState] = useState(
    createAlarmModeState.LOADING(),
  );

  const fetchAlarmStatuses = useCallback(
    (abortSignal) => {
      setAlarmModeState(createAlarmModeState.LOADING());
      getSiteAlarmStatus(siteId, {signal: abortSignal})
        .then((siteStatus) =>
          setAlarmModeState(createAlarmModeState.FETCHED(siteStatus)),
        )
        .catch(() => {});
    },
    [siteId],
  );

  const updateAlarmStatus = useCallback(
    async (mode, onSuccess, onError) => {
      const targetStatus = {
        ...alarmModeState.siteStatus,
        statuses: alarmModeState.siteStatus.statuses.map((v) => ({
          ...v,
          isActive: v.status === mode,
        })),
      };
      setAlarmModeState(createAlarmModeState.SWITCHING(targetStatus));
      try {
        await updateSiteAlarmMode(siteId, mode);
        setAlarmModeState(createAlarmModeState.FETCHED(targetStatus));
        onSuccess();
      } catch (e) {
        onError();
        fetchAlarmStatuses();
      }
    },
    [siteId, alarmModeState.siteStatus, fetchAlarmStatuses],
  );

  useEffect(
    () => {
      const abortController = new AbortController();
      fetchAlarmStatuses(abortController.signal);

      return () => {
        abortController.abort();
      };
    },
    [fetchAlarmStatuses, alarmModeFetchTrigger],
  );

  return {alarmModeState, updateAlarmStatus};
};
