import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Tooltip as MUITooltip} from '@mui/material';
import {isIE11} from '../../util/browser';

const Tooltip = React.forwardRef((props, ref) =>
  isIE11 ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <MUITooltip
      disableFocusListener
      disableTouchListener
      enterDelay={300}
      leaveDelay={50}
      ref={ref}
      PopperProps={{disablePortal: true}}
      {...props}
      title={props.title || ''}
    />
  ),
);

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Tooltip.defaultProps = {
  title: '',
  children: null,
};

export default Tooltip;
