export const addHexidecimalPrefix = (str) =>
  str.substring(0, 3) === '0x0' ? str : `0x0${str}`;

export const removeHexidecimalPrefix = (str) =>
  str.substring(0, 3) === '0x0' ? str.substring(3) : str;

export const motionMaskToArray = (motionMaskString = '') => {
  const maskParts = motionMaskString.match(/.{1,5}/g);
  if (maskParts === null) throw new Error('Motion Mask Invalid');

  return maskParts.map((maskPart) => {
    const binaryPart = parseInt(maskPart, 16)
      .toString(2)
      .padStart(20, '0');
    return binaryPart.split('').map((binary) => binary === '1');
  });
};

export const motionArrayToMask = (motionMaskArray = []) =>
  motionMaskArray
    .map((row) => {
      const binary = row.map((block) => (block ? 1 : 0));
      const mask = parseInt(binary.join(''), 2)
        .toString(16)
        .padStart(5, '0');
      return mask;
    })
    .join('');
