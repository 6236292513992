import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {Paper, Typography} from '@mui/material';
import classNames from 'classnames';

const styles = (theme) => ({
  message: {textAlign: 'center'},
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: [[theme.spacing(1.3), theme.spacing(3)]],
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.background.paper,
    borderTop: [[1, 'solid', theme.palette.primary.main]],
  },
  infoLinks: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    '& a': {
      padding: [[0, theme.spacing(1)]],
      textDecoration: 'none',
    },
  },
});

const Footer = ({className, classes, buildDisplay}) => (
  <Paper elevation={12} className={classNames(className, classes.wrapper)}>
    <Typography variant="caption" color="primary" component="span">
      © Copyright {new Date().getFullYear()} Envysion
    </Typography>
    <Typography
      variant="caption"
      color="primary"
      component="span"
      className={classes.message}
    >
      {buildDisplay}
    </Typography>
    <div className={classes.infoLinks}>
      <Typography
        variant="caption"
        color="primary"
        component="a"
        href="https://www.envysion.com/"
        target="_about"
      >
        About
      </Typography>
      <Typography
        variant="caption"
        color="primary"
        component="a"
        href="https://www.envysion.com/privacy/"
        target="_privacy"
      >
        Privacy
      </Typography>
      <Typography
        variant="caption"
        color="primary"
        component="a"
        href="https://envysion.com/contact-us/"
      >
        Contact Us
      </Typography>
    </div>
  </Paper>
);

export default withStyles(styles)(Footer);
