import React, {Fragment, useState, useMemo} from 'react';
import classNames from 'classnames';
import omit from 'lodash/omit';
import makeStyles from '@mui/styles/makeStyles';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  DialogContentText,
} from '@mui/material';
import WeekSchedule from './weekSchedule';
import Spinner from '../../../../shared/components/spinner';
import {useDialog} from '../../../../shared/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  field: {
    padding: [[theme.spacing(1), 0]],
  },
}));

const ConfirmEditDialog = (props) => {
  const [saveAsNew, setSaveAsNew] = useState(false);
  const {
    sitesWithScheduleCount,
    formikProps: {handleChange, values, errors, isValid, setFieldValue},
    onCancel,
    onCreate,
    onUpdate,
  } = props;

  return (
    <Fragment>
      <DialogTitle>
        {saveAsNew ? 'New Schedule' : 'Schedule Update'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {saveAsNew ? (
            <Field name="name">
              {({field}) => (
                <TextField
                  variant="outlined"
                  id="schedule-config-name"
                  label="name"
                  name="name"
                  type="text"
                  value={values.name}
                  onChange={handleChange}
                  helperText={errors[field.name]}
                  error={!!errors[field.name]}
                />
              )}
            </Field>
          ) : (
            <Typography color="error">
              This schedule is applied to {sitesWithScheduleCount} sites.
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button name="update-confirm-cancel" variant="text" onClick={onCancel}>
          Cancel
        </Button>
        {!saveAsNew && (
          <Button
            name="update-confirm-save-as-new"
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => {
              setSaveAsNew(true);
              setFieldValue('saveAsNew', true);
            }}
          >
            Save As New
          </Button>
        )}
        <Button
          name="update-confirm-save"
          color="primary"
          variant="contained"
          type="submit"
          disabled={!isValid}
          onClick={async () => {
            if (saveAsNew) {
              await onCreate();
            } else {
              await onUpdate();
            }
          }}
        >
          {saveAsNew ? 'Save' : 'Update'}
        </Button>
      </DialogActions>
    </Fragment>
  );
};

const ScheduleConfiguration = (props) => {
  const {className} = props;
  const classes = useStyles(props);
  const [
    confirmEditOpen,
    handleOpenConfirmEdit,
    handleCloseConfirmEdit,
  ] = useDialog();

  const {
    schedule,
    onCreate,
    onUpdate,
    onCancel,
    CardProps = {},
    sitesWithScheduleCount = 0,
    existingSchedules,
  } = props;

  const [scheduleError, setScheduleError] = useState(false);

  const currentUpdateNames = useMemo(
    () =>
      existingSchedules
        .filter(({id}) => id !== schedule.id)
        .map(({name}) => name),
    [existingSchedules, schedule],
  );

  const allNames = useMemo(() => existingSchedules.map(({name}) => name), [
    existingSchedules,
  ]);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Schedule name is required')
      .min(5, 'Must be 5 characters or more')
      .max(128, 'Must be 128 characters or less')
      // eslint-disable-next-line func-names
      .test('existingName', 'Schedule name is currently in use', function(
        value,
      ) {
        // eslint-disable-next-line react/no-this-in-sfc
        return !(this.parent.saveAsNew
          ? allNames
          : currentUpdateNames
        ).includes(value);
      }),
    notes: Yup.string()
      .nullable()
      .max(255, 'Must be 255 characters or less'),
    schedule: Yup.object().shape({
      weekly: Yup.array().test(
        'scheduleValues',
        'Schedule needs a time selection',
        (value) =>
          value.some(
            (scheduleString) => scheduleString && scheduleString !== '',
          ),
      ),
    }),
  });

  return (
    <Card {...CardProps}>
      <CardContent>
        <div
          className={classNames(classes.root, className)}
          name="schedule-config-form"
        >
          <Formik
            status={{canceled: false}}
            initialValues={schedule}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnChange
            onSubmit={async (values, {setSubmitting}) => {
              setSubmitting(true);
              if (schedule.id && sitesWithScheduleCount > 1) {
                // need to show confirm edit dialog
                handleOpenConfirmEdit();
              } else if (schedule.id) {
                await onUpdate(values);
              } else {
                await onCreate(values);
              }
            }}
          >
            {(formikProps) => {
              const {
                errors,
                isValid,
                isSubmitting,
                handleSubmit,
                dirty,
                setSubmitting,
              } = formikProps;
              return (
                <Fragment>
                  {isSubmitting ? (
                    <Spinner size={40} color="primary" />
                  ) : (
                    <Form className={classes.form}>
                      <Field name="name" className={classes.field}>
                        {({field}) => (
                          <TextField
                            className={classes.field}
                            variant="standard"
                            id="schedule-config-name"
                            name={field.name}
                            label="Schedule Name"
                            value={field.value}
                            onChange={field.onChange}
                            autoFocus
                            fullWidth
                            helperText={errors[field.name]}
                            error={!!errors[field.name]}
                          />
                        )}
                      </Field>
                      <Field name="schedule.weekly" className={classes.field}>
                        {({field, form}) => (
                          <WeekSchedule
                            name="schedule-config-weekly"
                            className={classes.field}
                            schedule={field.value}
                            onChange={(sch) => {
                              setScheduleError(sch.error);
                              form.setFieldValue(field.name, sch.value);
                            }}
                          />
                        )}
                      </Field>
                      <Field name="notes" classname={classes.field}>
                        {({field}) => (
                          <TextField
                            className={classes.field}
                            variant="outlined"
                            id="schedule-config-notes"
                            name={field.name}
                            label="Notes"
                            value={field.value || ''}
                            fullWidth
                            multiline
                            minRows={4}
                            onChange={field.onChange}
                            helperText={errors[field.name]}
                            error={!!errors[field.name]}
                          />
                        )}
                      </Field>
                      <CardActions className={classes.actions}>
                        <Button
                          name="schedule-config-cancel"
                          variant="text"
                          onClick={onCancel}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          name="schedule-config-save"
                          variant="contained"
                          color="primary"
                          disabled={!isValid || !dirty || scheduleError}
                          onClick={handleSubmit}
                        >
                          Save
                        </Button>
                      </CardActions>
                    </Form>
                  )}
                  <Dialog
                    id="update-confirm-modal"
                    open={confirmEditOpen}
                    maxWidth="md"
                    onClose={handleCloseConfirmEdit}
                    TransitionProps={{
                      onEnter: () => setSubmitting(false),
                    }}
                    keepMounted={false}
                  >
                    {!isSubmitting && (
                      <ConfirmEditDialog
                        formikProps={formikProps}
                        sitesWithScheduleCount={sitesWithScheduleCount}
                        onCancel={handleCloseConfirmEdit}
                        onCreate={async () => {
                          setSubmitting(true);
                          await onCreate(
                            omit(formikProps.values, 'id', 'saveAsNew'),
                          );
                          setSubmitting(false);
                          handleCloseConfirmEdit();
                        }}
                        onUpdate={async () => {
                          setSubmitting(true);
                          await onUpdate(omit(formikProps.values, 'saveAsNew'));
                          setSubmitting(false);
                          handleCloseConfirmEdit();
                        }}
                      />
                    )}
                  </Dialog>
                </Fragment>
              );
            }}
          </Formik>
        </div>
      </CardContent>
    </Card>
  );
};

ScheduleConfiguration.defaultProps = {
  schedule: {
    name: '',
    notes: null,
    schedule: {
      weekly: [],
    },
  },
};

export default ScheduleConfiguration;
