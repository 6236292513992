import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {
  getPreferenceById,
  getPreferences,
  getUsersWithPreference,
  getDomainsWithPreference,
} from '../../../api/preferences';
import {getDomains} from '../../../api/domains';
import PageLoader from '../../../shared/components/pageLoader';
import {PREFERENCE_ADMIN} from '../../../shared/util/allowed';
import GlobalErrorPage from '../../globalErrorPage';
import CapabilitiesGuard from '../../../shared/components/capabilitiesGuard';

function PreferenceRoutes({match}) {
  return (
    <CapabilitiesGuard
      allowed={[PREFERENCE_ADMIN]}
      renderDefault={() => <GlobalErrorPage error="NoAuth" />}
    >
      <Switch>
        <Route
          path={`${match.path}/:id`}
          render={(renderProps) => (
            <PageLoader
              page={() => import('./Details')}
              resources={{
                preference: () =>
                  getPreferenceById(renderProps.match.params.id),
                users: () =>
                  getUsersWithPreference(renderProps.match.params.id),
                domains: () =>
                  getDomainsWithPreference(renderProps.match.params.id),
                allDomains: getDomains,
              }}
              {...renderProps}
            />
          )}
        />
        <Route
          render={(renderProps) => (
            <PageLoader
              page={() => import('./List')}
              resources={{
                preferences: getPreferences,
              }}
              {...renderProps}
            />
          )}
        />
      </Switch>
    </CapabilitiesGuard>
  );
}

export default PreferenceRoutes;
