import axios from 'axios';

export const getWidgetsByDashboard = async (dashboardId) => {
  const url = `dashboard/${dashboardId}/widgets`;
  const res = await axios.get(url, {limit: 100});
  return res?.data;
};

export const updateWidget = async (widgetRef, dashboardId, enabled) => {
  const url = `dashboards/${dashboardId}/widgets/${widgetRef}`;
  const res = await axios.put(url, {
    enabled,
  });
  return res;
};
