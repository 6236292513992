import axios from 'axios';
import {DateTime} from 'luxon';
import mapValues from 'lodash/mapValues';

/*
 * We need to create an instance to not use these interceptors
 * because the transcode settings object are expected to be
 * camel case instead of snake case.
 */
const instance = axios.create();
instance.interceptors.request.eject(
  instance.interceptorIds?.requestCasingInterceptor,
);
instance.interceptors.response.eject(
  instance.interceptorIds?.responseCasingInterceptor,
);

export const patchCloudArchiveCamera = async (siteId, cameraId, set, unset) =>
  (await axios.patch(`/cloud_archive/sites/${siteId}/cameras/${cameraId}`, {
    set,
    unset,
  })).data;

// DEPRECATED
export const updateEnabledArchiveCameras = async (siteId, cameraIds) =>
  (await axios.put(`/cloud_archive/sites/${siteId}/cameras`, {
    camera_ids: cameraIds,
  })).data;

export const getSiteCloudArchiveSettings = async (siteId) => {
  try {
    const res = await axios.get(`/cloud_archive/sites/${siteId}`);
    return res.data;
  } catch (e) {
    if (e.response.status === 404) return {enabled: false};
    throw e;
  }
};

export const getAllSiteCloudArchiveSettings = async (includeStatuses = false) =>
  (await axios.get('cloud_archive/sites', {
    params: {includeStatuses: includeStatuses ? 1 : undefined},
  })).data;

export const getSiteCloudArchiveStatus = async (siteId) => {
  try {
    const res = await axios.get(`/cloud_archive/sites/${siteId}/status`);

    const {data} = res;
    data.cameras = mapValues(data.cameras, (v) => ({
      ...v,
      highWatermark: DateTime.fromISO(v.highWatermark),
    }));
    return data;
  } catch (e) {
    if (e.response.status === 404) return undefined;
    throw e;
  }
};

export const getSiteCloudArchiveHealth = async (siteId) => {
  try {
    const res = await axios.get(`/cloud_archive/sites/${siteId}/health`);

    const {data} = res;
    data.cameras = mapValues(data.cameras, (v) => ({
      ...v,
      highWatermark: DateTime.fromISO(v.highWatermark),
    }));
    return data;
  } catch (e) {
    if (e.response.status === 404) return undefined;
    throw e;
  }
};

export const getCloudArchiveStatusCount = async () =>
  (await axios.get('cloud_archive/status_count')).data;
