import React from 'react';
import {Route, Switch} from 'react-router-dom';

import {getDomains} from '../../../api/domains';
import PageLoader from '../../../shared/components/pageLoader';

function DomainRoutes({match}) {
  return (
    <Switch>
      <Route
        path={`${match.path}/domains`}
        render={(renderProps) => (
          <PageLoader
            page={() => import('./DomainList')}
            resources={{
              domains: getDomains,
            }}
            {...renderProps}
          />
        )}
      />
    </Switch>
  );
}

export default DomainRoutes;
