import React, {Fragment} from 'react';
import {ListItemIcon, ListItemText, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import WarningIcon from '@mui/icons-material/Warning';
import {NavLink} from 'react-router-dom';
import classNames from 'classnames';

import ListItemLink from '../../../shared/components/listItemLink';
import Tooltip from '../../../shared/components/tooltip';

const styles = (theme) => ({
  highlighted: {
    backgroundColor: theme.palette.action.hover,
  },
  alert: {
    color: theme.palette.error.main,
  },
  alertIcon: {
    marginRight: 0,
    justifySelf: 'flex-end',
  },
});

const NavLinkListItem = ({
  classes,
  primaryText,
  secondaryText,
  showAlert,
  icon,
  to,
  mini,
  ...rest
}) => {
  const collapsedAlert = mini && showAlert;
  const renderLink = React.useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      React.forwardRef((itemProps, ref) => (
        <NavLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  const listIcon = (
    <ListItemIcon
      name={`${primaryText}-icon`}
      className={classNames({
        [classes.alert]: collapsedAlert,
      })}
    >
      {icon}
    </ListItemIcon>
  );

  return (
    <ListItemLink
      to={to}
      relative
      button
      exact
      component={renderLink}
      activeClassName={classes.highlighted}
      {...rest}
    >
      {mini ? (
        <Tooltip
          placement="right"
          title={
            <Fragment>
              <Typography color="inherit">{primaryText}</Typography>
              <Typography color="inherit">{secondaryText}</Typography>
            </Fragment>
          }
        >
          {listIcon}
        </Tooltip>
      ) : (
        listIcon
      )}
      <ListItemText
        primary={primaryText}
        secondary={mini ? undefined : secondaryText}
      />
      {Boolean(showAlert) && (
        <ListItemIcon>
          <WarningIcon
            name="alert"
            className={classNames(classes.alert, classes.alertIcon)}
          />
        </ListItemIcon>
      )}
    </ListItemLink>
  );
};

export default withStyles(styles)(NavLinkListItem);
