import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';

export const RedirectWithQueryParams = ({to}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return (
    <Redirect
      to={{
        pathname: to,
        search: searchParams.toString(),
      }}
    />
  );
};

RedirectWithQueryParams.propTypes = {
  to: PropTypes.string.isRequired,
};
