import React, {useEffect, useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import ViewListIcon from '@mui/icons-material/ViewList';
import makeStyles from '@mui/styles/makeStyles';
import WeekSchedule from './weekSchedule';
import SelectList from '../../../../shared/components/selectList';
import Spinner from '../../../../shared/components/spinner';
import SectionTitleBar from '../SectionTitleBar';
import {
  createSiteAlarmSchedule,
  getAlarmSchedule,
  updateAlarmSchedule,
  updateSiteAlarmSchedule,
} from '../../../../api/alarms';
import ScheduleConfigurationDialog from './ScheduleConfigurationDialog';

const useStyles = makeStyles((theme) => ({
  siteSectionHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    justifyContent: 'space-between',
  },
  siteSectionHeaderText: {
    paddingLeft: theme.spacing(1),
  },
  siteSectionTitle: {
    display: 'flex',
  },
  scheduleSelect: {
    width: '80%',
    paddingBottom: theme.spacing(3),
  },
  siteScheduleEdit: {
    cursor: 'pointer',
  },
}));

const ScheduleConfigurationSection = (props) => {
  const classes = useStyles(props);
  const {
    schedules,
    selectedSite,
    domainSchedules,
    existingSiteSchedule,
    setExistingSiteSchedule,
    snackbar,
    schedulesLoaded,
    onSchedulesRefresh,
    schedulesConfigurationOpen,
    onSchedulesConfigurationOpen,
    onSchedulesConfigurationClose,
  } = props;

  const [loadingSiteSchedule, setLoadingSiteSchedule] = useState(false);

  useEffect(
    () => {
      const getExistingSiteSchedule = async () => {
        if (existingSiteSchedule) return;
        if (!schedulesLoaded) {
          setLoadingSiteSchedule(true);
          return;
        }

        const existingSchedule = domainSchedules.find(
          (sch) => sch.siteId === selectedSite.id,
        );

        if (existingSchedule) {
          try {
            setLoadingSiteSchedule(true);
            const schedule = await getAlarmSchedule(existingSchedule.id);
            setExistingSiteSchedule(schedule);
          } catch (e) {
            snackbar.error(
              'Unable to get site schedule. Please try again or contact support if the issue persists.',
            );
          }
        }
        setLoadingSiteSchedule(false);
      };
      getExistingSiteSchedule();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSite, domainSchedules],
  );

  const handleRightPanelScheduleUpdate = async (site, scheduleId) => {
    const initialSchedule = existingSiteSchedule && {...existingSiteSchedule};
    try {
      setExistingSiteSchedule(null);
      setLoadingSiteSchedule(true);
      await updateSiteAlarmSchedule(site.id, scheduleId);
      const schedule = await getAlarmSchedule(scheduleId);
      setExistingSiteSchedule(schedule);
      await onSchedulesRefresh();
      snackbar.success('Site schedule updated successfully.');
    } catch (e) {
      setExistingSiteSchedule(initialSchedule);
      snackbar.error(
        'Unable to update site schedule. Please try again or contact support if the issue persists.',
      );
    } finally {
      setLoadingSiteSchedule(false);
    }
  };

  const handleCreateSiteSchedule = async (schedule) => {
    try {
      const {id} = await createSiteAlarmSchedule(selectedSite.id, schedule);
      const newSchedule = {id, ...schedule};
      snackbar.success('Site schedule created successfully.');

      await onSchedulesRefresh();
      setExistingSiteSchedule(newSchedule);
    } catch (e) {
      snackbar.error(
        'Unable to create site schedule. Please try again or contact support if the issue persists.',
      );
    } finally {
      onSchedulesConfigurationClose();
    }
  };

  const handleUpdateSiteSchedule = async (schedule) => {
    try {
      await updateAlarmSchedule(schedule.id, omit(schedule, 'id'));
      snackbar.success('Schedule updated successfully.');

      await onSchedulesRefresh();
      setExistingSiteSchedule(schedule);
      onSchedulesConfigurationClose();
    } catch (e) {
      snackbar.error(
        'Unable to update schedule. Please try again or contact support if the issue persists.',
      );
    }
  };

  const sitesWithScheduleCount = useMemo(
    () =>
      domainSchedules.reduce(
        (count, sch) => count + (sch.id === existingSiteSchedule?.id ? 1 : 0),
        0,
      ),
    [domainSchedules, existingSiteSchedule],
  );

  return (
    <div name="site-schedule">
      <SectionTitleBar
        title="Schedules"
        onClick={onSchedulesConfigurationOpen}
        icon={<ViewListIcon />}
        isActionIconVisible={!loadingSiteSchedule && !!existingSiteSchedule}
      />
      <SelectList
        className={classes.scheduleSelect}
        id="site-schedule-select"
        placeholder="Select a schedule"
        value={existingSiteSchedule}
        options={[
          {name: 'Create a new schedule', action: 'create'},
          ...schedules,
        ]}
        hideSelectedOptions
        getOptionLabel={({name}) => name}
        getOptionValue={({name}) => name}
        onChange={({action, id}) => {
          if (action === 'create') {
            setExistingSiteSchedule(null);
            onSchedulesConfigurationOpen();
          } else {
            handleRightPanelScheduleUpdate(selectedSite, id);
          }
        }}
      />
      {loadingSiteSchedule && (
        <Spinner name="loading-spinner" size={40} color="primary" />
      )}
      {existingSiteSchedule?.name &&
        (!schedulesConfigurationOpen && (
          <WeekSchedule
            schedule={existingSiteSchedule?.schedule?.weekly}
            editable={false}
          />
        ))}
      <ScheduleConfigurationDialog
        schedules={domainSchedules}
        schedule={existingSiteSchedule}
        schedulesConfigurationOpen={schedulesConfigurationOpen}
        onSchedulesConfigurationClose={onSchedulesConfigurationClose}
        onCreateScheduleConfiguration={handleCreateSiteSchedule}
        onUpdateScheduleConfiguration={handleUpdateSiteSchedule}
        sitesWithScheduleCount={sitesWithScheduleCount}
      />
    </div>
  );
};

ScheduleConfigurationSection.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSite: PropTypes.shape({}).isRequired,
  domainSchedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setExistingSiteSchedule: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({}).isRequired,
  schedulesLoaded: PropTypes.bool.isRequired,
  onSchedulesRefresh: PropTypes.func.isRequired,
  schedulesConfigurationOpen: PropTypes.bool.isRequired,
  onSchedulesConfigurationOpen: PropTypes.func.isRequired,
  onSchedulesConfigurationClose: PropTypes.func.isRequired,
  existingSiteSchedule: PropTypes.shape({}),
};

ScheduleConfigurationSection.defaultProps = {
  existingSiteSchedule: null,
};

export default ScheduleConfigurationSection;
