import React, {useEffect} from 'react';
import RiskLevelTableWidget from './RiskLevelTableWidget';
import {getRiskiestSites} from '../../../../api/reports';
import {useApi} from '../../../../shared/hooks';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const SiteRiskWidget = (props) => {
  const {widgetConfig, currentUser} = props;
  let mappedResults = [];
  const [data, isLoading, isError, call] = useApi(getRiskiestSites);

  useEffect(
    () => {
      call();
    },
    [call],
  );

  if (data) {
    mappedResults = data.map((s) => ({
      id: s.siteId,
      name: s.siteName,
      riskLevel: s.siteRiskLevel,
      url: s.reportUrl,
    }));
  }

  const getContent = () => {
    return (
      <RiskLevelTableWidget
        title="Sites"
        header="Site Name"
        listItems={mappedResults}
        loading={isLoading}
        error={isError}
        name="site-risk-widget"
        errorMessage="Error loading site risk snapshot."
        currentUser={currentUser}
      />
    );
  };

  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      loading={isLoading}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(SiteRiskWidget);
