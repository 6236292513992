import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloudArchiveModal = ({cameras}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '10%',
    marginLeft: '5%',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
  };

  const mobileButtonStyle = {
    fontSize: '0.82rem',
    padding: 0,
    margin: '0 4px',
  };

  const CameraList = () => {
    const warningCameras = [];
    const errorCameras = [];
    const unknownCameras = [];
    if (cameras) {
      cameras.forEach((camera) => {
        if (camera.cameraCaStatus === 'ERROR') {
          errorCameras.push(camera.cameraName);
        }
        if (camera.cameraCaStatus === 'WARNING') {
          warningCameras.push(camera.cameraName);
        }
        if (camera.cameraCaStatus === 'UNKNOWN') {
          unknownCameras.push(camera.cameraName);
        }
      });
    }
    return (
      <Box>
        {errorCameras.length > 0 && (
          <Box>
            <Typography>Error Status:</Typography>
            <List
              sx={{flexDirection: 'column'}}
              className="ca-cameras-modal-error-column"
            >
              {errorCameras.map((cameraName) => (
                <ListItem sx={{py: 0.5}} key={cameraName}>
                  <ListItemText primary={cameraName} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {warningCameras.length > 0 && (
          <Box>
            <Typography>Warning Status:</Typography>
            <List
              sx={{flexDirection: 'column'}}
              className="ca-cameras-modal-warning-column"
            >
              {warningCameras.map((cameraName) => (
                <ListItem sx={{py: 0.5}} key={cameraName}>
                  <ListItemText primary={cameraName} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
        {unknownCameras.length > 0 && (
          <Box>
            <Typography>Unknown Status:</Typography>
            <List
              sx={{flexDirection: 'column'}}
              className="ca-cameras-modal-unknown-column"
            >
              {unknownCameras.map((cameraName) => (
                <ListItem sx={{py: 0.5}} key={cameraName}>
                  <ListItemText primary={cameraName} />
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Button
        data-cy="ca-cameras-btn"
        sx={mobileButtonStyle}
        variant="text"
        onClick={handleOpen}
      >
        CA Status
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="ca-cameras-modal">
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              className="ca-cameras-modal-title"
              variant="h6"
              sx={{p: 1}}
            >
              Cloud Archive Status
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CameraList />
        </Box>
      </Modal>
    </Box>
  );
};
export default CloudArchiveModal;
