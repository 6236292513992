// import {detect} from 'detect-browser';
import Bowser from 'bowser';

export const browser = Bowser.getParser(window.navigator.userAgent);

// iPadOS is to be treated as desktop (not mobile)
export const isIpadOS =
  browser.getPlatformType() === 'tablet' &&
  browser.getPlatform().model === 'iPad' &&
  parseInt(browser.getOS().version, 10) === 13;

export const isMobile =
  (browser.getPlatformType() === 'mobile' ||
    browser.getPlatformType() === 'tablet') &&
  browser.getOSName() !== 'Windows' &&
  !isIpadOS;

export const isSafari = browser.getBrowser().name === 'Safari';

export const isIE = browser.getBrowser().name === 'Internet Explorer';

export const isIE11 = browser.satisfies({'internet explorer': '11'});

export const isWin7 =
  browser.getOSName() === 'Windows' &&
  parseInt(browser.getOS().versionName, 10) === 7; // use versionName as version is 'NT 6.1'

export const isEdge = browser.getBrowser().name === 'Microsoft Edge';
