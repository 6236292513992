/* eslint-disable react/forbid-foreign-prop-types */
import React from 'react';
import loadable from '@loadable/component';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import DeepDiff from 'deep-diff';

import LoadingScreen from '../loadingScreen';
import LoadingComponent from '../loadingComponent';

// We are extracting the prop types here because this is a
// special case component. In order for the ignore prop types
// functionality to work correctly, we need to know what props
// are expected of this component and filter those out as well.
const componentPropTypes = {
  propsToIgnoreUpdate: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.func.isRequired,
  resources: PropTypes.objectOf(PropTypes.func),
  loadedToPageProps: PropTypes.func,
  isLoadingComponent: PropTypes.bool,
};

class PageLoader extends React.Component {
  state = {
    reload: new Date(),
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.reload !== this.state.reload) {
      return true;
    }

    const ignoreProps = this.props.propsToIgnoreUpdate.concat(
      Object.keys(componentPropTypes),
    );

    const diff = DeepDiff.diff(this.props, nextProps, (path, key) => {
      const toCheck = path.length === 0 ? key : `${path.join('.')}.${key}`;
      return ignoreProps.find((p) => toCheck.startsWith(p));
    });
    return !!diff;
  }

  reload = () => {
    this.setState({
      reload: new Date(),
    });
  };

  errorHandler = (isLoadingComponent, error, props) => {
    const ErrorComponent = isLoadingComponent ? (
      <LoadingComponent error={error} {...props} />
    ) : (
      <LoadingScreen error={error} {...props} />
    );
    return ErrorComponent;
  };

  render() {
    const {
      resources,
      page,
      propsToIgnoreUpdate,
      loadedToPageProps,
      isLoadingComponent,
      ...rest
    } = this.props;

    const {reload} = this;

    // Use the correct loading component
    const fallback = this.errorHandler(isLoadingComponent, undefined, rest);

    const Component = loadable(
      async () => {
        const loaders = {
          Page: page,
          ...resources,
        };

        let loaded;

        try {
          // Load all resources in parallel
          loaded = await Promise.all(
            Object.entries(loaders).map(async ([resourceName, loader]) => {
              const resourceData = await loader();
              return {
                // Store the component or data result by its name
                [resourceName]: resourceData?.default ?? resourceData,
              };
            }),
          );

          // Merge all resource objects into one
          loaded = Object.assign({}, ...loaded);
        } catch (error) {
          // If a resource throws an error, send it to the correct loading component
          return (props) => this.errorHandler(isLoadingComponent, error, props);
        }

        // Render the async page with all its resolved async resources
        const LoadablePage = (props) => {
          const {Page} = loaded;
          const loadedProps = omit(loaded, 'Page');
          return (
            <Page
              {...props}
              {...loadedToPageProps(loadedProps)}
              reload={reload}
            />
          );
        };

        return LoadablePage;
      },
      {
        fallback,
      },
    );

    return <Component {...rest} />;
  }
}

PageLoader.propTypes = componentPropTypes;

PageLoader.defaultProps = {
  resources: {},
  propsToIgnoreUpdate: [],
  loadedToPageProps: (loadedProps) => loadedProps,
  isLoadingComponent: false,
};

export default PageLoader;
