import React from 'react';

import {MOTION_ALARM_ADMIN, MOTION_ALARM_USER} from '../../shared/util/allowed';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import SmartSitePageResponsive from './SmartSitePageResponsive';

const SmartSiteProtection = (props) => {
  if (props.currentUser.settings.enableSspStatusWebPage) {
    return (
      <CapabilitiesGuard
        allowed={[MOTION_ALARM_ADMIN, MOTION_ALARM_USER]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <SmartSitePageResponsive currentUser={props.currentUser} />
      </CapabilitiesGuard>
    );
  }
  return <GlobalErrorPage />;
};

export default withCurrentUser(SmartSiteProtection);
