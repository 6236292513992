import React, {Fragment, useState, createContext} from 'react';
import {Alert, AlertTitle, Box, Button, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {wrapDisplayName} from '../../util/hocSupport';

export const FirebaseNotificationsContext = createContext();
export const withFirebaseNotifications = (Component) => {
  const FirebaseNotificationsComponent = (props) => {
    const [notificationAlert, setNotificationAlert] = useState(null);

    const redirect = () => {
      const {alarmId, siteId} = notificationAlert;
      setNotificationAlert(null);
      return alarmId && siteId
        ? (window.location.href = `/ui/sites/${siteId}/alarmEventsSummary?alarmId=${alarmId}`)
        : (window.location.href = '/ui/sites/');
    };

    return (
      <Fragment>
        {notificationAlert && (
          <Alert
            sx={{position: 'absolute', zIndex: 100000, width: '100%'}}
            severity="error"
            variant="filled"
            action={
              <Box sx={{display: 'flex', gap: 1}}>
                <Button color="inherit" variant="outlined" onClick={redirect}>
                  Review
                </Button>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setNotificationAlert(null)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Box>
            }
          >
            <AlertTitle>
              {notificationAlert?.title || 'Alarm detected'}
            </AlertTitle>
            {notificationAlert?.body || 'Incident happened'}
          </Alert>
        )}
        <FirebaseNotificationsContext.Consumer>
          {(firebaseClient) => (
            <Component
              {...props}
              firebaseClient={firebaseClient}
              setNotificationAlert={setNotificationAlert}
            />
          )}
        </FirebaseNotificationsContext.Consumer>
      </Fragment>
    );
  };

  FirebaseNotificationsComponent.displayName = wrapDisplayName(
    'WithFirebaseNotifications',
    Component,
  );

  return FirebaseNotificationsComponent;
};
