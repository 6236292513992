import React from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import {FormControl} from '@mui/material';
import DateTimePicker from '../dateTimePicker';
import LabelWithTooltip from '../labelWithTooltip';

const FormDateTime = ({fieldDef, formProps, classes, focused}) => {
  const {
    name,
    label,
    helperText,
    tooltip,
    onChange,
    ComponentProps = {},
    validation,
  } = fieldDef;

  const {setFieldTouched, setValues, errors, touched, values} = formProps;
  const required = get(validation, '_exclusive.required', false);

  let {shrink} = ComponentProps;
  if (tooltip) {
    shrink = true;
  }

  return (
    <FormControl>
      <DateTimePicker
        name={name}
        label={
          tooltip ? <LabelWithTooltip label={label} tooltip={tooltip} /> : label
        }
        initialValue={values[name]}
        helperText={touched[name] && errors[name] ? errors[name] : helperText}
        onChange={(value) => {
          if (typeof setFieldTouched === 'function') {
            setFieldTouched(name);
          }
          const updatedValues = {
            ...values,
            [name]: value || null,
          };
          setValues(updatedValues);
          if (typeof onChange === 'function') {
            onChange(value, name, formProps);
          }
        }}
        className={classes.formField}
        required={required}
        error={touched[name] && Boolean(errors[name])}
        disabled={fieldDef.disabled}
        autoFocus={focused}
        InputLabelProps={{
          shrink,
        }}
        InputProps={{
          id: name,
        }}
        {...omit(ComponentProps, 'shrink')}
      />
    </FormControl>
  );
};

export default FormDateTime;
