import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {Grid, Paper, Tab, Tabs, styled} from '@mui/material';
import {dateRange, getFilterDateRange} from '../dateRangeUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const StyledToggleButton = styled(ToggleButton)(({theme}) => ({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
  },
}));
const SspFilters = ({
  currentUser,
  onDurationChange,
  selectedDurationInDays,
  tabValue,
  handleTabChange,
}) => {
  const filterButton = ({days, displayText}) => (
    <StyledToggleButton value={days}>{displayText}</StyledToggleButton>
  );
  const onFilterChange = (_event, newSelectedDuration) => {
    if (newSelectedDuration)
      onDurationChange((filter) => ({
        ...filter,
        date: getFilterDateRange({days: newSelectedDuration}),
      }));
  };

  const filtersJustifyContent = currentUser.settings.haloIntegration
    ? 'space-between'
    : 'end';

  return (
    <Grid container justifyContent={filtersJustifyContent} spacing={2}>
      {currentUser.settings.haloIntegration && (
        <Grid item xs={12} md={6} lg={4}>
          <Paper sx={{width: 'fit-content'}}>
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              <Tab data-cy="ssp-main" label="SSP" />
              <Tab data-cy="ssp-halo-events" label="Halo Sensor" />
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12} md={6} lg={4}>
        <ToggleButtonGroup
          fullWidth
          value={selectedDurationInDays}
          exclusive
          onChange={onFilterChange}
          aria-label="date-range-filter"
          size="small"
          sx={{
            backgroundColor: 'white',
          }}
        >
          {filterButton(dateRange.last90Days)}
          {filterButton(dateRange.last30Days)}
          {filterButton(dateRange.last7Days)}
          {filterButton(dateRange.last24Hours)}
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );
};

export default withCurrentUser(SspFilters);
