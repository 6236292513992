import get from 'lodash/get';
import head from 'lodash/head';

export const flatten = (arr) =>
  arr.reduce(
    (flat, toFlatten) =>
      flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten),
    [],
  );

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const adjust = (arr, element, findIndex) => {
  const index = arr.findIndex(findIndex);
  let newArr = [];

  if (index === -1) {
    newArr = newArr.concat(arr, element);
  } else if (index === 0) {
    newArr = newArr.concat(arr.slice(1));
  } else if (index === arr.length - 1) {
    newArr = newArr.concat(arr.slice(0, -1));
  } else if (index > 0) {
    newArr = newArr.concat(arr.slice(0, index), arr.slice(index + 1));
  }

  return newArr;
};

export const getFromHead = (array, field, defaultValue) =>
  get(head(array), field, defaultValue);
