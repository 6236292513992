import React from 'react';
import {useLocation} from 'react-router-dom';
import {isNewNavigationWebEnabled} from '../../../shared/util/user';
import {RedirectWithQueryParams} from '../../../shared/components/redirectWithQueryParams';
import {withCurrentUser} from '../../../shared/contexts/currentUserContext';

const SitesNewNavRedirect = ({currentUser}) => {
  const location = useLocation();
  if (
    isNewNavigationWebEnabled(currentUser) &&
    !location.pathname.includes('/home') &&
    !location.pathname.includes('/video')
  ) {
    return <RedirectWithQueryParams to={`/home${location.pathname}`} />;
  }
  return null;
};

export default withCurrentUser(SitesNewNavRedirect);
