import React, {PureComponent} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';

import {getDelegations} from '../../../api/delegations';
import PageLoader from '../../../shared/components/pageLoader';
import {getDomains} from '../../../api/domains';

class DelegationsRoutes extends PureComponent {
  render() {
    const {match} = this.props;
    return (
      <Switch>
        <Route
          path={`${match.path}`}
          render={(renderProps) => (
            <PageLoader
              page={() => import('./DelegationsList')}
              resources={{
                delegations: getDelegations,
                domains: getDomains,
              }}
              {...renderProps}
            />
          )}
        />
      </Switch>
    );
  }
}

export default withRouter(DelegationsRoutes);
