// Handles Utils for the Removed Item widget
export const formatData = (element) => {
  return {
    ...element,
    name: element.name,
    y: element.percentTotal,
    drilldown: !element.reportUrl,
    url: element?.reportUrl?.reportUrl,
  };
};

export const extraOptions = () => {
  return {
    yAxis: {
      labels: {
        format: '{value}%',
      },
    },
  };
};

export const formatTooltip = (element, tipText) => {
  const {
    point: {
      options: {y, amount, count},
    },
    key,
  } = element;

  return `
        <div>
          <span>${key}</span><br>
          <span id="tooltip-percentage">
            Percentage: ${parseFloat(y).toFixed(2)}%
          </span><br>
          <span id="tooltip-amount">${tipText} $${amount}</span><br>
          <span id="tooltip-trans-count">Transaction Count: ${count}</span>
        </div>
        `;
};
