import axios from 'axios';
import {DateTime, Duration} from 'luxon';
import queryString from 'query-string';
import {generateNonce} from '../shared/util/nonce';

const adornCamerasWithImage = (cameras) =>
  cameras.map((c) => ({
    img: `/api/v3/sensor_views/${c.id}/thumbnail`,
    ...c,
  }));

export const getCamerasByCategory = async (categoryId) =>
  adornCamerasWithImage(
    (await axios.get(`/site_categories/${categoryId}/sensor_views`)).data
      .filter((cam) => !!cam.visible)
      .map(({id, isOnline, ...rest}) => ({
        ...rest,
        id: id.toString(),
        isOnline: !!isOnline,
      })),
  );

export const getCameras = async (siteId) => {
  const siteIds = Array.isArray(siteId) ? siteId.join(',') : siteId;
  const {data: cameras} = await axios.get('/sensor_views', {
    params: {siteIds},
  });
  return adornCamerasWithImage(cameras.filter((cam) => !!cam.visible));
};

export const getCamera = async (cameraId) => {
  const {data} = await axios.get(`/sensor_views/${cameraId}`);
  return data;
};

export const makeThumbnailUrl = (cameraIdArr, time, width, height) => {
  const params = {
    sensors: cameraIdArr.join(','),
  };
  if (time && !time.invalid) {
    params.time = time.toISO();
  }
  if (width && height) {
    params.res = `${width}x${height}`;
  }
  return `${
    axios.defaults.baseURL
  }/sensor_views/thumbnails?${queryString.stringify(params)}`;
};

export const makeLiveThumbnailUrl = (cameraId, width, height) => {
  const params = {
    res: `${width}x${height}`,
  };
  return `${
    axios.defaults.baseURL
  }/sensor_views/${cameraId}/thumbnail?${queryString.stringify(params)}`;
};

// Retrieve video thumbnail sprites for the given sensorId and time range.
// Supply a callback that will be called with each sprite image
// as it is loaded.
// useNonce can be used to help break the browser cache
export const getThumbnailSprites = (
  sensorId,
  startTime,
  endTime,
  spriteCallback,
  useNonce = false,
  errorCallback = () => {},
) => {
  // return a function that we can use for image.onload as a callback
  // to get image info and know that the image has loaded.
  const makeImageLoadCallback = (
    url,
    image,
    spriteStartTime,
    spriteEndTime,
    resolveFunc,
  ) => () => {
    // The data structure wrapping each image
    const sprite = {
      sensorId,
      src: url,
      img: image,
      height: image.naturalHeight,
      width: image.naturalWidth,
      startTime: spriteStartTime,
      endTime: spriteEndTime,
      dimensions: {width: 15, height: 12},
    };
    if (typeof resolveFunc === 'function') {
      resolveFunc(sprite);
    }
  };

  let url = `/api/v3/sensor_views/${sensorId}/thumbnail_sheet?timestamp=${encodeURIComponent(
    startTime.toISO(),
  )}`;

  // break the browser cache
  if (useNonce) {
    url = `${url}&nonce=${generateNonce()}`;
  }

  const image = new Image();
  image.onload = makeImageLoadCallback(
    url,
    image,
    startTime,
    endTime,
    spriteCallback,
  );
  image.onerror = errorCallback;
  image.src = url;
};

export const getTimeline = async (
  startTime,
  durationSeconds,
  sensorIds,
  motionMask,
) => {
  const params = {
    start: encodeURIComponent(startTime.toISO()),
    sensors: sensorIds,
    duration: durationSeconds,
    includeSources: 1,
  };
  if (motionMask) {
    params.motionMask = motionMask;
  }
  const {data} = await axios.get(
    `/sensor_views/timeline?${queryString.stringify(params, {
      sort: false,
      encode: false,
    })}`,
  );
  return {
    ...data,
    layout: data.layout,
    startTime: DateTime.fromISO(data.startTime),
    stopTime: DateTime.fromISO(data.stopTime),
    duration: Duration.fromObject({seconds: data.duration}),
    videos: data.videos.map((v) => ({
      ...v,
      startTime: DateTime.fromISO(v.startTime),
      stopTime: DateTime.fromISO(v.stopTime),
      duration: Duration.fromObject({seconds: v.duration}),
    })),
  };
};

export const getEstimate = async (sensorId, start, duration) => {
  const {data} = await axios.get(
    `/sensor_views/${sensorId}/export_estimate?start=${start.toISO({
      suppressMilliseconds: true,
    })}&duration=${duration}`,
  );
  return data;
};
