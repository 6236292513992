import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import Footer from './Footer';
import {hideFooter, getBuildDisplay} from '../../util/general';

const showFooter = !hideFooter();
const buildDisplay = getBuildDisplay();

const styles = (theme) => ({
  viewport: {
    ...theme.mixins.viewport,
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  children: {flex: '1 0 auto'},
  footer: {
    ...theme.mixins.footer,
    flexShrink: 0,
  },
});

const Viewport = ({className, classes, children}) => (
  <div className={className}>
    <div className={classes.viewport}>
      <div className={classes.children}>{children}</div>
      {showFooter ? (
        <Footer className={classes.footer} buildDisplay={buildDisplay} />
      ) : (
        // eslint-disable-next-line no-console
        console.info(`dev-ui version ${buildDisplay}`)
      )}
    </div>
  </div>
);

Viewport.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(Viewport);
