import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import HighChartsWidget from '../../../../shared/components/highcharts';
import {getSiteByDayEmpMgrDiscountStats} from '../../../../api/reports';
import * as empMgrDiscountDollars from './widgetUtils/empMgrDiscountDollarsUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

const EmpMgrDiscountWidget = ({currentUser, widgetConfig}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;
  const theme = useTheme();

  const getContent = () => {
    return (
      <HighChartsWidget
        id="highcharts-widget-empmgr-discounts"
        getData={getSiteByDayEmpMgrDiscountStats}
        reportLookBackDays={reportLookBackDays}
        currentUserDate={currentUserDate}
        getDrilldownData={getSiteByDayEmpMgrDiscountStats}
        formatData={empMgrDiscountDollars.formatData}
        tooltipProvider={empMgrDiscountDollars.formatTooltip}
        graphSettings={{
          tipText: 'Discount Dollars:',
          chartType: 'column',
          seriesName: 'Discounts',
          title:
            widgetConfig.name ||
            'Employee/Manager Discount Dollars as a Percentage of Sales',
          subtitle:
            widgetConfig.description ||
            'Employee/Manager discount dollars as a percentages of sales over the last seven days.',
          plotLinesLabel: 'avg discount dollars % sale',
          yAxisTitle: '',
          columnColor: theme.palette.primary.main,
          hoverColor: theme.palette.primary.light,
          widgetName: 'empmgr-discounts-widget',
        }}
        extraOptions={empMgrDiscountDollars.extraOptions}
        currentUser={currentUser}
      />
    );
  };

  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(EmpMgrDiscountWidget);
