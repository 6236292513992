import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from '../../../../shared/components/spinner';
import {openInOneTab} from '../../utils';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  header: {
    padding: [[theme.spacing(0.5), 0]],
  },
  riskCell: {
    display: 'flex',
  },
  minimalRiskIcon: {
    color: theme.palette.success.main,
  },
  lowRiskIcon: {
    color: theme.palette.warning.main,
  },
  mediumRiskIcon: {
    color: theme.palette.warning.dark,
  },
  highRiskIcon: {
    color: theme.palette.error.main,
  },
  unknownRiskIcon: {
    opacity: 0,
  },
  riskRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  error: {
    textAlign: 'center',
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
}));

const riskLevelToIcon = (riskLevel, classes) => {
  const riskClasses = [
    classes.minimalRiskIcon,
    classes.lowRiskIcon,
    classes.mediumRiskIcon,
    classes.highRiskIcon,
  ];
  const riskClass = riskClasses[riskLevel] || classes.unknownRiskIcon;
  return <CircleIcon className={riskClass} name={`icon-${riskLevel}`} />;
};

const RiskLevelTableWidget = (props) => {
  const {
    title,
    header,
    listItems,
    rowSize,
    loading,
    error,
    errorMessage,
    currentUser,
  } = props;
  const classes = useStyles(props);
  const handleRowClick = (url) => {
    if (!url) return;
    window.open(url, openInOneTab(currentUser));
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner size={40} color="primary" />
      ) : (
        <Fragment>
          {error ? (
            <Typography variant="subtitle1" className={classes.error}>
              {errorMessage}
            </Typography>
          ) : (
            <Table
              aria-label="Risk level table"
              size={rowSize}
              name={`risk-level-table-${title}`}
            >
              <TableHead name="risk-level-table-header">
                <TableRow>
                  <TableCell colSpan="4">
                    <Typography variant="subtitle2" className={classes.header}>
                      {header}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listItems.map((item) => (
                  <TableRow
                    key={item.id}
                    name={`risk-item-${item.id}`}
                    hover
                    onClick={() => handleRowClick(item.url)}
                    className={classes.riskRow}
                  >
                    <TableCell scope="row" name={`risk-item-name-${item.id}`}>
                      <Typography variant="body1">{item.name}</Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      name={`risk-item-icon-${item.id}`}
                      className={classes.riskCell}
                    >
                      {riskLevelToIcon(item.riskLevel, classes)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

RiskLevelTableWidget.propTypes = {
  title: PropTypes.string,
  header: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      riskLevel: PropTypes.number,
      url: PropTypes.string,
    }),
  ).isRequired,
  rowSize: PropTypes.oneOf(['small', 'medium', 'large']),
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  currentUser: PropTypes.shape({}).isRequired,
};

RiskLevelTableWidget.defaultProps = {
  rowSize: 'small',
  title: '',
  error: false,
  errorMessage: '',
};

export default RiskLevelTableWidget;
