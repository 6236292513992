import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Button, CircularProgress} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {withLogger} from '../logger';
import GlobalErrorPage from '../../../pages/globalErrorPage';

const styles = {
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const LoadingScreen = ({error, retry, timedOut, classes, logger}) => {
  let contents = null;
  if (error) {
    logger.error('Loader failed with error.', {}, error);
    contents = (
      <GlobalErrorPage>
        classes={classes}
        logger={logger}
        error={error}
        reload={() => window.location.reload(true)}
      </GlobalErrorPage>
    );
  } else if (timedOut) {
    contents = (
      <Fragment>
        <span>Taking a long time...</span>
        <Button onClick={retry}>Retry</Button>
      </Fragment>
    );
    // } else if (props.pastDelay) {
    //   contents = <CircularProgress size={75} />;
    // } else {
    //   return null;
    // }
  } else {
    contents = <CircularProgress size={75} />;
  }

  return <div className={classes.root}>{contents}</div>;
};

LoadingScreen.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  retry: PropTypes.func,
  timedOut: PropTypes.bool,
};

LoadingScreen.defaultProps = {
  error: null,
  retry: null,
  timedOut: null,
};

export default withLogger(withStyles(styles)(LoadingScreen));
