import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import Spinner from '../spinner';

const styles = {
  hidden: {
    visibility: 'hidden',
    height: 0,
    overflow: 'hidden',
  },
  visible: {},
};

class LoadablePromise extends React.PureComponent {
  state = {
    loading: false,
    error: undefined,
  };

  wrapAction = async (...args) => {
    this.setState({loading: true});
    let error;
    try {
      const result = await this.props.action(...args);
      return result;
    } catch (e) {
      error = e;
      if (e.response) {
        // axios error
        this.setState({
          error: {
            status: e.response.status,
            ...(e.response.data.error
              ? e.response.data.error
              : e.response.data),
          },
        });
      } else {
        this.setState({error: e});
      }
      throw e;
    } finally {
      if (!this.props.final || error) {
        this.setState({loading: false});
      }
    }
  };

  getSpinner = () => {
    const {loading} = this.state;
    const {SpinnerProps, outsideLoading} = this.props;
    return (
      (outsideLoading || loading) && (
        <Spinner size={50} color="primary" {...SpinnerProps} />
      )
    );
  };

  render() {
    const {loading, error} = this.state;
    const {
      children,
      classes,
      SpinnerProps,
      final,
      outsideLoading,
      action,
      alternatePosition,
      ...rest
    } = this.props;
    return (
      <div {...rest}>
        {!alternatePosition && this.getSpinner()}
        <div
          className={classNames({
            [classes.hidden]: !alternatePosition && (outsideLoading || loading),
            [classes.visible]: !(outsideLoading || loading),
          })}
        >
          {children(this.wrapAction, error, this.getSpinner)}
        </div>
      </div>
    );
  }
}

LoadablePromise.propTypes = {
  action: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  final: PropTypes.bool,
  outsideLoading: PropTypes.bool,
  alternatePosition: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types */
  SpinnerProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

LoadablePromise.defaultProps = {
  outsideLoading: false,
  alternatePosition: false,
  final: false,
  SpinnerProps: {},
};

export default withStyles(styles)(LoadablePromise);
