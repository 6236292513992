import axios from 'axios';

export const getDmpIntegrations = async (domainId) =>
  (await axios.get(`/dmp/${domainId}`)).data;

export const getDmpConfig = async (siteId) => {
  try {
    const res = await axios.get(`/dmp/config/${siteId}`);

    return res.data;
  } catch (e) {
    if (e.response.status === 403) return null;
    if (e.response.status === 404) return {};
    throw e;
  }
};

export const createDmpIntegration = async (domainId, name) => {
  const {data} = await axios.post(`/dmp/${domainId}`, {
    name,
  });

  return data;
};

export const deleteDmpIntegration = async (domainId, integrationId) => {
  return axios.delete(`/dmp/${domainId}/${integrationId}`, {
    domainId,
    integrationId,
  });
};

export const createDmpIntegrationConfig = async (
  siteId,
  integrationId,
  accountNumber,
) => {
  const {data} = await axios.post(`/dmp/config/${siteId}`, {
    integrationId,
    accountNumber,
  });

  return data;
};

export const modifyDmpIntegrationConfig = async (
  siteId,
  integrationId,
  accountNumber,
  panelId,
) => {
  const {data} = await axios.put(`/dmp/config/${siteId}`, {
    integrationId,
    accountNumber,
    panelId,
  });

  return data;
};

export const updateDmpPanelMapping = async (
  siteId,
  cameraId,
  zonesList = [],
) => {
  const path = `/dmp/site/${siteId}/zones`;

  // create panel zones (if zones are provided)
  if (zonesList.length > 0) {
    await axios.post(path, {
      zone_numbers: zonesList,
    });
  }

  // put/replace mapping for particular camera
  const data = await axios.put(path, {
    zone_ids: zonesList,
    camera_id: Number(cameraId),
  });

  return data;
};
