import React from 'react';
import {Redirect} from 'react-router-dom';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import allowed, {BI_REPORT_2, VIEW_PROJECTS} from '../../shared/util/allowed';

const LandingRoutes = ({currentUser}) => {
  const {landingPage} = currentUser.settings;

  let path = '/sites';
  if (landingPage === 'Default') {
    if (currentUser.settings.newDashboard) {
      path = '/dashboard';
    } else if (allowed(currentUser, [BI_REPORT_2, VIEW_PROJECTS])) {
      window.location.href = '/app/#/dashboard';
      return null;
    }
  }
  return <Redirect to={path} />;
};

export default withCurrentUser(LandingRoutes);
