import React from 'react';
import {Grid, useMediaQuery, useTheme} from '@mui/material';
import TabBar from '../../shared/components/tabBar';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const getIframeTab = (theme, isMediumScreen, path, title, frameProps) => {
  return (
    <Grid item xs={12}>
      <IFrameWrapper
        src={`https://${getHost()}/clips2/${path}?embedded=true`}
        title={title}
        FrameProps={{
          style: {
            border: 0,
            height: `calc(100vh - ${theme.mixins.toolbar.height +
              (isMediumScreen
                ? 0
                : theme.mixins.bottomNavigationBar.height)}px - 96px)`,
          },
          ...frameProps,
        }}
      />
    </Grid>
  );
};
const getTabs = (theme, isMediumScreen) => [
  {
    key: 'myClips',
    path: 'my-clips',
    label: 'My Clips',
    component: () =>
      getIframeTab(theme, isMediumScreen, 'root', 'My Clips', {
        sandbox: 'allow-same-origin allow-scripts allow-downloads',
      }),
  },
  {
    key: 'externalShares',
    path: 'external-shares',
    label: 'External Shares',
    component: () =>
      getIframeTab(
        theme,
        isMediumScreen,
        'external-shares',
        'External Shares',
        {
          sandbox:
            'allow-scripts allow-same-origin allow-downloads allow-popups',
        },
      ),
  },
  {
    key: 'trash',
    path: 'trash',
    label: 'Trash',
    component: () =>
      getIframeTab(theme, isMediumScreen, 'trash', 'Trash', {
        sandbox: 'allow-scripts allow-same-origin allow-downloads',
      }),
  },
];

const ClipsPageResponsive = () => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery((t) => t.breakpoints.up('md'));

  return (
    <Grid container data-cy="clips-content">
      <Grid item xs={12}>
        <TabBar onChange={() => {}} tabs={getTabs(theme, isMediumScreen)} />
      </Grid>
    </Grid>
  );
};

export default ClipsPageResponsive;
