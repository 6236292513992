import React, {Fragment, useState, useCallback, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';
import partition from 'lodash/partition';
import isEmpty from 'lodash/isEmpty';
import {Calendar, luxonLocalizer} from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import TimeRangePicker from './TimeRangePicker';

// base styles
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const localizer = luxonLocalizer(DateTime);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 280,
  },
  weekSchedule: {
    // header
    '& .rbc-header': {
      height: 30,
      borderBottom: 'none',
    },
    // header days
    '& .rbc-header > button > span': {
      fontSize: 12,
      color: theme.palette.grey['700'],
      cursor: 'default',
    },
    '& .rbc-overflowing': {
      margin: '0 !important',
    },
    // header extra elements
    '& .rbc-addons-dnd-row-body': {
      display: 'none',
    },
    // time sidebar
    '& .rbc-label': {
      color: theme.palette.grey['600'],
    },
    // day columns
    '& .rbc-time-view': {
      border: 'none',
      borderRight: `1px solid ${theme.palette.grey['300']}`,
    },
    '& .rbc-time-content': {
      overflow: 'hidden', // removes scroll on overflow
    },
    // event container
    '& .rbc-events-container': {
      margin: '0 !important',
    },
    // event box
    '& .rbc-timeslot-group': {
      minHeight: 25,
    },
    '& .rbc-day-slot .rbc-timeslot-group': {
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      borderLeft: `1px solid ${theme.palette.grey['400']}`,
    },
    // event
    '& .rbc-event': {
      width: '100% !important', // prevents shrinking when events close
      left: `0 !important`, // prevents shifting when events close
      backgroundColor: theme.palette.primary.light,
      border: 'none !important',
      borderBottom: `1px solid ${theme.palette.grey['100']}`, // event separator
      borderRadius: 0,
      marginLeft: 1,
      marginRight: 1,
      padding: 0,
    },
    // event label
    '& .rbc-event-label': {
      display: 'none',
    },
    // event slot
    '& .rbc-day-slot .rbc-event': {
      minHeight: 0,
    },
    // event selected
    '& .rbc-selected': {
      backgroundColor: theme.palette.primary.dark,
    },
    // time slot
    '& .rbc-time-slot': {
      border: 0,
    },
  },
}));

// Normalize week (we don't care about dates, just weekdays, but want to avoid any daylight savings conflicts in local zone)
const startOfWeek = DateTime.fromSeconds(518400) // arbitrary day in middle of the week, not around daylight savings (1/7/1970 1am GMT)
  .set({weekday: 0})
  .startOf('day');

const getMsFromTimeString = (timeString) => {
  const hours = timeString.slice(0, 2);
  const minutes = timeString.slice(-2);

  return (hours * 3600 + minutes * 60) * 1000;
};

const isOverlapping = (event, events) => {
  const startTime = event.start.getTime();
  const endTime = event.end.getTime();

  return events.some(
    (e) =>
      e.wkd === event.wkd && // same day
      // adding events downwards
      ((startTime < e.start.getTime() && // ensure event is above comparison event
        endTime > e.start.getTime()) || // stretches into or past the comparsion event start
        // adding events upwards
        (endTime > e.end.getTime() && // ensure event is below comparison event
          startTime < e.end.getTime())), // stretches into or past the comparsion event end
  );
};

const isSameTime = (start, end) => start.getTime() === end.getTime();

const generateEventsFromSchedule = (schedule) =>
  schedule.reduce((acc, sched, idx) => {
    // ignore invalid elements
    if (idx > 6) return acc;

    if (sched) {
      const day = startOfWeek.plus({days: idx});

      sched.split(',').forEach((timeBlock) => {
        const [startString, stopString] = timeBlock.split(':');
        const start = day.plus(getMsFromTimeString(startString));
        const end = day.plus(getMsFromTimeString(stopString));

        const startDate = start.toJSDate();
        const endDate =
          stopString === '2400'
            ? end.minus({seconds: 1}).toJSDate() // avoids crossing into next day for 12am
            : end.toJSDate();

        acc.push({
          id: startDate.getTime(),
          start: startDate,
          end: endDate,
          wkd: idx,
          timeRange: `${localizer.format(
            startDate,
            'hh:mm a',
          )} - ${localizer.format(end.toJSDate(), 'hh:mm a')}`, // keep 2400 for tooltip display
        });
      });
    }

    return acc;
  }, []);

const generateScheduleFromEvents = (events) =>
  events
    .reduce(
      (acc, {wkd, start, end}) => {
        const startString = DateTime.fromJSDate(start).toFormat('HHmm');
        const endStringWithSeconds = DateTime.fromJSDate(end).toFormat(
          'HHmmss',
        );

        acc[wkd].push(
          `${startString}:${
            endStringWithSeconds === '235959' // needed to handle 12am end of day
              ? '2400'
              : endStringWithSeconds.slice(0, 4)
          }`,
        );
        return acc;
      },
      [[], [], [], [], [], [], []],
    )
    .map((e) => {
      if (!e.length) return '';
      e.sort();
      return e.join(',');
    });

// eslint-disable-next-line react/display-name
const WeekSchedule = React.memo((props) => {
  const {
    defaultMinutes,
    schedule,
    step,
    timeslots,
    onChange,
    editable,
    className,
    ...rest
  } = props;
  const classes = useStyles(props);

  const [error, setError] = useState(false);
  const [events, setEvents] = useState(generateEventsFromSchedule(schedule));
  const [activeEvent, setActiveEvent] = useState();
  const [selection, setSelection] = useState(); // used to track selection changes (avoid input rerender in time picker)

  // handles reset to new schedule
  useEffect(
    () => {
      if (!schedule.length) {
        setEvents(generateEventsFromSchedule(schedule));
      }
    },
    [schedule],
  );

  useEffect(
    () => {
      onChange({value: generateScheduleFromEvents(events), error});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events, error],
  );

  // used to keep track of previous selection
  const selectionRef = useRef();

  const handleSelectEvent = useCallback((event) => {
    selectionRef.current = event;
    setActiveEvent(event);
    setSelection(event);
  }, []);

  const handleAddEvent = ({action, start, end}) => {
    const weekdayNumber = DateTime.fromJSDate(start).weekday;

    const newEvent = {
      id: start.getTime(),
      start,
      end,
      wkd: weekdayNumber === 7 ? 0 : weekdayNumber, // handles sunday as day 7
    };

    if (action === 'click') {
      newEvent.end = new Date(start.getTime() + defaultMinutes * 60 * 1000);
    }
    if (isOverlapping(newEvent, events)) return;

    const updatedEvents = [...events, newEvent];

    setEvents(updatedEvents);
    handleSelectEvent(newEvent);
  };

  const handleRemoveEvent = useCallback(
    () => {
      setEvents(events.filter(({id}) => id !== activeEvent.id));
      setActiveEvent(null);
      onChange({value: generateScheduleFromEvents(events)});
    },
    [activeEvent, events, onChange],
  );

  const handleResizeEvent = useCallback(
    ({event, start, end}) => {
      setEvents((prev) => {
        const [[existing], filtered] = partition(
          prev,
          (e) => e.id === event.id,
        );
        const newEvent = {...existing, id: start.getTime(), start, end};

        if (
          isSameTime(newEvent.start, newEvent.end) ||
          isOverlapping(newEvent, filtered)
        )
          return prev;

        // update selection if resizing event that is not current selected event
        if (selectionRef.current?.id !== event.id) {
          handleSelectEvent(newEvent);
        }

        selectionRef.current = newEvent;
        setActiveEvent(newEvent);

        return [...filtered, newEvent];
      });
    },
    [handleSelectEvent],
  );

  const handleDragResize = useCallback(
    (event) => {
      handleResizeEvent(event);
      setSelection(event);
    },
    [handleResizeEvent],
  );

  const handleKeyboardResize = useCallback(
    (times) => {
      const hasErrors = !isEmpty(times.errors);
      setError(hasErrors);

      if (!hasErrors) {
        handleResizeEvent({event: activeEvent, ...times});
      }
    },
    [activeEvent, handleResizeEvent],
  );

  return (
    <Fragment>
      <div
        name="week-schedule"
        className={classNames(className, classes.root)}
        {...rest}
      >
        <DragAndDropCalendar
          className={classes.weekSchedule}
          localizer={localizer}
          defaultDate={startOfWeek.toJSDate()}
          formats={{
            dayFormat: 'ccc',
            eventTimeRangeFormat: () => null,
            timeGutterFormat: (date, _, local) => local.format(date, 'h a'),
          }}
          step={step}
          timeslots={timeslots}
          selected={editable && activeEvent}
          selectable={editable}
          resizable={editable}
          defaultView="week"
          views={{
            week: true,
          }}
          toolbar={false}
          events={events}
          onEventResize={handleDragResize}
          onSelectSlot={handleAddEvent}
          onSelectEvent={handleSelectEvent}
          draggableAccessor={() => !!editable}
          tooltipAccessor={editable ? null : 'timeRange'}
        />
        {editable && (
          <TimeRangePicker
            className={classes.timeRangePicker}
            selection={selection}
            startDate={activeEvent?.start ?? null}
            endDate={activeEvent?.end ?? null}
            disabled={!activeEvent}
            onChange={handleKeyboardResize}
            onDelete={handleRemoveEvent}
          />
        )}
      </div>
    </Fragment>
  );
});

WeekSchedule.defaultProps = {
  schedule: [],
  defaultMinutes: 30,
  step: 15,
  timeslots: 8,
  editable: true,
  onChange: () => {},
};

WeekSchedule.propTypes = {
  /**
   * An array of time strings in the format: ['', '0000:0300,0520:0821:2300:2400', '', '', '', '', '']
   * Element positions are representative of the day of week, ie. element 0 is Sunday, etc. Empty strings indicate no schedule.
   */
  schedule: PropTypes.arrayOf(PropTypes.string),
  /**
   * Default size of new events added to the schedule.
   */
  defaultMinutes: PropTypes.number,
  /**
   * Determines the selectable time increments in minutes.
   */
  step: PropTypes.number,
  /**
   * The number of slots per time grid box, adjusts with step.
   */
  timeslots: PropTypes.number,
  /**
   * Option for view only weekly schedule, no editing functionality.
   */
  editable: PropTypes.bool,
  /**
   * Handler for updated schedule.
   */
  onChange: PropTypes.func,
};

export default WeekSchedule;
