import React from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';

import GppGoodIcon from '@mui/icons-material/GppGood';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SectionTitleBar from '../SectionTitleBar';
import {openInOneTab} from '../../../../apps/dashboard/utils';

const AlarmSummarySection = ({selectedSiteId, currentUser}) => {
  const handleClick = () => {
    const endDate = DateTime.local();
    const dateParams = `startDate=${endDate
      .minus({day: 1})
      .toFormat('yyyy-MM-dd')}&endDate=${endDate.toFormat('yyyy-MM-dd')}`;
    window.open(
      currentUser.settings.newNavigationWeb
        ? `/ui/ssp/alarm-events?siteId=${selectedSiteId}&${dateParams}`
        : `/ui/sites/${selectedSiteId}/alarmEventsSummary?${dateParams}`,
      openInOneTab(currentUser),
    );
  };

  return (
    <SectionTitleBar
      title="Alarm Summary"
      icon={<GppGoodIcon />}
      isActionIconVisible={!!handleClick}
      actionIcon={<ChevronRightIcon />}
      onClick={handleClick}
    />
  );
};

AlarmSummarySection.propTypes = {
  selectedSiteId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    settings: PropTypes.shape({}),
  }).isRequired,
};

export default AlarmSummarySection;
