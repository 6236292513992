import React, {Fragment} from 'react';
import {Grid, Typography, Button, Box} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

const ActionButton = styled(Button)(({theme}) => ({
  color: theme.palette.primary.main,
  paddingBottom: 0,
  marginRight: theme.spacing(3),
}));

const SectionHeader = ({subtitle, title}) => (
  <Box mr={(theme) => theme.spacing(3)}>
    <Typography
      id={`section-title-${title}`}
      data-cy="section-title"
      variant="h6"
    >
      {title}
    </Typography>
    <Typography
      id={`section-subtitle-${subtitle}`}
      data-cy="section-subtitle"
      variant="body1"
    >
      {subtitle}
    </Typography>
  </Box>
);

SectionHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.string.isRequired,
};

const SectionActions = ({actions, title}) => (
  <Box>
    {actions.map((action) => (
      <ActionButton
        id={`section-actions-${title}`}
        data-cy="section-actions"
        key={action.id}
        {...action.ButtonProps}
      >
        {action.label}
      </ActionButton>
    ))}
  </Box>
);

SectionActions.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      ButtonProps: PropTypes.shape({}),
    }),
  ).isRequired,
};

const DashboardSection = ({
  actions,
  children,
  title,
  subtitle,
  filters,
  ...rest
}) => (
  <Fragment>
    <Grid
      item
      xs={12}
      display="flex"
      alignItems="flex-end"
      flexWrap="wrap"
      {...rest}
    >
      {title ? <SectionHeader title={title} subtitle={subtitle} /> : null}
      {actions?.length ? (
        <SectionActions actions={actions} title={title} />
      ) : null}
      {filters}
    </Grid>
    {children}
  </Fragment>
);

DashboardSection.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      buttonLabel: PropTypes.string,
      ButtonProps: PropTypes.shape({}),
    }),
  ),
  children: PropTypes.node,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  filters: PropTypes.node,
};
DashboardSection.defaultProps = {
  actions: [],
  subtitle: undefined,
  title: undefined,
  filters: undefined,
  children: React.createElement(React.Fragment),
};
export default DashboardSection;
