import PropTypes from 'prop-types';

import hasSomeCapabilities, {hasAllCapabilities} from '../../util/allowed';
import {withCurrentUser} from '../../contexts/currentUserContext';

export const CapabilitiesGuardComponent = (props) => {
  const {
    children,
    currentUser,
    allowed,
    renderDefault = () => null,
    hasAll = false,
  } = props;
  const isAllowed = hasAll ? hasAllCapabilities : hasSomeCapabilities;

  if (isAllowed(currentUser, allowed)) {
    return children;
  }

  return renderDefault();
};

CapabilitiesGuardComponent.propTypes = {
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.shape({
    capabilities: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string})),
  }).isRequired,
  allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderDefault: PropTypes.func,
  hasAll: PropTypes.bool,
};

export default withCurrentUser(CapabilitiesGuardComponent);
