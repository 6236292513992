export const ACCESS_CONTROL_ADMIN = 'AccessControlAdmin';
export const ACCESS_CONTROL_USER = 'AccessControlUser';
export const ADMIN = 'isAdminRole';
export const AUDIT = 'AuditCapability';
export const BI_REPORT_2 = 'BiReportCapability2';
export const BI_REPORT_ADMIN = 'BiReportAdminCapability2';
export const CLIPS_ADMIN = 'ClipsAdminCapability';
export const CLOUD_ARCHIVE_ADMIN = 'CloudArchiveAdmin';
export const CLOUD_ARCHIVE_DOMAIN_USAGE = 'CloudArchiveDomainUsage';
export const CLOUD_ARCHIVE_USER = 'CloudArchiveUser';
export const DELEGATION_ADMIN = 'DelegationAdminCapability';
export const DOWNLOAD_VIDEO = 'downloadVideo';
export const DOWNLOAD_VIDEO_CLIP = 'downloadToComputer';
export const ENVR_ADMIN = 'EnvrAdministrationCapability';
export const ENVY_LOCAL_ACCESS = 'EnvyLocalAccessCapability';
export const EVENT_DATA = 'EventDataCapability';
export const GRANT_TEMP_ACCESS = 'GrantTemporaryAccess';
export const GROUP_ADMIN = 'GroupCapability';
export const INCIDENT_MANAGEMENT = 'IncidentManagementCapability';
export const INCIDENT_MANAGEMENT_CREATE = 'IncidentManagementCreateCapability';
export const INCIDENT_MANAGEMENT_USER_CREATE =
  'IncidentManagementUserCreateCapability';
export const INTEGRATION_ADMIN = 'IntegrationAdmin';
export const LIVE_VIDEO = 'LiveVideoCapability';
export const MANAGE_IN_APP_MESSAGING = 'ManageInAppMessaging';
export const MOTION_ALARM_ADMIN = 'MotionAlarmAdmin';
export const MOTION_ALARM_USER = 'MotionAlarmUser';
export const MY_CLIPS = 'MyClipsCapability';
export const PANIC_BUTTON_ADMIN = 'PanicButtonAdmin';
export const PLATFORM_ADMIN = 'PlatformAdminCapability';
export const POS_CONFIG = 'posConfiguration';
export const PREFERENCE_ADMIN = 'PreferenceDefinitionAdmin';
export const RECORDED_VIDEO = 'RecordedVideoCapability';
export const SAVE_VIDEO_CLIP = 'SaveVideoClipsCapability';
export const SECURITY_ADMINISTRATOR = 'SecurityAdministratorCapability';
export const SITE_HIERARCHY_ADMIN = 'SiteHierarchyAdminCapability';
export const SITE_PREFERENCES_ADMIN = 'SitePreferencesAdmin';
export const SNAPSHOT_VIDEO = 'snapshotVideo';
export const SSO_ADMIN = 'SSOAdmin';
export const SWITCH_USER = 'SwitchUserCapability';
export const UPDATE_SITE_METADATA = 'UpdateSiteMetadata';
export const USER_ADMIN = 'UserAdministrationCapability';
export const VIEW_PROJECTS = 'ViewProjectsCapability';
export const UPDATE_USER_PROFILE = 'UpdateUserProfileCapability';
export const USER_STATISTICS_ACCESS = 'userStatisticsAccess';
export const POS_DATA_ACCESS = 'posDataAccess';
export const PROJECT_ADMIN_CAPABILITY = 'ProjectAdminCapability';
export const DOMAIN_PREFERENCE = 'DomainPreferenceCapability';
export const SKU_CATEGORY = 'SkuCategoryCapability';
export const OPERATIONS_PORTAL = 'OperationsPortal';
export const VIDEO_ANALYTICS = 'VideoAnalyticsCapability';
export const THIRD_PARTY_INSTALLER = 'ThirdPartyInstaller';
export const SSP_CONFIGURATION_ADMIN = 'SSPConfigurationAdmin';

export const hasCapability = (user, capabilityName) =>
  user?.capabilities.some((capability) => capability.name === capabilityName);

const allowed = (currentUser, capabilityNames) =>
  capabilityNames.length === 0 ||
  capabilityNames.some((capabilityName) =>
    hasCapability(currentUser, capabilityName),
  );

export const hasAllCapabilities = (currentUser, capabilityNames) =>
  capabilityNames.filter((capabilityName) =>
    hasCapability(currentUser, capabilityName),
  ).length === capabilityNames.length;

export default allowed;
