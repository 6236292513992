const styles = (theme) => ({
  colorWarning: {
    '&, & .MuiChip-icon': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  },
  colorError: {
    '&, .MuiChip-icon': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  },
  colorSuccess: {
    '&, .MuiChip-icon': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
  },
  colorMain: {
    '&, .MuiChip-icon': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  colorInfo: {
    '&, .MuiChip-icon': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
});

export default styles;
