import React from 'react';

import {Icon, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  textStyle: {
    display: 'flex',
    justifyContent: 'start',
    '& span': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  icon: {
    horizontalAlign: 'middle',
    fontSize: '0.75em',
    marginBottom: '6px',
    padding: '0px 0px',
  },
}));

export default function ContactItem(props) {
  const {
    name,
    mail,
    phone,
    itemProps,
    nameProps,
    mailProps,
    phoneProps,
  } = props;
  const classes = useStyles();

  return (
    <div {...itemProps}>
      <Typography className={classes.textStyle} variant="body1" {...nameProps}>
        {name}
      </Typography>
      <React.Fragment>
        <Typography className={classes.textStyle} variant="body2">
          <Icon>
            <EmailOutlinedIcon className={classes.icon} />
          </Icon>
          <span {...mailProps}>{mail || '---'}</span>
        </Typography>
        <Typography className={classes.textStyle} variant="body2">
          <Icon>
            <PhoneIcon className={classes.icon} />
          </Icon>
          <span {...phoneProps}>{phone || '---'}</span>
        </Typography>
      </React.Fragment>
    </div>
  );
}

ContactItem.propTypes = {
  name: PropTypes.string.isRequired,
  mail: PropTypes.string,
  phone: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  itemProps: PropTypes.object,
  nameProps: PropTypes.object,
  mailProps: PropTypes.object,
  phoneProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

ContactItem.defaultProps = {
  mail: '',
  phone: '',
  itemProps: {},
  nameProps: {},
  mailProps: {},
  phoneProps: {},
};
