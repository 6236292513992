import React, {Fragment} from 'react';

import EmployeeRiskWidget from './riskSnapshot/EmployeeRiskWidget';
import SiteRiskWidget from './riskSnapshot/SiteRiskWidget';
import TransactionsRiskWidget from './riskSnapshot/TransactionsRiskWidget';
import SalesSummaryWidget from './salesInsight/SalesSummaryWidget';
import AprManagerRefundWidget from './salesInsight/AprManagerRefundWidget';
import RefundDollarsWidget from './salesInsight/RefundDollarsWidget';
import RemovedItemsWidget from './salesInsight/RemovedItemsWidget';
import DiscountDollarsWidget from './salesInsight/DiscountDollarsWidget';
import EmpMgrDiscountWidget from './salesInsight/EmpManagerMealDiscountWidget';
import DeletedItemsWidget from './salesInsight/DeletedItemsWidget';
import CancelledItemsWidget from './salesInsight/CancelledItemsWidget';
import VoidedItemsWidget from './salesInsight/VoidedtemsWidget';
import ApplianceWidget from './systemHealth/ApplianceWidget';
import CameraWidget from './systemHealth/CameraWidget';
import LateDepartureWidget from './ssp/LateDepatureWidget';
import PoliceContactedWidget from './ssp/PoliceContactedWidget';
import TotalAlarmsWidget from './ssp/TotalAlarmsWidget';
import RiskSnapshotHeaderWidget from './riskSnapshot/RiskSnapshotHeaderWidget';
import SysHealthHeaderWidget from './systemHealth/SystemHealthHeaderWidget';
import SalesInsightsHeaderWidget from './salesInsight/SalesInsightsHeaderWidget';
import SspHeaderWidget from './ssp/SspHeaderWidget';
import CloudArchiveWidget from './systemHealth/CloudArchiveWidget';

// Add widgets into this dict if you want them displayed on the dashboard
const WidgetMapping = (props) => {
  const {widgetConfig} = props;
  const widgetRef = {
    'risk-snapshot-header': (
      <RiskSnapshotHeaderWidget showButton widgetConfig={widgetConfig} />
    ),
    'risk-snapshot-int-header': (
      <RiskSnapshotHeaderWidget widgetConfig={widgetConfig} />
    ),
    'sales-insights-header': (
      <SalesInsightsHeaderWidget showButton widgetConfig={widgetConfig} />
    ),
    'sales-insights-int-header': (
      <SalesInsightsHeaderWidget widgetConfig={widgetConfig} />
    ),
    'smart-site-protection-header': (
      <SspHeaderWidget showButton widgetConfig={widgetConfig} />
    ),
    'system-health-header': (
      <SysHealthHeaderWidget showButton widgetConfig={widgetConfig} />
    ),
    'rs-transactions-widget': (
      <TransactionsRiskWidget widgetConfig={widgetConfig} />
    ),
    'rs-sites-widget': <SiteRiskWidget widgetConfig={widgetConfig} />,
    'rs-employees-widget': <EmployeeRiskWidget widgetConfig={widgetConfig} />,
    'removed-items-widget': <RemovedItemsWidget widgetConfig={widgetConfig} />,
    'refund-dollars-widget': (
      <RefundDollarsWidget widgetConfig={widgetConfig} />
    ),
    'sales-summary-widget': <SalesSummaryWidget widgetConfig={widgetConfig} />,
    'refund-manager-widget': (
      <AprManagerRefundWidget widgetConfig={widgetConfig} />
    ),
    'discount-dollars-widget': (
      <DiscountDollarsWidget widgetConfig={widgetConfig} />
    ),
    'empmgr-discount-dollars-widget': (
      <EmpMgrDiscountWidget widgetConfig={widgetConfig} />
    ),
    'cancelled-items-widget': (
      <CancelledItemsWidget widgetConfig={widgetConfig} />
    ),
    'deleted-items-widget': <DeletedItemsWidget widgetConfig={widgetConfig} />,
    'voided-items-widget': <VoidedItemsWidget widgetConfig={widgetConfig} />,
    'appliance-widget': <ApplianceWidget widgetConfig={widgetConfig} />,
    'camera-widget': <CameraWidget widgetConfig={widgetConfig} />,
    'cloud-archive-widget': <CloudArchiveWidget widgetConfig={widgetConfig} />,
    'total-alarms-widget': <TotalAlarmsWidget widgetConfig={widgetConfig} />,
    'total-dispatched-widget': (
      <PoliceContactedWidget widgetConfig={widgetConfig} />
    ),
    'total-no-threat-widget': (
      <LateDepartureWidget widgetConfig={widgetConfig} />
    ),
  };
  return widgetRef[widgetConfig.widgetReference] || <Fragment />;
};

export default WidgetMapping;
