import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, useMediaQuery} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '../tooltip';

const styles = {
  root: {
    pointerEvents: 'auto',
  },
  label: {},
  disabled: {},
};

const IconButtonWithTooltip = ({
  title,
  className,
  TooltipProps = {},
  ...rest
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const divStyle = {
    ...(rest.disabled
      ? {
          pointerEvents: 'auto',
          cursor: 'not-allowed',
        }
      : {pointerEvents: 'none'}),
  };

  return (
    <Tooltip
      title={title}
      disableTouchListener={!isSmallScreen}
      {...TooltipProps}
    >
      {/* div here is for when the button is disabled, tooltip warning otherwise */}
      <div className={className} style={divStyle}>
        <IconButton size="large" {...rest} />
      </div>
    </Tooltip>
  );
};

IconButtonWithTooltip.muiName = IconButton.muiName;

IconButtonWithTooltip.propTypes = {
  TooltipProps: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

IconButtonWithTooltip.defaultProps = {
  TooltipProps: {},
};

export default withStyles(styles)(IconButtonWithTooltip);
