import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, LinearProgress, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SitesCategoriesList from './index';

const useStyles = makeStyles((theme) => ({
  loadButton: {
    width: '100%',
  },
  errorContainer: {
    padding: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
  },
}));

const PagedList = ({
  list,
  hasFilter,
  showSiteCategoryList,
  showProgress,
  showError,
  showMoreButton,
  disabledMoreButton,
  handleLoadMore,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      {showSiteCategoryList && (
        <SitesCategoriesList list={list} hasFilter={hasFilter} {...restProps} />
      )}
      {showProgress && <LinearProgress />}
      {showError && (
        <div className={classes.errorContainer} data-cy="error">
          <Typography color="error" variant="body1">
            An error occurred, please try again.
          </Typography>
        </div>
      )}
      {showMoreButton && (
        <Button
          data-cy="load-more"
          className={classes.loadButton}
          onClick={handleLoadMore}
          disabled={disabledMoreButton}
        >
          Load More
        </Button>
      )}
    </Fragment>
  );
};

PagedList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      numSites: PropTypes.number,
      sitePath: PropTypes.string,
      applianceIds: PropTypes.arrayOf(PropTypes.string),
      bitrate: PropTypes.number,
      city: PropTypes.string,
      isOnline: PropTypes.bool,
      region: PropTypes.string,
      retail_store_code: PropTypes.string,
      street1: PropTypes.string,
      street2: PropTypes.string,
      timezoneId: PropTypes.number,
    }),
  ).isRequired,
  hasFilter: PropTypes.bool.isRequired,
  showSiteCategoryList: PropTypes.bool.isRequired,
  showProgress: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  showMoreButton: PropTypes.bool.isRequired,
  disabledMoreButton: PropTypes.bool.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
};

export default PagedList;
