import React from 'react';
import {Box, Grid} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import {DateTime} from 'luxon';
import {getSite} from '../../../../../api/sites';
import {flattenSitesListForSearch} from '../../../../../shared/util/sites';
import SiteSelectionMenu from '../../../../../shared/components/siteSelectionMenu/SiteSelectionMenu';
import {getCamerasBySiteId} from '../../../../../api/cameras';
import PageLoader from '../../../../../shared/components/pageLoader';
import {
  getAccessControlEvents,
  getEntriesPerCamera,
  getAccessControlUsers,
  getCredentialTypes,
  getEntries,
} from '../../../../../api/accessControl';
import {useSiteSelector} from '../../../../../shared/hooks';
import withUrlState from '../../../../../shared/components/withUrlState';

const AccessControlNewNavigation = ({urlState, setUrlState}) => {
  const [selectedSite, sitesList, reload, handleSiteSelect] = useSiteSelector(
    urlState,
    setUrlState,
  );

  const handleAcDate = async (date) => {
    if (!isEmpty(selectedSite.id) && !isEmpty(selectedSite.name)) {
      const site = await getSite(selectedSite.id);
      const acStartDate = DateTime.fromObject({})
        .setZone(site.timezoneName)
        .minus({hours: 24})
        .toSeconds();
      const acEndDate = DateTime.fromObject({})
        .setZone(site.timezoneName)
        .toSeconds();
      switch (date) {
        case 'acStartDate':
          return acStartDate;
        case 'acEndDate':
          return acEndDate;
        case 'acFilterDate':
          return `uiData.time:(${acStartDate}--${acEndDate})`;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      data-cy="access-control-page-content"
    >
      <Grid container justifyContent="end" spacing={1} marginBottom={3}>
        <Grid item xs={12} md={4} lg={3}>
          <SiteSelectionMenu
            siteSearchList={
              !isEmpty(sitesList)
                ? flattenSitesListForSearch(sitesList, sitesList.name)
                : []
            }
            title={selectedSite.name || 'Select...'}
            rootCategory={sitesList}
            setCurrentSite={async (e) => {
              if (e.currentSite) {
                handleSiteSelect(e);
              }
            }}
            dropdownProps={{variant: 'outlined', size: 'small'}}
            autofocus={!selectedSite.id}
          />
        </Grid>
      </Grid>
      {!isEmpty(selectedSite.id) && !isEmpty(selectedSite.name) && (
        <PageLoader
          key="accessControl"
          reload={reload}
          page={() => import('../accessControlContent')}
          resources={{
            accessControlEvents: () =>
              getAccessControlEvents(
                selectedSite.id,
                handleAcDate('acFilterDat'),
              ),
            entriesPerCamera: () => getEntriesPerCamera(selectedSite.id),
            accessControlUsers: () => getAccessControlUsers(),
            credentialTypes: () => getCredentialTypes(),
            accessControlEntries: () => getEntries(selectedSite.id),
            site: () => getSite(selectedSite.id),
            siteCameras: () => getCamerasBySiteId(selectedSite.id),
            acStartDate: () => handleAcDate('acStartDate'),
            acEndDate: () => handleAcDate('acEndDate'),
          }}
        />
      )}
    </Box>
  );
};

export default withUrlState()(AccessControlNewNavigation);
