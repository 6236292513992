/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';

import {getBuildInfo, getEnvironmentName} from '../../util/general';

class SentryError extends Error {
  constructor(title = 'Generic Error', context, ...params) {
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, SentryError);
    }

    this.name = 'SentryError';
    // Custom debugging information
    this.title = title;
    this.context = context;
    this.date = new Date();
  }
}

export class SentryLogger {
  constructor() {
    this.buildInfo = getBuildInfo();
    const environment = getEnvironmentName(this.buildInfo.branch);
    if (environment !== 'local') {
      Sentry.init({
        dsn: 'https://afa21efbb9b14204be9c3f82faf7dbee@sentry.io/1440574',
        release: this.buildInfo.version,
        environment: getEnvironmentName(this.buildInfo.branch),
      });
    }
  }

  setUserContext = (user) => {
    Sentry.configureScope((scope) => {
      if (user) {
        this.user = user;
        scope.setUser({
          'Role Name': user.roleName,
          'Domain Name': user.domainName,
          username: user.username,
          id: user.id,
        });
        scope.setTag('domain', user.domainName);
      }
    });
  };

  setCorrelationId = (correlationId) => {
    Sentry.configureScope((scope) => {
      scope.setExtra('X-Request-Id', correlationId);
    });
  };

  shouldLog = (level) =>
    !window.Cypress &&
    window.location.hostname.indexOf('local.') === -1 &&
    (['warn', 'error'].includes(level) ||
      (this.user &&
        this.user.settings &&
        this.user.settings.verboseLogging === 1));

  log(level, message, context, error) {
    if (!this.shouldLog(level)) {
      return;
    }

    const payload = {message, level, context};
    if (error) {
      payload.error = JSON.stringify(error, Object.getOwnPropertyNames(error));
    }

    if (level === 'error') {
      Sentry.captureException(
        new SentryError(payload.message, payload.error, payload.context),
      );
    } else if (level !== 'info') {
      Sentry.captureMessage(
        `${message} ${error ? ` | Error: ${error}` : ''}`,
        level === 'warn' ? 'warning' : level,
      );
    }
  }

  debug(message, context, error) {
    this.log('debug', message, context, error);
    console.debug(message, context, error);
  }

  info(message, context, error) {
    this.log('info', message, context, error);
    console.info(message, context, error);
  }

  warn(message, context, error) {
    this.log('warn', message, context, error);
    console.warn(message, context, error);
  }

  error(message, context, error) {
    this.log('error', message, context, error);
    console.error(message, context, error);
  }
}

// Should only be used for testing. Won't actually log.
export const NullLogger = {
  setUserContext: (user) => (this.user = user),
  setCorrelationId: () => {},
  debug: () => null,
  info: () => null,
  warn: () => null,
  error: () => null,
};
