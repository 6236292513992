import {
  AppBar,
  Toolbar,
  Box,
  Tooltip,
  IconButton,
  Avatar,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import React, {Fragment, useEffect, useState} from 'react';
import {PropTypes} from 'prop-types';
import logo from '../../images/envysion_logo.png';
import {
  userRoutes,
  helpCenterRoutes,
  administrationRoutes,
  platformAdminRoutes,
} from './routes';
import NavigationMenu from './navigationMenu';
import {getUserLandingPage, hasAllMenuCapabilities} from './utils';
import {useDialog} from '../../hooks';
import WorkspaceMenuDialog from './workspaceMenu';

const TopNavigationBar = (props) => {
  const {user} = props;
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [eventMenu, setEventMenu] = useState('');

  const [
    isWorkspaceOpen,
    handleWorkspaceOpen,
    handleWorkspaceClose,
  ] = useDialog();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const handleOpenMenu = (event, eventItem) => {
    setAnchorElMenu(event.target);
    setEventMenu(eventItem);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setEventMenu('');
  };

  const pageTitle = () => {
    const {pathname} = window.location;
    switch (true) {
      case pathname.includes('/ui/ssp'):
        return 'Security';
      case pathname.includes('/ui/reports'):
        return 'Intelligence';
      case pathname.includes('/ui/home'):
        return 'Home';
      default:
        return '';
    }
  };

  useEffect(
    () => {
      if (
        !isMediumScreen &&
        (eventMenu === 'Administration' || eventMenu === 'PlatformAdmin')
      ) {
        handleCloseMenu();
      }
    },
    [eventMenu, isMediumScreen],
  );

  const navItems = [
    {
      tooltipTitle: 'Open Help Center',
      menuTitle: 'Help',
      items: helpCenterRoutes,
      icon: <HelpOutlineIcon data-cy="help-center-icon" />,
      visibleOnSmallScreen: true,
    },
    {
      tooltipTitle: 'Open Administration Settings',
      menuTitle: 'Administration',
      items: administrationRoutes,
      icon: <SettingsOutlinedIcon data-cy="administration-settings-icon" />,
      visibleOnSmallScreen: false,
    },
    {
      tooltipTitle: 'Open Profile Settings',
      menuTitle: 'Profile',
      items: userRoutes(user),
      icon: (
        <Avatar
          data-cy="user-profile-icon"
          sx={(theme) => ({
            height: theme.spacing(4),
            width: theme.spacing(4),
          })}
        >
          <Typography variant="subtitle1">
            {(user.firstName ? user.firstName.charAt(0).toUpperCase() : '') +
              (user.lastName ? user.lastName.charAt(0).toUpperCase() : '')}
          </Typography>
        </Avatar>
      ),
      visibleOnSmallScreen: true,
    },
    {
      tooltipTitle: 'Open Platform Admin Settings',
      menuTitle: 'PlatformAdmin',
      items: platformAdminRoutes,
      icon: <AdminPanelSettingsOutlinedIcon data-cy="platform-admin-icon" />,
      visibleOnSmallScreen: false,
    },
  ];

  return (
    <Fragment>
      <WorkspaceMenuDialog
        user={user}
        isWorkspaceOpen={isWorkspaceOpen}
        onWorkspaceClose={handleWorkspaceClose}
      />
      <AppBar
        id="top-navigation-bar"
        position="sticky"
        sx={{
          color: 'primary.dark',
          height: (theme) => theme.mixins.toolbar.height,
          display: 'flex',
          verticalAlign: 'middle',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        data-cy="top-navigation-bar"
      >
        <Toolbar variant="dense">
          <IconButton
            onClick={handleWorkspaceOpen}
            size="medium"
            sx={{
              color: 'common.white',
              marginRight: 3,
            }}
          >
            <AppsIcon fontSize="large" data-cy="workspace-icon" />
          </IconButton>
          {isMediumScreen && (
            <Link href={getUserLandingPage(user)} display="flex">
              <Box
                component="img"
                sx={{
                  height: (theme) => theme.spacing(4),
                  paddingTop: 0.5,
                }}
                src={logo}
                data-cy="envysion-logo"
              />
            </Link>
          )}
          <Typography
            variant="h6"
            sx={{
              paddingLeft: isMediumScreen ? 5 : 0,
              color: 'primary.contrastText',
            }}
            data-cy="pillar-title"
          >
            {pageTitle()}
          </Typography>
          <Box sx={{flexGrow: 1}} />
          {navItems.map(
            (navItem) =>
              (navItem.visibleOnSmallScreen || isMediumScreen) &&
              hasAllMenuCapabilities(user, navItem.items) && (
                <Box key={navItem.menuTitle}>
                  <Tooltip title={navItem.tooltipTitle}>
                    <IconButton
                      size="medium"
                      sx={{color: 'common.white'}}
                      onClick={(event) =>
                        handleOpenMenu(event, navItem.menuTitle)
                      }
                    >
                      {navItem.icon}
                    </IconButton>
                  </Tooltip>
                  <NavigationMenu
                    items={navItem.items}
                    menuItem={navItem.menuTitle}
                    anchorElMenu={anchorElMenu}
                    handleCloseMenu={handleCloseMenu}
                    user={user}
                    eventMenu={eventMenu}
                  />
                </Box>
              ),
          )}
        </Toolbar>
      </AppBar>
    </Fragment>
  );
};

TopNavigationBar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    capabilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    settings: PropTypes.shape({}),
  }).isRequired,
};
export default TopNavigationBar;
