import React from 'react';
import PropTypes from 'prop-types';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '../tooltip';

const useStyles = makeStyles((theme) => ({
  labelWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const LabelWithTooltip = (props) => {
  const {label, tooltip, ...rest} = props;
  const classes = useStyles(props);
  return (
    <div className={classes.labelWithTooltip} {...rest}>
      {label}
      <Tooltip title={tooltip}>
        <HelpOutlineIcon
          aria-label="help-icon"
          fontSize="small"
          color="action"
        />
      </Tooltip>
    </div>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

LabelWithTooltip.defaultProps = {
  tooltip: undefined,
};

export default LabelWithTooltip;
