import {capitalize} from 'lodash';

const verificationStatus2ApiValue = (status) => {
  if (status === 'threat') return 'yes';
  if (status === 'noThreat') return 'no';
  return status;
};

export const getSelectedVerificationStatuses = (verificationFilters) => {
  return Object.entries(verificationFilters)
    .filter(([, value]) => value)
    .map(([key]) => verificationStatus2ApiValue(key));
};

export const getColor = (category, theme) => {
  switch (category) {
    case 'yes':
      return theme.palette.error.light;
    case 'no':
      return theme.palette.success.light;
    case 'unverifiable':
      return theme.palette.warning.main;
    case 'unclassified':
      return theme.palette.primary.light;
    default:
      return undefined;
  }
};

export const getCategoryName = (category, separator = '') => {
  if (category === 'yes') {
    return `Threat${separator}`;
  }
  if (category === 'no') {
    return `No Threat${separator}`;
  }
  return `${capitalize(category)}${separator}`;
};

export const getDonutChartData = (getNameValue, getColorValue, data, theme) =>
  Object.entries(data).map((entry) => ({
    name: getNameValue(entry[0], ' '),
    y: entry[1],
    color: getColorValue(entry[0], theme),
  }));
