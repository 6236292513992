import axios from 'axios';

export const getLoginMessage = async () =>
  (await axios.get('/login_messages')).data;

export const addLoginMessage = async (messageData) => {
  const res = await axios.post('/login_messages', messageData);
  return res.data;
};

export const editLoginMessage = async (messageData, id) => {
  await axios.put(`/login_messages/${id}`, messageData);
};

export const deleteLoginMessage = async (id) => {
  await axios.delete(`/login_messages/${id}`);
};
