import {Box, Button, Typography} from '@mui/material';
import capitalize from 'lodash/capitalize';
import React, {forwardRef, useState} from 'react';
import * as Yup from 'yup';
import CapabilitiesGuard from '../../../../../shared/components/capabilitiesGuard';
import FormBuilder from '../../../../../shared/components/formBuilder';
import LoadablePromise from '../../../../../shared/components/loadablePromise';
import {PANIC_BUTTON_ADMIN} from '../../../../../shared/util/allowed';

const CreateButtonSection = forwardRef(
  (
    {
      onSubmit,
      buttonCount,
      onCancel,
      siteName,
      addButtonSectionOpen,
      onSectionOpen,
    },
    ref,
  ) => {
    const structure = [
      {
        name: 'name',
        label: 'Button Name',
        defaultValue: `Panic Button #${buttonCount + 1} (${siteName})`,
        validation: Yup.string()
          .required('Please enter a button name')
          .min(3, 'Please enter a longer name')
          .max(64, 'Please enter a shorter name'),
        ComponentProps: {'data-cy': 'add-button-form-button-name'},
      },
      {
        name: 'serial_number',
        label: 'ICCID',
        helperText: "You can find this number in the device's battery case",
        validation: Yup.string()
          .required('Please enter a serial number')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(19, 'Must be exactly 19 digits')
          .max(19, 'Must be exactly 19 digits'),
        ComponentProps: {'data-cy': 'add-button-form-button-number'},
      },
    ];
    const [error, setError] = useState('');
    const handleSubmit = async (data) => {
      try {
        await onSubmit(data);
      } catch (e) {
        setError(
          e.response
            ? capitalize(e.response.data?.error?.message)
            : 'Please try again',
        );
      }
    };
    return (
      <CapabilitiesGuard allowed={[PANIC_BUTTON_ADMIN]}>
        {!addButtonSectionOpen ? (
          <Button
            fullWidth
            data-cy="safety-button-manual-provision-button"
            variant="outlined"
            onClick={onSectionOpen}
          >
            Add New Button +
          </Button>
        ) : (
          <Box ref={ref}>
            <Typography variant="h6" sx={{py: 1}}>
              Add Button
            </Typography>
            <LoadablePromise action={handleSubmit}>
              {(action) => (
                <FormBuilder
                  final
                  structure={structure}
                  submitButtonText="Add Button"
                  onCancel={onCancel}
                  onSubmit={action}
                  error={error}
                />
              )}
            </LoadablePromise>
          </Box>
        )}
      </CapabilitiesGuard>
    );
  },
);

CreateButtonSection.displayName = 'CreateButtonSection';
export default CreateButtonSection;
