import React, {useRef} from 'react';
import {useTheme} from '@mui/material';
import {merge, cloneDeep} from 'lodash';
import PropTypes from 'prop-types';
import HighCharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {defaultHighChartsOptionsJson} from '../highChartsOptionsJson';
import {useThemeFont} from '../../../themes';

export const createDefaultHighChartsJson = (
  graphSettings,
  tooltipProvider,
) => ({
  chart: {
    type: 'pie',
    height: graphSettings?.height ?? '100%',
  },
  title: {
    text: graphSettings.title,
  },
  subtitle: graphSettings.subtitle,
  plotOptions: graphSettings.plotOptions,
  ...(tooltipProvider && {
    tooltip: {
      formatter: function tooltipWrapper() {
        return tooltipProvider(this, graphSettings.tipText);
      },
      useHTML: true,
    },
  }),
});

export const createHighChartJson = (
  extraOptions,
  graphSettings,
  tooltipProvider,
  theme,
  data,
) => {
  const initialJson = createDefaultHighChartsJson(
    graphSettings,
    tooltipProvider,
    theme,
  );

  const extraOptionsJson = extraOptions ? extraOptions(theme) : {};
  const series = {
    series: [
      {
        ...graphSettings.series,
        data,
      },
    ],
  };

  return merge(
    cloneDeep(series),
    cloneDeep(defaultHighChartsOptionsJson),
    cloneDeep(extraOptionsJson),
    cloneDeep(initialJson),
  );
};

const createDonutSettings = (titleStyles) => ({
  plotOptions: {
    pie: {
      shadow: false,
      size: '100%',
      innerSize: '70%',
      dataLabels: {
        alignTo: 'plotEdges',
        distance: 1,
        connectorWidth: 1,
        style: {
          textOverflow: 'clip',
          lineHeight: '15%',
        },
      },
    },
  },
  subtitle: {
    useHTML: true,
    verticalAlign: 'middle',
    align: 'center',
    floating: true,
    style: {
      ...titleStyles,
      textAlign: 'center',
      fontWeight: 'bold',
    },
  },
});

const DonutChartWidget = (props) => {
  const chartComponent = useRef(null);
  const theme = useTheme();
  const titleStyles = useThemeFont('h6');

  const {
    data,
    extraOptions,
    graphSettings,
    tooltipProvider,
    withDefaultNoData,
  } = props;

  // Creates options json for highcharts
  const chartOptionsJson = createHighChartJson(
    extraOptions,
    merge(
      cloneDeep(createDonutSettings(titleStyles)),
      cloneDeep(graphSettings),
    ),
    tooltipProvider,
    theme,
    data,
  );
  NoDataToDisplay(HighCharts);

  if (!withDefaultNoData) {
    chartOptionsJson.lang.noData = undefined;
  }
  highchartsAccessibility(HighCharts);

  return (
    <HighChartsReact
      key={graphSettings.widgetName}
      ref={chartComponent}
      highcharts={HighCharts}
      options={chartOptionsJson}
    />
  );
};

DonutChartWidget.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      y: PropTypes.number,
      color: PropTypes.string,
    }),
  ).isRequired,
  graphSettings: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.shape({
      text: PropTypes.string,
    }),
    series: PropTypes.shape({
      fillColor: PropTypes.string,
    }),
    widgetName: PropTypes.string,
  }).isRequired,
  extraOptions: PropTypes.func,
  tooltipProvider: PropTypes.func,
  withDefaultNoData: PropTypes.bool,
};

DonutChartWidget.defaultProps = {
  extraOptions: null,
  tooltipProvider: null,
  withDefaultNoData: false,
};
export default DonutChartWidget;
