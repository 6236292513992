import toString from 'lodash/toString';
import sortBy from 'lodash/sortBy';

export const convertIdToString = (object) => ({
  ...object,
  id: toString(object.id),
});

export const convertIdsToString = (data) => {
  if (Array.isArray(data)) {
    return data.map(convertIdToString);
  }
  return convertIdToString(data);
};

export const defaultSort = (fields) => (data) => sortBy(data, fields);

export const serializedRequests = async (requests) => {
  const results = [];
  await requests.reduce(
    (p, request) => p.then(request).then((result) => results.push(result)),
    Promise.resolve(),
  );

  return results;
};
