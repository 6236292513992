import React from 'react';

import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import withSnackbar from '../../../shared/components/snackbarSupport';
import withUrlState from '../../../shared/components/withUrlState';
import TabBar from '../../../shared/components/tabBar';
import ScheduleExceptionsSection from './scheduleExceptions/ScheduleExceptionsSection';
import AlarmBasicConfigurationSection from './AlarmBasicConfigurationSection';

export const alarmConfigurationTabsEnum = {
  ALARM_CONFIGURATION: 0,
  SCHEDULE_EXCEPTIONS: 1,
};

const AlarmConfigurationPageContent = (props) => {
  const {snackbar, currentUser, urlState, setUrlState} = props;

  const handleChangeTab = (tab) => {
    setUrlState({
      selectedTab: tab,
    });
  };

  const getTabs = () => [
    {
      key: 'Configuration',
      label: 'Configuration',
      component: AlarmBasicConfigurationSection,
      props: {snackbar, currentUser},
    },
    {
      key: 'Schedule Exceptions',
      label: 'Schedule Exceptions',
      component: ScheduleExceptionsSection,
      props: {snackbar},
    },
  ];

  return (
    <Box
      data-cy="alarm-configuration-page-content"
      sx={{
        display: 'flex',
        flex: '1 1',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <TabBar
        data-cy="alarm-configuration-page-tabs"
        tabs={getTabs()}
        onChange={handleChangeTab}
        selectedTab={urlState.selectedTab}
        PaperProps={{sx: {mb: 1}}}
      />
    </Box>
  );
};

AlarmConfigurationPageContent.propTypes = {
  currentUser: PropTypes.shape({}).isRequired,
  snackbar: PropTypes.shape({}).isRequired,
};

export default compose(
  withUrlState(),
  withSnackbar,
)(AlarmConfigurationPageContent);
