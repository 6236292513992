import axios from 'axios';
import {convertIdsToString} from './util';
import {sortByNullableField} from '../shared/util/sorting';

export const getAccessList = async (aclId) => {
  const {data} = await axios.get(`/acls/${aclId}`);
  return convertIdsToString(data);
};

export const createAccessList = async (name, description, hidden) => {
  const {data} = await axios.post(`/acls`, {name, description, hidden});
  return convertIdsToString(data);
};

export const updateAccessList = async (aclId, name, description) => {
  const {data} = await axios.put(`/acls/${aclId}`, {name, description});
  return convertIdsToString(data);
};

export const grantAccess = async (aclId, appliances, cameras) => {
  const {data} = await axios.patch(`/acls/${aclId}/grant`, {
    cameras,
    appliances,
  });
  return convertIdsToString(data);
};

export const revokeAccess = async (aclId, appliances, cameras) => {
  const {data} = await axios.patch(`/acls/${aclId}/revoke`, {
    cameras,
    appliances,
  });
  return convertIdsToString(data);
};

export const getAccessLists = async () => {
  const {data} = await axios.get('/acls');

  return sortByNullableField(convertIdsToString(data), 'name', false);
};

export const deleteAccessList = async (id) => axios.delete(`/acls/${id}`);
