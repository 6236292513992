/* eslint-disable react/display-name */
import React, {Component, Fragment} from 'react';
import {Box, Button, Typography} from '@mui/material';
import compose from 'lodash/flowRight';
import clamp from 'lodash/clamp';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import {withWidth} from '../../shared/themes';

import {
  addHexidecimalPrefix,
  motionArrayToMask,
  motionMaskToArray,
  removeHexidecimalPrefix,
} from '../motionMaskModal/utils';
import {CameraMaskEnum} from './const';
import ImageLoader from '../../shared/components/imageLoader';
import ImageLoadError from '../motionMaskModal/ImageLoadError';
import Spinner from '../../shared/components/spinner';

const styles = (theme) => ({
  image: {
    width: '100%',
    height: '100%',
    margin: '0 auto',
  },
  gridCanvas: {
    position: 'absolute',
    top: 0,
    opacity: '.6',
    width: '100%',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    overflowY: 'hidden',
    width: '100%',
    height: '100%',
  },
  topButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  revertButton: {
    marginLeft: theme.spacing(2),
  },
});

class CameraMaskTool extends Component {
  state = {
    gridWidth: null,
    gridHeight: null,
    motionMask: this.props.initialMotionMask
      ? removeHexidecimalPrefix(this.props.initialMotionMask)
      : this.motionMaskEmpty,
    imageLoaded: false,
    isInitialMask: true,
    isMaskCleared: false,
    caption: this.props.caption,
    maskHasSelection:
      this.props.initialMotionMask &&
      this.props.initialMotionMask.indexOf('1') >= 0,
  };

  componentDidMount() {
    this.motionMaskEmpty = removeHexidecimalPrefix(CameraMaskEnum.NONE);
    this.isDragging = false;
    this.isGridSpaceSelected = true;
    this.motionMaskArray = motionMaskToArray(this.state.motionMask);
  }

  loadImg = (img) => {
    const scale = this.isFullScreen ? 1 : 0.75;
    const isPortrait = img.width < img.height;

    // resize portrait images to fit viewport
    if (isPortrait) {
      const ratio = img.width / img.height;

      // eslint-disable-next-line no-param-reassign
      img.width = this.isFullScreen
        ? this.deviceWidth
        : this.deviceHeight * ratio;
      // eslint-disable-next-line no-param-reassign
      img.height = this.isFullScreen
        ? this.deviceWidth / ratio
        : this.deviceHeight;

      // shrink image height if larger than content area
      // eslint-disable-next-line no-param-reassign
      img.height = Math.min(img.height, this.deviceHeight * 0.75);
    }

    const {width, height} = img;

    this.motionMaskSizeX = 20;
    this.motionMaskSizeY = 15;
    this.boxSizeWidth = (width * scale) / this.motionMaskSizeX;
    this.boxSizeHeight = (height * scale) / this.motionMaskSizeY;
    this.gridCanvas.width = width * scale;
    this.gridCanvas.height = height * scale;
    this.setState(
      {gridWidth: this.gridCanvas.width, gridHeight: this.gridCanvas.height},
      this.drawBoxes,
    );
  };

  drawBoxes = () => {
    const {theme} = this.props;
    const ctx = this.gridCanvas.getContext('2d');
    let haveSelectedBox = false;
    ctx.beginPath();
    ctx.lineWidth = 1;
    ctx.strokeStyle = theme.palette.accent.main;
    ctx.fillStyle = theme.palette.primary.main;

    this.motionMaskArray.forEach((rowValue, row) =>
      // eslint-disable-next-line array-callback-return
      this.motionMaskArray[row].forEach((columnValue, column) => {
        const x = column * this.boxSizeWidth;
        const y = row * this.boxSizeHeight;

        ctx.rect(x, y, this.boxSizeWidth, this.boxSizeHeight);
        if (columnValue) {
          ctx.fillRect(x, y, this.boxSizeWidth, this.boxSizeHeight);
          haveSelectedBox = true;
        } else {
          ctx.clearRect(x, y, this.boxSizeWidth, this.boxSizeHeight);
        }
        ctx.stroke();
      }),
    );
    ctx.closePath();
    if (haveSelectedBox !== this.state.maskHasSelection) {
      this.setState({maskHasSelection: haveSelectedBox});
    }
    this.setState({isMaskCleared: this.isMaskCleared()});
  };

  getCoords = (e) => {
    const x = Math.floor(
      clamp(
        e.nativeEvent.offsetX / this.boxSizeWidth,
        0,
        this.motionMaskSizeX - 1,
      ),
    );
    const y = Math.floor(
      clamp(
        e.nativeEvent.offsetY / this.boxSizeHeight,
        0,
        this.motionMaskSizeY - 1,
      ),
    );
    return {column: x, row: y};
  };

  getBit = (column, row) => this.motionMaskArray[row][column];

  setBit = (column, row, value) => {
    if (this.motionMaskArray[row][column] === value) {
      return;
    }
    this.motionMaskArray[row][column] = value;
    this.props.setMotionMask(
      addHexidecimalPrefix(motionArrayToMask(this.motionMaskArray)),
    );
    this.setState({isInitialMask: this.isInitialMask()});
  };

  handleClick = (e) => {
    const coords = this.getCoords(e);
    const value = this.getBit(coords.column, coords.row);
    this.setBit(coords.column, coords.row, !value);
    this.drawBoxes();
  };

  handleMouseDown = (e) => {
    const coords = this.getCoords(e);
    const value = this.getBit(coords.column, coords.row);
    this.isDragging = true;
    this.isGridSpaceSelected = !value;
  };

  handleDrag = (e) => {
    const coords = this.getCoords(e);
    if (this.isDragging) {
      this.setBit(coords.column, coords.row, this.isGridSpaceSelected);
      this.drawBoxes();
    }
  };

  handleMouseUp = (e) => {
    if (this.isDragging) {
      const coords = this.getCoords(e);
      this.setBit(coords.column, coords.row, !this.isGridSpaceSelected);
      this.isDragging = false;
    }
  };

  revertMask = () => {
    this.motionMaskArray = motionMaskToArray(
      removeHexidecimalPrefix(this.props.initialMotionMask),
    );
    this.drawBoxes();
    this.props.setMotionMask(
      addHexidecimalPrefix(motionArrayToMask(this.motionMaskArray)),
    );
    this.setState({isInitialMask: true});
  };

  clearMotionMask = () => {
    this.motionMaskArray = motionMaskToArray(this.motionMaskEmpty);
    this.drawBoxes();

    this.props.setMotionMask(
      addHexidecimalPrefix(motionArrayToMask(this.motionMaskArray)),
    );
    this.setState({isInitialMask: this.isInitialMask()});
    this.setState({isMaskCleared: this.isMaskCleared()});
  };

  isMaskCleared = () =>
    motionArrayToMask(this.motionMaskArray) === this.motionMaskEmpty;

  isInitialMask = () => {
    return (
      addHexidecimalPrefix(motionArrayToMask(this.motionMaskArray)) ===
      this.props.initialMotionMask
    );
  };

  render() {
    const {classes, imageUrl, useHandleRevert, configSaveSuccess} = this.props;
    const {
      gridWidth,
      gridHeight,
      imageLoaded,
      isInitialMask,
      isMaskCleared,
      caption,
    } = this.state;

    const width = withWidth();
    this.deviceWidth = window.innerWidth;
    this.deviceHeight = window.innerHeight;
    this.isFullScreen = ['xs', 'sm'].includes(width);

    return (
      <Fragment>
        <div className={classes.topButtons}>
          <Typography style={{alignSelf: 'center'}}>
            {caption ||
              'Draw in the area where you wish to detect motion for your alarm'}
          </Typography>
          {imageLoaded && (
            <div>
              <Button
                color="primary"
                onClick={this.clearMotionMask}
                disabled={isMaskCleared || configSaveSuccess}
                data-cy="clear-mask"
              >
                Clear
              </Button>
              {useHandleRevert && (
                <Button
                  className={classes.revertButton}
                  color="primary"
                  onClick={this.revertMask}
                  disabled={isInitialMask || configSaveSuccess}
                  data-cy="revert-mask"
                >
                  Revert
                </Button>
              )}
            </div>
          )}
        </div>
        <div id="camera-mask-tool" className={classes.contentContainer}>
          <Box position="relative">
            <Box width={gridWidth} height={gridHeight}>
              <ImageLoader
                alt=""
                src={imageUrl}
                className={classes.image}
                unloader={<ImageLoadError />}
                loader={<Spinner size={40} color="primary" />}
                showSpinnerOnChange
                onLoaded={(img) => {
                  this.loadImg(img);
                  this.setState({imageLoaded: true});
                }}
              />
            </Box>

            <canvas
              ref={(node) => {
                this.gridCanvas = node;
              }}
              className={classes.gridCanvas}
              onClick={this.handleClick}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
              onMouseMove={this.handleDrag}
              data-cy="camera-mask-canvas"
            />
          </Box>
        </div>
      </Fragment>
    );
  }
}

CameraMaskTool.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  initialMotionMask: PropTypes.string,
  setMotionMask: PropTypes.func.isRequired,
  useHandleRevert: PropTypes.bool,
  configSaveSuccess: PropTypes.bool.isRequired,
};

CameraMaskTool.defaultProps = {
  initialMotionMask: undefined,
  useHandleRevert: true,
};

export default compose(withStyles(styles, {withTheme: true}))(CameraMaskTool);
