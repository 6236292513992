import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

import PageTemplate from '../../shared/components/pageTemplate';
import ReportProblemForm from '../../apps/reportProblemForm';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const ReportProblem = ({location}) => {
  const classes = useStyles();

  const queryParams = queryString.parse(location.search);

  return (
    <PageTemplate
      title="Report Problem"
      PageHeaderProps={{
        title: 'Report Problem',
      }}
      className={classes.root}
    >
      <ReportProblemForm
        name="report-problem-form"
        defaultValues={queryParams}
      />
    </PageTemplate>
  );
};

export default withRouter(ReportProblem);
