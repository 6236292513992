import React, {useEffect, useState, useMemo} from 'react';
import {TextField, InputAdornment, IconButton} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

function CallbackFilterField(props) {
  const debounceTimeInMs = 300;
  const {
    classes,
    filterValue,
    InputButtonProps,
    onFilter,
    isDebounced,
    ...other
  } = props;

  const [filterField, setFilterFieldValue] = useState(filterValue);

  useEffect(
    () => {
      setFilterFieldValue(filterValue);
    },
    [filterValue],
  );
  const debounceFilter = useMemo(
    () =>
      debounce((val) => {
        onFilter(val);
      }, debounceTimeInMs),
    [onFilter, debounceTimeInMs],
  );

  const handleChange = (event) => {
    setFilterFieldValue(event.target.value);

    if (isDebounced) {
      debounceFilter(event.target.value);
    }
  };

  return (
    <TextField
      {...other}
      name="callback-filter-field"
      value={filterField}
      onChange={handleChange}
      InputLabelProps={{style: {top: 'unset'}}}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Search"
              onClick={() => onFilter(filterField)}
              {...InputButtonProps}
              size="large"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

CallbackFilterField.propTypes = {
  label: PropTypes.string,
  margin: PropTypes.string,
  onFilter: PropTypes.func,
  isDebounced: PropTypes.bool,
  filterValue: PropTypes.string,
  InputButtonProps: PropTypes.shape({}),
};

CallbackFilterField.defaultProps = {
  label: 'Search',
  margin: 'normal',
  onFilter: () => {},
  isDebounced: false,
  filterValue: undefined,
  InputButtonProps: {},
};

export default CallbackFilterField;
