import React, {createContext, useState, useContext} from 'react';

export const SiteAlarmConfigurationContext = createContext(null);

export const AlarmSiteConfigurationContextProvider = ({
  children,
  initConfig = {},
}) => {
  const [siteConfig, setSiteConfig] = useState(initConfig);

  return (
    <SiteAlarmConfigurationContext.Provider
      value={{
        selectedSiteConfig: siteConfig,
        setSelectedSiteConfig: setSiteConfig,
      }}
    >
      {children}
    </SiteAlarmConfigurationContext.Provider>
  );
};

export const useAlarmSiteConfig = () => {
  const {selectedSiteConfig, setSelectedSiteConfig} = useContext(
    SiteAlarmConfigurationContext,
  );

  return [selectedSiteConfig, setSelectedSiteConfig];
};
