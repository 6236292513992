import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import HighChartsWidget from '../../../../shared/components/highcharts';
import {getSiteByDayDataSummary} from '../../../../api/reports';
import * as salesDollars from './widgetUtils/salesSummaryUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

const SalesSummaryWidget = ({currentUser, widgetConfig}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;
  const theme = useTheme();

  const getContent = () => {
    return (
      <HighChartsWidget
        id="highcharts-widget-sales"
        getData={getSiteByDayDataSummary}
        reportLookBackDays={reportLookBackDays}
        currentUserDate={currentUserDate}
        getDrilldownData={null}
        formatData={salesDollars.formatData}
        formatDualAxes={salesDollars.formatDataDualAxis}
        tooltipProvider={salesDollars.formatTooltip}
        graphSettings={{
          tipText: 'Sales Dollars:',
          chartType: 'column',
          seriesName: 'Sales Summary',
          title: widgetConfig.name || 'Sales Summary',
          subtitle:
            widgetConfig.description ||
            'Summary of sales information over the last seven days.',
          yAxisTitle: '',
          columnColor: theme.palette.primary.main,
          hoverColor: theme.palette.primary.light,
          widgetName: 'sales-summary-widget',
        }}
        extraOptions={salesDollars.extraOptions}
        currentUser={currentUser}
      />
    );
  };
  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(SalesSummaryWidget);
