import React, {useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {Box, ListItemIcon, ListItemText, Link} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import SecondNavListLink from './SecondNavListLink';

const SecondNavListItem = (props) => {
  const {
    primaryTitleText,
    shortTitleText,
    icon,
    to,
    bottom,
    secondary,
    onSubNavOpen,
    onMainNavItemClick,
    isHovered,
    isOpenedNavigation,
    onSubNavClose,
    mouseEnter,
    mouseLeave,
    navItemsCount,
    redirectToNewTab,
    redirectOutsideRoute,
    relativePath,
    customIsActiveMatcher,
  } = props;
  const hasSubItems = Array.isArray(to);
  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      forwardRef((itemProps, ref) =>
        redirectOutsideRoute ? (
          <Link
            href={`https://${window.location.host}${to}`}
            {...itemProps}
            ref={ref}
            target={redirectToNewTab ? '_blank' : ''}
          />
        ) : (
          <NavLink
            to={Array.isArray(to) ? to.toString() : to}
            {...itemProps}
            innerRef={ref}
          />
        ),
      ),
    [to, redirectToNewTab, redirectOutsideRoute],
  );

  const handleListItemBoxStyle = () => {
    const baseStyle = {
      display: 'flex',
    };

    const openedStyle = {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    };

    const closedStyle = {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      whiteSpace: 'pre-wrap',
      width: (theme) => theme.spacing(7),
      pt: 0.5,
    };

    return {
      ...baseStyle,
      ...(isOpenedNavigation ? openedStyle : closedStyle),
    };
  };

  const handleListItemBoxBottomStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 'max-content',
  };
  const bottomNavItemWidth = `calc(100vw/${navItemsCount} - 24px)`; // 24px - sum of paddings

  const elipsisBottomText = bottom
    ? {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: bottomNavItemWidth,
      }
    : {};

  const menuIcon = (
    <ListItemIcon
      name={`${primaryTitleText}-icon`}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mr: bottom ? 0 : 2,
      }}
    >
      {icon}
    </ListItemIcon>
  );

  const getPrimaryFontSize = () => {
    if (bottom) {
      return 10;
    }
    return isOpenedNavigation ? 'inherit' : 8;
  };

  const menuText = (
    <ListItemText
      sx={{
        mt: bottom ? 0.25 : 0,
      }}
      primary={(bottom && shortTitleText) || primaryTitleText}
      secondary={isOpenedNavigation ? secondary : null}
      primaryTypographyProps={{
        sx: {
          fontSize: getPrimaryFontSize(),
          textAlign: bottom || !isOpenedNavigation ? 'center' : 'left',
          marginRight: bottom ? 0 : 2,
          ...elipsisBottomText,
        },
      }}
      secondaryTypographyProps={{
        sx: {
          fontSize: 10,
          fontWeight: 500,
        },
      }}
    />
  );

  return (
    <SecondNavListLink
      to={to}
      component={!hasSubItems ? renderLink : null}
      relative={relativePath}
      bottom={bottom}
      onSubNavOpen={onSubNavOpen}
      onMainNavItemClick={onMainNavItemClick}
      onSubNavClose={onSubNavClose}
      isHovered={isHovered}
      mouseEnter={mouseEnter}
      mouseLeave={mouseLeave}
      customIsActiveMatcher={customIsActiveMatcher}
    >
      <Box
        sx={bottom ? handleListItemBoxBottomStyle : handleListItemBoxStyle()}
      >
        {menuIcon}
        {menuText}
      </Box>
      {!bottom && hasSubItems && <KeyboardArrowRightIcon />}
    </SecondNavListLink>
  );
};

SecondNavListItem.propTypes = {
  /**
   * Default label for items
   */
  primaryTitleText: PropTypes.string.isRequired,
  /**
   * Second label, used only if is present and only for bottom navigation items
   */
  shortTitleText: PropTypes.string,
  /**
   * Icon of menu item
   */
  icon: PropTypes.node.isRequired,
  /**
   * Path to redirect the page.
   * string - is for main item;
   * array of strings - is for item that contains sub items
   */
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  /**
   * Boolean for positioning menu in bottom, below medium viewport 720px.
   */
  bottom: PropTypes.bool,
  /**
   * Sub title, present only when drawer is open
   * Inform about current selected sub item of menu
   */
  secondary: PropTypes.string,
  /**
   * Callback function for open menu item when sub items are present
   */
  onSubNavOpen: PropTypes.func.isRequired,
  /**
   * Callback function handle on clicking the menu item
   */
  onMainNavItemClick: PropTypes.func,
  /**
   * Information if main item is hovered to apply styles
   */
  isHovered: PropTypes.bool,
  /**
   * Information if the navigation drawer is open / closed.
   */
  isOpenedNavigation: PropTypes.bool,
  /**
   * Callback function for close menu item when sub items are present
   */
  onSubNavClose: PropTypes.func,
  /**
   * Callback function handle on mouse enter event
   */
  mouseEnter: PropTypes.func,
  /**
   * Callback function handle on mouse leave event
   */
  mouseLeave: PropTypes.func,
  /**
   * Number of menu items length
   */
  navItemsCount: PropTypes.number.isRequired,
  /**
   * Information if user should be redirected to new tab when clicked on item
   */
  redirectToNewTab: PropTypes.bool,
  /**
   * Information if url should contain default route /ui
   */
  redirectOutsideRoute: PropTypes.bool,
  /**
   * Information if path is relative
   */
  relativePath: PropTypes.bool,
  /**
   * Callback function handle for custom path match to highlight active item
   */
  customIsActiveMatcher: PropTypes.func,
};

SecondNavListItem.defaultProps = {
  bottom: false,
  secondary: null,
  onMainNavItemClick: null,
  isHovered: false,
  isOpenedNavigation: false,
  onSubNavClose: null,
  mouseEnter: null,
  mouseLeave: null,
  shortTitleText: '',
  redirectToNewTab: false,
  redirectOutsideRoute: false,
  relativePath: true,
  customIsActiveMatcher: null,
};
export default SecondNavListItem;
