import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import MonitorIcon from '@mui/icons-material/Monitor';
import OpenInNewIcon from '@mui/icons-material/OpenInNewOutlined';
import {Alert} from '@mui/material';

import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';
import {useApi} from '../../../../shared/hooks';
import {getAppliancesHealthSummary} from '../../../../api/hardwareHealth';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import {openInOneTab} from '../../utils';

const ApplianceWidget = ({widgetConfig, currentUser}) => {
  const [
    applianceHealthSummary,
    applianceHealthSummaryLoading,
    isError,
    getApplianceHealthSummary,
  ] = useApi(getAppliancesHealthSummary);

  useEffect(
    () => {
      const currentTimestamp = DateTime.utc().toISO();
      const dayAgoTimestamp = DateTime.utc()
        .minus({
          hours: 24,
        })
        .toISO();
      getApplianceHealthSummary(dayAgoTimestamp, currentTimestamp);
    },
    [getApplianceHealthSummary],
  );
  const count = applianceHealthSummary?.offline;
  const offlineChanges = applianceHealthSummary?.offlineChanges;
  const loading = applianceHealthSummaryLoading;

  const content = isError
    ? [
        {
          id: 'error-appliances',
          label: <Alert severity="error">Error loading widget.</Alert>,
        },
      ]
    : [
        {
          id: 'offline-appliances',
          label:
            count > 0
              ? 'Total Offline Appliances:'
              : 'All appliances are online',
          value: count ? count.toString() : undefined,
        },
        {
          id: 'last-day-offline-appliances',
          label: count > 0 ? 'Offline appliances in the last 24 h:' : undefined,
          value: count > 0 ? offlineChanges.toString() : undefined,
        },
      ];

  return (
    <DashboardStatusWidget
      id={widgetConfig.widgetReference}
      content={content}
      title="Appliances"
      icon={MonitorIcon}
      loading={loading}
      pathname="/hardware-health"
      search="?status=offline,impaired"
      target={openInOneTab(currentUser)}
      buttonLabel="View Appliance Details"
      buttonIcon={OpenInNewIcon}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(ApplianceWidget);
