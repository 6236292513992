import React, {Fragment} from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import {useMediaQuery} from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => {
  const isMenuVisible =
    document.getElementById('envysion-common-menu') ||
    document.getElementById('top-navigation-bar');

  return {
    mediumBackdrop: {position: 'absolute', zIndex: 12, height: '100%'},
    expandCollapse: {
      textAlign: 'right',
      padding: theme.spacing(1),
    },
    temporaryDrawerPaper: {
      ...theme.mixins.drawer,
    },
    temporaryDrawer: {
      position: 'absolute',
      width: theme.spacing(32),
      zIndex: 2,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      paddingTop: isMenuVisible
        ? `${theme.mixins.toolbar.height * 2}px`
        : theme.mixins.toolbar.height,
    },
    drawerContent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      paddingTop: theme.spacing(3),
    },
    spacing: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    overflow: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflowX: 'hidden',
    },
  };
});

const ResponsiveDrawer = ({
  openDrawer,
  onOpenDrawer,
  drawerContent,
  secondNavigation,
  drawerHeight,
}) => {
  const classes = useStyles();
  const isLowerThanMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const isMedium = useMediaQuery((theme) => theme.breakpoints.only('md'));

  const drawerBody = (isCollapsed) =>
    typeof drawerContent === 'function'
      ? drawerContent(isCollapsed)
      : drawerContent;

  return (
    <Fragment>
      {isLowerThanMediumScreen ? (
        <Drawer
          data-cy="temporary-drawer"
          variant="temporary"
          open={openDrawer}
          onClose={onOpenDrawer}
          classes={{
            root: classes.temporaryDrawer,
            paper: classes.temporaryDrawerPaper,
          }}
        >
          <div className={classes.drawer}>{drawerBody(!openDrawer)}</div>
        </Drawer>
      ) : (
        <Drawer
          data-cy="mini-variant-drawer"
          variant="permanent"
          open={openDrawer}
          anchor="left"
          sx={(theme) => ({
            ...theme.mixins.drawer,
            position: isMedium ? 'absolute' : 'relative',
            zIndex: theme.zIndex.drawer,
            height: drawerHeight,
            transition: theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: openDrawer
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
            }),
            width: openDrawer ? theme.spacing(32) : theme.spacing(9),
            boxShadow: theme.shadows[16],
          })}
          PaperProps={{
            sx: {
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          <div
            className={classNames(classes.drawerContent, {
              [classes.spacing]: !secondNavigation,
            })}
          >
            <div className={classes.overflow}>{drawerBody(!openDrawer)}</div>
          </div>
          <div className={classes.expandCollapse}>
            <IconButton size="large" onClick={onOpenDrawer}>
              {openDrawer ? (
                <KeyboardDoubleArrowLeftIcon data-cy="collapse-icon" />
              ) : (
                <KeyboardDoubleArrowRightIcon data-cy="expand-icon" />
              )}
            </IconButton>
          </div>
        </Drawer>
      )}
    </Fragment>
  );
};

ResponsiveDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  drawerContent: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired,
  secondNavigation: PropTypes.bool,
  drawerHeight: PropTypes.string,
};

ResponsiveDrawer.defaultProps = {
  secondNavigation: false,
  drawerHeight: undefined,
};
export default ResponsiveDrawer;
