import React, {PureComponent} from 'react';
import {Switch, Route} from 'react-router-dom';
import queryString from 'query-string';

import PageLoader from '../../shared/components/pageLoader';
import {getCameras} from '../../api/sensors';
import {getSites} from '../../api/sites';
import {
  getRootCategory,
  findCategory,
  getChildSites,
} from '../../shared/util/sites';
import {getViews} from '../../api/savedViews';
import {LIVE_MONITOR_SITES_PER_LOAD} from '../../shared/constants';
import {LIVE_VIDEO} from '../../shared/util/allowed';
import GlobalErrorPage from '../globalErrorPage';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';

class LiveMonitor extends PureComponent {
  state = {
    filterValue: '',
  };

  setRouteState = (stateChanges) => {
    this.setState(stateChanges);
  };

  render() {
    const {match} = this.props;
    const loaderProps = {
      ...this.state,
      setRouteState: this.setRouteState,
      forceToRoot: !!this.state.filterValue,
    };

    return (
      <CapabilitiesGuard
        hasAll
        allowed={[LIVE_VIDEO]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <Switch>
          <Route
            exact
            path={match.path}
            render={() => (
              <PageLoader
                page={() => import('./select')}
                resources={{
                  all: async () => {
                    const category = getRootCategory(await getSites());
                    return {
                      category,
                      views: await getViews(),
                      cameras: await getCameras(
                        getChildSites(category)
                          .slice(0, LIVE_MONITOR_SITES_PER_LOAD)
                          .map((s) => s.id),
                      ),
                    };
                  },
                }}
                propsToIgnoreUpdate={['filterValue']}
                {...loaderProps}
              />
            )}
          />
          <Route
            exact
            path={`${match.path}/category/:categoryId`}
            render={(renderProps) => {
              const {categoryId} = renderProps.match.params;
              return (
                <PageLoader
                  page={() => import('./select')}
                  resources={{
                    all: async () => {
                      const rootCategory = getRootCategory(await getSites());
                      const category = findCategory(rootCategory, categoryId);
                      const siteIds = getChildSites(category).map((s) => s.id);
                      return {
                        category,
                        rootCategory,
                        views: await getViews(),
                        cameras: siteIds.length
                          ? await getCameras(siteIds)
                          : [],
                      };
                    },
                  }}
                  propsToIgnoreUpdate={['filterValue']}
                  {...loaderProps}
                />
              );
            }}
          />
          <Route
            path={`${match.path}/view`}
            render={(renderProps) => {
              const {cameras} = queryString.parse(renderProps.location.search);
              const cameraIds = cameras.split(',');
              return (
                <PageLoader
                  page={() => import('./view')}
                  resources={{cameraIds: () => Promise.resolve(cameraIds)}}
                  {...renderProps}
                />
              );
            }}
          />
        </Switch>
      </CapabilitiesGuard>
    );
  }
}

export default LiveMonitor;
