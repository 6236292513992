import memoize from 'memoize-one';

export const calculateState = memoize((urlState, defaultState) => {
  const params = {};
  Object.entries(urlState).forEach(([key, val]) => {
    let value = val;
    if (key === 'cameras' || key === 'savedCameras') {
      value = val.split(',' || '');
    }
    if (value === 'true') {
      value = true;
    }
    if (value === 'false') {
      value = false;
    }
    Object.assign(params, {
      [key]: value,
    });
  });
  return {...defaultState, ...params};
});
