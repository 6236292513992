import React, {Fragment} from 'react';
import {Alert, AlertTitle, List, ListItem, styled, Box} from '@mui/material';
import {DateTime} from 'luxon';
import {PropTypes} from 'prop-types';

const StyledList = styled(List)({
  listStyleType: 'disc',
  listStylePosition: 'inside',
});
const StyledListItem = styled(ListItem)({
  display: 'list-item',
  fontSize: '14px',
});

const EditTemplateInfoSection = ({conflicts}) => {
  if (conflicts) {
    const format = (date) =>
      DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm').toFormat(
        'MMM/dd/yy - hh:mma',
      );

    return (
      <Alert
        data-cy="template-conflict-section"
        severity="error"
        sx={{marginTop: 1}}
      >
        <AlertTitle>Conflicts with:</AlertTitle>
        <Box>
          Exception cannot be Saved as we found Conflicts (overlapping) with
          existing Exceptions.
        </Box>
        <Box>
          To continues please adjust hours so Exceptions don&apos;t overlap, or
          remove conflicted sites.
        </Box>
        <StyledList>
          <Fragment>
            {conflicts.map((c) => (
              <StyledListItem
                disablePadding
                key={`schedule-conflict-${c.name}`}
              >
                {c.name} - {format(c.startDate)} - {format(c.endDate)}
              </StyledListItem>
            ))}
          </Fragment>
        </StyledList>
      </Alert>
    );
  }

  return (
    <Alert
      data-cy="template-information-section"
      severity="info"
      sx={{marginTop: 1}}
    >
      <AlertTitle>Exception Info:</AlertTitle>
      <StyledList>
        <Fragment>
          <StyledListItem disablePadding>
            Alarm will be Armed during this time
          </StyledListItem>
          <StyledListItem disablePadding>
            Ensure sites are signed to the template
          </StyledListItem>
          <StyledListItem disablePadding>
            Alarm Exceptions will rolloff the list after time period is complete
          </StyledListItem>
        </Fragment>
      </StyledList>
    </Alert>
  );
};

EditTemplateInfoSection.propTypes = {
  conflicts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }),
  ),
};

EditTemplateInfoSection.defaultProps = {
  conflicts: null,
};

export default EditTemplateInfoSection;
