import React from 'react';
import {Button, Typography} from '@mui/material';
import {Formik, Form} from 'formik';
import * as Yup from 'yup';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {DateTime} from 'luxon';

import {buildField, structureToSchemaReducer} from './formBuilderUtils';

const styles = (theme) => ({
  form: {},
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    '& $formField': {
      paddingBottom: theme.spacing(2),
    },
  },
  formField: {},
  selectInputLabel: {
    // necessary for IE-11
    position: 'relative',
  },
  formControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& button:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  switchHelpText: {
    // using withStyles in switch causes warnings
    marginTop: theme.spacing(-2),
  },
  smallHelpIcon: {
    fontSize: theme.typography.body1.fontSize,
  },
});

const mapPropsToValues = (structure, props) => {
  const reducer = (acc, value) => {
    const fieldType = typeof get(props, value.name);
    acc[value.name] =
      fieldType === 'number' || fieldType === 'boolean'
        ? get(props, value.name)
        : get(props, value.name) || value.defaultValue;
    return acc;
  };
  return structure.reduce(reducer, {});
};

const FormBuilder = (props) => {
  const {
    structure,
    onSubmit,
    classes,
    onCancel,
    error,
    final,
    extraActions,
    reloadOnPropChange,
    submitButtonText,
    initialDirty,
    ...rest
  } = props;

  const validationSchema = Yup.object().shape(
    structure.reduce(structureToSchemaReducer, {}),
  );

  const focusedIndex = structure.findIndex(
    (fieldDef) =>
      !fieldDef.disabled &&
      (['TextField', 'SelectList'].includes(fieldDef.component) ||
        typeof fieldDef.component === 'undefined'),
  );

  return (
    <Formik
      validationSchema={validationSchema}
      validateOnMount
      validateOnChange
      isInitialValid={
        typeof initialDirty === 'boolean' ? !initialDirty : undefined
      }
      initialValues={mapPropsToValues(structure, rest)}
      onSubmit={async (values, {setSubmitting}) => {
        setSubmitting(true);
        try {
          await onSubmit(values);
        } finally {
          if (!final) {
            setSubmitting(false);
          }
        }
      }}
      enableReinitialize={reloadOnPropChange}
    >
      {(formProps) => (
        <Form className={classes.form}>
          <div className={classes.formFields}>
            {structure.map((fieldDef, index) => {
              let {visible = true} = fieldDef;
              if (typeof fieldDef.visible === 'function') {
                visible = fieldDef.visible(formProps.values);
              }
              if (!visible) {
                return null;
              }
              return buildField(
                fieldDef,
                formProps,
                classes,
                index === focusedIndex,
              );
            })}
          </div>
          {error && (
            <div className={classes.error} name="form-errors">
              <Typography color="inherit">
                Unable to submit your request:
              </Typography>
              <Typography color="inherit">{error}</Typography>
            </div>
          )}
          <div className={classes.formControls}>
            {extraActions}
            {onCancel && (
              <Button variant="text" onClick={onCancel} data-cy="cancel-btn">
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              color="primary"
              disabled={formProps.isSubmitting || !formProps.isValid}
              variant="contained"
              onClick={formProps.handleSubmit}
            >
              {submitButtonText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

FormBuilder.propTypes = {
  structure: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string,
      defaultValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf(DateTime),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.arrayOf(PropTypes.string),
      ]),
      component: PropTypes.string,
      render: PropTypes.func,
      validation: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
        }),
      ),
      format: PropTypes.func,
      visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
      helperText: PropTypes.string,
      ComponentProps: PropTypes.shape({}),
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  error: PropTypes.string,
  final: PropTypes.bool,
  extraActions: PropTypes.node,
  reloadOnPropChange: PropTypes.bool,
  submitButtonText: PropTypes.string,
  initialDirty: PropTypes.bool,
};

FormBuilder.defaultProps = {
  onCancel: null,
  error: undefined,
  final: false,
  extraActions: [],
  reloadOnPropChange: false,
  submitButtonText: 'Submit',
  initialDirty: undefined,
};

export default withStyles(styles)(FormBuilder);
