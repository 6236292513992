import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListItemButton,
  Paper,
  DialogContentText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import omit from 'lodash/omit';
import Tooltip from '../../../shared/components/tooltip';
import {getAlarmSchedule, updateAlarmSchedule} from '../../../api/alarms';
import Spinner from '../../../shared/components/spinner';
import ScheduleConfigurationDialog from './scheduleConfiguration/ScheduleConfigurationDialog';
import ConfirmationDialog from '../../../shared/components/confirmationDialog';

const listItemSx = {
  '&:hover': {
    '& .deleteSchedule': {
      visibility: 'visible',
    },
  },
};

const SchedulesList = (props) => {
  const {
    schedules,
    onDelete,
    onRefresh,
    primaryTypographyProps,
    snackbar,
  } = props;

  const [deletedScheduleId, setDeletedScheduleId] = useState();
  const [scheduleConfiguration, setScheduleConfig] = useState({
    loading: false,
    data: null,
    id: null,
  });

  const updateScheduleConfig = (newScheduleConfig) =>
    setScheduleConfig((oldScheduleConfig) => ({
      ...oldScheduleConfig,
      ...newScheduleConfig,
    }));

  const handleClearScheduleConfig = () => {
    updateScheduleConfig({data: null, id: null});
  };

  const handleUpdateScheduleConfig = async (schedule) => {
    try {
      await updateAlarmSchedule(schedule.id, omit(schedule, 'id'));
      snackbar.success('Schedule updated successfully.');

      await onRefresh();
    } catch (e) {
      snackbar.error(
        'Unable to update schedule. Please try again or contact support if the issue persists.',
      );
    }
    handleClearScheduleConfig();
  };

  useEffect(
    () => {
      const getSchedulesConfig = async () => {
        if (scheduleConfiguration.id) {
          updateScheduleConfig({loading: true});
          try {
            updateScheduleConfig({
              data: await getAlarmSchedule(scheduleConfiguration.id),
              loading: false,
            });
          } catch (e) {
            updateScheduleConfig({data: null, loading: false});
            snackbar.error(
              'Unable to get site schedule. Please try again or contact support if the issue persists.',
            );
          }
        }
      };
      getSchedulesConfig();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduleConfiguration.id],
  );

  const isLoading = (schedule) =>
    scheduleConfiguration.loading && schedule.id === scheduleConfiguration.id;

  return (
    <Paper sx={{overflow: 'auto'}}>
      <List data-cy="unassigned-schedules-list" id="schedules-list">
        {schedules?.length ? (
          schedules.map((schedule) => (
            <ListItemButton
              id="schedules-list-item"
              sx={listItemSx}
              key={`schedule-${schedule.id}`}
              onClick={() => {
                updateScheduleConfig({id: schedule.id});
              }}
            >
              <ListItemText
                primary={schedule.name}
                primaryTypographyProps={primaryTypographyProps}
              />
              <ListItemSecondaryAction>
                {isLoading(schedule) ? (
                  <Spinner name="loading-spinner" size={30} color="primary" />
                ) : (
                  <Tooltip
                    className="deleteSchedule"
                    sx={{visibility: 'hidden'}}
                    title={
                      <span style={{whiteSpace: 'nowrap'}}>
                        Delete schedule
                      </span>
                    }
                    placement="left"
                    PopperProps={{disablePortal: true}}
                  >
                    <IconButton
                      id="schedule-delete"
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation(); // disable main row click action
                        setDeletedScheduleId(schedule.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItemSecondaryAction>
            </ListItemButton>
          ))
        ) : (
          <ListItemButton>
            <ListItemText primary="No data" />
          </ListItemButton>
        )}
      </List>
      <ScheduleConfigurationDialog
        schedules={schedules}
        schedule={scheduleConfiguration.data}
        schedulesConfigurationOpen={!!scheduleConfiguration.data}
        onSchedulesConfigurationClose={handleClearScheduleConfig}
        onUpdateScheduleConfiguration={handleUpdateScheduleConfig}
      />
      <ConfirmationDialog
        maxWidth="sm"
        fullWidth
        open={deletedScheduleId != null}
        onClose={() => setDeletedScheduleId(null)}
        title="Delete Confirmation"
        onConfirm={() => onDelete(deletedScheduleId)}
        confirmLabel="delete"
        destructive
      >
        <DialogContentText>
          Are you sure you want to delete this schedule?
        </DialogContentText>
      </ConfirmationDialog>
    </Paper>
  );
};

SchedulesList.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onDelete: PropTypes.func,
  onRefresh: PropTypes.func,
  primaryTypographyProps: PropTypes.shape({}),
};

SchedulesList.defaultProps = {
  onDelete: () => {},
  onRefresh: () => {},
  primaryTypographyProps: {},
};

export default SchedulesList;
