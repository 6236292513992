import * as React from 'react';
import {TableCell, TableRow, Collapse, IconButton, Box} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  mainRow: {
    '& > td': {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      borderBottom: 'unset',
    },
    '&:hover': {
      '& $rowActions': {
        visibility: 'visible',
      },
    },
  },
  rowActions: {
    visibility: 'hidden',
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

const Row = (props) => {
  const {
    columns,
    rowData,
    onExpandCollapsibleData: handleAdditionalData,
    onRowClick,
    rowActions,
    columnsSpan,
    selected,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const [expandableData, setExpandableData] = React.useState([]);

  const handleOpenCollapsibleRecord = async (row) => {
    if (!open) {
      const data = await handleAdditionalData(row);
      setExpandableData(data);
    }
    setOpen(!open);
  };

  const handleRowClick = async () => {
    if (onRowClick) {
      await onRowClick(rowData);
    }
    if (handleAdditionalData) {
      await handleOpenCollapsibleRecord(rowData);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        data-cy="collapsible-row"
        hover
        onClick={handleRowClick}
        className={classes.mainRow}
        selected={selected}
      >
        {handleAdditionalData && (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpenCollapsibleRecord(rowData)}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((col) => {
          const {format} = col;
          const cellData = get(rowData, col.id);
          const formattedData = format ? format(cellData, rowData) : cellData;
          return (
            <TableCell key={col.id} name={col.id}>
              <React.Fragment>{formattedData}</React.Fragment>
            </TableCell>
          );
        })}
        {rowActions && (
          <TableCell>
            <div data-cy="row-actions" className={classes.rowActions}>
              {rowActions.map((action, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`actions_${idx}`}>{action(rowData)}</div>
              ))}
            </div>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell
          style={{padding: 0, borderTop: 'hidden'}}
          colSpan={columnsSpan}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{margin: 0}}>{expandableData}</Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      format: PropTypes.func,
    }),
  ).isRequired,
  onExpandCollapsibleData: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rowData: PropTypes.object,
  onRedirectAction: PropTypes.func,
  /**
   * The span of columns calculated for collapsible row. If additional row elements are present
   * - it is incremented accordingly.
   */
  columnsSpan: PropTypes.number,
};

Row.defaultProps = {
  onExpandCollapsibleData: undefined,
  rowData: {},
  onRedirectAction: undefined,
  columnsSpan: 0,
};

export default Row;
