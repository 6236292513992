import React from 'react';
import PropTypes from 'prop-types';
import {Dialog} from '@mui/material';
import ScheduleConfiguration from './ScheduleConfiguration';

const ScheduleConfigurationDialog = (props) => {
  const {
    schedules,
    schedule,
    schedulesConfigurationOpen,
    onSchedulesConfigurationClose,
    onCreateScheduleConfiguration,
    onUpdateScheduleConfiguration,
    sitesWithScheduleCount,
  } = props;

  return (
    <Dialog
      data-cy="schedule-set-modal"
      open={schedulesConfigurationOpen}
      onClose={onSchedulesConfigurationClose}
      maxWidth="md"
    >
      {schedulesConfigurationOpen && (
        <ScheduleConfiguration
          schedule={schedule || {name: '', notes: ''}}
          existingSchedules={schedules}
          sitesWithScheduleCount={sitesWithScheduleCount}
          onCreate={onCreateScheduleConfiguration}
          onUpdate={onUpdateScheduleConfiguration}
          onCancel={onSchedulesConfigurationClose}
          CardProps={{elevation: 12}}
        />
      )}
    </Dialog>
  );
};

ScheduleConfigurationDialog.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  schedulesConfigurationOpen: PropTypes.bool.isRequired,
  schedule: PropTypes.shape({}),
  onSchedulesConfigurationClose: PropTypes.func,
  onCreateScheduleConfiguration: PropTypes.func,
  onUpdateScheduleConfiguration: PropTypes.func,
  sitesWithScheduleCount: PropTypes.number,
};

ScheduleConfigurationDialog.defaultProps = {
  schedule: {name: '', notes: ''},
  onSchedulesConfigurationClose: () => {},
  onCreateScheduleConfiguration: () => {},
  onUpdateScheduleConfiguration: () => {},
  sitesWithScheduleCount: 0,
};

export default ScheduleConfigurationDialog;
