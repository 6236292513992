import React, {useCallback, useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  useMediaQuery,
  Button,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';

import EditTemplateDialog from './EditTemplateDialog';
import {getAlarmModeComponent} from '../sitesAlarms/AlarmModeIcon';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import {
  getAlarmSummaryForSites,
  getDomainAlarmSchedules,
} from '../../../../api/alarms';
import {useApi, useDialog} from '../../../../shared/hooks';
import ManageSitesDialog from './ManageSitesDialog';
import {fetchSites} from './hooks';
import Spinner from '../../../../shared/components/spinner';

const columnDefinitions = [
  {
    id: 'nameAndDescription',
    label: 'Template Name',
    style: {width: '20%'},
  },
  {
    id: 'startEnd',
    label: 'Start/End',
    style: {
      width: '20%',
      textAlign: 'center',
    },
  },
  {
    id: 'alarmMode',
    label: 'Mode',
    style: {width: '20%', textAlign: 'center'},
    notSortable: true,
  },
  {
    id: 'editSchedule',
    label: 'Edit',
    style: {width: '20%', textAlign: 'center'},
    notSortable: true,
  },
  {
    id: 'sitesAssigned',
    label: 'Sites Assigned',
    style: {width: '20%', textAlign: 'center'},
    notSortable: true,
  },
  {
    id: 'manageSitesButton',
    label: '',
    style: {width: '20%', textAlign: 'center'},
    notSortable: true,
  },
];

const initialOrderOptions = {
  initialOrderBy: 'startEnd',
  initialOrder: 'asc',
};

const fetchScheduleExceptionsTemplates = async (
  page,
  rowsPerPage,
  order,
  orderBy,
) => {
  const offset = page * rowsPerPage;
  let orderSetup = {sortByStartDate: undefined, sortByName: undefined};
  switch (orderBy) {
    case 'startEnd':
      orderSetup = {
        ...orderSetup,
        sortByStartDate: order,
      };
      break;
    case 'nameAndDescription':
      orderSetup = {
        ...orderSetup,
        sortByName: order,
      };
      break;
    default:
      break;
  }
  const results = await getDomainAlarmSchedules(
    'template',
    rowsPerPage,
    offset,
    orderSetup.sortByStartDate,
    orderSetup.sortByName,
  );
  return results;
};
const formatScheduleDate = (date) =>
  DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm').toFormat('MMM/dd/yy - hh:mma');

const renderTemplateNameAndDescription = (name, description) => (
  <Box>
    <Typography variant="body2">{name}</Typography>
    <Typography variant="caption">{description}</Typography>
  </Box>
);

const renderSitesAssigned = (
  sitesAssigned,
  sitesTotalCount,
  isMediumScreen,
) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <Typography
      variant="body2"
      sx={{
        fontWeight: 500,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {!sitesAssigned && (
        <Tooltip
          data-cy="info-icon-tooltip"
          title="No sites are assigned"
          placement="top"
        >
          <InfoOutlinedIcon
            sx={{
              position: 'absolute',
              right: isMediumScreen ? 40 : 60,
              color: 'error.main',
            }}
          />
        </Tooltip>
      )}
      {sitesAssigned}/{sitesTotalCount}
    </Typography>
  </Box>
);

const renderStartEndDates = (startDate, endDate) => (
  <Box sx={{textAlign: 'center'}}>
    <Typography variant="body2" sx={{whiteSpace: 'nowrap'}}>
      {formatScheduleDate(startDate)}
    </Typography>
    <Typography variant="body2" sx={{whiteSpace: 'nowrap'}}>
      {formatScheduleDate(endDate)}
    </Typography>
  </Box>
);

const renderAlarmMode = (mode) => (
  <Box sx={{justifyContent: 'center', display: 'flex'}}>
    {getAlarmModeComponent(mode)}
  </Box>
);

const renderEditScheduleButton = (handleEditDialog) => (
  <Box display="flex" justifyContent="center">
    <Button
      variant="text"
      onClick={handleEditDialog}
      data-cy="schedule-exceptions-edit-button"
    >
      Edit
    </Button>
  </Box>
);

const renderManageSitesButton = (
  handleManageSitesButton,
  scheduleId,
  scheduleName,
) => (
  <Box display="flex" justifyContent="center">
    <Button
      variant="outlined"
      sx={{whiteSpace: 'nowrap'}}
      onClick={() => handleManageSitesButton(scheduleId, scheduleName)}
    >
      Manage Sites
    </Button>
  </Box>
);

const ScheduleExceptionsTable = ({snackbar, tableRefresh, onTableRefresh}) => {
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [sites, sitesLoading, , getSites] = useApi(getAlarmSummaryForSites);
  const [selectedSchedule, setSelectedSchedule] = useState();

  const [
    manageScheduleExceptionsOpen,
    handleManageScheduleExceptionsOpen,
    handleManageScheduleExceptionsClose,
  ] = useDialog();

  const [
    createTemplateDialogOpen,
    handleCreateTemplateDialogOpen,
    handleCreateTemplateDialogClose,
  ] = useDialog();

  useEffect(
    () => {
      fetchSites(getSites);
    },
    [getSites],
  );

  const handleEditDialog = (template) => {
    setSelectedSchedule({
      id: template.id,
      name: template.name,
      notes: template.notes,
      startDate: DateTime.fromFormat(template.startDate, 'yyyy-MM-dd HH:mm'),
      endDate: DateTime.fromFormat(template.endDate, 'yyyy-MM-dd HH:mm'),
    });
    handleCreateTemplateDialogOpen();
  };

  const totalSSPSitesCount = sites?.count;

  const onLoadData = useCallback(
    async (page, rowsPerPage, sortingOrder, sortingColumn) => {
      if (sites) {
        const handleManageSitesButton = (schedule) => {
          const {id, name, startDate, endDate} = schedule;
          setSelectedSchedule({
            id,
            name,
            startDate,
            endDate,
          });
          handleManageScheduleExceptionsOpen();
        };

        const data = await fetchScheduleExceptionsTemplates(
          page,
          rowsPerPage,
          sortingOrder,
          sortingColumn,
        );

        return {
          count: data.count,
          results: data.results.map((result) => {
            return {
              ...result,
              nameAndDescription: renderTemplateNameAndDescription(
                result.name,
                result.notes,
              ),
              startEnd: renderStartEndDates(result.startDate, result.endDate),
              alarmMode: renderAlarmMode('Armed'),
              sitesAssigned: renderSitesAssigned(
                result.sitesCount,
                totalSSPSitesCount,
                isMediumScreen,
              ),
              editSchedule: renderEditScheduleButton(() =>
                handleEditDialog(result),
              ),
              manageSitesButton: renderManageSitesButton(
                handleManageSitesButton,
                result,
              ),
            };
          }),
        };
      }
      return {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isMediumScreen,
      handleManageScheduleExceptionsOpen,
      totalSSPSitesCount,
      sites,
      tableRefresh,
    ],
  );

  return (
    <Grid data-cy="schedule-exceptions-table" item xs={12} display="grid">
      {sitesLoading ? (
        <Spinner size={40} color="primary" />
      ) : (
        <CollapsibleTable
          rowId="id"
          columns={columnDefinitions}
          sortable
          orderOptions={initialOrderOptions}
          onLoadData={onLoadData}
          paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
          stickyHeader={false}
        />
      )}
      {createTemplateDialogOpen && (
        <EditTemplateDialog
          open={createTemplateDialogOpen}
          handleClose={handleCreateTemplateDialogClose}
          snackbar={snackbar}
          selectedSchedule={selectedSchedule}
          onSaveSuccess={onTableRefresh}
        />
      )}
      {manageScheduleExceptionsOpen && (
        <ManageSitesDialog
          dialogOpen={manageScheduleExceptionsOpen}
          selectedSchedule={selectedSchedule}
          onDialogClose={handleManageScheduleExceptionsClose}
          snackbar={snackbar}
          totalSSPSitesCount={totalSSPSitesCount}
          isMediumScreen={isMediumScreen}
          onTableRefresh={onTableRefresh}
        />
      )}
    </Grid>
  );
};

ScheduleExceptionsTable.propTypes = {
  snackbar: PropTypes.shape({}).isRequired,
  tableRefresh: PropTypes.bool.isRequired,
  onTableRefresh: PropTypes.func.isRequired,
};

export default ScheduleExceptionsTable;
