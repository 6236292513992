import React from 'react';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = {
  spinner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
};

function Spinner({classes, size, color, className, ...rest}) {
  return (
    <div className={classNames(classes.spinner, className)} {...rest}>
      <CircularProgress size={size} color={color} />
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
};

Spinner.defaultProps = {
  size: 75,
  color: 'inherit',
};

export default withStyles(styles)(Spinner);
