import React from 'react';
import {Typography, Checkbox, ListItem, Grid, ImageList} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';
import CameraTile from '../../../../shared/components/cameraList/CameraTile';

const ResponsiveGridItem = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'smallScreen',
})(({theme, smallScreen}) => ({
  flexGrow: 1,
  height: '100%',
  '& ul': {
    margin: 0,
  },
  '& .camera-tile': {
    display: 'grid',
    gridRow: '1fr',
    height: '100%',
  },
  ...(smallScreen && {
    justifyContent: 'center',
    padding: theme.spacing(1),
  }),
}));

const CameraListRow = ({
  camera,
  smallScreen,
  mappingMaxReached,
  checked,
  onSmallScreenSelect,
  onChange,
  disableSelect,
  children,
}) => {
  const cameraWithImg = {
    ...camera,
    img: `/api/v3/sensor_views/${camera.id}/thumbnail`,
  };
  const smallScreenSelectable = typeof onSmallScreenSelect === 'function';
  return (
    <ListItem name={`camera-${cameraWithImg.id}`} disablePadding>
      <ResponsiveGridItem
        container
        alignItems="center"
        smallScreen={smallScreen}
      >
        {!smallScreen && (
          <Grid item md={2}>
            <Checkbox
              disableRipple
              id={cameraWithImg.id}
              name={`${cameraWithImg.id}-checkbox`}
              checked={checked}
              onChange={onChange}
              disabled={disableSelect}
            />
          </Grid>
        )}
        <Grid className="camera-tile" item flexGrow={1} py={1} md={3}>
          <ImageList sx={{minHeight: (theme) => theme.spacing(10)}} cols={1}>
            <CameraTile
              camera={cameraWithImg}
              key={cameraWithImg.id}
              selectable
              layoutMaxReached={mappingMaxReached}
              doLazyLoading
              refreshInterval={-1}
              selected={checked}
              disableSelect={
                smallScreenSelectable
                  ? disableSelect
                  : !checked ?? disableSelect
              }
              {...(smallScreen
                ? {
                    onSelect: onSmallScreenSelect,
                  }
                : {
                    disableSelect: true,
                    disableItemBar: true,
                  })}
            />
          </ImageList>
        </Grid>
        {!smallScreen && (
          <Grid item md={6}>
            <Typography className="camera-name" pl={2}>
              {cameraWithImg.name}
            </Typography>
          </Grid>
        )}
        {children}
      </ResponsiveGridItem>
    </ListItem>
  );
};

CameraListRow.propTypes = {
  camera: PropTypes.shape({}).isRequired,
  smallScreen: PropTypes.bool.isRequired,
  mappingMaxReached: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onSmallScreenSelect: PropTypes.func,
  onChange: PropTypes.func.isRequired,
};
CameraListRow.defaultProps = {
  mappingMaxReached: undefined,
  onSmallScreenSelect: undefined,
};

export default CameraListRow;
