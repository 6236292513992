import React, {Fragment} from 'react';
import {Alert, AlertTitle, List, ListItem, styled} from '@mui/material';
import {PropTypes} from 'prop-types';
import {subscriptionEventTypes} from './utils';

const StyledList = styled(List)({
  listStyleType: 'disc',
  listStylePosition: 'inside',
});
const StyledListItem = styled(ListItem)({
  display: 'list-item',
  fontSize: '14px',
});

const UsersListErrorAlert = () => (
  <Alert severity="error" sx={{marginTop: 2}}>
    <AlertTitle>Error</AlertTitle>
    Something went wrong. Please try again.
  </Alert>
);

const UsersListVideoAlarmAlert = ({isEM}) => (
  <Alert severity="info" sx={{marginTop: 2}}>
    <AlertTitle>Info</AlertTitle>
    <StyledList>
      {isEM ? (
        <Fragment>
          <StyledListItem disablePadding>
            Only users with phone number can be set as Video Alarm Contacts
          </StyledListItem>
          <StyledListItem disablePadding>
            You can set up to 3 Priority Contacts, which we will call in case of
            emergency, and unlimited number of Email recipients.
          </StyledListItem>
        </Fragment>
      ) : (
        <StyledListItem disablePadding>
          Please select Notification Subscribers from the list.
        </StyledListItem>
      )}
    </StyledList>
  </Alert>
);

const UsersListPanicAlarmAlert = () => (
  <Alert severity="info" sx={{marginTop: 2}}>
    <AlertTitle>Info</AlertTitle>
    <StyledList>
      <StyledListItem disablePadding>
        Only users with phone number can be set as Video Alarm Contacts.
      </StyledListItem>
      <StyledListItem disablePadding>
        You can set up to 3 Priority Contacts, which we will call in case of
        emergency.
      </StyledListItem>
    </StyledList>
  </Alert>
);

export const UsersListAlert = ({showError, eventType, isEM}) => {
  if (showError) {
    return <UsersListErrorAlert />;
  }

  switch (eventType) {
    case subscriptionEventTypes.video:
      return <UsersListVideoAlarmAlert isEM={isEM} />;

    case subscriptionEventTypes.panic:
      return <UsersListPanicAlarmAlert />;

    case subscriptionEventTypes.panel:
      return <UsersListVideoAlarmAlert isEM={false} />;

    default:
      return <UsersListVideoAlarmAlert isEM={isEM} />;
  }
};

UsersListAlert.propTypes = {
  showError: PropTypes.bool.isRequired,
  eventType: PropTypes.oneOf(Object.values(subscriptionEventTypes)).isRequired,
  isEM: PropTypes.bool.isRequired,
};

export default UsersListAlert;
