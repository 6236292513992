import crypto from 'crypto';

const md5HashString = (string) =>
  crypto
    .createHash('md5')
    .update(string)
    .digest('hex');

export default class LogMaster {
  constructor(loggers) {
    this.loggers = loggers;
    this.setCorrelationId(this.correlationId);
    this.recentExceptionHash = [];
  }

  correlationId = Array(32)
    .fill(0)
    .map(() =>
      Math.random()
        .toString(36)
        .charAt(2),
    )
    .join('');

  getCorrelationId = () => this.correlationId;

  setCorrelationId = (correlationId) => {
    this.loggers.forEach((loggingClass) =>
      loggingClass.setCorrelationId(correlationId),
    );
  };

  setUserContext = (user) => {
    this.user = user;
    this.loggers.forEach((loggingClass) => loggingClass.setUserContext(user));
  };

  shouldLog = (level) =>
    !window.Cypress &&
    (['warn', 'error'].includes(level) ||
      (this.user &&
        this.user.settings &&
        this.user.settings.verboseLogging === 1));

  log(level, message, context, error) {
    if (!this.shouldLog(level)) {
      return;
    }

    const errorHash = md5HashString(JSON.stringify({level, message, error}));
    if (this.recentExceptionHash.indexOf(errorHash) === -1) {
      if (this.recentExceptionHash.length >= 5) {
        this.recentExceptionHash.shift();
      }
      this.recentExceptionHash.push(errorHash);

      // eslint-disable-next-line no-console
      console[level](message, context, error);

      this.loggers.forEach((loggingClass) => {
        loggingClass.log(level, message, context, error);
      });
    }
  }

  debug(message, context, error) {
    this.log('debug', message, context, error);
  }

  info(message, context, error) {
    this.log('info', message, context, error);
  }

  warn(message, context, error) {
    this.log('warn', message, context, error);
  }

  error(message, context, error) {
    this.log('error', message, context, error);
  }
}
