import queryString from 'query-string';
import {isEnvysionRoute} from '../../shared/util/general';

const ExternalRedirect = ({location}) => {
  const {target} = queryString.parse(location.search);
  window.location.href = isEnvysionRoute(target) ? target : '/ui/sites';
  return null;
};

export default ExternalRedirect;
