import React from 'react';
import {wrapDisplayName} from '../util/hocSupport';

const CurrentUserContext = React.createContext();

export const withCurrentUser = (Component) => {
  function CurrentUserComponent(props) {
    return (
      <CurrentUserContext.Consumer>
        {(currentUser) => (
          <Component
            {...props}
            currentUser={currentUser && currentUser.currentUser}
          />
        )}
      </CurrentUserContext.Consumer>
    );
  }

  CurrentUserComponent.displayName = wrapDisplayName(
    'WithCurrentUser',
    Component,
  );

  return CurrentUserComponent;
};

export default CurrentUserContext;
