import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';
import HaloTableWidget from '../HaloTableWidget';

const HaloTab = ({startDate, endDate}) => {
  const [auxOptions, setAuxOptions] = useState();

  return (
    <HaloTableWidget
      auxOptions={auxOptions}
      setAuxOptions={setAuxOptions}
      startDate={startDate}
      endDate={endDate}
    />
  );
};

HaloTab.propTypes = {
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
};

export default HaloTab;
