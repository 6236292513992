import React from 'react';
import {Link} from 'react-router-dom';
import compose from 'lodash/flowRight';
import DashboardSection from '../../../../shared/components/dashboardComponent/dashboardSection';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import withSnackbar from '../../../../shared/components/snackbarSupport';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';

const SysHealthHeaderWidget = ({showButton, widgetConfig, currentUser}) => {
  return (
    <DashboardSection
      id={widgetConfig.widgetReference}
      title={widgetConfig.title || 'System Health Status'}
      subtitle={
        widgetConfig.description ||
        'Track the Health of Your Video Surveillance Network'
      }
      actions={
        showButton && [
          {
            id: 'link_button',
            label: 'See full system health status',
            ButtonProps: {
              component: Link,
              to: {
                pathname: isNewNavigationWebEnabled(currentUser)
                  ? '/home/system-health'
                  : '/hardware-health',
                state: {fromDashboard: true},
              },
            },
          },
        ]
      }
      {...widgetConfig.properties}
    />
  );
};

export default compose(
  withCurrentUser,
  withSnackbar,
)(SysHealthHeaderWidget);
