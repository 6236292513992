import React from 'react';
import Select, {createFilter} from 'react-select';
import {alpha} from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import * as componentOverrides from './Components';

const styles = (theme) => ({
  input: {
    display: 'flex',
    alignItems: 'center',
  },
  inputMultiValue: {
    minHeight: '1.1875em',
    height: 'unset',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    height: theme.spacing(3),
  },
  valueContainerMultiValueTruncate: {
    minWidth: 0,
    height: theme.spacing(3),
  },
  indicatorsContainer: {
    height: '0.01em',
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '2em',
    alignItems: 'center',
  },
  noOptionsMessage: {
    padding: [theme.spacing(1), theme.spacing(2)],
  },
  multiValue: {
    margin: 2,
  },
  singleValue: {
    fontSize: theme.typography.subtitle1.fontSize,
  },
  placeholder: {
    marginLeft: theme.spacing(0.25),
    fontSize: theme.typography.subtitle1.fontSize,
    height: theme.spacing(3),
  },
  placeholderDisabled: {
    color: alpha(theme.palette.text.disabled, 0.17),
  },
  valueContainerMultiValue: {
    height: '100%',
  },
  multiValueTruncate: {
    marginLeft: theme.spacing(0.25),
    fontSize: theme.typography.subtitle1.fontSize,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flex: 1,
  },
  valueDisabled: {
    color: theme.palette.text.disabled,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  clearIndicator: {
    '& svg': {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  },
  divider: {
    height: theme.spacing(2),
  },
  drowdownIndicator: {
    padding: 0,
    paddingLeft: theme.spacing(0.5),
    cursor: 'pointer',
    color: theme.palette.action.active,
    display: 'flex',
    alignItems: 'center',
  },
  indicatorSeparator: {
    width: 1,
    height: theme.spacing(2),
    margin: theme.spacing(0.25),
  },
  groupHeading: {
    paddingTop: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.8),
    borderTop: '1px dotted',
  },
});

class SelectList extends React.PureComponent {
  // Track the number of options that are currently visible.
  visibleOptions = new Set();

  dynamicPlaceholder = () => {
    const {maxOptions, totalOptions, options, placeholder} = this.props;
    if (!options) {
      return placeholder;
    }
    const total = totalOptions || options.length;

    let dynamicPlaceholder = placeholder;
    if (total > maxOptions) {
      dynamicPlaceholder = `${maxOptions}/${total} displayed.  Type to search...`;
    }
    return dynamicPlaceholder;
  };

  render() {
    const {
      classes,
      id,
      component,
      disabled,
      clearable,
      components,
      dynamicPlaceholder,
      onInputChange,
      maxOptions,
      ...restProps
    } = this.props;

    this.visibleOptions = new Set();
    const filterFn = createFilter();

    const placeholder = dynamicPlaceholder
      ? this.dynamicPlaceholder()
      : this.props.placeholder;

    return React.createElement(component, {
      id: `select_${id}`,
      classes,
      components: {
        ...componentOverrides,
        ...components,
      },
      styles: {
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        input: (base) => ({
          ...base,
          margin: 0,
          padding: 0,
          paddingBottom: 0,
          paddingTop: 0,
        }),
      },
      menuPortalTarget: document.body,
      menuShouldBlockScroll: true,
      inputId: id,
      maxMenuHeight: 300,
      menuPlacement: 'auto',
      isDisabled: disabled,
      isClearable: clearable,
      filterOption: (option, inputValue) => {
        if (
          this.visibleOptions.size >= maxOptions &&
          !this.visibleOptions.has(option.value)
        ) {
          return false;
        }
        const isVisible = filterFn(option, inputValue);
        if (isVisible) {
          this.visibleOptions.add(option.value);
        }
        return isVisible;
      },
      ...restProps,
      onInputChange: (...args) => {
        this.visibleOptions = new Set();
        if (typeof onInputChange === 'function') {
          onInputChange(...args);
        }
      },
      placeholder,
    });
  }
}

SelectList.propTypes = {
  id: PropTypes.string.isRequired,
  maxVisibleOptions: PropTypes.number,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.string,
  ]),
  components: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  dynamicPlaceholder: PropTypes.bool,
  maxOptions: PropTypes.number,
  totalOptions: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func,
};

SelectList.defaultProps = {
  maxVisibleOptions: 50,
  disabled: false,
  clearable: false,
  component: Select,
  getOptionLabel: (option) => option.name,
  getOptionValue: (option) => option.id,
  dynamicPlaceholder: true,
  maxOptions: 50,
  totalOptions: undefined,
  components: {},
  options: undefined,
  placeholder: undefined,
  onInputChange: undefined,
};

export default withStyles(styles, {withTheme: true})(SelectList);
