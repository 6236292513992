import axios from 'axios';

export const getPeopleCountConfigSites = async (paginationModel) => {
  const {page, pageSize} = paginationModel || {
    page: 0,
    pageSize: 10,
  };

  const limit = pageSize;
  const offset = page * pageSize;

  const res = await axios.get('/transactions/people_count', {
    params: {
      limit,
      offset,
    },
  });

  return {
    count: res.data?.count,
    results: res.data?.results.map((element, index) => {
      return {
        id: index + offset,
        siteId: element.siteId,
        siteName: element.siteName,
        registerName: `${element.enabledRegisters} Registers Enabled`,
        enabled: element.enabledRegisters > 0,
      };
    }),
  };
};

export const getPeopleCountConfigs = async (paginationModel, args) => {
  const {siteId} = args;
  const res = await axios.get(`/transactions/people_count/${siteId}`, {});
  return {
    count: res.data?.count,
    results: res.data?.results.map((element, index) => {
      return {
        id: index,
        siteId: element.siteId,
        registerName: element.registerName,
        registerId: element.registerId,
        cameraId: element.cameraId,
        cameraName: element.cameraName,
        enabled: element.enabled,
        mask: element.mask,
        viewId: element.calipsaViewId,
      };
    }),
  };
};

export const getPeopleCountAnalyticTypes = async (types) => {
  const analyticTypesUrl = '/transactions/analytics';

  const results = await axios.get(analyticTypesUrl, {
    params: {
      analyticTypes: types.join(','),
    },
  });

  return results.data;
};

export const createPeopleCountConfig = async (configBody) =>
  (await axios.post(`/transactions/people_count`, configBody)).data;

export const deletePeopleCountConfig = async (siteId, registerId) =>
  (await axios.delete(`transactions/people_count/${siteId}/${registerId}`))
    .data;

export const updatePeopleCountConfig = async (siteId, registerId, configBody) =>
  (await axios.put(
    `/transactions/people_count/${siteId}/${registerId}`,
    configBody,
  )).data;
