import axios from 'axios';
import sortBy from 'lodash/sortBy';

import {mergeSiteCat} from '../shared/util/sites';

export const getOrganization = async () => {
  const {data} = await axios.get('/site_category?retain_empty_categories=true');
  return data;
};

export const addCategory = async (parentId, name) => {
  const {data} = await axios.post(`/site_categories/${parentId}`, {name});
  return data;
};

export const updateCategory = async (categoryId, name, parentId) => {
  const {data} = await axios.put(`/site_categories/${categoryId}`, {
    name,
    parent_id: parseInt(parentId, 10),
  });
  return data;
};

export const deleteCategory = async (categoryId) => {
  const {data} = await axios.delete(`/site_categories/${categoryId}`);
  return data;
};

export const moveApplianceToCategory = async (categoryId, applianceId) => {
  const {data} = await axios.post(`/site_categories/${categoryId}/add`, {
    appliance_id: applianceId,
  });
  return data;
};

export const getSites = async () => {
  const {data} = await axios.get('/site_category/extensive');
  return mergeSiteCat(data);
};

export const getSite = async (siteId) => {
  const {data} = await axios.get(`/sites/${siteId}`);
  return data;
};

export const getSiteActivity = async (
  siteId,
  page,
  limit,
  sortingOrder,
  startDate,
  endDate,
) => {
  const {data} = await axios.get(`/system_health/${siteId}/hardware_activity`, {
    params: {
      offset: page * limit,
      limit,
      sortingOrder,
      startDate,
      endDate,
    },
  });
  return data;
};

export const patchSite = async (siteId, set, unset) =>
  (await axios.patch(`/sites/${siteId}`, {set, unset})).data;

export const updateSiteTimezone = async (siteId, timezoneId) =>
  patchSite(siteId, {timezoneId});

export const getTimezones = async () => {
  const {data} = await axios.get(`/timezones`);
  return sortBy(data, [(tz) => tz.name.toLowerCase()]);
};

export const getPreferences = async (siteId) =>
  (await axios.get(`/sites/${siteId}/preferences`)).data;

export const updatePreference = async (siteId, preference, value) =>
  axios.put(`/sites/${siteId}/preferences/${preference}`, {
    preferenceValue: value,
  });

export const updateBulkPreferences = async (siteId, preferences) =>
  axios.put(`/sites/${siteId}/preferences`, {
    preferences,
  });

export const getBitrate = async (siteId) => {
  const {data} = await axios.get(`/sites/${siteId}/bitrate`);
  return data;
};

export const fetchCategoryDetail = async (categoryId, extended) =>
  (await axios.get(
    `/site_categories/${categoryId}${extended && '?extended=true'}`,
  )).data;

export const getCategoriesAppliancesList = async () =>
  (await axios.get(`/all_categories_appliances`)).data;

export const getSiteAccessUsers = async (siteId) =>
  (await axios.get(`/sites/${siteId}/acls/members?temporary_only=true`)).data;

export const addSiteAccessUser = async (siteId, userId, daysToExpire) =>
  axios.put(`/sites/${siteId}/acls/members/${userId}`, {
    daysToExpire: parseInt(daysToExpire, 10),
  });

export const deleteSiteAccessUser = async (siteId, userId) =>
  axios.delete(`/sites/${siteId}/acls/members/${userId}`);

export const fetchCategoryNode = async (categoryId, offset, limit) => {
  const nodes = await axios.get(`/site_categories/${categoryId}/nodes`, {
    params: {
      limit,
      offset,
    },
  });

  const {count, results} = nodes.data;
  return {
    count,
    results,
  };
};

export const fetchCloudArchiveSites = async (categoryId, offset, limit) => {
  const cloudArchive = await axios.get(
    `cloud_archive/sites?category_id=${categoryId}`,
    {
      params: {
        limit,
        offset,
      },
    },
  );
  const {count, results} = cloudArchive.data;
  return {
    count,
    results: results?.map(({uuid, enabled}) => enabled && uuid) || [],
  };
};

export const searchNodes = async (search, offset, limit, signal) => {
  const nodes = await axios.get(`/site_categories/nodes`, {
    params: {
      limit,
      offset,
      search,
    },
    signal,
  });

  const {count, results} = nodes.data;
  return {
    count,
    results,
  };
};

export const searchCloudArchiveStatus = async (
  search,
  offset,
  limit,
  signal,
) => {
  const cloudArchive = await axios.get(`cloud_archive/sites`, {
    params: {
      limit,
      offset,
      search,
    },
    signal,
  });

  const {count, results} = cloudArchive.data;
  return {
    count,
    results: results?.map(({uuid, enabled}) => enabled && uuid) || [],
  };
};
