export const DEFAULT_ROWS_PER_PAGE = 10;
export const DEFAULT_LIVE_MONITOR_REFRESH_INTERVAL = 5000;
export const LIVE_MONITOR_SITES_PER_LOAD = 10;
export const LIVE_MONITOR_MAXSTREAMS = 16;
export const MINIMUM_SITES_FOR_CATEGORY_DISPLAY = 10;
export const DEFAULT_NETWORK_CONFIG = {
  interface: 'eth0',
  configuration: {
    dhcpEnabled: true,
  },
};

export default {
  DEFAULT_ROWS_PER_PAGE,
  DEFAULT_LIVE_MONITOR_REFRESH_INTERVAL,
  LIVE_MONITOR_SITES_PER_LOAD,
  LIVE_MONITOR_MAXSTREAMS,
  MINIMUM_SITES_FOR_CATEGORY_DISPLAY,
  DEFAULT_NETWORK_CONFIG,
};
