import React, {Fragment} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {PropTypes} from 'prop-types';
import {
  CameraEnabledPrerequisite,
  EnvysionMonitoringSubscriberPrerequisite,
  ScheduleSetPrerequisite,
  NotificationSubscriberPrerequisite,
} from './AlarmModePrerequisite';

const useStyles = makeStyles((theme) => ({
  cautionContent: {
    paddingBottom: theme.spacing(2),
  },
  dialogContentText: {
    textAlign: 'center',
    padding: theme.spacing(1, 3),
    color: theme.palette.common.black,
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(1.25),
  },
}));

const AlarmModesWarningDialog = (props) => {
  const {
    open,
    onClose,
    onConfirm,
    warningDialogInput,
    isEnvysionMonitoring,
    onCamerasConfigurationOpen,
    onNotificationsConfigurationOpen,
    onSchedulesConfigurationOpen,
  } = props;

  const classes = useStyles(props);

  const onConfirmClick = () => {
    onConfirm();
    onClose();
  };

  const displayDialogTitle = (dialogInput) => {
    let dialogTitle;
    switch (dialogInput.action) {
      case 'SwitchToArmed':
      case 'SwitchToTestMode':
        dialogTitle = 'Recommendation';
        break;
      case 'SwitchToDisarmed':
      case 'DisabledSwitchClick':
        dialogTitle = 'Caution';
        break;
      default:
        break;
    }
    return dialogTitle;
  };

  const definePrerequisite = (prerequisite) => {
    let missingPrerequisite;
    switch (prerequisite) {
      case 'scheduleSet':
        missingPrerequisite = (
          <ScheduleSetPrerequisite
            state={false}
            onSchedulesConfigurationOpen={onSchedulesConfigurationOpen}
          />
        );
        break;
      case 'cameraEnabled':
        missingPrerequisite = (
          <CameraEnabledPrerequisite
            state={false}
            onCamerasConfigurationOpen={onCamerasConfigurationOpen}
          />
        );
        break;
      case 'selfMonitoringSubscriber':
        missingPrerequisite = (
          <NotificationSubscriberPrerequisite
            state={false}
            onNotificationsConfigurationOpen={onNotificationsConfigurationOpen}
          />
        );
        break;
      case 'envysionMonitoringSubscriber':
        missingPrerequisite = (
          <EnvysionMonitoringSubscriberPrerequisite
            state={false}
            onNotificationsConfigurationOpen={onNotificationsConfigurationOpen}
          />
        );
        break;
      default:
        break;
    }
    return missingPrerequisite;
  };

  const displayDialogContent = (dialogInput, isEM) => {
    let dialogDescription;
    switch (dialogInput.action) {
      case 'SwitchToArmed':
        dialogDescription = isEM
          ? 'Before Arming, please make sure this site is generating less than 2 events per day.'
          : 'Before Arming, please make sure there are enough cameras enabled and this site is generating less than 2 events per day.';
        break;
      case 'SwitchToDisarmed':
        dialogDescription = 'Are you sure you want to Disarm?';
        break;
      case 'DisabledSwitchClick':
        dialogDescription = (
          <div>
            <div className={classes.cautionContent}>
              To enable this mode, please finish required actions:
            </div>
            {Object.keys(dialogInput.data)
              .filter((key) => !dialogInput.data[key])
              .map((p) => (
                <div key={p}>{definePrerequisite(p)}</div>
              ))}
          </div>
        );
        break;
      case 'SwitchToTestMode':
        dialogDescription =
          'SSP is intended to be used with enough cameras to cover most of the interior of the store.';
        break;
      default:
        break;
    }
    return dialogDescription;
  };
  const commonActionButtons = (
    <Fragment>
      <Button
        data-cy="cancel-button"
        onClick={onClose}
        className={classes.cancelButton}
        variant="text"
      >
        Cancel
      </Button>
      <Button
        data-cy="confirm-button"
        onClick={onConfirmClick}
        variant="contained"
        color="primary"
      >
        Confirm
      </Button>
    </Fragment>
  );
  const displayActionButtons = (dialogInput) => {
    let dialogActions;
    switch (dialogInput.action) {
      case 'SwitchToArmed':
      case 'SwitchToDisarmed':
      case 'SwitchToTestMode':
        dialogActions = commonActionButtons;
        break;
      case 'DisabledSwitchClick':
        dialogActions = (
          <Button
            data-cy="close-button"
            onClick={onClose}
            className={classes.cancelButton}
            variant="text"
          >
            Close
          </Button>
        );
        break;
      default:
        break;
    }
    return dialogActions;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle data-cy="alarm-dialog-title">
        {displayDialogTitle(warningDialogInput)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          classes={{root: classes.dialogContentText}}
          component="div"
          data-cy="alarm-dialog-content"
        >
          {displayDialogContent(warningDialogInput, isEnvysionMonitoring)}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{root: classes.dialogActions}}>
        {displayActionButtons(warningDialogInput)}
      </DialogActions>
    </Dialog>
  );
};

AlarmModesWarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  warningDialogInput: PropTypes.shape({
    action: PropTypes.string,
    selectedMode: PropTypes.string,
    data: PropTypes.shape({}),
  }).isRequired,
  isEnvysionMonitoring: PropTypes.bool.isRequired,
  onCamerasConfigurationOpen: PropTypes.func.isRequired,
  onNotificationsConfigurationOpen: PropTypes.func.isRequired,
  onSchedulesConfigurationOpen: PropTypes.func.isRequired,
};

export default AlarmModesWarningDialog;
