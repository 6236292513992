import React, {useCallback, useState} from 'react';
import {Grid, IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import makeStyles from '@mui/styles/makeStyles';
import snakeCase from 'lodash/snakeCase';
import CollapsibleTable from '../../../../shared/components/collapsibleTable';
import {getAlarmModeComponent} from './AlarmModeIcon';

const useStyles = makeStyles(() => ({
  schedule: {
    overflowWrap: 'break-word',
    wordBreak: 'break-all',
  },
}));

const columnDefinitions = [
  {
    id: 'siteName',
    label: 'Site name',
    style: {width: '30%'},
  },
  {
    id: 'scheduleName',
    label: 'Schedule',
    style: {width: '30%'},
  },
  {
    id: 'alarmMode',
    label: 'Alarm mode',
    style: {width: '30%'},
  },
  {
    id: 'alarmsCount',
    label: 'Events 24h',
    style: {width: '5%'},
  },
];

const initialOrderOptions = {
  initialOrderBy: 'alarmsCount',
  initialOrder: 'desc',
};

const SitesAlarmsTable = (props) => {
  const {onSelectSite, handleDataFetch} = props;
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState('');

  const onRowClick = (siteData) => {
    const selectedSite = {
      id: siteData.siteId,
      name: siteData.siteName,
      alarmsCount: siteData.alarmsCount,
    };
    onSelectSite(selectedSite);
    setSelectedId(siteData.siteId);
  };

  const onLoadData = useCallback(
    async (page, rowsPerPage, sortingOrder, sortingColumn) => {
      const getSchedule = (scheduleName) => (
        <div className={classes.schedule}>{scheduleName}</div>
      );
      const data = await handleDataFetch(
        page,
        rowsPerPage,
        snakeCase(sortingColumn),
        sortingOrder,
      );
      return {
        count: data.count,
        results: data.results.map((e) => {
          return {
            ...e,
            scheduleName: getSchedule(e.scheduleName),
            alarmMode: getAlarmModeComponent(e.alarmMode),
          };
        }),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleDataFetch],
  );
  return (
    <Grid data-cy="alarms-list-table" item xs={12} display="grid">
      <CollapsibleTable
        rowId="siteId"
        columns={columnDefinitions}
        sortable
        orderOptions={initialOrderOptions}
        onLoadData={onLoadData}
        onRowClick={(siteData) => onRowClick(siteData)}
        paginateOptions={{initialRecords: 5, useBasicNavigation: true}}
        rowActions={[
          (siteData) => (
            <IconButton
              aria-label="open alarm configuration"
              size="small"
              onClick={() => onRowClick(siteData)}
            >
              <ChevronRightIcon />
            </IconButton>
          ),
        ]}
        stickyHeader={false}
        selectedId={selectedId}
      />
    </Grid>
  );
};

SitesAlarmsTable.propTypes = {
  onSelectSite: PropTypes.func.isRequired,
  handleDataFetch: PropTypes.func.isRequired,
};

export default SitesAlarmsTable;
