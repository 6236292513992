import React from 'react';
import PropTypes from 'prop-types';
import ApiCheckbox from './ApiCheckbox';
import {checkboxStatuses, subscriptionDisplayTypes} from './utils';
import {manageSubscription} from '../../../../api/subscribers';

const NotificationSubscriptionCheckbox = (props) => {
  const {
    checkboxStatus,
    userSubscriptionType,
    userIndx,
    setCheckboxStatus,
    subCounter,
    userId,
    subIds,
    hasSubscription,
    priorityUsers,
    checkboxDisabled,
  } = props;

  let userSubscriptionChecked;
  let subIdType;
  let checkSubscription;

  switch (userSubscriptionType) {
    case subscriptionDisplayTypes.email:
      userSubscriptionChecked = subCounter.email;
      subIdType = subIds.email;
      checkSubscription = hasSubscription.email;
      break;
    case subscriptionDisplayTypes.noonlight:
      userSubscriptionChecked = subCounter.noonlight;
      subIdType = subIds.noonlight;
      checkSubscription = hasSubscription.noonlight;
      break;
    case subscriptionDisplayTypes.push_notification:
      userSubscriptionChecked = subCounter.pushNotification;
      subIdType = subIds.pushNotification;
      checkSubscription = hasSubscription.pushNotification;
      break;
    default:
      break;
  }

  const setPriority = (isChecked) =>
    userSubscriptionType === subscriptionDisplayTypes.noonlight && isChecked
      ? priorityUsers.length + 1
      : undefined;

  return (
    <ApiCheckbox
      isDisabled={
        checkboxStatus === checkboxStatuses.Loading || checkboxDisabled
      }
      auxCallback={(checked) => (userSubscriptionChecked[userIndx] = checked)}
      onError={() => setCheckboxStatus(checkboxStatuses.Error)}
      onSuccess={() => setCheckboxStatus(checkboxStatuses.Success)}
      apiPromise={(isChecked) => {
        setCheckboxStatus(checkboxStatuses.Loading);
        return manageSubscription(
          userId,
          subIdType,
          isChecked,
          setPriority(isChecked),
        );
      }}
      controlChecked={checkSubscription}
      data-cy={`${
        priorityUsers && priorityUsers.some((user) => user.uuid === userId)
          ? 'priority-'
          : ''
      }${userSubscriptionType.toLowerCase()}-checkbox`}
    />
  );
};

NotificationSubscriptionCheckbox.defaultProps = {
  priorityUsers: [],
  checkboxDisabled: false,
};

NotificationSubscriptionCheckbox.propTypes = {
  checkboxStatus: PropTypes.string.isRequired,
  userSubscriptionType: PropTypes.string.isRequired,
  userIndx: PropTypes.number.isRequired,
  setCheckboxStatus: PropTypes.func.isRequired,
  subCounter: PropTypes.shape({
    email: PropTypes.arrayOf(PropTypes.bool),
    noonlight: PropTypes.arrayOf(PropTypes.bool),
    pushNotification: PropTypes.arrayOf(PropTypes.bool),
  }).isRequired,
  userId: PropTypes.string.isRequired,
  subIds: PropTypes.shape({}).isRequired,
  hasSubscription: PropTypes.shape({}).isRequired,
  priorityUsers: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      uuid: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      roleName: PropTypes.string,
      subscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          eventType: PropTypes.string,
          subscriptionId: PropTypes.string,
          type: PropTypes.string,
        }),
      ),
    }),
  ),
  checkboxDisabled: PropTypes.bool,
};

export default NotificationSubscriptionCheckbox;
