import React, {useEffect} from 'react';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import RiskLevelTableWidget from './RiskLevelTableWidget';
import {getRiskiestEmployees} from '../../../../api/reports';
import {useApi} from '../../../../shared/hooks';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';

const EmployeeRiskWidget = (props) => {
  const {widgetConfig, currentUser} = props;
  let mappedResults = [];
  const [data, isLoading, isError, call] = useApi(getRiskiestEmployees);

  useEffect(
    () => {
      call();
    },
    [call],
  );

  if (data) {
    mappedResults = data.map((e) => ({
      id: e.empId,
      name: `${e.fullName} [${e.employeeCode}]`,
      riskLevel: e.employeeRiskLevel,
      url: e.reportUrl,
    }));
  }

  const getContent = () => {
    return (
      <RiskLevelTableWidget
        header="Name [Code]"
        listItems={mappedResults}
        loading={isLoading}
        error={isError}
        errorMessage="Error loading employee risk snapshot."
        currentUser={currentUser}
      />
    );
  };

  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      loading={isLoading}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default withCurrentUser(EmployeeRiskWidget);
