import React, {useRef} from 'react';
import {useTheme} from '@mui/material';
import {merge, cloneDeep} from 'lodash';
import PropTypes from 'prop-types';
import HighCharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighChartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {defaultHighChartsOptionsJson} from '../highChartsOptionsJson';
import {useThemeFont} from '../../../themes';

export const createDefaultHighChartsJson = (
  graphSettings,
  tooltipProvider,
  onLegendClick,
  theme,
  titleStyles,
) => ({
  chart: {...{...graphSettings.chart}},
  title: {
    text: graphSettings.title,
    margin: parseFloat(theme.spacing(5)),
    useHTML: true,
    style: {...titleStyles, whiteSpace: 'nowrap'},
  },
  xAxis: {
    labels: {
      rotation: 0,
    },
    ...{...graphSettings.xAxis},
  },
  legend: {
    align: 'center',
    x: 0,
    verticalAlign: 'bottom',
    y: 0,
    enabled: true, // to override the default settings
    margin: parseFloat(theme.spacing(5)),
    ...{...graphSettings.legend},
  },
  plotOptions: {
    column: {
      stacking: 'normal',
      pointWidth: graphSettings.pointWidth,
      minPointLength: 0,
    },
    series: {
      events: {
        ...(onLegendClick && {legendItemClick: onLegendClick}),
      },
    },
    ...{...graphSettings.plotOptions},
  },
  ...(tooltipProvider && {
    tooltip: {
      formatter: function tooltipWrapper() {
        return tooltipProvider(this, graphSettings.tipText);
      },
      useHTML: true,
    },
  }),
});

export const createHighChartJson = (
  extraOptions,
  graphSettings,
  tooltipProvider,
  onLegendClick,
  theme,
  data,
  titleStyles,
) => {
  const initialJson = createDefaultHighChartsJson(
    graphSettings,
    tooltipProvider,
    onLegendClick,
    theme,
    titleStyles,
  );
  const extraOptionsJson = extraOptions ? extraOptions(theme) : {};
  const series = {
    series: [...data],
  };
  return merge(
    cloneDeep(series),
    cloneDeep(defaultHighChartsOptionsJson),
    cloneDeep(extraOptionsJson),
    cloneDeep(initialJson),
  );
};

const StackedColumnChartWidget = (props) => {
  const chartComponent = useRef(null);
  const theme = useTheme();
  const titleStyles = useThemeFont('h6');

  const {
    data,
    extraOptions,
    graphSettings,
    tooltipProvider,
    onLegendClick,
    containerProps,
  } = props;

  const chartOptionsJson = createHighChartJson(
    extraOptions,
    graphSettings,
    tooltipProvider,
    onLegendClick,
    theme,
    data,
    titleStyles,
  );

  NoDataToDisplay(HighCharts);
  highchartsAccessibility(HighCharts);

  return (
    <HighChartsReact
      key={graphSettings.widgetName}
      ref={chartComponent}
      highcharts={HighCharts}
      options={chartOptionsJson}
      containerProps={containerProps}
    />
  );
};

StackedColumnChartWidget.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          y: PropTypes.number,
          drilldown: PropTypes.bool,
        }),
      ),
      color: PropTypes.string,
    }),
  ).isRequired,
  graphSettings: PropTypes.shape({
    title: PropTypes.string,
    widgetName: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  onLegendClick: PropTypes.func,
  extraOptions: PropTypes.func,
  tooltipProvider: PropTypes.func,
  containerProps: PropTypes.shape({}),
};

StackedColumnChartWidget.defaultProps = {
  extraOptions: null,
  tooltipProvider: null,
  onLegendClick: null,
  containerProps: {},
};
export default StackedColumnChartWidget;
