import React, {Fragment} from 'react';

import PropTypes from 'prop-types';

import {Button, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {withLogger} from '../logger';
import Spinner from '../spinner';

const styles = (theme) => ({
  root: {
    width: '100%',
    minHeight: theme.spacing(8),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1.5),
  },
  errorMsg: {
    color: theme.palette.error.main,
    maxWidth: '45rem',
  },
});

const LoadingComponent = ({error, retry, timedOut, classes, logger}) => {
  let contents = null;
  if (error) {
    logger.error('Loader failed with error.', {}, error);
    contents = (
      <Typography className={classes.errorMsg}>
        An error occurred loading a resource, please try again. If the issue
        persists, please contact Support by clicking on the gear icon at the top
        right and clicking Report Problem.
      </Typography>
    );
  } else if (timedOut) {
    contents = (
      <Fragment>
        <Typography>Timed out while loading a resource.</Typography>
        <Button onClick={retry}>Retry</Button>
      </Fragment>
    );
  } else {
    contents = <Spinner size={30} color="primary" />;
  }

  return <div className={classes.root}>{contents}</div>;
};

LoadingComponent.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  retry: PropTypes.func,
  timedOut: PropTypes.bool,
};

LoadingComponent.defaultProps = {
  error: null,
  retry: null,
  timedOut: null,
};

export default withLogger(
  withStyles(styles, {withTheme: true})(LoadingComponent),
);
