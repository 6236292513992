import React from 'react';
import {Route} from 'react-router-dom';
import PropTypes from 'prop-types';

import isAllowed from '../../util/allowed';
import {withCurrentUser} from '../../contexts/currentUserContext';

const CapabilityEnabledRoute = ({
  currentUser,
  capabilities,
  renderDefault,
  ...restProps
}) => {
  const allowed = isAllowed(currentUser, capabilities);

  const renderDefaultComponent = () =>
    React.isValidElement(renderDefault)
      ? React.cloneElement(renderDefault)
      : null;

  return allowed ? <Route {...restProps} /> : renderDefaultComponent();
};

CapabilityEnabledRoute.propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderDefault: PropTypes.node,
  currentUser: PropTypes.shape({}),
};

CapabilityEnabledRoute.defaultProps = {
  renderDefault: null,
  currentUser: {},
};

export default withCurrentUser(CapabilityEnabledRoute);
