import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Checkbox,
  Typography,
} from '@mui/material';

import {manageSitesToScheduleTemplate} from '../../../../api/alarms';

import CallbackFilterField from '../../../../shared/components/callbackFilterField';
import {handleFilterChange} from '../filterReducer';
import {useSiteManagementDialog} from './hooks';
import PagedList from '../../../../apps/sitesCategoriesList/PagedList';
import Spinner from '../../../../shared/components/spinner';
import {useDialog} from '../../../../shared/hooks';

const ScheduleExceptionsDialog = ({
  dialogOpen,
  onDialogClose,
  selectedSchedule,
  snackbar,
  totalSSPSitesCount,
  isMediumScreen,
  onTableRefresh,
}) => {
  const {
    siteList,
    handleLoadMoreData,
    selectedSites,
    disabledSubmit,
    handleCheckboxSelect,
    handleSelectAll,
    resetState,
    searchValue,
    isSearchValueValid,
    dispatch,
    statuses,
    unprocessableSites,
    setUnprocessableSites,
    conflictedSchedules,
    isFirstPage,
  } = useSiteManagementDialog(selectedSchedule, dialogOpen, totalSSPSitesCount);
  const {isLoadingStatus: isConflictsLoading} = conflictedSchedules;
  const [isSaving, setIsSaving] = useState(false);
  const {dataLoading, dataReloading, dataFetched, dataFetchError} = statuses;

  const [
    unprocessableSitesDialogOpen,
    handleUnprocessableSitesDialogOpen,
    handleUnprocessableSitesDialogClose,
  ] = useDialog();

  const handleClose = () => {
    dispatch({type: 'RESET_SEARCH'});
    onDialogClose();
    resetState();
  };

  const handleSave = async (scheduleId, siteIds) => {
    setIsSaving(true);
    await manageSitesToScheduleTemplate(scheduleId, siteIds)
      .then(() => {
        snackbar.success('Sites have been successfully managed.');
        onTableRefresh((prev) => !prev);
        setIsSaving(false);
        handleClose();
        resetState();
      })
      .catch(({response}) => {
        setIsSaving(false);
        if (
          response.status === 422 &&
          response.data.error?.unprocessableSites
        ) {
          setUnprocessableSites(response.data.error.unprocessableSites);
          handleUnprocessableSitesDialogOpen();
        } else {
          snackbar.error('Failed to manage sites.');
          handleClose();
          resetState();
        }
      });
  };

  const handleShowingSiteCategoriesList = () => {
    const shouldShowList = dataFetched || dataLoading || !dataReloading;
    if (isFirstPage && !searchValue) {
      return siteList.length > 0 && !dataReloading && !isConflictsLoading;
    }
    return shouldShowList;
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="lg"
        fullWidth
        fullScreen={isMediumScreen}
        PaperProps={{sx: {height: !isMediumScreen ? '80vh' : '100%'}}}
        data-cy="manage-site-dialog"
        open={dialogOpen}
        onClose={handleClose}
      >
        <DialogTitle
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Checkbox
            data-cy="select-all-checkbox"
            onChange={({target}) => handleSelectAll(target)}
            color="primary"
            disabled={
              !siteList.length ||
              siteList.some((s) => s.disabled) ||
              dataReloading ||
              dataLoading ||
              isConflictsLoading
            }
            checked={selectedSites.length === totalSSPSitesCount}
          />
          <CallbackFilterField
            sx={{margin: 0}}
            size="small"
            data-cy="manage-site-search"
            isDebounced
            filterValue={searchValue}
            onFilter={(value) => handleFilterChange(dispatch, value)}
            error={!isSearchValueValid}
            helperText={
              isSearchValueValid ? null : 'Please enter at least 3 characters'
            }
            variant="outlined"
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{paddingTop: 0}}>
          <PagedList
            list={siteList}
            hasFilter={!!siteList}
            showSiteCategoryList={handleShowingSiteCategoriesList()}
            showProgress={dataLoading || dataReloading || isConflictsLoading}
            showError={dataFetchError}
            showMoreButton={!(dataFetched || dataReloading)}
            disabledMoreButton={dataLoading}
            dropdownNav
            checkboxListMode
            onCheckboxSelect={(site, e) => handleCheckboxSelect(site, e)}
            handleLoadMore={() => handleLoadMoreData()}
            disableSiteDetails
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSave(selectedSchedule.id, selectedSites)}
            disabled={disabledSubmit || dataLoading || isSaving}
          >
            Save
            {isSaving && <Spinner style={{marginLeft: '10px'}} size={15} />}
          </Button>
        </DialogActions>
      </Dialog>
      {unprocessableSitesDialogOpen && unprocessableSites.length > 0 && (
        <Dialog
          maxWidth="sm"
          PaperProps={!isMediumScreen && {sx: {height: '40vh'}}}
          data-cy="assignment-error"
          open={unprocessableSitesDialogOpen}
          onClose={handleUnprocessableSitesDialogClose}
        >
          <DialogTitle
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Assignment Error
          </DialogTitle>
          <DialogContent sx={{height: '100%', overflow: 'auto'}}>
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Schedule Exception cannot be assign to sites with shared Alarm
              Schedule, if not all of those sites are selected.
            </Typography>
            <br />
            <Typography variant="subtitle2" sx={{textAlign: 'center'}}>
              Please unselect or change the Alarm Schedule for fallowing site(s)
              before Saving:
            </Typography>
            <br />
            <Typography
              variant="subtitle2"
              sx={{textAlign: 'center', whiteSpace: 'pre-line'}}
            >
              {unprocessableSites
                ?.map(({name}, ind) => `${ind + 1}. ${name}`)
                ?.join('\n')}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              onClick={handleUnprocessableSitesDialogClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Fragment>
  );
};

ScheduleExceptionsDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  selectedSchedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  snackbar: PropTypes.shape({}).isRequired,
  isMediumScreen: PropTypes.bool.isRequired,
  onTableRefresh: PropTypes.func.isRequired,
  totalSSPSitesCount: PropTypes.number,
};
ScheduleExceptionsDialog.defaultProps = {
  selectedSchedule: undefined,
  totalSSPSitesCount: undefined,
};
export default ScheduleExceptionsDialog;
