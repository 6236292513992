// Local Storage
export const getLocalStorageItem = (name) => {
  if (!window.localStorage) {
    return null;
  }

  const storedObject = window.localStorage.getItem(name);

  if (!storedObject) {
    return null;
  }

  try {
    return JSON.parse(storedObject);
  } catch (e) {
    // someone may have tampered with the value
    return null;
  }
};

export const setLocalStorageItem = (name, value) => {
  if (!window.localStorage) {
    return false;
  }

  try {
    window.localStorage.setItem(name, JSON.stringify(value));
  } catch (e) {
    // Throws if storage is full (e.g., always on iOS 5+ Safari private mode, where
    // storage is set to 0).
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem#Exceptions
    // No need to perform any operation.
    return false;
  }

  return value;
};

// Cookies
export const getCookie = (cname) => {
  const c = document.cookie.match(`(^|[^;]+)\\s*${cname}\\s*=\\s*([^;]+)`);
  return c ? c.pop() : '';
};

export const setCookie = (cname, cvalue, duration) => {
  let maxAge = '';
  if (duration) {
    maxAge = `max-age=${duration.as('seconds')};`; // max-age in seconds
  }
  document.cookie = `${cname}=${cvalue};domain=.envysion.com;secure=true;${maxAge}path=/`;
};
