import axios from 'axios';

const normalizeCreateUpdateRecord = (register) => {
  const normalizedRegister = register;
  normalizedRegister.registerNumber = parseInt(register.registerNumber, 10);
  return normalizedRegister;
};

export const getRegisters = async () => {
  const {data: registers} = await axios.get('/registers');
  return registers;
};

export const getRegister = async (registerId) => {
  const {data: register} = await axios.get(`/registers/${registerId}`);
  return register;
};

export const createRegister = async (registerRecord) => {
  const {data} = await axios.post(
    `/registers`,
    normalizeCreateUpdateRecord(registerRecord),
  );
  return data;
};

export const updateRegister = async (registerId, registerRecord) => {
  const {data} = await axios.put(
    `/registers/${registerId}`,
    normalizeCreateUpdateRecord(registerRecord),
  );
  return data;
};

export const deleteRegister = async (registerId) => {
  const {data} = await axios.delete(`/registers/${registerId}`);
  return data;
};
