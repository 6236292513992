import axios from 'axios';

const transform = (preference) => ({
  ...preference,
  id: preference.name,
  name: undefined,
});

const transformValue = (defaultValue, inputType) => {
  if (typeof defaultValue === 'undefined') {
    return undefined;
  }
  switch (inputType) {
    case 'JSON':
      return typeof defaultValue === 'object'
        ? defaultValue
        : JSON.parse(defaultValue);
    case 'NUMBER':
      return parseInt(defaultValue, 10);
    case 'TEXT':
      return defaultValue.toString();
    default:
      return defaultValue;
  }
};

export const transformForServer = (preference) => ({
  ...preference,
  name: preference.id,
  id: undefined,
  visible: Boolean(preference.visible),
  defaultValue: transformValue(preference.defaultValue, preference.inputType),
});

const transformPreferenceInstance = (preference) => ({
  ...preference,
  id: preference.uuid,
});

export const getPreferences = async () =>
  (await axios.get('/preferences')).data.map(transform);

export const getPreferenceById = async (id) =>
  transform((await axios.get(`/preferences/${id}`)).data);

export const savePreference = (preference) => {
  const transformed = transformForServer(preference);
  return axios.put(`/preferences/${transformed.name}`, transformed);
};

const getScopeWithPreferences = (scope) => async (id) =>
  (await axios.get(`/preferences/${id}/${scope}`)).data.map(
    transformPreferenceInstance,
  );

export const getUsersWithPreference = getScopeWithPreferences('users');
export const getDomainsWithPreference = getScopeWithPreferences('domains');

const deleteScopePreference = (scope) => async (preferenceId, scopeId) =>
  axios.delete(`/preferences/${preferenceId}/${scope}/${scopeId}`);

export const deleteUserPreference = deleteScopePreference('users');
export const deleteDomainPreference = deleteScopePreference('domains');

const putScopePreference = (scope) => async (preference, scopeId, value) => {
  const {id, inputType} = preference;
  const transformedValue = transformValue(value, inputType);
  const response = await axios.put(`/preferences/${id}/${scope}/${scopeId}`, {
    preference_value: transformedValue,
  });
  return {
    ...response,
    value: transformedValue,
  };
};

export const putUserPreference = putScopePreference('users');
export const putDomainPreference = putScopePreference('domains');
