import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import WorkspaceMenuItem from './WorkspaceMenuItem';
import {workspaceItems} from '../routes';
import {hasRequiredCapabilityWithMatchingPreference} from '../utils';

const WorkspaceMenuDialog = ({isWorkspaceOpen, onWorkspaceClose, user}) => (
  <Dialog
    open={isWorkspaceOpen}
    onClose={onWorkspaceClose}
    sx={{
      '& .MuiDialog-container': {
        alignItems: {xs: 'center', sm: 'flex-start'},
        position: {sm: 'absolute'},
        top: {sm: (theme) => theme.spacing(5)},
        left: {sm: (theme) => theme.spacing(0)},
      },
      '& .MuiDialog-paper': {mt: 3, mx: 2.5},
    }}
  >
    <DialogTitle>
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Workspace</Typography>
        <IconButton onClick={onWorkspaceClose} data-cy="workspace-close-button">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent sx={{maxWidth: 400, minWidth: 300, p: 3, mt: 1}}>
      <Grid container spacing={1.5}>
        {workspaceItems.map((item) => {
          if (hasRequiredCapabilityWithMatchingPreference(item, user)) {
            return (
              <WorkspaceMenuItem
                key={item.title}
                item={item}
                selected={window.location.href.includes(item.path)}
                bgColor={item.bgColor}
              />
            );
          }
          return null;
        })}
      </Grid>
    </DialogContent>
  </Dialog>
);

WorkspaceMenuDialog.propTypes = {
  isWorkspaceOpen: PropTypes.bool.isRequired,
  onWorkspaceClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    capabilities: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    settings: PropTypes.shape({}),
  }).isRequired,
};

export default WorkspaceMenuDialog;
