import React from 'react';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
});

class PaginationActions extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  handlePageSelection = (event) => {
    this.props.onPageChange(event, event.target.value - 1);
  };

  render() {
    const {classes, count, page, rowsPerPage} = this.props;

    return (
      <div data-cy="pagination-actions" className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
          size="large"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
          size="large"
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          size="large"
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          size="large"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

PaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default withStyles(styles, {withTheme: true})(PaginationActions);
