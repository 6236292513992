import React, {Children} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Switch, Route} from 'react-router-dom';
import DashboardComponent from '../../shared/components/dashboardComponent';
import SspSection from './landingPage/SspSection';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {sspTheme} from '../../shared/themes';
import {isMobileApp} from '../../shared/util/general';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import {getTabs} from './securityPillarRoutes';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';
import {getNavigationItems} from '../../shared/components/getNavigationItems';

const SmartSitePageResponsive = ({currentUser}) => {
  const isContentPadding =
    !window.location.href.includes('/ui/ssp/alarm-configuration') ||
    !window.location.href.includes('/ui/ssp/alarm-events');

  const navigationProps = getNavigationItems(getTabs(currentUser), currentUser);

  const titleProps =
    !isMobileApp() && !currentUser.settings.newNavigationWeb
      ? {title: 'Smart Site Protection'}
      : {};

  return (
    <ThemeProvider theme={sspTheme}>
      <PageTemplateResponsive
        PageHeaderProps={{title: 'Smart Site Protection'}}
        PageContentProps={{
          marginY: isContentPadding ? {xs: 1, sm: 3, md: 3, lg: 3, xl: 3} : 0,
          marginX: isContentPadding ? {xs: 2, sm: 2.5, md: 3, lg: 5} : 0,
        }}
        {...titleProps}
        {...navigationProps}
      >
        {isNewNavigationWebEnabled(currentUser) ? (
          <Switch>
            <Route exact path="/ssp">
              <RedirectWithQueryParams to="/ssp/landing-page" />
            </Route>
            {getTabs(currentUser).map((tab) => (
              <Route
                exact
                key={tab.key}
                path={`/ssp/${tab.path}`}
                render={() => React.createElement(tab.component, tab.props)}
              />
            ))}
          </Switch>
        ) : (
          <DashboardComponent data-cy="ssp-landing-page-content">
            {Children.toArray(<SspSection />)}
          </DashboardComponent>
        )}
      </PageTemplateResponsive>
    </ThemeProvider>
  );
};

export default withCurrentUser(SmartSitePageResponsive);
