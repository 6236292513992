import React from 'react';
import FormSwitch from './FormSwitch';
import FormTextField from './FormTextField';
import FormSelectList from './FormSelectList';
import DescriptionText from './DescriptionText';
import FormDateTime from './FormDateTime';

export const buildField = (fieldDef, props, classes, focused) => {
  if (fieldDef.render && typeof fieldDef.render === 'function') {
    return fieldDef.render(fieldDef, props, classes);
  }

  switch (fieldDef.component) {
    case 'Switch':
      return (
        <FormSwitch
          key={fieldDef.name}
          fieldDef={fieldDef}
          formProps={props}
          classes={classes}
        />
      );
    case 'SelectList':
      return (
        <FormSelectList
          key={fieldDef.name}
          fieldDef={fieldDef}
          formProps={props}
          classes={classes}
          focused={focused}
        />
      );
    case 'Description':
      return (
        <DescriptionText
          key={fieldDef.name}
          typographyDef={fieldDef}
          classes={classes}
        />
      );
    case 'DateTime':
      return (
        <FormDateTime
          key={fieldDef.name}
          fieldDef={fieldDef}
          formProps={props}
          classes={classes}
          focused={focused}
        />
      );
    default:
      return (
        <FormTextField
          key={fieldDef.name}
          fieldDef={fieldDef}
          formProps={props}
          classes={classes}
          focused={focused}
        />
      );
  }
};

export const structureToSchemaReducer = (acc, value) => {
  if (value.validation) acc[value.name] = value.validation;
  return acc;
};
