import React from 'react';
import {ThemeProvider} from '@mui/material';
import {responsiveTheme} from '../../themes';
import {wrapDisplayName} from '../../util/hocSupport';

export const withResponsiveTheme = (Component) => {
  const ResponsiveTheme = (props) => {
    return (
      <ThemeProvider theme={responsiveTheme}>
        <Component {...props} />
      </ThemeProvider>
    );
  };
  ResponsiveTheme.displayName = wrapDisplayName(
    'WithResponsiveTheme',
    Component,
  );

  return ResponsiveTheme;
};
