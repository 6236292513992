import React from 'react';
import {Card, Typography, Button} from '@mui/material';
import omit from 'lodash/omit';
import withStyles from '@mui/styles/withStyles';
import oopsImage from './oops.png';
import {withLogger} from '../../shared/components/logger';

const styles = (theme) => ({
  wrapper: {
    textAlign: 'center',
    marginTop: '5vh',
    padding: [[0, theme.spacing(2)]],
  },
  centered: {
    padding: '4vh',
    textAlign: 'center',
    margin: '0 auto',
  },
  oops: {
    height: '40vh',
  },
});

const GlobalErrorPage = (props) => {
  const {classes, logger, error, reload} = props;
  if (error === 'NoAuth') {
    logger.warn(
      '403 error page rendered.',
      {
        pathname: window.location && window.location.pathname,
        ...props,
      },
      new Error(),
    );
    return (
      <div id="global-error-page" className={classes.wrapper}>
        <Card className={classes.centered}>
          <Typography variant="h5" gutterBottom>
            Not Authorized
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            Please contact your Envysion Administrator if you
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            believe you need access to this page or ...
          </Typography>
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={reload}
          >
            Reload App
          </Button>
        </Card>
      </div>
    );
  }
  logger.warn(
    '404 error page rendered.',
    {
      pathname: window.location && window.location.pathname,
      ...omit(props, ['classes', 'children', 'logger', 'reload']),
      error: typeof error === 'object' ? JSON.stringify(error) : error,
    },
    new Error(),
  );
  return (
    <div className={classes.wrapper}>
      <Card className={classes.centered}>
        <Typography variant="h5" gutterBottom>
          Page not found.
        </Typography>
        <img className={classes.oops} alt="Page not found" src={oopsImage} />
        <Typography gutterBottom variant="body2">
          Try the back button or ...
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={reload}
        >
          Reload App
        </Button>
      </Card>
    </div>
  );
};

GlobalErrorPage.defaultProps = {reload: () => (window.location = '/')};

export default withLogger(withStyles(styles)(GlobalErrorPage));
