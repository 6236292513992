import axios from 'axios';
import {camelizeKeys, decamelizeKeys} from 'humps';
import isArray from 'lodash/isArray';

axios.defaults.baseURL = '/api/v3';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.withCredentials = true;

// Store a reference to the original axios.create function
const axiosCreate = axios.create;

// TODO: this was removed to allow the user list to update after
// a new user is created.
// axios.defaults.adapter = throttleAdapterEnhancer(
//   cacheAdapterEnhancer(axios.defaults.adapter),
//   {threshold: 1000},
// );

const convertData = (data, fn) =>
  isArray(data) ? data.map((o) => fn(o)) : fn(data);

const nonAuthRedirectUrls = [
  /\/api\/v3\/users\/me\/extensive/,
  /\/api\/v3\/users\/me\/menu/,
  /\/api\/v3\/sso\/tokens\/.+\/validate/,
  /\/api\/v3\/sso\/login/,
];

const shouldRedirect = ({status, request, config}) => {
  const url = request?.url || request?.responseURL || config.url;
  return status === 401 && !nonAuthRedirectUrls.find((r) => r.test(url));
};

function setupInterceptors(instance) {
  const requestCasingInterceptor = instance.interceptors.request.use(
    (config) => {
      const {params, data} = config;
      return {
        ...config,
        params: decamelizeKeys(params),
        data: convertData(data, decamelizeKeys),
      };
    },
    (error) => Promise.reject(error),
  );

  const responseCasingInterceptor = instance.interceptors.response.use(
    (response) => {
      const {data} = response;
      return {
        ...response,
        data: convertData(data, camelizeKeys),
      };
    },
    (error) => {
      const {response} = error;
      if (response) {
        const {data} = response;
        const newError = {
          ...error,
          response: {
            ...response,
            data: convertData(data, camelizeKeys),
          },
        };
        return Promise.reject(newError);
      }
      return Promise.reject(error);
    },
  );

  instance.interceptors.request.use((config) => {
    const {headers, ...rest} = config;
    if (window.backTraceCorrelationId) {
      headers['X-Request-Id'] = window.backTraceCorrelationId;
    }
    return {...rest, headers};
  });

  const redirectionInterceptor = instance.interceptors.response.use(
    (response) => {
      if (shouldRedirect(response)) {
        window.location.assign('/ui/auth/login');
      }
      return response;
    },
    (error) => {
      if (error.response && shouldRedirect(error.response)) {
        window.location.assign('/ui/auth/login');
      }
      return Promise.reject(error);
    },
  );

  // Return interceptor IDs
  return {
    requestCasingInterceptor,
    responseCasingInterceptor,
    redirectionInterceptor,
  };
}

// The basic idea here is to replace the axios.create function with our own that
// creates a new instance and sets a default set of interceptors on the instance.
// We also store the interceptorIds on the instance itself for optional ejection later
function createAxiosInstanceWithInterceptors(...args) {
  // Call the original create function with the provided arguments with "this" set to the axios object
  const instance = axiosCreate.apply(axios, args);
  const interceptorIds = setupInterceptors(instance);

  instance.interceptorIds = interceptorIds;

  return instance;
}

// We also want to setup our interceptions on the default axios instance, not just manually created ones.
const interceptorIds = setupInterceptors(axios);
axios.interceptorIds = interceptorIds;

// Replace the create function with our own that adds default interceptors
axios.create = createAxiosInstanceWithInterceptors;
