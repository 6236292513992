import axios from 'axios';

export const getDelegations = async () => {
  const {data: registers} = await axios.get('/delegations');
  return registers;
};

export const getDelegation = async (id) => {
  const {data: register} = await axios.get(`/delegations/${id}`);
  return register;
};

export const createDelegation = async (delegationRecord) => {
  const {data} = await axios.post(`/delegations`, delegationRecord);
  return data;
};

export const updateDelegation = async (delegation) => {
  const {data} = await axios.put(`/delegations/${delegation.id}`, delegation);
  return data;
};

export const deleteDelegation = async (id) => {
  const {data} = await axios.delete(`/delegations/${id}`);
  return data;
};
