import axios from 'axios';

export const getGroups = async () =>
  (await axios.get('/groups')).data.map((group) => ({
    ...group,
    members: group.members - 1,
  }));

export const getGroup = async (id) => (await axios.get(`/groups/${id}`)).data;

export const getMembers = async (groupId) =>
  (await axios.get(`/groups/${groupId}/members`)).data;

export const addMembers = async (users, groupId) =>
  (await axios.put(`/groups/${groupId}/join`, users)).data;

export const removeMembers = async (users, groupId) =>
  (await axios.put(`/groups/${groupId}/leave`, users)).data;

export const updateGroup = async (groupId, values) =>
  (await axios.put(`/groups/${groupId}`, values)).data;

export const createGroup = async (formData) =>
  (await axios.post('/groups', formData)).data;

export const deleteGroup = async (groupId) =>
  (await axios.delete(`/groups/${groupId}`)).data;

export default {getGroup, getGroups};
