import React, {PureComponent} from 'react';
import ListItem from '@mui/material/ListItem';
import {withRouter, Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import {overrideComponent} from '../../types/common';

class ListItemLink extends PureComponent {
  render() {
    const {
      children,
      component,
      to,
      match,
      relative,
      dropdownNav,
      ...rest
    } = this.props;

    let incomingTo = to;

    if (!isEmpty(incomingTo) && !incomingTo.startsWith('/')) {
      incomingTo = `/${to}`;
    }

    const actualTo = relative ? `${match.url}${incomingTo}` : incomingTo;

    return (
      <li>
        {dropdownNav ? (
          <ListItem {...omit(rest, 'staticContext', 'history')} button>
            {children}
          </ListItem>
        ) : (
          <ListItem
            {...omit(rest, 'staticContext', 'history')}
            to={actualTo}
            button
            component={component}
          >
            {children}
          </ListItem>
        )}
      </li>
    );
  }
}

ListItemLink.muiName = 'ListItem';

ListItemLink.propTypes = {
  component: overrideComponent,
  children: PropTypes.node.isRequired,
  relative: PropTypes.bool,
  to: PropTypes.string.isRequired,
  match: PropTypes.shape({url: PropTypes.string}),
  dropdownNav: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.shape({}),
    PropTypes.bool,
  ]),
};

ListItemLink.defaultProps = {
  component: Link,
  relative: false,
  match: {url: ''},
  dropdownNav: undefined,
};

export default withRouter(ListItemLink);
