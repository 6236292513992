import axios from 'axios';

export const getLocalUsers = async (siteId) => {
  const {data} = await axios.get(`/sites/${siteId}/local`);
  return data;
};

export const createLocalUser = async (siteId, localUser) => {
  const {data} = await axios.post(`/sites/${siteId}/local`, localUser);
  return data;
};

export const deleteLocalUser = async (siteId, localUserId) => {
  const {data} = await axios.delete(`/sites/${siteId}/local/${localUserId}`);
  return data;
};

export default {getLocalUsers, createLocalUser, deleteLocalUser};
