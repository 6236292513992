import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Checkbox,
  Typography,
  Box,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ImageLoader from '../imageLoader';

import Spinner from '../spinner';
import {generateNonce} from '../../util/nonce';

import defaultThumbnail from '../../images/defaultThumbnail.png';

const imageStyle = {
  width: '100%',
  height: 'auto',
  transition: 'opacity .5s',
};

const PositionedPlayIcon = styled(IconButton)(({theme}) => ({
  position: 'absolute',
  color: theme.palette.common.white,
  visibility: 'hidden',
}));

const OfflineBox = styled('div')(({theme}) => ({
  color: theme.palette.getContrastText(theme.palette.common.black),
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

const TransitionImageLoader = styled(ImageLoader)(() => ({
  ...imageStyle,
}));

const FlexedImageListItem = styled(ImageListItem, {
  shouldForwardProp: (prop) =>
    prop !== 'cameraOnline' && prop !== 'playableTile',
})(({theme, cameraOnline, playableTile}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.common.black,
  overflow: 'hidden',
  '&:hover': {
    '& button': {
      visibility: 'visible',
    },
    '& canvas': {
      ...(cameraOnline && playableTile && {opacity: '.5'}),
    },
    '& .offline-camera': {
      ...(playableTile && {color: theme.palette.grey[800]}),
    },
  },
}));

const CameraTile = ({
  camera,
  selected,
  playCamera,
  selectable,
  refreshInterval,
  mini,
  onSelect,
  disableSelect,
  doLazyLoading,
  layoutMaxReached,
  disableItemBar,
  sx,
  ...rest
}) => {
  const [cameraThumbnail, setCameraThumbnail] = useState(camera.img);
  const interval = useRef(null);
  const playable = typeof playCamera === 'function';

  useEffect(
    () => {
      const refreshThumbnail = () => {
        setCameraThumbnail(`${camera.img}?nonce=${generateNonce()}`);
      };

      // if refreshInterval is <= 0, no refresh will be provided
      if (refreshInterval > 0 && camera.isOnline) {
        interval.current = setInterval(refreshThumbnail, refreshInterval);
      } else {
        clearInterval(interval.current);
        interval.current = null;
      }

      return () => clearInterval(interval.current);
    },
    [camera.img, camera.isOnline, refreshInterval],
  );

  const play = () => {
    if (playable) {
      playCamera(camera);
    }
  };

  const selectCamera = () => {
    if (typeof onSelect === 'function') {
      onSelect(camera);
    }
  };

  return (
    <FlexedImageListItem
      {...rest}
      cameraOnline={!!camera.isOnline}
      playableTile={!!playable}
      name="camera-tile"
      id={`camera-tile-${camera.id}`}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {camera.isOnline ? (
        <TransitionImageLoader
          src={cameraThumbnail}
          alt={`${camera.name} thumbnail`}
          doLazyLoading={doLazyLoading}
          unloader={
            <Box
              component="img"
              src={defaultThumbnail}
              sx={imageStyle}
              alt="Default thumbnail"
            />
          }
          loader={
            <Spinner
              {...disableItemBar && {size: 30, style: {position: 'absolute'}}}
              color="primary"
            />
          }
        />
      ) : (
        <OfflineBox className="offline-camera">
          <VideocamOffIcon />
          <Typography
            color="inherit"
            variant={mini || disableItemBar ? 'subtitle1' : 'h5'}
          >
            Camera is offline
          </Typography>
        </OfflineBox>
      )}
      {!disableItemBar && (
        <ImageListItemBar
          title={camera.name}
          sx={[
            mini && {
              height: (theme) => theme.spacing(3),
            },
          ]}
          actionPosition="left"
          position="top"
          actionIcon={
            selectable && (
              <Checkbox
                sx={[
                  {color: (theme) => theme.palette.common.white},
                  {
                    '&.Mui-disabled': {
                      color: 'grey.800',
                    },
                  },
                ]}
                onChange={selectCamera}
                checked={selected}
                disabled={disableSelect || (layoutMaxReached && !selected)}
              />
            )
          }
        />
      )}
      {typeof playCamera === 'function' && (
        <PositionedPlayIcon onClick={play} name="play" size="large">
          <PlayCircleOutlineOutlinedIcon style={{fontSize: mini ? 24 : 48}} />
        </PositionedPlayIcon>
      )}
    </FlexedImageListItem>
  );
};
CameraTile.muiName = 'ImageListItem';

CameraTile.propTypes = {
  camera: PropTypes.shape({}).isRequired,
  selected: PropTypes.bool,
  playCamera: PropTypes.func,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  mini: PropTypes.bool,
  layoutMaxReached: PropTypes.bool,
  refreshInterval: PropTypes.number,
  disableSelect: PropTypes.bool,
  doLazyLoading: PropTypes.bool,
  disableItemBar: PropTypes.bool,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

CameraTile.defaultProps = {
  // if refreshInterval is <= 0, no refresh will be provided
  refreshInterval: 30000, // 30 seconds,
  disableSelect: false,
  doLazyLoading: false,
  disableItemBar: undefined,
  playCamera: undefined,
  selectable: undefined,
  layoutMaxReached: undefined,
  mini: false,
  selected: false,
  onSelect: undefined,
  sx: undefined,
};

export default CameraTile;
