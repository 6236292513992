/* eslint-disable camelcase */

import {isNewNavigationWebEnabled} from '../../../shared/util/user';

export const formatCloudConfig = (config) => {
  const {enabled, quality, transcode_settings} = config;
  const {resolution, video_kbps, fps} = transcode_settings || {};

  let formattedResolution = resolution;
  if (typeof resolution === 'object') {
    formattedResolution = `${resolution.width}x${resolution.height}`;
  }

  return {
    enabled,
    quality,
    video_kbps: enabled && video_kbps,
    fps: enabled && fps,
    resolution: enabled && formattedResolution,
  };
};

export const mapCloudArchiveSettings = (cameras, policyMap) => {
  const result = cameras.map((camera) => ({
    ...camera,
    ...formatCloudConfig(policyMap[camera.id]),
  }));
  return result;
};

export const getSitesBasePath = (currentUser) =>
  isNewNavigationWebEnabled(currentUser) ? '/home' : '';
