import React, {useEffect, useState, useMemo, useCallback} from 'react';

import {TextField, IconButton, styled} from '@mui/material';
import {debounce} from '@mui/material/utils';
import isEqual from 'lodash/isEqual';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const SearchInput = styled(TextField)(({theme}) => ({
  width: 'inherit',
  paddingBottom: theme.spacing(1),
  '& input': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiInput-underline:before': {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& input[type="search"]::-webkit-search-decoration,
  & input[type="search"]::-webkit-search-cancel-button,
  & input[type="search"]::-webkit-search-results-button,
  & input[type="search"]::-webkit-search-results-decoration`]: {
    /* clears the 'X' icon from Chrome */
    display: 'none',
  },
}));

const debounceMs = 500;

const MobileGridSearch = (props) => {
  const {searchParam, setSearchParam, ...rest} = props;
  const [searchValue, setSearchValue] = useState('');
  const [prevFilterValues, setPrevFilterValues] = useState();

  useEffect(
    () => {
      if (!isEqual(prevFilterValues, searchParam)) {
        // The model of  filter value has been updated
        setPrevFilterValues(searchParam);

        // Update the input value if needed to match the new model
        setSearchValue((prevSearchValue) =>
          isEqual(prevSearchValue, searchParam)
            ? prevSearchValue
            : searchParam ?? '',
        );
      }
    },
    [prevFilterValues, searchParam],
  );

  const updateSearchValue = useCallback(
    (newSearchValue) => {
      setSearchParam(newSearchValue);
    },
    [setSearchParam],
  );

  const debouncedUpdateSearchValue = useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue],
  );

  // Calls API when 2 or more characters present on a 500ms debounce,
  // or characters are deleted.
  const handleSearchValueChange = useCallback(
    (event) => {
      const newSearchValue = event.target.value;
      const delContent = event.nativeEvent.data === null;
      setSearchValue(newSearchValue);
      if (
        newSearchValue.length > 1 ||
        (newSearchValue.length < 2 && delContent)
      ) {
        debouncedUpdateSearchValue(newSearchValue);
      }
    },
    [debouncedUpdateSearchValue],
  );

  const handleSearchReset = useCallback(
    () => {
      setSearchValue('');
      updateSearchValue('');
    },
    [updateSearchValue],
  );

  return (
    <SearchInput
      variant="standard"
      value={searchValue}
      onChange={handleSearchValueChange}
      label="Search"
      type="search"
      placeholder="Site Name or MAC"
      InputProps={{
        startAdornment: <SearchIcon sx={{color: 'rgba(0, 0, 0, 0.54)'}} />,
        endAdornment: (
          <IconButton
            sx={{visibility: searchValue ? 'visible' : 'hidden'}}
            onClick={handleSearchReset}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
};

export default MobileGridSearch;
