import React from 'react';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import ProgressWrenchIcon from '../../../shared/images/ProgressWrenchIcon';
import Slider from '../../../shared/components/slider';
import SectionTitleBar from './SectionTitleBar';

const SliderWidget = (props) => {
  const {
    title,
    helperText,
    icon,
    value,
    marksValues,
    step,
    units,
    onChange,
  } = props;

  const min = marksValues[0];
  const max = marksValues[marksValues.length - 1];

  const getMarks = () => {
    return range(min, max + 1, step).map((val) => {
      let label = '';
      if (marksValues.includes(val)) label = val ? `${val} ${units}` : 'Off';
      return {
        value: val,
        label,
      };
    });
  };

  return (
    <Box
      sx={{
        marginBottom: 1,
      }}
    >
      <SectionTitleBar title={title} icon={icon} isActionIconVisible={false} />
      <Box
        sx={(theme) => ({
          margin: theme.spacing(2, 4, 2, 1),
        })}
      >
        <Slider
          aria-label="range-slider"
          value={value}
          step={step}
          marks={getMarks()}
          min={min}
          max={max}
          size="medium"
          onChangeCommitted={(event, val) => onChange(val)}
        />
      </Box>
      <Typography variant="body2">{helperText}</Typography>
    </Box>
  );
};

SliderWidget.propTypes = {
  title: PropTypes.string,
  helperText: PropTypes.string,
  icon: PropTypes.node,
  value: (props, propName, componentName) => {
    if (typeof props[propName] !== 'number' || props[propName] < 0) {
      return new Error(
        `Invalid value of ${propName} supplied to ${componentName}.`,
      );
    }
    return undefined;
  },
  marksValues: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.number,
  units: PropTypes.string,
  onChange: PropTypes.func,
};

SliderWidget.defaultProps = {
  title: 'Select value',
  helperText: 'Select a value from the range',
  icon: <ProgressWrenchIcon />,
  value: 0,
  marksValues: [0, 1, 3, 5],
  step: 1,
  units: '',
  onChange: () => {},
};

export default SliderWidget;
