import React from 'react';
import {Card, CardHeader, Typography, CardContent} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/sortedUniqBy';
import classNames from 'classnames';
import * as cases from '../../api/cases';
import {getSites} from '../../api/sites';
import {getAppliancesByParams} from '../../api/appliances';

import PageLoader from '../../shared/components/pageLoader';
import {flattenSites} from '../../shared/util/sites';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: theme.spacing(40),
    maxWidth: theme.spacing(70),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
}));

const Loader = ({className, defaultValues, ...props}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, className)} {...props}>
      <Card className={classes.card}>
        <PageLoader
          page={() => import('./Form')}
          resources={{
            problemTypes: cases.getProblemTypes,
            sites: async () =>
              getSites().then((s) =>
                uniqBy(sortBy(flattenSites(s), 'name'), 'id'),
              ),
            appliance: async () => {
              if (defaultValues.envrName) {
                return getAppliancesByParams({
                  name: defaultValues.envrName,
                }).then((r) => r.results[0]);
              }
              return undefined;
            },
          }}
          defaultValues={defaultValues}
        />
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Call Envysion Support" />
        <CardContent>
          <Typography paragraph variant="body2">
            For urgent matters that require immediate assistance, please call
            (toll-free) 877.258.9441, Option 1
          </Typography>
          <Typography paragraph variant="body2">
            Hours of operation for our Technical Support Team: 6:00 am – 8:00 pm
            MDT, 7 days a week (including holidays)
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default Loader;
