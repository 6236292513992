import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const CircleSliceIcon = (props) => {
  return (
    <SvgIcon sx={{fill: 'currentColor'}}>
      <svg {...props}>
        <path d="M12 2C17.5 2 22 6.5 22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4M12 5C13.93 5 15.68 5.78 16.95 7.05L12 12V5Z" />
      </svg>
    </SvgIcon>
  );
};

export default CircleSliceIcon;
