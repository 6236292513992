import axios from 'axios';

const catchError = (e) => {
  if (e.response?.status === 422 || e.response?.status === 403) {
    return {
      error: e.response.data.error.message,
      status: e.response.status,
    };
  }
  throw e; // unexpected error
};

export const getAccessControlEvents = async (
  siteId,
  filter,
  cursor,
  signal,
) => {
  try {
    const response = await axios.get(`/alta/sites/${siteId}/activityEvents`, {
      params: {limit: 10000, filter, cursor},
      signal,
    });
    return response.data;
  } catch (e) {
    return catchError(e);
  }
};

export const getAccessControlUsers = async () => {
  try {
    const response = await axios.get('/alta/users');
    return response.data;
  } catch (e) {
    return catchError(e);
  }
};

export const getCredentialTypes = async () => {
  try {
    const response = await axios.get('/alta/credentialtypes');
    return response.data;
  } catch (e) {
    return catchError(e);
  }
};

export const getEntries = async (siteId) => {
  try {
    const response = await axios.get(`/alta/sites/${siteId}/entries`);
    return response.data;
  } catch (e) {
    return catchError(e);
  }
};

export const getEntriesPerCamera = async (siteId) => {
  try {
    const response = await axios.get(`/alta/sites/${siteId}/entries/mapping`);
    return response.data;
  } catch (e) {
    return catchError(e);
  }
};

export const updateEntryMapping = async (siteId, cameraId, entryIds) => {
  const {data} = (await axios.put(`alta/sites/${siteId}/entries/mapping`, {
    entry_ids: entryIds,
    camera_id: cameraId,
  })).data;
  return data;
};
