import React from 'react';
import PropTypes from 'prop-types';
import {Drawer, Box, IconButton, useMediaQuery, useTheme} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CloseButton = ({onClick}) => (
  <IconButton
    data-cy="close-alarm-drawer"
    sx={{margin: 2}}
    aria-label="close drawer"
    name="close drawer"
    onClick={onClick}
  >
    <CloseIcon />
  </IconButton>
);

const AlarmDrawer = ({open, onClose, children, newNavigationEnabled}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();
  const navHeight = `${theme.mixins.toolbar.height +
    (newNavigationEnabled ? 0 : 54)}px`; // 54px is fixed #envysion-common-menu height

  return (
    <Drawer
      data-cy="alarm-configuration-drawer"
      PaperProps={{
        elevation: 2,
        sx: {
          width: isSmallScreen ? '100%' : 600,
          marginTop: navHeight,
          height: `calc(100% - ${navHeight})`,
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CloseButton onClick={onClose} />
      </Box>
      {children}
    </Drawer>
  );
};

AlarmDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  newNavigationEnabled: PropTypes.bool,
};
AlarmDrawer.defaultProps = {
  children: null,
  newNavigationEnabled: false,
};

export default AlarmDrawer;
