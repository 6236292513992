import axios from 'axios';

const instance = axios.create({baseURL: '/nakika-service/api'});
instance.interceptors.request.eject(
  instance.interceptorIds?.requestCasingInterceptor,
);
export const getTransaction = async (transId) =>
  (await axios.get(`/transactions/${transId}`)).data;

export const getTransactionFraudLearning = async (transUuid) =>
  (await axios.get(`/transactions/${transUuid}/fraud_learning`)).data;

export const setTransactionFraudLearning = async (transUuid, notFraud) => {
  await axios.put(`/transactions/${transUuid}/fraud_learning`, {
    notFraud,
  });
};

export const getJourney = async (transId) =>
  (await axios.get(`/journeys/${transId}`)).data;

export const getAnalyticEvents = async (transId) =>
  (await instance.get(`/analyticEvents/${transId}`)).data;

export const getEmployeeEvents = async (transId) =>
  (await instance.get(`/employeeEvents/${transId}`)).data;
