import axios from 'axios';

export const getHardwareHealth = async (
  page,
  limit,
  sortingColumn,
  sortingOrder,
  search,
  status,
  bitrateQuality,
  cameraStatus,
  caStatus,
  impairedCameras,
) => {
  return (await axios.get('/sites/health_status', {
    params: {
      offset: page * limit,
      limit,
      sortingColumn,
      sortingOrder,
      ...(search !== '' && {search}),
      ...(status && {status}),
      ...(bitrateQuality && {bitrateQuality}),
      ...(caStatus && {caStatus}),
      ...(cameraStatus && {cameraStatus}),
      ...(impairedCameras && {impairedCameras}),
    },
  })).data;
};

export const getHealthDetailsForSite = async (siteId) => {
  const {data} = await axios.get(`/sites/${siteId}/health_status`);
  return data;
};

export const getSystemHealthSummary = async (startDate, endDate) => {
  const {data} = await axios.get('/sites/health_status/summary', {
    params: {
      start_date: startDate.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
      end_date: endDate.toUTC().toFormat('yyyy-MM-dd HH:mm:ss'),
    },
  });
  return data;
};

export const getAppliancesHealthSummary = async (startDate, endDate) => {
  const {data} = await axios.get('system_health/appliance', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return data;
};

export const getCamerasHealthSummary = async (startDate, endDate) => {
  const {data} = await axios.get('system_health/camera', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
  return data;
};
