import {React, useCallback} from 'react';
import PropTypes from 'prop-types';
import {DateTime} from 'luxon';
import {Grid} from '@mui/material';
import {snakeCase} from 'lodash';
import queryString from 'query-string';

import CollapsibleTable from '../../../shared/components/collapsibleTable';
import {getAlarmForSitesByTypeAndSource} from '../../../api/alarms';

const HaloTableWidget = ({auxOptions, setAuxOptions, startDate, endDate}) => {
  const columnsDefinition = [
    {
      id: 'siteName',
      label: 'Site Name',
      style: {minWidth: 150},
    },
    {
      id: 'gunshot',
      label: 'Gunshot',
      style: {minWidth: 150},
    },
    {
      id: 'help',
      label: 'Help Keyword',
      style: {minWidth: 150},
    },
    {
      id: 'tamper',
      label: 'Tamper',
      style: {minWidth: 150},
    },
    {
      id: 'aggression',
      label: 'Aggression',
      style: {minWidth: 150},
    },
    {
      id: 'alarmsCount',
      label: 'Events',
      style: {minWidth: 150},
    },
  ];

  const {passedSortingOrder} = queryString.parse(window.location.search);

  const formatUrl = (data) =>
    `/sites/${
      data.siteId
    }/alarmEventsSummary?startDate=${startDate
      .toUTC()
      .toISO()}&endDate=${endDate.toUTC().toISO()}`;

  const downloadHaloTableData = useCallback(
    (pageNum, limit, sortingOrder, sortingColumn) => {
      return getAlarmForSitesByTypeAndSource({
        startDate,
        endDate,
        limit,
        offset: pageNum * limit,
        type: 'halo_event',
        sortingColumn: snakeCase(sortingColumn),
        sortingOrder,
      });
    },
    [endDate, startDate],
  );

  return (
    <Grid item xs={12} width={10}>
      <CollapsibleTable
        name="ssp-landing-page-list"
        onLoadData={downloadHaloTableData}
        onRowClick={(siteData) => window.open(`/ui${formatUrl(siteData)}`)}
        sortable
        paginateOptions={{initialRecords: 10, useBasicNavigation: true}}
        orderOptions={{
          initialOrderBy: passedSortingOrder ?? 'alarmsCount',
          initialOrder: 'desc',
        }}
        auxOptions={auxOptions}
        setAuxOptions={setAuxOptions}
        columns={columnsDefinition}
        rowId="siteId"
        isExpandable={false}
        stickyHeader={false}
      />
    </Grid>
  );
};

HaloTableWidget.displayName = 'Halo Table';

HaloTableWidget.propTypes = {
  auxOptions: PropTypes.shape({
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  setAuxOptions: PropTypes.func,
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  endDate: PropTypes.instanceOf(DateTime).isRequired,
};

HaloTableWidget.defaultProps = {
  auxOptions: undefined,
  setAuxOptions: undefined,
};

export default HaloTableWidget;
