import React from 'react';
import GlobalErrorPage from '../globalErrorPage';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const PageIFrameWrapper = (
  path,
  sandbox,
  capability,
  title,
  host = `https://${getHost()}`,
  params = '',
) => {
  const url = `${host}${path}?embedded=true${params}`;

  return (
    <CapabilitiesGuard
      allowed={capability}
      renderDefault={() => <GlobalErrorPage error="NoAuth" />}
    >
      <PageTemplateResponsive
        PageContentProps={{
          marginY: 0,
          marginX: 0,
        }}
      >
        <IFrameWrapper src={url} title={title} FrameProps={{sandbox}} />
      </PageTemplateResponsive>
    </CapabilitiesGuard>
  );
};

export default PageIFrameWrapper;
