import axios from 'axios';

export const getAdaptersList = async () =>
  (await axios.get('/integrations/adapters')).data;

export const getAdapterCategoryList = async () =>
  (await axios.get('/integrations/adapters/categories')).data;
export const getAdapterCategoryListDomain = async () =>
  (await axios.get('/integrations/adapters/categories/domain')).data;
export const getAdapterCategoryListSite = async () =>
  (await axios.get('/integrations/adapters/categories/site')).data;

// Domain-level calls
export const getIntegrationsDomain = async (domainId) =>
  (await axios.get(`/integrations/configurations/domain/${domainId}`)).data;

export const createDomainConfig = async (domainId, integrationBody) =>
  (await axios.post(
    `/integrations/configurations/domain/${domainId}`,
    integrationBody,
  )).data;

export const deleteIntegrationDomain = async (configId, domainId) =>
  (await axios.delete(
    `/integrations/configurations/${configId}/domain/${domainId}`,
  )).data;

export const updateIntegrationDomain = async (domainId, integrationBody) =>
  (await axios.put(
    `/integrations/configurations/domain/${domainId}`,
    integrationBody,
  )).data;

// Site-level calls
export const getIntegrationsSite = async (siteId) =>
  (await axios.get(`/integrations/configurations/site/${siteId}`)).data;

export const createSiteConfig = async (siteId, integrationBody) =>
  (await axios.post(
    `/integrations/configurations/site/${siteId}`,
    integrationBody,
  )).data;

export const deleteIntegrationSite = async (configId, siteId) =>
  (await axios.delete(
    `/integrations/configurations/${configId}/site/${siteId}`,
  )).data;

export const updateIntegrationSite = async (siteId, integrationBody) =>
  (await axios.put(
    `/integrations/configurations/site/${siteId}`,
    integrationBody,
  )).data;
