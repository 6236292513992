import {DateTime} from 'luxon';

export const dateRange = Object.freeze({
  last30Days: {
    days: 30,
    displayText: '30 days',
  },
  last90Days: {
    days: 90,
    displayText: '90 days',
  },
  last7Days: {
    days: 7,
    displayText: '7 days',
  },
  last24Hours: {
    days: 1,
    displayText: '24 hours',
  },
});

export const getFilterDateRange = ({days}) => ({
  duration: days,
  startDate: DateTime.local().minus({
    day: days,
  }),
  endDate: DateTime.local(),
});
