import React, {Fragment} from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Icon,
  Box,
  Backdrop,
  Grid,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import Spinner from '../../spinner';

const CardAligned = styled(Card)(({theme}) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
}));

const CardContentAligned = styled(CardContent)(({theme}) => ({
  padding: 0,
  margin: theme.spacing(3, 0),
  position: 'relative',
}));

const CardIcon = styled(Icon)(({theme}) => ({
  color: theme.palette.grey[600],
}));

const WidgetTitle = ({title, icon, isSmallScreen}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Typography
        id={`widget-title-${title}`}
        data-cy="widget-title"
        variant={isSmallScreen ? 'caption' : 'h6'}
        sx={{fontWeight: 500}}
        flex={1}
      >
        {title}
      </Typography>
      {icon && <CardIcon>{icon}</CardIcon>}
    </Box>
  );
};

WidgetTitle.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
};

WidgetTitle.defaultProps = {
  icon: undefined,
};

const DashboardWidget = ({
  CardProps,
  CardHeaderProps,
  CardContentProps,
  CardActionsProps,
  BoxProps,
  title,
  icon,
  loading,
  content,
  actions,
  isSmallScreen,
  ...rest
}) => (
  <Grid item {...rest}>
    <CardAligned {...CardProps}>
      {title && (
        <CardHeader
          {...CardHeaderProps}
          title={
            <WidgetTitle
              title={title}
              icon={icon}
              isSmallScreen={isSmallScreen}
            />
          }
          disableTypography
          sx={{padding: 0}}
        />
      )}
      <CardContentAligned data-cy="widget-content" {...CardContentProps}>
        <Fragment>
          <Backdrop
            transitionDuration={0}
            open={loading}
            sx={(theme) => ({
              minHeight: 16,
              backgroundColor: theme.palette.primary.contrastText,
              position: 'absolute',
              zIndex: 2,
              left: 0,
              top: 0,
            })}
            data-cy="backdrop-widget-spinner"
          >
            <Spinner data-cy="widget-spinner" size={16} color="primary" />
          </Backdrop>
          <Box sx={{visibility: loading ? 'hidden' : 'visible'}} {...BoxProps}>
            {content}
          </Box>
        </Fragment>
      </CardContentAligned>
      <CardActions sx={{padding: 0}} {...CardActionsProps}>
        {actions}
      </CardActions>
    </CardAligned>
  </Grid>
);

DashboardWidget.propTypes = {
  CardProps: PropTypes.shape({}),
  CardHeaderProps: PropTypes.shape({}),
  CardContentProps: PropTypes.shape({}),
  CardActionsProps: PropTypes.shape({}),
  BoxProps: PropTypes.shape({}),
  title: PropTypes.string,
  icon: PropTypes.node,
  loading: PropTypes.bool,
  content: PropTypes.node,
  actions: PropTypes.node,
  isSmallScreen: PropTypes.bool,
};
DashboardWidget.defaultProps = {
  CardProps: {},
  CardHeaderProps: {},
  CardContentProps: {},
  CardActionsProps: {},
  BoxProps: {},
  icon: undefined,
  title: undefined,
  content: undefined,
  actions: undefined,
  isSmallScreen: false,
  loading: false,
};

export default DashboardWidget;
