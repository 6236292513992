import React, {Fragment} from 'react';
import {Button, DialogActions, DialogContent, Typography} from '@mui/material';
import startCase from 'lodash/startCase';
import SafetyButtonCard from '../SafetyButtonCard';
import CreateButtonSection from './CreateButtonSection';
import {formatMode} from '../../notifications/utils';

const SelectContent = ({
  safetyButtons,
  buttonsMappedCameras,
  onButtonChange,
  onDialogClose,
  onSectionOpen,
  onSectionClose,
  addButtonSectionOpen,
  onAddSafetyButton,
  siteName,
  addButtonSectionRef,
}) => {
  return (
    <Fragment>
      <DialogContent>
        {safetyButtons.map((safetyButton) => {
          const cameraMapping = safetyButton.cameras;
          return (
            <SafetyButtonCard
              key={safetyButton.integrationId}
              buttonData={safetyButton}
              mappedCameras={buttonsMappedCameras[safetyButton.integrationId]}
              camerasVisible
            >
              <Typography>
                {startCase(formatMode(safetyButton.mode))}
              </Typography>
              <Button
                name="edit-button"
                onClick={() => onButtonChange(safetyButton.integrationId)}
                variant="outlined"
                disabled={!Array.isArray(cameraMapping)}
              >
                EDIT
              </Button>
            </SafetyButtonCard>
          );
        })}
        <CreateButtonSection
          onCancel={onSectionClose}
          siteName={siteName}
          buttonCount={safetyButtons.length}
          onSubmit={onAddSafetyButton}
          addButtonSectionOpen={addButtonSectionOpen}
          onSectionOpen={onSectionOpen}
          ref={addButtonSectionRef}
        />
      </DialogContent>
      <DialogActions>
        {!addButtonSectionOpen && (
          <Button
            name="safety-button-dialog-close-button"
            data-cy="safety-button-dialog-close-button"
            variant="text"
            onClick={onDialogClose}
          >
            Close
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );
};
export default SelectContent;
