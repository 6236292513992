import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

import SignalCellularConnectedNoInternet0BarIcon from '@mui/icons-material/SignalCellularConnectedNoInternet0Bar';
import SignalCellularNullIcon from '@mui/icons-material/SignalCellularNull';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import Tooltip from '../tooltip';
import {bitrateFormat} from '../../util/bitrateFormat';

const styles = (theme) => ({
  level0: {
    color: theme.palette.error.main,
  },
  level1: {
    color: theme.palette.error.main,
  },
  level2: {
    color: theme.palette.error.main,
  },
  level3: {
    color: theme.palette.warning.main,
  },
  level4: {
    color: theme.palette.success.main,
  },
});
export const levels = {
  l1: 300000,
  l2: 600000,
  l3: 1000000,
  l4: 3000000,
};

function BandwidthIndicator({bandwidth, offline, classes, className, ...rest}) {
  let icon = SignalCellular4BarIcon;
  let levelClassName = classes.level4;
  let level = 'level4';

  if (offline) {
    icon = SignalCellularConnectedNoInternet0BarIcon;
    levelClassName = classes.offline;
    level = 'offline';
  } else if (bandwidth <= 0) {
    icon = SignalCellularNullIcon;
    levelClassName = classes.level0;
    level = 'level0';
  } else if (bandwidth < levels.l1) {
    icon = SignalCellular1BarIcon;
    levelClassName = classes.level1;
    level = 'level1';
  } else if (bandwidth < levels.l2) {
    icon = SignalCellular2BarIcon;
    levelClassName = classes.level2;
    level = 'level2';
  } else if (bandwidth < levels.l3) {
    icon = SignalCellular3BarIcon;
    levelClassName = classes.level3;
    level = 'level3';
  }

  return (
    <Tooltip title={bitrateFormat(bandwidth, offline)} placement="right">
      <div>
        {React.createElement(icon, {
          className: classNames(className, levelClassName),
          'data-cy': level,
          ...rest,
        })}
      </div>
    </Tooltip>
  );
}

BandwidthIndicator.propTypes = {
  offline: PropTypes.bool,
  bandwidth: PropTypes.number,
};

BandwidthIndicator.defaultProps = {
  offline: false,
  bandwidth: 0,
};

BandwidthIndicator.muiName = 'Icon';

export default withStyles(styles)(BandwidthIndicator);
