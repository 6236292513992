import React from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Slider as MuiSlider} from '@mui/material';

const SliderWithThumbnail = styled(MuiSlider)(() => ({
  '& .MuiSlider-thumb': {
    height: 22,
    width: 22,
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    top: 24,
    backgroundColor: 'unset',
  },
}));

const Slider = ({variant, ...restProps}) => {
  switch (variant) {
    case 'valueInThumb':
      return <SliderWithThumbnail valueLabelDisplay="on" {...restProps} />;
    default:
      return <MuiSlider {...restProps} />;
  }
};

Slider.propTypes = {
  ...MuiSlider.propTypes,
  variant: PropTypes.string,
};

Slider.defaultProps = {
  variant: undefined,
};

export default Slider;
