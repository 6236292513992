import {createContext} from 'react';

const fns = {};
const register = (name, fn) => {
  fns[name] = fn;
};
const dispatch = (name, newFn) => {
  if (typeof fns[name] === 'function')
    fns[name]((prevState) => newFn(prevState));
};

// context does not change and does not trigger re-renders so it's treated as a ref
// it is used to handle various actions within alarms modal
const RefContext = createContext({register, dispatch});
export default RefContext;
