import {DateTime} from 'luxon';
// get all unique camera ids that have detected the alarm within grouped alarm event

// database pre-configured alarm labels
export const alarmLabels = Object.freeze({
  eventsOnly: 'alarm mode events only',
  lateDeparture: 'late departure',
  alarmClipTimeout: 'alarm clip timeout',
  alarmClipFailure: 'alarm clip failure',
});

export const alarmDispatches = Object.freeze({
  serviceContacted: 'service_contacted',
  canceled: 'canceled',
  closed: 'closed',
});

export const getCamerasPerEvent = (groupedEvent = []) => {
  if (!Array.isArray(groupedEvent)) return [];

  const allCameras = groupedEvent
    .filter((alarm) => alarm.type !== 'panel_event') // exclude non-motion events (panel events)
    .map((alarm) => alarm.cameraId);
  const uniqueCameras = new Set(allCameras);

  return [...uniqueCameras];
};

export const getEnabledCameras = (siteCameras, alarmCameras) => {
  const activeSiteCameras = siteCameras.filter(
    (camera) => camera.enabled && camera.recordMode !== 'None',
  );
  if (activeSiteCameras.length === 0) {
    return [];
  }
  return alarmCameras.filter(
    (alarmCamera) =>
      alarmCamera.configuration?.motionAlarmEnabled &&
      activeSiteCameras.some((cam) => cam.id === alarmCamera.cameraId),
  );
};

export const getOriginalAlarm = (groupedEvent = []) => {
  if (!Array.isArray(groupedEvent)) return null;

  const originalAlarm = groupedEvent.find((e) => e.id === e.correlationId);
  return originalAlarm;
};

export const getEventTitle = (type) => {
  let title;
  switch (type) {
    case 'button_event':
      title = 'panic alarm';
      break;
    case 'panel_event':
      title = 'panel alarm';
      break;
    default:
      title = 'video alarm';
      break;
  }
  return title;
};

export const getGroupedEventType = (groupedEvent = []) => {
  if (!Array.isArray(groupedEvent)) return undefined;
  // get button_event event if exists or original alarm event otherwise
  let event;
  for (let idx = 0; idx < groupedEvent.length; idx += 1) {
    const ev = groupedEvent[idx];
    if (ev.type === 'button_event') {
      event = ev;
      break;
    }
    if (!event && ev.id === ev.correlationId) {
      event = ev;
    }
  }
  return event?.type;
};

export const buildCameraEvents = (alarm, cameras) => {
  const {alarmsInGroup, groupedCameras} = alarm;
  const cameraEvents = alarmsInGroup.reduce((acc, curr) => {
    const mappedCamera = cameras.find((el) => el.id === curr.cameraId);
    if (mappedCamera) {
      acc.push({
        cameraId: curr.cameraId,
        camera: mappedCamera,
        applianceId: mappedCamera.applianceId,
        timestamp: curr.timestamp,
        clipUrl: curr.media && curr.media[0].url ? curr.media[0].url : null,
      });
    }
    return acc;
  }, []);

  if (alarm.type === 'button_event' || alarm.type === 'panel_event')
    groupedCameras?.forEach((id) => {
      const mappedCamera = cameras.find((el) => el.id === id);
      if (mappedCamera) {
        cameraEvents.unshift({
          cameraId: mappedCamera.id,
          camera: mappedCamera,
          applianceId: mappedCamera.applianceId,
          timestamp: alarm.timestamp,
          clipUrl: null,
        });
      }
    });

  return cameraEvents;
};

export const mapEventsWithAppliance = (appliances, cameraEvents) => {
  const alarmsDataForAppliances = [];
  appliances.forEach((a) => {
    const applianceData = {};
    const cameraIds = new Set();
    const cameraObj = [];
    let startTime = DateTime.local();
    applianceData.applianceId = a.id;
    applianceData.name = a.name;

    cameraEvents.forEach((ce) => {
      if (ce.applianceId === a.id) {
        if (!cameraIds.has(ce.cameraId.toString())) {
          cameraIds.add(ce.cameraId.toString());
          cameraObj.push(ce.camera);
        }
        const startTimeForCurrent = DateTime.fromISO(ce.timestamp);
        if (startTimeForCurrent < startTime) {
          startTime = startTimeForCurrent;
        }
      }
    });
    applianceData.startTime = startTime;
    applianceData.cameraIds = [...cameraIds];
    applianceData.cameras = cameraObj;
    alarmsDataForAppliances.push(applianceData);
  });
  return alarmsDataForAppliances;
};
