import React from 'react';
import {Typography, useTheme, useMediaQuery, Button} from '@mui/material';
import PropTypes from 'prop-types';

import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';
import DonutChartWidget from '../../../../shared/components/highcharts/donutChart';
import DashboardStatusWidget from '../../../../shared/components/dashboardComponent/dashboardStatusWidget';

const LateDepartureWidget = ({
  id,
  data,
  loading,
  onActionClick,
  buttonLabel,
  buttonIcon,
  ...rest
}) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  const isDataValid =
    !loading &&
    data.lateDepartures !== undefined &&
    data.otherNoThreats !== undefined;
  const total = (data.lateDepartures || 0) + (data.otherNoThreats || 0);

  const getName = (responseName) => {
    switch (responseName) {
      case 'lateDepartures':
        return 'Late Departure';
      case 'otherNoThreats':
        return 'Other Alarms';
      default:
        return undefined;
    }
  };
  const getColor = (responseName) => {
    switch (responseName) {
      case 'lateDepartures':
        return {color: theme.palette.success.light, opacity: 1};
      case 'otherNoThreats':
        return {color: theme.palette.success.light, opacity: 0.5};
      default:
        return undefined;
    }
  };

  const chartData = Object.entries(data).map((entry) => ({
    name: getName(entry[0]),
    y: entry[1],
    ...getColor(entry[0]),
  }));

  const content = Object.entries(data).map((entry, idx) => ({
    id: entry[0],
    label: getName(entry[0]),
    value: `${entry[1]}`,
    type: idx === 0 ? 'main' : 'secondary',
  }));

  const getChartSubtitle = (totalNoThreats) =>
    isDataValid
      ? `${Math.max(0, totalNoThreats)}`
      : 'UNABLE TO \n RETRIEVE DATA';

  const widgetTitle = 'Total No Threats';

  return isSmallScreen ? (
    <DashboardStatusWidget
      title={widgetTitle}
      CardHeaderProps={{
        subheader: (
          <Typography
            sx={{marginTop: theme.spacing(2)}}
            variant="h3"
            color="text.secondary"
          >
            {!loading ? total : ''}
          </Typography>
        ),
      }}
      loading={loading}
      content={content}
      withActions={false}
      isSmallScreen={isSmallScreen}
      CardProps={{sx: {padding: 1}}}
      CardContentProps={{sx: {margin: theme.spacing(1.5, 0, 0, 0)}}}
      {...rest}
    />
  ) : (
    <DashboardWidget
      title={widgetTitle}
      id={id}
      loading={loading}
      CardContentProps={{sx: {m: 0}}}
      CardProps={{sx: {padding: 2, justifyContent: 'center'}}}
      actions={[
        onActionClick && (
          <Button
            key="button"
            data-cy="view-by-site-late-departure"
            onClick={onActionClick}
            sx={{color: theme.palette.primary.main}}
            endIcon={buttonIcon}
          >
            {buttonLabel}
          </Button>
        ),
      ]}
      content={
        <DonutChartWidget
          data={chartData}
          graphSettings={{
            widgetName: 'late-departures',
            height: '55%',
            subtitle: {
              text: !loading ? getChartSubtitle(total) : '',
            },
            series: {
              fillColor: theme.palette.grey[400],
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  format: '{point.name} {point.y}',
                  filter: {
                    operator: '>',
                    property: 'y',
                    value: 0,
                  },
                  style: {
                    fontSize: '0.8rem',
                    color: theme.palette.default.contrastText,
                  },
                },
                enableMouseTracking: false,
                innerSize: '75%',
              },
            },
          }}
        />
      }
      {...rest}
    />
  );
};

LateDepartureWidget.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    lateDepartures: PropTypes.number,
    otherNoThreats: PropTypes.number,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonIcon: PropTypes.node,
};
LateDepartureWidget.defaultProps = {
  buttonIcon: undefined,
};
export default LateDepartureWidget;
