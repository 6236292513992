import querystring from 'querystring';
import React, {PureComponent} from 'react';
import {Route, Switch} from 'react-router-dom';
import get from 'lodash/get';
import GlobalErrorPage from '../../globalErrorPage';

import {withCurrentUser} from '../../../shared/contexts/currentUserContext';

import {
  getFullUserById,
  personaList,
  getUserList,
  getDomainUsageSummary,
} from '../../../api/users';
import {getRoles} from '../../../api/adminRoles';
import {getAccessLists} from '../../../api/accessLists';
import {getAuthConfiguration} from '../../../api/auth';

import allowed, {USER_ADMIN, SWITCH_USER} from '../../../shared/util/allowed';

import CapabilitiesGuard from '../../../shared/components/capabilitiesGuard';
import PageLoader from '../../../shared/components/pageLoader';
import withSnackbar from '../../../shared/components/snackbarSupport';

class UserRoutes extends PureComponent {
  state = {
    hideDisabledUsers: true,
    filterValue: '',
    domainId: undefined,
  };

  componentDidMount() {
    const {location} = this.props;
    const query = querystring.parse(location.search.substr(1));
    this.setState({domainId: query.domain_id});
  }

  setRouteState = (stateChanges) => {
    this.setState(stateChanges);
  };

  render() {
    const {match, currentUser, snackbar} = this.props;

    const {domainId} = this.state;

    const loaderProps = {
      ...this.state,
      setRouteState: this.setRouteState,
    };

    return (
      <Switch>
        <Route
          path={`${match.path}/usage-dashboard`}
          render={(renderProps) => (
            <PageLoader
              page={() => import('./UsageDashboard')}
              resources={{
                roles: allowed(currentUser, [USER_ADMIN])
                  ? getRoles
                  : () => Promise.resolve([]),
                accessLists: allowed(currentUser, [USER_ADMIN])
                  ? getAccessLists
                  : () => Promise.resolve([]),
                domainUsageSummary: getDomainUsageSummary,
              }}
              {...renderProps}
            />
          )}
        />
        <Route
          path={`${match.path}/:id`}
          render={(renderProps) => {
            const userId = get(renderProps, 'match.params.id');

            return (
              <PageLoader
                page={() => import('./Details')}
                resources={{
                  roles: allowed(currentUser, [USER_ADMIN])
                    ? getRoles
                    : () => Promise.resolve([]),
                  accessLists: allowed(currentUser, [USER_ADMIN])
                    ? getAccessLists
                    : () => Promise.resolve([]),
                  personas: personaList,
                  userInstance: () => getFullUserById(userId),
                  userList: allowed(currentUser, [USER_ADMIN])
                    ? () => getUserList(domainId)
                    : () => Promise.resolve([]),
                  domainId: () => Promise.resolve(domainId),
                  authConfig: allowed(currentUser, [USER_ADMIN])
                    ? () => getAuthConfiguration(currentUser.domainId)
                    : () => Promise.resolve({}),
                }}
                pageLoadSnackBar={snackbar}
                {...renderProps}
              />
            );
          }}
        />
        <Route
          path={`${match.path}`}
          render={(renderProps) => (
            <CapabilitiesGuard
              allowed={[USER_ADMIN, SWITCH_USER]}
              renderDefault={() => <GlobalErrorPage error="NoAuth" />}
            >
              <PageLoader
                page={() => import('./List')}
                resources={{
                  roles: allowed(currentUser, [USER_ADMIN])
                    ? getRoles
                    : () => Promise.resolve([]),
                  accessLists: allowed(currentUser, [USER_ADMIN])
                    ? getAccessLists
                    : () => Promise.resolve([]),
                  personas: personaList,
                  userList: allowed(currentUser, [USER_ADMIN, SWITCH_USER])
                    ? () => getUserList(domainId)
                    : () => Promise.resolve([]),
                  authConfig: allowed(currentUser, [USER_ADMIN])
                    ? () => getAuthConfiguration(currentUser.domainId)
                    : () => Promise.resolve({}),
                }}
                propsToIgnoreUpdate={['hideDisabledUsers', 'filterValue']}
                {...loaderProps}
                {...renderProps}
              />
            </CapabilitiesGuard>
          )}
        />
      </Switch>
    );
  }
}

export default withCurrentUser(withSnackbar(UserRoutes));
