import React from 'react';

import PageLoader from '../../shared/components/pageLoader';
import {getReportPath} from './util';

const Loader = ({
  match: {
    params: {notificationId, taskId},
  },
}) => (
  <PageLoader
    page={() => import('./TaskRedirect')}
    resources={{
      reportLink: async () => getReportPath(notificationId, taskId),
    }}
  />
);

export default Loader;
