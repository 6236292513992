import axios from 'axios';

export const getViews = async () => {
  const {data} = await axios.get('/saved_views');
  return data;
};

export const getView = async (id) => {
  const {data: register} = await axios.get(`/savedViews/${id}`);
  return register;
};

export const createView = async (viewRecord) => {
  const {data} = await axios.post(`/saved_views`, viewRecord);
  return data;
};

export const updateView = async (view) => {
  const {data} = await axios.put(`/saved_views/${view.id}`, view);
  return data;
};

export const deleteView = async (id) => axios.delete(`/saved_views/${id}`);
