export const formatUsername = (firstName, lastName, role) =>
  `${firstName || ''}${' '}${lastName || ''}${role ? ` · ${role}` : ''}`;

export const subscriptionEventTypes = Object.freeze({
  video: 'video_alarm',
  panic: 'button_alarm',
  panel: 'panel_alarm',
});

export const checkboxStatuses = Object.freeze({
  Idle: 'Idle',
  Loading: 'Loading',
  Success: 'Success',
  Error: 'Error',
});

export const subscriptionDisplayTypes = Object.freeze({
  push_notification: 'Push',
  noonlight: 'Phone',
  email: 'Email',
});

export const priorityContactsDisplayText = (priority) => {
  switch (priority) {
    case 1:
      return '1st Contact';
    case 2:
      return '2nd Contact';
    case 3:
      return '3rd Contact';
    default:
      return null;
  }
};

export const getSelectablePriorities = (priorityUsers, subEventType) => {
  const usersPriorities = priorityUsers.map((priorityUser) => {
    const {priority} = priorityUser.subscriptions.find(
      (sub) => sub.type === 'noonlight' && sub.eventType === subEventType,
    );
    return priority;
  });
  const selectablePriorities = usersPriorities.map((_, index) => index + 1);

  return selectablePriorities;
};

export const priorities = Object.freeze({
  first: 1,
  second: 2,
  third: 3,
});

export const hasSpecificSub = (user, subType) => {
  const subs = user.subscriptions;
  if (subs == null) return false;
  return subs.some((sbr) => sbr.type === subType);
};

export const formatMode = (mode) => (mode ? mode.replace('_', ' ') : mode);
