import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import {
  SSP_CONFIGURATION_ADMIN,
  ENVR_ADMIN,
  hasCapability,
  hasAllCapabilities,
} from '../../../../shared/util/allowed';
import CamerasList from './CamerasList';
import {isNewNavigationWebEnabled} from '../../../../shared/util/user';

const CameraConfigurationDialog = ({
  selectedSite,
  currentUser,
  existingSiteSchedule,
  handleCameraEnablementClick,
  handleEditCamera,
  camerasList,
  isVideoAlarmSupported,
  camerasConfigurationOpen,
  onCamerasConfigurationClose,
  dispatch,
  sspProMonitoring,
}) => {
  const isNewNav = isNewNavigationWebEnabled(currentUser);
  return (
    <Dialog
      data-cy="camera-enabled-modal"
      open={camerasConfigurationOpen}
      onClose={onCamerasConfigurationClose}
      PaperProps={{
        sx: {
          maxHeight: '80vh',
          ...(isNewNav && {maxWidth: 600}),
        },
      }}
      fullWidth
    >
      <DialogTitle>Camera Enablement</DialogTitle>
      <DialogContent>
        <CamerasList
          site={selectedSite}
          isEnvrAdmin={hasCapability(currentUser, ENVR_ADMIN)}
          allowConfig={hasAllCapabilities(currentUser, [
            ENVR_ADMIN,
            ...(sspProMonitoring ? [SSP_CONFIGURATION_ADMIN] : []),
          ])}
          siteHasSchedule={!!existingSiteSchedule}
          onCameraEnableClick={handleCameraEnablementClick}
          onCameraEdit={handleEditCamera}
          setCamerasList={(value) => {
            dispatch({
              type: 'update',
              payload: {camerasList: value},
            });
          }}
          camerasList={camerasList}
          isVideoAlarmSupported={isVideoAlarmSupported}
        />
      </DialogContent>
      <DialogActions>
        <Button
          name="camera-enablement-close-button"
          variant="text"
          onClick={onCamerasConfigurationClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CameraConfigurationDialog.propTypes = {
  selectedSite: PropTypes.shape({}),
  currentUser: PropTypes.shape({}).isRequired,
  existingSiteSchedule: PropTypes.shape({}),
  handleCameraEnablementClick: PropTypes.func.isRequired,
  handleEditCamera: PropTypes.func.isRequired,
  camerasList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isVideoAlarmSupported: PropTypes.bool.isRequired,
  camerasConfigurationOpen: PropTypes.bool.isRequired,
  onCamerasConfigurationClose: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  sspProMonitoring: PropTypes.bool.isRequired,
};

CameraConfigurationDialog.defaultProps = {
  selectedSite: {},
  existingSiteSchedule: null,
};
export default CameraConfigurationDialog;
