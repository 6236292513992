import axios from 'axios';
import {convertIdsToString} from './util';
import {sortByNullableField} from '../shared/util/sorting';

export const getRoles = async () => {
  const {data} = await axios.get(`/roles`);
  return sortByNullableField(convertIdsToString(data), 'name', false);
};

export const getRole = async (id) => {
  const {data} = await axios.get(`/roles/${id}`);
  return convertIdsToString(data);
};

export const createRole = async (roleProperties) => {
  const {data} = await axios.post(`/roles`, roleProperties);
  return convertIdsToString(data);
};

export const updateRole = async (roleId, roleProperties) => {
  const {data} = await axios.put(`/roles/${roleId}`, roleProperties);
  return convertIdsToString(data);
};

export const deleteRole = async (roleId) => {
  const {data} = await axios.delete(`/roles/${roleId}`);
  return data;
};

export const getCapabilities = async () => {
  const {data} = await axios.get(`/capabilities`);
  return data;
};

export const getRoleCapabilities = async (roleId) => {
  const {data} = await axios.get(`/roles/${roleId}/capabilities`);
  return data;
};

export const grantCapabilities = async (roleId, capabilities) => {
  const {data} = await axios.put(`/roles/${roleId}/capabilities`, capabilities);
  return data;
};
