export const defaultHighChartsOptionsJson = {
  chart: {
    type: 'column',
  },
  lang: {
    noData: 'No data to display',
  },
  noData: {
    position: {
      x: 0,
      y: 0,
      align: 'center',
      verticalAlign: 'middle',
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    align: 'left',
    margin: 5,
  },
  subtitle: {
    align: 'left',
    text: '',
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  xAxis: {
    type: 'category',
    labels: {
      rotation: -90,
      distance: 2,
    },
  },
  yAxis: {
    title: {
      text: '',
    },
    labels: {
      format: '{value}',
    },
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    // General options for all series types
    series: {
      minPointLength: 5,
    },
    // General options for all column series
    column: {
      pointPadding: 0.0,
      groupPadding: 0.1,
    },
    // Add general options for other chart types i.e. bar, arc-diagram etc...
  },
  drilldown: {
    breadcrumbs: {
      position: {
        align: 'right',
        verticalAlign: 'top',
        y: -25,
      },
      floating: true,
    },
    plotOptions: {
      // General options for all series types
      series: {
        cursor: 'pointer',
      },
      // Add general options for specific chart types i.e. column etc...
    },
    activeAxisLabelStyle: {
      color: 'black',
      cursor: 'pointer',
      fontWeight: 'light',
      textDecoration: 'none',
    },
  },
};
