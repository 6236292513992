import React, {Fragment} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import compose from 'lodash/flowRight';
import {Route} from 'react-router-dom';
import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import DashboardApp from '../../apps/dashboard';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';

import {sspTheme} from '../../shared/themes';
import {isNewNavigationWebEnabled} from '../../shared/util/user';

const Dashboard = ({currentUser}) => {
  if (currentUser.settings.newDashboard) {
    return (
      <ThemeProvider theme={sspTheme}>
        {isNewNavigationWebEnabled(currentUser) ? (
          <Fragment>
            <Route exact path="/dashboard">
              <RedirectWithQueryParams to="/home/dashboard" />
            </Route>
            <DashboardApp dashboardId={1} data-cy="main-dashboard" />
          </Fragment>
        ) : (
          <PageTemplateResponsive
            PageHeaderProps={{title: 'Dashboard'}}
            title="Dashboard"
          >
            <DashboardApp dashboardId={1} />
          </PageTemplateResponsive>
        )}
      </ThemeProvider>
    );
  } // width fix the MUI 5 Grid negative margin issue
  return <GlobalErrorPage />;
};

export default compose(withCurrentUser)(Dashboard);
