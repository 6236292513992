import axios from 'axios';

export const getDeterrents = async (siteId) =>
  (await axios.get(`/deterrents/site/${siteId}`)).data;

export const updateDeterrent = async (deterrentId, body) =>
  (await axios.put(`/deterrents/${deterrentId}`, body)).data;

export const removeDeterrent = async (deterrentId) =>
  axios.delete(`/deterrents/${deterrentId}`);

export const provisionDeterrent = async (data) =>
  (await axios.post(`/deterrents`, data)).data;
