import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import {DateTime} from 'luxon';
import {Typography} from '@mui/material';

import {paginationStatus, useListPaginator} from '../../../../shared/hooks';
import {
  getSitesBySchedule,
  getAlarmSummaryForSites,
  getSchedulesTemplateBySite,
} from '../../../../api/alarms';
import {filterReducer} from '../filterReducer';
import {getConflictingSchedules, yyyyMMdd} from './utils';

export const fetchSites = async (
  getSites,
  limit = 10,
  offset = 0,
  searchValue,
) => {
  return getSites(
    DateTime.local().minus({
      hours: 24,
    }),
    DateTime.local(),
    undefined,
    limit,
    offset,
    true,
    false,
    searchValue,
    undefined,
    'site_name',
    'asc',
    true,
  );
};

const fetchSiteAssignedSchedules = async (
  sspSites,
  selectedSchedule,
  setter,
) => {
  setter((prev) => ({...prev, isLoadingStatus: true}));
  const fetchSiteConflicts = async (
    site,
    limit = 25,
    offset = 0,
    accumulatedConflicts = [],
  ) => {
    try {
      const result = await getSchedulesTemplateBySite(
        site.siteId,
        yyyyMMdd(selectedSchedule.startDate),
        yyyyMMdd(selectedSchedule.endDate),
        limit,
        offset,
      );
      const conflictsForPage = getConflictingSchedules(
        selectedSchedule,
        result.results,
        site.siteName,
      );

      const allConflicts = [...accumulatedConflicts, ...conflictsForPage];
      if (result.results.length < limit) {
        return {
          conflicts: allConflicts,
          siteId: site.siteId,
        };
      }
      return fetchSiteConflicts(site, limit, offset + limit, allConflicts);
    } catch {
      return {
        conflicts: [{name: 'Error'}],
        siteId: site.siteId,
      };
    }
  };
  const conflicts = await Promise.all(
    sspSites.map((site) => fetchSiteConflicts(site)),
  );

  setter({conflicts, isLoadingStatus: false});
};

export const useSiteManagementDialog = (
  selectedSchedule,
  dialogOpen,
  totalSSPSitesCount,
) => {
  const selectAllCheckboxCheckedRef = useRef(false);
  const changesRef = useRef({
    checked: new Set(),
    unchecked: new Set(),
  });

  const [selectedSites, setSelectedSites] = useState([]);
  const [unprocessableSites, setUnprocessableSites] = useState([]);
  const [conflictedSchedules, setConflictedSchedules] = useState({
    isLoadingStatus: true,
    conflicts: [],
  });
  const [isFirstPage, setIsFirstPage] = useState(true);

  const fetchAllSspSites = useCallback(
    async () => {
      await fetchSites(getAlarmSummaryForSites, totalSSPSitesCount).then(
        ({results}) => {
          setSelectedSites(results.map(({siteId}) => siteId));
          changesRef.current.checked = new Set(
            results.map(({siteId}) => siteId),
          );
          changesRef.current.unchecked = new Set();
        },
      );
    },
    [totalSSPSitesCount],
  );

  const getChangedAssignments = () => {
    return {
      checked: Array.from(changesRef.current.checked),
      unchecked: Array.from(changesRef.current.unchecked),
    };
  };

  const [{isSearchValueValid, searchValue}, dispatch] = useReducer(
    filterReducer,
    {
      isSearchValueValid: true,
      searchValue: '',
    },
  );

  const [sspSitesStatus, sspSites, onFetchSspSites] = useListPaginator(
    (limit, offset) =>
      fetchSites(getAlarmSummaryForSites, limit, offset, searchValue),
    0,
    25,
  );

  const [
    sitesByScheduleStatus,
    sitesBySchedule,
    onFetchSitesBySchedule,
    totalSitesByScheduleCount,
  ] = useListPaginator(
    (limit, offset) =>
      selectAllCheckboxCheckedRef.current
        ? fetchAllSspSites()
        : getSitesBySchedule(selectedSchedule.id, searchValue, limit, offset),
    0,
    25,
  );

  const fetchHandlersRef = useRef({
    onFetchSspSites,
    onFetchSitesBySchedule,
  });

  const statuses = useMemo(
    () => ({
      dataLoading:
        sspSitesStatus === paginationStatus.Loading ||
        sitesByScheduleStatus === paginationStatus.Loading,
      dataFetched: sspSitesStatus === paginationStatus.Terminated,
      dataFetchError:
        sspSitesStatus === paginationStatus.Error ||
        sitesByScheduleStatus === paginationStatus.Error,
      dataReloading:
        sspSitesStatus === paginationStatus.Reloading ||
        sitesByScheduleStatus === paginationStatus.Reloading,
    }),
    [sspSitesStatus, sitesByScheduleStatus],
  );

  useEffect(
    () => {
      fetchHandlersRef.current.onFetchSspSites = onFetchSspSites;
      fetchHandlersRef.current.onFetchSitesBySchedule = onFetchSitesBySchedule;
    },
    [onFetchSspSites, onFetchSitesBySchedule],
  );

  useEffect(
    () => {
      const abortController = new AbortController();
      if (dialogOpen) {
        fetchHandlersRef.current.onFetchSspSites(true, abortController.signal);
        fetchHandlersRef.current.onFetchSitesBySchedule(
          true,
          abortController.signal,
        );
      }
      return () => {
        abortController.abort();
      };
    },
    [dialogOpen, searchValue],
  );

  useEffect(
    () => {
      if (
        selectedSchedule &&
        !statuses.dataLoading &&
        !statuses.dataReloading &&
        !!sspSites.length
      ) {
        fetchSiteAssignedSchedules(
          sspSites,
          selectedSchedule,
          setConflictedSchedules,
        );
      }
    },
    [sspSites, selectedSchedule, statuses],
  );

  useEffect(
    () => {
      if (sitesBySchedule.length) {
        const {checked, unchecked} = getChangedAssignments();
        const combinedChanges = [
          ...new Set(
            [...sitesBySchedule, ...checked].filter(
              (el) => !unchecked.includes(el),
            ),
          ),
        ];
        setSelectedSites(combinedChanges);
      }
    },
    [sitesBySchedule, sspSites],
  );

  selectAllCheckboxCheckedRef.current =
    selectedSites.length === totalSSPSitesCount;

  const initialAssignedSites = sitesBySchedule.length ? sitesBySchedule : [];

  const siteList = useMemo(
    () =>
      sspSites.map((s) => {
        const {siteId} = s;
        const selected = selectedSites.some((r) => r === siteId);
        const conflicts =
          conflictedSchedules.conflicts
            .find((cs) => cs.siteId === siteId)
            ?.conflicts.map((r) => r.name) || [];
        const disabled =
          !!conflicts.length || conflictedSchedules.isLoadingStatus;
        const additionalInfo = (
          <Typography variant="body2">
            {conflicts[0] === 'Error'
              ? 'Failed to fetch conflicting schedules for this site.'
              : `Conflict: ${conflicts?.join(
                  ', ',
                )} already assigned to this date.`}
          </Typography>
        );

        return {
          type: 'site',
          name: s.siteName,
          id: s.siteId,
          selected,
          disabled,
          ...(conflicts.length && {additionalInfo}),
        };
      }),
    [
      selectedSites,
      sspSites,
      conflictedSchedules.conflicts,
      conflictedSchedules.isLoadingStatus,
    ],
  );
  const disabledSubmit =
    (selectedSites.length === initialAssignedSites.length &&
      selectedSites.every((site) => initialAssignedSites.includes(site))) ||
    statuses.dataReloading ||
    unprocessableSites.length > 0;

  const handleLoadMoreData = () => {
    setIsFirstPage(false);
    onFetchSspSites(false);
    if (sitesBySchedule.length !== totalSitesByScheduleCount.current) {
      onFetchSitesBySchedule(false);
    }
  };

  const handleCheckboxSelect = useCallback((site, checked) => {
    setUnprocessableSites([]);
    setSelectedSites((prev) =>
      prev.some((el) => el === site.id)
        ? prev.filter((el) => el !== site.id)
        : [...prev, site.id],
    );
    if (checked) {
      changesRef.current.checked.add(site.id);
      changesRef.current.unchecked.delete(site.id);
    } else {
      changesRef.current.unchecked.add(site.id);
      changesRef.current.checked.delete(site.id);
    }
  }, []);

  const handleSelectAll = (target) => {
    selectAllCheckboxCheckedRef.current = !selectAllCheckboxCheckedRef.current;
    if (target.checked) {
      fetchAllSspSites();
    } else {
      setSelectedSites([]);
      changesRef.current.unchecked = new Set(getChangedAssignments().checked);
      changesRef.current.checked = new Set();
    }
  };

  const resetState = () => {
    setSelectedSites([]);
    selectAllCheckboxCheckedRef.current = false;
    changesRef.current = {
      checked: new Set(),
      unchecked: new Set(),
    };
    setUnprocessableSites([]);
  };
  return {
    siteList,
    handleLoadMoreData,
    selectedSites,
    disabledSubmit,
    handleCheckboxSelect,
    handleSelectAll,
    resetState,
    searchValue,
    isSearchValueValid,
    dispatch,
    statuses,
    unprocessableSites,
    setUnprocessableSites,
    conflictedSchedules,
    isFirstPage,
  };
};
