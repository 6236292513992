import React, {PureComponent} from 'react';

import VideoContext from './videoPlayerContext';
import withUrlState from '../../components/withUrlState';
import {calculateState} from './videoContextUtil';

const defaultState = {
  player: undefined,
  currentTime: undefined,
  remountVideoPlayerKey: 0,
  startTime: undefined,
  cameras: undefined,
  savedCameras: undefined,
  focusIndex: undefined,
  motionMask: undefined,
  selectedCamera: undefined,
  audioChannel: null,
  startMuted: true,
  qualityLevel: undefined,
  pausePlayer: undefined,
};
class VideoContextProvider extends PureComponent {
  playlistStartTime = undefined;

  playlistStopTime = undefined;

  state = defaultState;

  componentDidMount() {
    const {urlState} = this.props;
    this.setState(calculateState(urlState, defaultState));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.urlState.startTime !== this.props.urlState.startTime ||
      prevState.startTime !== this.state.startTime
    ) {
      const newState = calculateState(this.props.urlState, defaultState);
      this.setState(newState);
    }
  }

  componentWillUnmount() {
    this.setState(defaultState);
  }

  setPlayerEvents = (events, eventPlayer) =>
    events &&
    Object.keys(events).map((eventName) =>
      eventPlayer.on(
        eventName.toLowerCase(),
        (playerRef) => eventPlayer && events[eventName](playerRef),
      ),
    );

  handlePlaylistLoaded = (playlist) => {
    this.playlistStartTime = playlist.startTime;
    this.playlistStopTime = playlist.stopTime;
  };

  setAudioChannel = (audioChannel) => {
    this.setState({audioChannel});
  };

  // handleJumpTo
  handleJumpTo = (dateTime) => {
    const {setUrlState, urlState} = this.props;
    const params = {...urlState};
    if (!dateTime) {
      params.startTime = undefined;
      setUrlState(params);
      this.setState((prevState) => ({
        remountVideoPlayerKey: prevState.remountVideoPlayerKey + 1,
        startTime: undefined,
      }));
      return;
    }
    params.startTime = dateTime.toISO();
    setUrlState(params);
    this.setState((prevState) => ({
      remountVideoPlayerKey: prevState.remountVideoPlayerKey + 1,
      startTime: dateTime,
    }));
  };

  // handleCamerasChange
  handleCamerasChange = (cameras, dateTime) => {
    const {setUrlState} = this.props;
    const {focusIndex, savedCameras} = this.state;

    const cameraIdArray = cameras.map((c) => c.id);

    const params = {
      cameras: cameraIdArray.join(','),
      motionMask: undefined,
    };

    if (dateTime) {
      params.startTime = dateTime.toISO();
    }

    if (typeof focusIndex !== 'undefined') {
      const camera = cameras[0];

      savedCameras[focusIndex] = camera.id;
      params.cameras = camera.id;
      params.savedCameras = savedCameras.join(',');
    }
    this.setState((prevState) => ({
      remountVideoPlayerKey: prevState.remountVideoPlayerKey + 1,
      cameras: cameraIdArray,
      motionMask: undefined,
    }));
    this.setStartTime(dateTime);
    setUrlState(params);
  };

  // handleFocus
  handleFocus = (focusedCameraIndex, camera) => {
    const {
      cameras: cameraIds,
      currentTime,
      startMuted,
      audioChannel,
    } = this.state;
    const {setUrlState} = this.props;
    const savedCameras = cameraIds;
    const focusedCamera = Array(cameraIds[focusedCameraIndex]);
    this.setStartTime(currentTime);
    setUrlState({
      focusIndex: focusedCameraIndex,
      cameras: focusedCamera,
      savedCameras: savedCameras.join(','),
      startMuted:
        camera.applianceSensorNum === audioChannel ? startMuted : true,
      audioChannel: !startMuted ? audioChannel : undefined,
    });
    this.setState((prevState) => ({
      remountVideoPlayerKey: prevState.remountVideoPlayerKey + 1,
      focusIndex: focusedCameraIndex,
      cameras: focusedCamera,
      savedCameras,
    }));
  };

  // handleUnfocus
  handleUnfocus = () => {
    const {setUrlState, urlState} = this.props;
    const {savedCameras, currentTime, startMuted, audioChannel} = this.state;
    const cameras = savedCameras;
    this.setState((prevState) => ({
      remountVideoPlayerKey: prevState.remountVideoPlayerKey + 1,
      cameras,
      savedCameras: undefined,
      focusIndex: undefined,
      motionMask: undefined,
    }));
    const params = {...urlState};
    params.cameras = cameras.join(',');
    params.focusIndex = undefined;
    params.motionMask = undefined;
    params.startMuted = startMuted;
    params.audioChannel = !startMuted ? audioChannel : undefined;
    this.setStartTime(currentTime);

    setUrlState(params);
  };

  setCurrentTime = (currentTime) => {
    this.setState({currentTime});
  };

  setStartTime = (startTime) => {
    this.setState({startTime});
  };

  setSelectedCamera = (camera) => {
    this.setState({selectedCamera: camera});
  };

  // control if the video player should start muted
  // i.e. when in expanded video and previously playing audio
  setStartMuted = (startMuted) => {
    this.setState({startMuted});
  };

  setPausePlayer = (pausePlayer) => {
    this.setState({pausePlayer});
  };

  // enterExitMotionSearch
  enterExitMotionSearch = (motionMask, startTime) => {
    const {setUrlState, urlState} = this.props;
    const params = {...urlState};
    params.startTime = startTime.toISO();
    params.motionMask = motionMask;
    this.setState({motionMask, startTime});
    setUrlState(params);
  };

  handleQualityChange = (qualityLevel) => {
    const {setUrlState, urlState} = this.props;
    const params = {...urlState};
    params.qualityLevel = qualityLevel;
    this.setState({qualityLevel});
    setUrlState(params);
  };

  render() {
    const {
      remountVideoPlayerKey,
      startTime,
      cameras,
      savedCameras,
      focusIndex,
      motionMask,
      currentTime,
      selectedCamera,
      audioChannel,
      startMuted,
      qualityLevel,
      pausePlayer,
    } = this.state;
    const {setUrlState, children, value} = this.props;
    return (
      <VideoContext.Provider
        value={{
          ...value,
          setPlayerEvents: (eventPlayer) =>
            this.setPlayerEvents(this.eventHandlers, eventPlayer),
          setCurrentTime: this.setCurrentTime,
          currentTime,
          setStartTime: this.setStartTime,
          startTime,
          remountVideoPlayerKey,
          handleCamerasChange: this.handleCamerasChange,
          cameras,
          savedCameras,
          handleFocus: this.handleFocus,
          handleUnfocus: this.handleUnfocus,
          focusIndex,
          enterExitMotionSearch: this.enterExitMotionSearch,
          motionMask,
          calculateState: this.calculateState,
          setUrlState,
          handleJumpTo: this.handleJumpTo,
          handlePlaylistLoaded: this.handlePlaylistLoaded,
          setSelectedCamera: this.setSelectedCamera,
          selectedCamera,
          setAudioChannel: this.setAudioChannel,
          audioChannel,
          setStartMuted: this.setStartMuted,
          startMuted,
          handleQualityChange: this.handleQualityChange,
          qualityLevel,
          setPausePlayer: this.setPausePlayer,
          pausePlayer,
        }}
      >
        {children}
      </VideoContext.Provider>
    );
  }
}

export default withUrlState()(VideoContextProvider);
