import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PageLoader from '../../shared/components/pageLoader';
import GlobalErrorPage from '../globalErrorPage';

import MenuDrawer from './MenuDrawer';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import withSnackbar from '../../shared/components/snackbarSupport';

import {getClips, getBreadcrumb} from '../../api/clips';
import {getExternalShares} from '../../api/externalShares';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import {
  DOWNLOAD_VIDEO,
  MY_CLIPS,
  hasAllCapabilities as isAllowed,
} from '../../shared/util/allowed';

const Clips2Routes = (props) => {
  const {match, snackbar, currentUser, ...rest} = props;

  return (
    <Route>
      <MenuDrawer {...rest}>
        {(collapseDrawer, expandDrawer, setActions) => {
          const wrapperProps = {
            collapseDrawer,
            expandDrawer,
            setActions,
            snackbar,
          };
          return (
            <Switch>
              <Route
                path={`${match.path}/external-shares`}
                render={() => (
                  <CapabilitiesGuard
                    allowed={[MY_CLIPS, DOWNLOAD_VIDEO]}
                    renderDefault={() => <GlobalErrorPage error="NoAuth" />}
                  >
                    <PageLoader
                      key="exernalShares"
                      page={() => import('./externalShares')}
                      propsToIgnoreUpdate={['forceToRoot', 'snackbar']}
                      resources={{
                        externalShares: getExternalShares,
                      }}
                      {...wrapperProps}
                    />
                  </CapabilitiesGuard>
                )}
              />
              <Route
                path={`${match.path}/:id?`}
                render={({match: {params}}) => (
                  <CapabilitiesGuard
                    allowed={[MY_CLIPS]}
                    renderDefault={() => <GlobalErrorPage error="NoAuth" />}
                  >
                    <PageLoader
                      key="myclips"
                      page={() => import('./myClips')}
                      folderId={params.id}
                      propsToIgnoreUpdate={['forceToRoot', 'snackbar']}
                      resources={{
                        clipData: async () => getClips(params.id),
                        breadCrumbs: async () =>
                          params.id && params.id !== 'root'
                            ? getBreadcrumb(params.id)
                            : Promise.resolve([]),
                      }}
                      clipsAndDownloadCapabilities={isAllowed(currentUser, [
                        DOWNLOAD_VIDEO,
                        MY_CLIPS,
                      ])}
                      {...wrapperProps}
                    />
                  </CapabilitiesGuard>
                )}
              />
              <Route path="*" component={GlobalErrorPage} />
            </Switch>
          );
        }}
      </MenuDrawer>
    </Route>
  );
};

export default withSnackbar(withCurrentUser(Clips2Routes));
