import React, {useState} from 'react';
import DashboardSection from '../../../shared/components/dashboardComponent/dashboardSection';
import {dateRange, getFilterDateRange} from './dateRangeUtils';
import {verificationStatuses} from './sspUtils';
import HaloTab from './tabs/HaloTab';
import SspTab from './tabs/SspTab';
import SspFilters from './haloIntegrationFilters';

const SspTabEnum = {
  SSP: 0,
  HALO_SENSOR: 1,
};

const SspSection = () => {
  const [filters, setFilters] = useState({
    date: getFilterDateRange(dateRange.last7Days),
    verifications: [
      verificationStatuses.yes,
      verificationStatuses.no,
      verificationStatuses.unverifiable,
      verificationStatuses.unclassified,
    ],
  });

  const [tabValue, setTabValue] = useState(SspTabEnum.SSP);

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardSection
      filters={
        <SspFilters
          onDurationChange={setFilters}
          selectedDurationInDays={filters.date.duration}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      }
    >
      {tabValue === SspTabEnum.SSP && (
        <SspTab filters={filters} setFilters={setFilters} />
      )}
      {tabValue === SspTabEnum.HALO_SENSOR && (
        <HaloTab
          startDate={filters.date.startDate}
          endDate={filters.date.endDate}
        />
      )}
    </DashboardSection>
  );
};

export default SspSection;
