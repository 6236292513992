import React from 'react';
import {
  FormControlLabel,
  FormHelperText,
  Switch,
  FormControl,
} from '@mui/material';
import LabelWithTooltip from '../labelWithTooltip';

const FormSwitch = ({fieldDef, formProps, classes}) => {
  const {name, label, helperText, tooltip, onChange, ComponentProps} = fieldDef;
  const {handleChange, values} = formProps;
  const checkedValue = Boolean(values[name]);
  return (
    <FormControl>
      <FormControlLabel
        control={
          <Switch
            name={name}
            value="checkedC"
            onChange={(value) => {
              handleChange(value);
              if (typeof onChange === 'function') {
                onChange(value, name, formProps);
              }
            }}
            aria-checked={checkedValue ? 'true' : 'false'}
            checked={checkedValue}
            disabled={fieldDef.disabled}
            {...ComponentProps}
          />
        }
        label={
          tooltip ? <LabelWithTooltip label={label} tooltip={tooltip} /> : label
        }
        className={classes.formField}
      />
      {helperText && (
        <FormHelperText className={classes.switchHelpText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSwitch;
