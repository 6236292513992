import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import Spinner from '../../../../shared/components/spinner';
import CameraMaskTool from '../../../../apps/cameraMaskTool';
import {makeThumbnailUrl} from '../../../../api/sensors';
import {CameraMaskEnum} from './shared/const';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const CameraEnablementDialog = ({
  open,
  onCancel,
  onSave,
  camera,
  existingSiteSchedule,
  isCameraEdit,
  cameraUpdateStatus: {configSaveSuccess, configSaveError},
}) => {
  const localClasses = useStyles();

  // Either full mask or existing mask for a camera
  const [initialCameraMask, setInitialCameraMask] = useState(null);
  // Mask being tracked to be sent on api call
  const [cameraMask, setCameraMask] = useState(null);
  const [fetchingCameraMaskData, setFetchingCameraMaskData] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(
    () => {
      if (!isEmpty(camera)) {
        setFetchingCameraMaskData(true);
        setInitialCameraMask(
          camera.motionAlarmMask?.toLowerCase() ?? CameraMaskEnum.NONE,
        );
        setCameraMask(
          camera.motionAlarmMask?.toLowerCase() ?? CameraMaskEnum.NONE,
        );
        setFetchingCameraMaskData(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [camera, existingSiteSchedule],
  );

  const getCameraThumbnailUrl = (cameraId) => {
    const deviceWidth = window.innerWidth;
    return makeThumbnailUrl([cameraId], undefined, deviceWidth, -1);
  };
  const setMotionMask = (newMask) => {
    setCameraMask(newMask);
  };

  const handleResetDialog = () => {
    setInitialCameraMask(null);
    setCameraMask(null);
    onCancel();
  };

  const handleSubmitDialog = async () => {
    setIsSubmitting(true);
    await onSave(
      {
        siteId: camera.siteId,
        cameraId: camera.id,
        cameraConfigData: {mask: cameraMask, enabled: true},
      },
      () => {
        setInitialCameraMask(cameraMask);
      },
      () => {
        setCameraMask(initialCameraMask);
      },
    );
    setIsSubmitting(false);
  };

  const dialogAlert = (configError, configSuccess) => {
    if (configError) {
      return (
        <Alert
          data-cy="camera-enable-error"
          severity="error"
          className={localClasses.alert}
        >
          <AlertTitle>Error</AlertTitle>
          Something went wrong, please try again.
        </Alert>
      );
    }
    if (configSuccess) {
      return (
        <Alert
          data-cy="camera-enable-success"
          severity="success"
          className={localClasses.alert}
        >
          <AlertTitle>Success</AlertTitle>
          Camera {isCameraEdit ? 'updated' : 'enabled'} successfully.
        </Alert>
      );
    }
    return (
      <Alert
        data-cy="camera-enable-info"
        severity="info"
        className={localClasses.alert}
      >
        <AlertTitle>Tip</AlertTitle>
        Envysion recommends highlighting just the floor or &apos;paint the
        floor&apos;.
        <br />
        This will help avoid false positives with people outside the windows,
        and reflective surfaces inside.
      </Alert>
    );
  };
  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleResetDialog();
      }}
      maxWidth="lg"
      id="camera-enablement-dialog"
    >
      <DialogTitle>Enable camera: {camera.name}</DialogTitle>
      <DialogContent>
        <DialogContentText component="div">
          {fetchingCameraMaskData || isSubmitting ? (
            <Spinner color="primary" />
          ) : (
            <Fragment>
              <CameraMaskTool
                imageUrl={getCameraThumbnailUrl(camera.id)}
                initialMotionMask={initialCameraMask}
                setMotionMask={setMotionMask}
                useHandleRevert
                configSaveSuccess={configSaveSuccess}
              />
              {dialogAlert(configSaveError, configSaveSuccess)}
            </Fragment>
          )}
        </DialogContentText>
        <DialogActions style={{padding: 0}}>
          {!isSubmitting && (
            <Button
              name="cancel-button"
              variant="text"
              onClick={handleResetDialog}
            >
              {configSaveSuccess ? 'Close' : 'Cancel'}
            </Button>
          )}
          {!fetchingCameraMaskData && !isSubmitting && !configSaveSuccess && (
            <Button
              name="submit-button"
              variant="contained"
              color="primary"
              disabled={
                cameraMask === CameraMaskEnum.NONE ||
                (isCameraEdit && cameraMask === initialCameraMask)
              }
              onClick={handleSubmitDialog}
            >
              {isCameraEdit ? 'Save' : 'Enable Camera'}
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

CameraEnablementDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  camera: PropTypes.shape({}).isRequired,
  existingSiteSchedule: PropTypes.shape({}),
  isCameraEdit: PropTypes.bool.isRequired,
  cameraUpdateStatus: PropTypes.shape({
    configSaveSuccess: PropTypes.bool.isRequired,
    configSaveError: PropTypes.bool.isRequired,
  }).isRequired,
};
CameraEnablementDialog.defaultProps = {
  existingSiteSchedule: null,
};

export default CameraEnablementDialog;
