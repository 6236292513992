import React from 'react';
import get from 'lodash/get';

import SelectList from '../selectList';

const FormSelectList = ({fieldDef, formProps, classes, focused}) => {
  const {
    name,
    label,
    options,
    helperText,
    onChange,
    dependent,
    clearDependentOnChange,
    validation,
    ComponentProps = {},
  } = fieldDef;

  const {setFieldTouched, touched, values, setValues, errors} = formProps;

  const multiSelect = ComponentProps.isMulti;

  const defaultGetOptionValue = (o) => o.id;
  const optionValue = ComponentProps.getOptionValue || defaultGetOptionValue;
  let optionValues = [];

  if (values[name]) {
    if (Array.isArray(values[name])) {
      optionValues = options.filter((o) =>
        values[name].includes(optionValue(o)),
      );
    } else {
      optionValues = options.filter((o) => optionValue(o) === values[name]);
    }
  }

  const required = get(validation, '_exclusive.required', false);

  const id = `${name}-select-list`;

  return (
    <SelectList
      className={classes.formField}
      helperText={touched[name] && errors[name] ? errors[name] : helperText}
      required={required}
      id={id}
      name={name}
      label={label}
      value={optionValues}
      options={options}
      error={touched[name] && Boolean(errors[name])}
      placeholder={fieldDef.placeholder}
      disabled={fieldDef.disabled}
      autoFocus={focused}
      onChange={(selected) => {
        if (typeof setFieldTouched === 'function') {
          setFieldTouched(name);
        }
        const selectedIds = multiSelect
          ? (selected || []).map(optionValue)
          : optionValue(selected || {});
        const updatedValues = {
          ...values,
          [name]: selectedIds,
        };
        if (dependent && clearDependentOnChange) {
          updatedValues[dependent] = undefined;
        }
        setValues(updatedValues);
        if (typeof onChange === 'function') {
          onChange(selectedIds, name, formProps);
        }
      }}
      {...ComponentProps}
    />
  );
};

export default FormSelectList;
