import React, {Fragment, PureComponent} from 'react';
import {Snackbar} from '@mui/material';
import withTheme from '@mui/styles/withTheme';

import VariantSnackbarContent from './VariantSnackbarContent';
import {wrapDisplayName} from '../../util/hocSupport';

const withSnackbar = (WrappedComponent, snackbarProps) => {
  const WithSnackbar = withTheme(
    class SnackbarWrapper extends PureComponent {
      state = {
        open: false,
        messageInfo: {},
      };

      queue = [];

      processQueue = () => {
        if (this.queue.length > 0) {
          this.setState({
            messageInfo: this.queue.shift(),
            open: true,
          });
        }
      };

      handleExited = () => {
        this.processQueue();
      };

      handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({open: false});
      };

      renderSnackbar = (variant) => (message, icon, queue = false) => {
        const snack = {
          message,
          icon,
          variant,
          key: new Date().getTime(),
        };
        if (!queue) {
          this.queue = [snack];
        } else {
          this.queue.push(snack);
        }

        if (this.state.open) {
          if (!queue) {
            // immediately begin dismissing current message
            // to start showing new one
            this.setState({open: false});
          }
        } else {
          // only process the queue if it is currently closed
          this.processQueue();
        }
      };

      render() {
        const snackbar = {
          error: this.renderSnackbar('error'),
          warning: this.renderSnackbar('warning'),
          info: this.renderSnackbar('info'),
          success: this.renderSnackbar('success'),
          close: () => this.setState({open: false}),
        };

        const {open, messageInfo} = this.state;
        const {key, ...content} = messageInfo;
        const {theme, ...rest} = this.props;

        return (
          <Fragment>
            <Snackbar
              style={{
                position: 'absolute',
                zIndex: theme.zIndex.tooltip * 2,
              }}
              autoHideDuration={6000}
              {...snackbarProps}
              key={key}
              open={open}
              onClose={this.handleClose}
              TransitionProps={{
                onExited: this.handleExited,
                unmountOnExit: true,
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div>
                <VariantSnackbarContent
                  {...content}
                  onClose={this.handleClose}
                />
              </div>
            </Snackbar>
            <WrappedComponent {...rest} snackbar={snackbar} />
          </Fragment>
        );
      }
    },
  );

  WithSnackbar.displayName = wrapDisplayName('WithSnackbar', WrappedComponent);

  return WithSnackbar;
};

export default withSnackbar;
