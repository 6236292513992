import React from 'react';
import {Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    textAlign: 'center',
  },
  message: {
    fontWeight: '500',
  },
}));

const ImageLoadError = (props) => {
  const classes = useStyles(props);

  return (
    <div id="image-load-error" className={classes.root}>
      <Typography
        component="h1"
        color="error"
        variant="body1"
        className={classes.message}
      >
        The camera image is currently unavailable.
      </Typography>
      <Typography
        component="h1"
        color="error"
        variant="body1"
        className={classes.message}
      >
        Please try again later.
      </Typography>
    </div>
  );
};

export default ImageLoadError;
