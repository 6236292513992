import React, {useState} from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const OfflineCamerasModal = ({cameras}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '10%',
    marginLeft: '5%',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 1,
  };

  const mobileButtonStyle = {
    fontSize: '0.82rem',
    padding: 0,
    margin: '0 4px',
  };

  const CameraList = () => {
    const offlineCameras = [];
    if (cameras) {
      cameras.forEach((camera) => {
        if (!camera.isOnline) {
          offlineCameras.push(camera.cameraName);
        }
      });
    }
    return (
      offlineCameras.length > 0 && (
        <List
          className="offline-cameras-modal-list"
          sx={{flexDirection: 'column'}}
        >
          {offlineCameras.map((cameraName) => (
            <ListItem sx={{py: 0.5}} key={cameraName}>
              <ListItemText primary={cameraName} />
            </ListItem>
          ))}
        </List>
      )
    );
  };

  return (
    <Box>
      <Button
        data-cy="offline-cameras-btn"
        sx={mobileButtonStyle}
        variant="text"
        onClick={handleOpen}
      >
        Cameras
      </Button>
      <Modal
        sx={{
          overflow: 'scroll',
        }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={style} className="offline-cameras-modal">
          <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography
              className="offline-cameras-modal-title"
              variant="h6"
              sx={{p: 1}}
            >
              Offline Cameras
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CameraList />
        </Box>
      </Modal>
    </Box>
  );
};
export default OfflineCamerasModal;
