import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import {Lazy} from 'react-lazy';

import {withLogger} from '../logger';

const transparent1x1image =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

// An image component that will allow you to pass in other
// components to handle loading / unloading (spinners etc..)
// and will reload the image on a src change.
// Loading will only be triggered once (on initial load
// or, for lazy loading, when image scrolls within the viewport)
// and not every time the src changes.
class ImageLoader extends Component {
  state = {
    isLoaded: false,
    hasError: false,
  };

  componentDidMount() {
    if (!this.props.doLazyLoading) {
      this.loadImg(this.props.src);
    }
  }

  /* eslint-disable camelcase */
  UNSAFE_componentWillUpdate(nextProps) {
    this.prevImg = this.img;
    if (this.props.src !== nextProps.src) {
      this.img = undefined;
    }
  }
  /* eslint-enable camelcase */

  componentDidUpdate(prevProps) {
    const {src} = this.props;
    if (prevProps.src !== src && !this.props.doLazyLoading) {
      this.loadImg(src);
    }
  }

  componentWillUnmount() {
    this.disposing = true;
  }

  drawImageToCanvas = (img) => {
    const {width, height} = img;
    const {onLoaded, logger} = this.props;
    const ctx = this.imageCanvas.getContext('2d');
    // image does not draw correctly if we don't give w & h to canvas
    this.imageCanvas.width = width;
    this.imageCanvas.height = height;
    try {
      ctx.drawImage(this.img, 0, 0, width, height);
      if (onLoaded) {
        onLoaded(img);
      }
    } catch (err) {
      logger.error('Error drawing image onto canvas', err);
      this.onError();
    }
  };

  onLoad = () => {
    if (this.disposing) return;
    this.setState({isLoaded: true, hasError: false});
    if (!this.imageCanvas) return;
    this.drawImageToCanvas(this.img);
  };

  onError = () => {
    if (this.disposing) return;
    const {onError} = this.props;
    // only set to an error state if we never loaded an image.
    // otherwise, we'll just keep showing the image we have.
    this.img = this.prevImg;
    if (onError) onError();

    this.setState({hasError: true});
  };

  // Load the image so it is ready for quick display in the Dom.
  // Note that this is called via componentDidMount (if not lazy loading),
  // otherwise it is called via a Lazy loaded (when in viewport) image onLoad
  loadImg = (src) => {
    if (this.props.showSpinnerOnChange) {
      this.setState({isLoaded: false});
    }
    this.img = new Image();
    this.img.src = src;
    this.img.onerror = this.onError;

    // take advantage of decode if it is present
    if (this.img.decode) {
      this.img
        .decode()
        .then(this.onLoad)
        .catch(this.onError);
    } else {
      this.img.onload = this.onLoad;
    }
  };

  // Lazy Load the image when it is in viewport
  getLazyLoadLoader = () => {
    if (this.img) return null;
    // A placeholder is needed for correct positioning of Lazy in viewport on Chrome 75
    return (
      <Fragment>
        <img src={transparent1x1image} alt={`placeholder-${this.props.alt}`} />
        <Lazy onLoad={() => this.loadImg(this.props.src)} clientOnly>
          <img
            src={transparent1x1image}
            onError={this.onError}
            alt={`lazy-${this.props.alt}`}
          />
        </Lazy>
      </Fragment>
    );
  };

  render() {
    const {
      loader,
      unloader,
      alt,
      showSpinnerOnChange,
      overlaySpinner,
      src,
      onLoaded,
      doLazyLoading,
      logger,
      ...rest
    } = this.props;
    const {hasError, isLoaded} = this.state;

    if (hasError) {
      return (
        <Fragment>
          {doLazyLoading && this.getLazyLoadLoader()}
          {unloader}
        </Fragment>
      );
    }
    // if we have loaded, show img
    if (isLoaded || overlaySpinner) {
      return (
        <Fragment>
          <canvas
            src={src}
            {...rest}
            ref={(node) => {
              this.imageCanvas = node;
            }}
          >
            <p>{alt}</p>
          </canvas>
          {doLazyLoading && this.getLazyLoadLoader()}
          {!isLoaded && overlaySpinner && loader}
        </Fragment>
      );
    }
    // Still loading, render any loader component passed in
    return (
      <Fragment>
        {doLazyLoading && this.getLazyLoadLoader()}
        {loader}
      </Fragment>
    );
  }
}

ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  loader: PropTypes.node,
  unloader: PropTypes.node,
  showSpinnerOnChange: PropTypes.bool,
  overlaySpinner: PropTypes.bool,
  onError: PropTypes.func,
  doLazyLoading: PropTypes.bool,
  alt: PropTypes.string,
  onLoaded: PropTypes.func,
};

ImageLoader.defaultProps = {
  loader: undefined,
  unloader: undefined,
  showSpinnerOnChange: false,
  overlaySpinner: false,
  onError: null,
  doLazyLoading: false,
  alt: '',
  onLoaded: null,
};

export default withLogger(ImageLoader);
