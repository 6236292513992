import {lowerCase} from 'lodash';

export const verificationStatuses = Object.freeze({
  yes: 'yes',
  no: 'no',
  unverifiable: 'unverifiable',
  unclassified: 'unclassified',
});

export const prepareStatus = (status) => {
  if (status === 'No Threat') {
    return verificationStatuses.no;
  }
  if (status === 'Threat') {
    return verificationStatuses.yes;
  }
  return lowerCase(status);
};

export const handleVerificationFiltering = (filters, selectedStatus) => {
  if (
    filters.verifications.findIndex((status) => status === selectedStatus) >= 0
  ) {
    return {
      ...filters,
      verifications: filters.verifications.filter(
        (status) => status !== selectedStatus,
      ),
    };
  }
  return {
    ...filters,
    verifications: [...filters.verifications, selectedStatus],
  };
};
