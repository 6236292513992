import React from 'react';
import {withRouter} from 'react-router-dom';
import {DateTime} from 'luxon';
import queryString from 'query-string';
import compose from 'lodash/flowRight';

import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {getCameras} from '../../api/sensors';
import {getSite} from '../../api/sites';
import PageLoader from '../../shared/components/pageLoader';

const Loader = (props) => {
  const {location, currentUser} = props;
  const query = queryString.parse(location.search);
  const siteId = query.site_id;
  const duration = parseInt(query.duration, 10);
  const startTime = DateTime.fromISO(query.start_time);
  const playbackCameraIds = query.camera_ids?.split(',');
  const paramsToken = query.jwt_token;
  const queryProps = {
    siteId,
    duration,
    startTime,
    playbackCameraIds,
    paramsToken,
  };

  return (
    <PageLoader
      page={() => import('./Page')}
      resources={{
        selectableCameras: async () => {
          if (siteId) {
            return getCameras(siteId);
          }
          return undefined;
        },
        site: async () => {
          if (siteId) {
            return getSite(siteId);
          }
          return {};
        },
      }}
      {...queryProps}
      currentUser={currentUser}
    />
  );
};

export default compose(
  withRouter,
  withCurrentUser,
)(Loader);
