import omit from 'lodash/omit';
import chunk from 'lodash/chunk';
import {omitBy, isEmpty, isNil, pick, snakeCase} from 'lodash';
import axios from 'axios';
import {serializedRequests} from './util';

const applianceClient = axios.create();
applianceClient.defaults.headers.get = {
  ...omit(applianceClient.defaults.headers.get, ['Cache-Control']),
};
applianceClient.interceptors.response.eject(
  applianceClient.interceptorIds?.redirectionInterceptor,
);
applianceClient.interceptors.response.eject(
  applianceClient.interceptorIds?.responseCasingInterceptor,
);

const applianceClientWithCasing = axios.create();
applianceClientWithCasing.defaults.headers.get = {
  ...omit(applianceClientWithCasing.defaults.headers.get, ['Cache-Control']),
};
applianceClientWithCasing.interceptors.response.eject(
  applianceClientWithCasing.interceptorIds?.redirectionInterceptor,
);

export const getToken = async (applianceId, cameraIds) =>
  (await axios.post(`/appliances/${applianceId}/token`, cameraIds)).data.token;

export const getAppliancesByParams = async (options) => {
  const {name, active, siteId} = options;
  const siteIds = Array.isArray(siteId) ? siteId.join(',') : siteId;
  const params = omitBy(
    {
      site_ids: siteIds,
      search: name,
      active,
    },
    isNil,
  );
  return axios
    .get(`/appliances`, {
      params,
    })
    .then((r) => {
      return r.data;
    });
};

export const getAppliance = async (applianceId) => {
  const instance = axios.create();
  instance.interceptors.response.eject(
    instance.interceptorIds?.redirectionInterceptor,
  );
  const response = await instance.get(`/appliances/${applianceId}`);
  return response.data;
};

export const getApplianceLatestConfigPushResult = async (
  applianceId,
  status = 'latest',
) => (await axios.get(`/appliances/${applianceId}/acb/results/${status}`)).data;

export const getAppliancesForSite = async (siteId) => {
  const siteIds = Array.isArray(siteId) ? siteId.join(',') : siteId;
  return (await axios.get(`/appliances?site_ids=${siteIds}`)).data.results;
};

export const getInterface = async (applianceId) =>
  (await axios.get(`/appliances/${applianceId}/interfaces`)).data;

export const replaceAppliance = async (
  oldApplianceId,
  newApplianceId,
  {replacementIsEnVR = false} = {},
) =>
  (await axios.post(`/appliances/${oldApplianceId}/replace`, {
    appliance_id: newApplianceId,
    allow_dvr_model_transfer: !replacementIsEnVR, // invert boolean for papi to receive
  })).data;

export const replaceApplianceStatus = async (applianceId, requestId) =>
  (await axios.get(`/appliances/${applianceId}/replace/status/${requestId}`))
    .data;

export const updateInterface = async (applianceId, interfaceName, resource) => {
  const instance = axios.create();
  instance.interceptors.request.eject(
    applianceClient.interceptorIds?.requestCasingInterceptor,
  );
  return (await instance.put(
    `/appliances/${applianceId}/interfaces/${interfaceName}`,
    {
      dhcp_enabled: resource.dhcpEnabled || false,
      ipv4_address: resource.ipv4Address,
      gateway_ipv4_address: resource.gatewayIpv4Address,
      netmask: resource.netmask,
    },
  )).data;
};

export const addChannels = async (applianceId, channels) =>
  (await axios.post(`/appliances/${applianceId}/add_channels`, channels)).data;

export const pushConfig = async (applianceId) =>
  (await axios.post(`/appliances/${applianceId}/push_config`)).data;

export const getApplianceClipEstimate = async (applianceId, duration) => {
  const {data} = await axios.get(
    `/appliances/${applianceId}/export_estimate?duration=${duration}`,
  );
  return data;
};

export const cameraDiscovery = async (applianceId) =>
  (await axios.post(`/appliances/${applianceId}/discover`)).data;

export const addCameras = async (applianceId, cameras, addCredentials) => {
  const cams = cameras.map((c) => ({
    discoveredDeviceId: c.id,
    username:
      addCredentials && c.credentials ? c.credentials.username : undefined,
    password:
      addCredentials && c.credentials ? c.credentials.password : undefined,
    useIpAddress: c.useIp || undefined,
  }));
  return (await axios.post(`/appliances/${applianceId}/cameras`, cams)).data;
};

export const getStatus = async (statusUrl) => {
  const useUrl = statusUrl.startsWith('/api/v3')
    ? statusUrl.slice(7)
    : statusUrl;
  return (await axios.get(useUrl)).data;
};

export const patchAppliance = async (
  applianceId,
  fields,
  disableAcbPush = false,
) =>
  axios
    .patch(
      `/appliances/${applianceId}`,
      {set: fields},
      {params: {disableAcbPush}},
    )
    .then((r) => r.data);

export const getApplianceUrl = (applianceId) => {
  let env = '';
  const {hostname} = window.location;
  const envMatch = hostname.match(/local|dev|test/i);

  if (envMatch) {
    const [match] = envMatch;
    env = match === 'local' ? 'dev' : match;
  }

  return `https://${applianceId}.${env}appliance.envysion.com`;
};

export const discovery = async (applianceId) => {
  const result = await axios.post(
    `${getApplianceUrl(applianceId)}/aapi/devices/v2/discover`,
  );

  return result.data;
};

export const getStatusFromAppliance = async (applianceId) => {
  const result = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/status/v1`,
  );

  return result.data;
};

export const updateableAudioChannelFields = [
  'gain',
  'recordEnabled',
  'sourceChannel',
];

export const buildPatchAudioChannelBody = (audioChannel) => {
  const fields = pick(audioChannel, updateableAudioChannelFields);

  // checks if value is empty (undefined, null, '', or empty object OTHER THAN empty array)
  // playbackChannels empty array is settable
  const isEmptyValue = (val) =>
    !['number', 'boolean'].includes(typeof val) &&
    !Array.isArray(val) &&
    isEmpty(val);

  // set fields that have a non-empty value
  const set = omitBy(fields, isEmptyValue);

  // unset fields that are defined but have an empty value
  // must convert unset field names to snakecase as axios does not convert strings
  const unset = updateableAudioChannelFields
    .filter((f) => typeof fields[f] !== 'undefined' && isEmptyValue(fields[f]))
    .map((u) => snakeCase(u));
  const body = omitBy({set, unset}, isEmpty);
  if (audioChannel.playbackChannelsToAdd) {
    body.add = {playback_channels: audioChannel.playbackChannelsToAdd};
  }
  if (audioChannel.playbackChannelsToRemove) {
    body.remove = {
      playback_channels: audioChannel.playbackChannelsToRemove,
    };
  }
  return body;
};

export const patchAudioChannel = (audioChannel) =>
  axios.patch(
    `/appliances/${audioChannel.applianceId}/audio_channels/${audioChannel.id}`,
    buildPatchAudioChannelBody(audioChannel),
  );

export const getAudioChannelsByApplianceId = async (applianceId) =>
  (await axios.get(`/appliances/${applianceId}/audio_channels`)).data;

export const probeCameras = async (applianceId, cameras) => {
  // probe requests in batches of 16 requested serially
  const batchCameras = chunk(cameras, 16);

  const responses = await serializedRequests(
    batchCameras.map((cams) => () =>
      axios
        .post(`${getApplianceUrl(applianceId)}/aapi/devices/v1/probe`, cams)
        .catch(() => ({data: [...new Array(cams.length)].map(() => ({}))})),
    ),
  );

  return responses.map(({data}) => data).flat();
};

export const buildPvmConfigBody = (config) => {
  const body = {
    monitors: config.cameraPositions.map((cameraPositions, idx) => {
      return {
        // camera_positions come from the camera_positions array as the full camera object, we need to map through and only send the camera channel
        camera_positions: cameraPositions.map(
          (camera) => camera?.channel ?? null,
        ),
        // layout comes from the layouts array as an object with the name and dimentions, we need to only send the name
        layout_id: config.layouts[idx].name,
        options: {
          show_name_in_pvm: config.showNameInPVM,
          correct_aspect_ratio: config.correctAspectRatio,
        },
      };
    }),
    options: {
      monitor_mode: config.independentMode ? 'independent' : 'mirror',
    },
  };
  return body;
};

export const getPVMConfigByApplianceId = async (applianceId) => {
  const response = await axios.get(`/appliances/${applianceId}/pvm`);
  return response.data;
};

export const updatePvmConfig = async (applianceId, config) => {
  const result = await axios.put(
    `/appliances/${applianceId}/pvm`,
    buildPvmConfigBody(config),
  );
  return result.data;
};

export const getCameraStatusByApplianceId = async (applianceId) => {
  const response = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/status/v1/cameras`,
  );
  return response.data;
};

export const getLayoutsByApplianceId = async (applianceId) => {
  const response = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/layouts`,
  );
  return response.data;
};

// Legacy appliance endpoint
export const getLayoutByApplianceId = async (applianceId, layoutId) => {
  const response = await applianceClient.get(
    `${getApplianceUrl(
      applianceId,
    )}/aapi/pvm-admin/layout/${layoutId}?max_width=10000&max_height=10000`,
  );
  return response.data;
};

export const getPVMConfigFromAppliance = async (applianceId) => {
  const response = await applianceClientWithCasing.get(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/config`,
  );
  return response.data;
};

export const legacyPVMConfigAppliance = async (applianceId, config) => {
  const response = await applianceClient.put(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/config`,
    buildPvmConfigBody(config),
  );
  return response.data;
};

export const patchPVMConfigAppliance = async (applianceId, config) => {
  const response = await applianceClient.patch(
    `${getApplianceUrl(applianceId)}/aapi/config/v1/pvm`,
    buildPvmConfigBody(config),
  );
  return response.data;
};

export const getMonitorsByApplianceId = async (applianceId) => {
  const response = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/status/v1/hardware/`,
  );
  return response.data;
};

export const getMirrorStatusByApplianceId = async (applianceId) => {
  const response = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/dual-pvm`,
  );

  return response.data;
};

export const buildMirrorStatusBody = (status) => {
  const body = {
    enabled: status,
  };
  return body;
};

export const updateMirrorStatusByApplianceId = async (applianceId, status) => {
  const response = await applianceClient.put(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/dual-pvm`,
    buildMirrorStatusBody(status),
  );

  return response.data;
};

export const getHeatmapData = async (applianceId, channels, timeRanges) => {
  const response = await applianceClient.post(
    `${getApplianceUrl(applianceId)}/aapi/dvr/heatmap`,
    {
      channels,
      time_ranges: timeRanges,
    },
  );
  return response.data;
};

export const getPVMPreviewSnapshot = async (
  applianceId,
  monitorId,
  // -1 means retain it's aspect ratio and return the image while satisfying the other param
  {width = '-1', height = '720'},
) => {
  const params = {
    monitor: monitorId,
    resolution: `${width}x${height}`,
  };
  const response = await applianceClient.get(
    `${getApplianceUrl(applianceId)}/aapi/pvm-admin/v2/screenshot`,
    {
      params,
      responseType: 'arraybuffer',
    },
  );
  return response;
};

export const getAppliancePreferences = async (applianceId) => {
  const response = await applianceClient.get(
    `/appliances/${applianceId}/preferences`,
  );
  return response.data;
};

export const updateAppliancePreferences = async (applianceId, preferences) => {
  const response = await applianceClient.put(
    `/appliances/${applianceId}/preferences`,
    preferences,
  );
  return response.data;
};

export const getNetworkConfigFromDatabase = async (applianceId) => {
  const response = await axios.get(`/appliances/${applianceId}/network_config`);
  return response.data;
};

export const updateNetworkConfigToDatabase = async (applianceId, config) => {
  const response = await axios.put(
    `/appliances/${applianceId}/network_config`,
    config,
  );
  return response.data;
};

export const getNetworkDetailsFromAppliance = async (applianceId) => {
  const result = await applianceClientWithCasing.get(
    `${getApplianceUrl(applianceId)}/aapi/status/v1/interfaces`,
  );
  return result.data;
};
