import React, {useState, useContext} from 'react';
import {
  Button,
  Popover,
  TextField,
  Box,
  Typography,
  InputAdornment,
} from '@mui/material';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import {PropTypes} from 'prop-types';
import {patchUser} from '../../../../api/users';
import RefContext from './AlarmsRefContext';
import {useApi} from '../../../../shared/hooks';
import Spinner from '../../../../shared/components/spinner';

const AddPhoneNumberPopover = (props) => {
  const {open, anchorEl, handleClosePhonePopover, userId} = props;

  const [phoneCurrNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [, userLoading, , updateUser] = useApi(patchUser);
  const {dispatch} = useContext(RefContext);

  const handlePhoneNumberChange = (phoneValue) => {
    const phoneRegExp = '^([1]{0,1})[0-9]{10}$';
    const phoneNumber = phoneValue.target.value;

    if (phoneNumber.match(phoneRegExp)) {
      setPhoneNumber(phoneValue.target.value.replace(/[^0-9]/g, ''));
      setError('');
    } else {
      setError('Invalid phone number');
    }
  };

  const handleSubmit = async () => {
    await updateUser(userId, {phoneNumber: phoneCurrNumber});
    handleClosePhonePopover();
    dispatch('setDataNewUsers', () => true);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClosePhonePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Typography marginTop={2} marginLeft={2} sx={{fontWeight: 'medium'}}>
        Add number
      </Typography>
      <TextField
        data-cy="phone-number-text-field"
        variant="standard"
        label="Phone number"
        error={!!error}
        helperText={error}
        onChange={handlePhoneNumberChange}
        sx={{marginX: 2, width: 300}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        sx={{
          marginX: 2,
          marginY: 2,
          justifyContent: 'right',
          display: 'flex',
        }}
      >
        <Button onClick={handleClosePhonePopover}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!!error || !phoneCurrNumber}
          data-cy="submit-phone-number"
        >
          {userLoading ? <Spinner size={20} /> : 'Submit'}
        </Button>
      </Box>
    </Popover>
  );
};

AddPhoneNumberPopover.defaultProps = {
  anchorEl: null,
};

AddPhoneNumberPopover.propTypes = {
  handleClosePhonePopover: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  anchorEl: PropTypes.shape({}),
};

export default AddPhoneNumberPopover;
