import React from 'react';
import {List, ListItemText} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import omit from 'lodash/omit';
import sluggify from 'lodash/kebabCase';
import classNames from 'classnames';

import PageTemplate from '../../shared/components/pageTemplate';
import ListItemLink from '../../shared/components/listItemLink';
import allowed, {DOWNLOAD_VIDEO, MY_CLIPS} from '../../shared/util/allowed';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';

const styles = (theme) => ({
  drawerList: {
    backgroundColor: '#f7f7f7',
    padding: [[theme.spacing(1), 0]],
    height: '100%',
    '& li': {
      height: theme.spacing(6.875),
      borderLeft: '3px solid transparent',
    },
  },
  listItemContainer: {
    '&.selected': {
      fontSize: 11,
      backgroundColor: 'transparent',
    },
  },
  listItemContainerSelected: {
    backgroundColor: 'transparent',
  },
  listItem: {
    height: '100%',
    borderLeft: '3px solid transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.selected': {
      fontSize: 12,
      backgroundColor: 'transparent',
    },
  },
  listItemText: {
    fontSize: '.9rem',
    color: '#666e76',
    fontFamily: 'Open Sans,Arial,Helvetica,sans-serif',
    '&:before': {
      fontFamily: 'FontAwesome',
      marginRight: 5,
    },
  },
  listItemTextSelected: {
    fontSize: '.9rem',
    color: '#0087e9',
    fontWeight: 700,
    '&:before': {
      fontFamily: 'FontAwesome',
      marginRight: 5,
    },
  },
  listItemSelected: {
    borderColor: '#0087e9',
    backgroundColor: 'transparent',
  },
  background: {
    background: theme.palette.common.white,
    padding: 0,
  },
  drawerWrapperRoot: {
    height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
  },
  drawerWrapperContent: {
    height: `calc(100vh - ${theme.mixins.toolbar.height}px)`,
  },
  trash: {
    '&:before': {
      fontSize: '1.2rem',
    },
  },
});

const MenuDrawer = ({currentUser, children, drawerCollapsed, classes}) => {
  const clipsNav = [
    {
      to: '/clips2/root',
      name: 'my-clips',
      icon: 'fa-film',
      primaryText: 'My Clips',
      href: '/clips2/root',
      component: 'a',
    },
    {
      show: !!allowed(currentUser, [MY_CLIPS, DOWNLOAD_VIDEO]),
      to: '/external-shares',
      selected: window.location.href.indexOf('/external-shares') > -1,
      relative: true,
      name: 'external-shares',
      icon: 'fa-share',
      primaryText: 'External Shares',
    },
    {
      show: true,
      to: '/clips2/trash',
      name: 'trash',
      icon: 'fa-trash-o',
      primaryText: 'Trash',
      href: '/clips2/trash',
      component: 'a',
    },
  ];

  const drawerContent = (
    <List disablePadding className={classes.drawerList}>
      {clipsNav.map(
        ({show, primaryText, secondaryText, mini, ...props}) =>
          (typeof show === 'undefined' || show) && (
            <ListItemLink
              {...omit(props, 'selected')}
              mini={drawerCollapsed}
              key={sluggify(primaryText)}
              classes={{
                root: classNames(
                  classes.listItem,
                  props.selected && classes.listItemSelected,
                ),
              }}
              ContainerProps={{
                classes: {
                  root: classes.listItemContainer,
                  selected: classes.listItemContainerSelected,
                },
              }}
            >
              <ListItemText
                primary={primaryText}
                primaryTypographyProps={{
                  classes: {
                    root: classNames([
                      !props.selected
                        ? classes.listItemText
                        : classes.listItemTextSelected,
                      'fa',
                      props.icon,
                      props.name === 'trash' ? classes.trash : null,
                    ]),
                  },
                }}
                secondary={mini ? undefined : secondaryText}
              />
            </ListItemLink>
          ),
      )}
    </List>
  );
  return (
    <PageTemplate
      withDrawer
      category="My Clips"
      title="Archive"
      className={classes.background}
      classes={{
        root: classes.drawerWrapperRoot,
        content: classNames(classes.drawerWrapperContent, classes.background),
      }}
      drawerContent={drawerContent}
      showHeader={false}
    >
      {children}
    </PageTemplate>
  );
};

export default withStyles(styles)(withCurrentUser(MenuDrawer));
