import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const SetTopBoxIcon = (props) => {
  return (
    <SvgIcon sx={{fill: 'currentColor'}}>
      <svg {...props}>
        <path d="M5,15.5A0.5,0.5 0 0,1 4.5,16H3.5A0.5,0.5 0 0,1 3,15.5V15H2A1,1 0 0,1 1,14V11A1,1 0 0,1 2,10H22A1,1 0 0,1 23,11V14A1,1 0 0,1 22,15H21V15.5A0.5,0.5 0 0,1 20.5,16H19.5A0.5,0.5 0 0,1 19,15.5V15H5V15.5M3,12V13H5V12H3M6,12V13H8V12H6M20.5,11.5A1,1 0 0,0 19.5,12.5A1,1 0 0,0 20.5,13.5A1,1 0 0,0 21.5,12.5A1,1 0 0,0 20.5,11.5Z" />
      </svg>
    </SvgIcon>
  );
};

export default SetTopBoxIcon;
