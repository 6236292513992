import {DateTime} from 'luxon';
import {getTransaction} from '../../api/transactions';
import {getTask, getReportId} from '../../api/notifications';

const getEarliestDate = (dates) =>
  DateTime.min(...dates.map((d) => DateTime.fromISO(d, {setZone: true})));

const getLatestDate = (dates) =>
  DateTime.max(...dates.map((d) => DateTime.fromISO(d, {setZone: true})));

const taskJson = async (notificationId, taskId) =>
  getTask(notificationId, taskId);

const getBaseReportId = async () => getReportId('QSR_Transactions');

export const getReportPath = async (notificationId, taskId) => {
  const tasks = await taskJson(notificationId, taskId);
  const {transactions} = tasks.data;
  const findReportId = await getBaseReportId();

  const receiptNumbers = transactions.map((t) => t.receiptNum);
  const rawDates = transactions.map((t) => t.startTime);
  const date1 = getEarliestDate(rawDates);
  const date2 = getLatestDate(rawDates);
  const [dateOperator, dates] = date1.hasSame(date2, 'day')
    ? ['EQUAL', [date1]]
    : ['BETWEEN', [date1, date2]];
  const formattedDates = dates.map((d) => d.toISODate());

  const sitesPromises = transactions.map((t) => getTransaction(t.id));
  const sites = await Promise.all(sitesPromises);
  const storeCodes = [...new Set(sites.map((s) => s.site.code))];
  const siteOperator = storeCodes.length > 1 ? 'IN' : 'EQUAL';

  return (
    `/app/#/reports/br${findReportId.baseReportId}?` +
    `paramName=date&paramName=v_receipt_number&paramName=v_store_code` +
    `&operatorType=${dateOperator}&operatorType=IN&operatorType=${siteOperator}` +
    `&value=${formattedDates.join(',')}&value=${receiptNumbers.join(
      ',',
    )}&value=${storeCodes.join(',')}`
  );
};
