// Setup global things that can help us debug in here

// Allow a way for dev to manipulate playlist URL params
// pass in a simple key value object of url params to values
// example:
// window.playlistParams.set({master_playlist_algorithm: 'bitrate_limit'})
// Using sessionStorage so things survive a page reload.
// Clear things with the clear() func.
class PlaylistParams {
  constructor() {
    this.key = 'playlistParams';
  }

  set(paramsObject) {
    window.sessionStorage.setItem(this.key, JSON.stringify(paramsObject));
  }

  get() {
    const params = window.sessionStorage.getItem(this.key);
    if (params) {
      return JSON.parse(params);
    }
    return undefined;
  }

  clear() {
    window.sessionStorage.removeItem(this.key);
  }
}

window.playlistParams = new PlaylistParams();
