export const isOwner = (user, group) =>
  (group.ownerId && user.id === group.ownerId) ||
  (group.ownerLegacyId && user.legacyId === group.ownerLegacyId);

export const addOwnerToGroup = (group) => ({
  ...group,
  owner: `${group.ownerFirstname || ''} ${group.ownerLastname || ''}`.trim(),
});

export const formatGroupsData = (groupList) => groupList.map(addOwnerToGroup);

export const filterAndFormatUsers = (users, group) =>
  users.reduce((list, user) => {
    if (!user.disabled && !isOwner(user, group))
      list.push({
        ...user,
        name: `${user.firstName} ${user.lastName}`.trim(),
        secondaryText: user.username,
      });
    return list;
  }, []);

export default {formatGroupsData, filterAndFormatUsers, isOwner};
