import React from 'react';
import IFrameWrapper from '../../shared/components/iFrameWrapper';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getHost} from '../../shared/components/iFrameWrapper/hostUtil';

const AdminFrameWrapper = (path, prePath, sandbox) => {
  const url = `https://${getHost()}${prePath}${path}?embedded=true`;

  return (
    <PageTemplateResponsive
      PageContentProps={{
        marginY: 0,
        marginX: 0,
      }}
    >
      <IFrameWrapper
        src={url}
        title={path.replaceAll('/', '')}
        FrameProps={{
          sandbox,
        }}
      />
    </PageTemplateResponsive>
  );
};

export default AdminFrameWrapper;
