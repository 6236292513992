import React from 'react';
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import {baseTheme} from './shared/themes';

const Theme = ({children}) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={baseTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Theme;
