import axios from 'axios';
import queryString from 'query-string';
import {MOTION_ALARM_ADMIN, MOTION_ALARM_USER} from '../shared/util/allowed';

const fetchData = (url) => {
  return axios
    .get(url)
    .then((response) => response.data)
    .catch(() => null);
};

export const getSubscribers = async (siteId, eventType) => {
  const urlSubscribers = `notifications/subscriptions/site/${siteId}/users?${queryString.stringify(
    {
      event_type: eventType,
    },
  )}`;

  const {results: subscribers} = await fetchData(urlSubscribers);

  return subscribers;
};

// new notification component
export const getPaginatedUsers = async (siteId, limit, offset, search) => {
  const urlUsers = `sites/${siteId}/users?${queryString.stringify(
    {
      capabilities: [MOTION_ALARM_ADMIN, MOTION_ALARM_USER],
      limit,
      offset,
      ...(search && {search}),
    },
    {
      arrayFormat: 'comma',
    },
  )}`;

  return fetchData(urlUsers);
};

export const getSiteNotificationSubscription = async (siteId, eventType) => {
  const urlSiteSubs = `/notifications/subscriptions?${queryString.stringify({
    site_id: siteId,
    event_type: eventType,
  })}`;

  const siteSubs = await fetchData(urlSiteSubs);

  const results = {
    noonlightEnabled:
      siteSubs && Boolean(siteSubs.find((sub) => sub.type === 'noonlight')),
    siteSubscriptions: siteSubs,
  };
  return results;
};

// manage subscriptions with specific user & subscription
export const manageSubscription = async (
  userId,
  subscriptionId,
  checked,
  priority,
) => {
  const action = checked ? 'subscribe' : 'unsubscribe';

  const urlSub = `notifications/subscriptions/${subscriptionId}/user/${userId}/${action}`; // all subscribed users - array contains subscription data
  return axios.put(urlSub, {priority});
};
