import React, {useMemo, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {ListItemIcon, ListItemText} from '@mui/material';
import SecondNavSubListLink from './SecondNavSubListLink';

const SecondNavSubListItem = (props) => {
  const {
    to,
    icon,
    primaryTitleText,
    shortTitleText,
    onClick,
    bottom,
    customIsActiveMatcher,
  } = props;
  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      forwardRef((itemProps, ref) => (
        <NavLink to={to} {...itemProps} innerRef={ref} />
      )),
    [to],
  );

  return (
    <SecondNavSubListLink
      component={renderLink}
      to={to}
      onClick={onClick}
      relative
      bottom={bottom}
      customIsActiveMatcher={customIsActiveMatcher}
    >
      <ListItemIcon name={`${primaryTitleText}-icon`}>{icon}</ListItemIcon>
      <ListItemText
        sx={{
          marginY: 0,
          ...(bottom ? {margin: 0} : {marginX: 3}),
        }}
        {...bottom && {primaryTypographyProps: {fontSize: '0.75rem'}}}
        primary={(bottom && shortTitleText) || primaryTitleText}
      />
    </SecondNavSubListLink>
  );
};
SecondNavSubListItem.propTypes = {
  /**
   * String containing path directing where to navigate on click
   */
  to: PropTypes.string.isRequired,
  /**
   * Node with icon to display in sub nav menu item
   */
  icon: PropTypes.node.isRequired,
  /**
   * String containing display name of sub nav menu item primary title
   *    is used on bottom item when no shortTitleText is provided
   */
  primaryTitleText: PropTypes.string.isRequired,
  /**
   * String containing display name of sub nav menu item short title
   *    is used on bottom item if provided
   */
  shortTitleText: PropTypes.string,
  /**
   *  Callback function handle on clicking sub nav item
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Boolean for positioning menu in bottom, below medium breakpoint (width 720px)
   */
  bottom: PropTypes.bool,
  /**
   * Callback function handle for custom path match to highlight active item
   */
  customIsActiveMatcher: PropTypes.func,
};
SecondNavSubListItem.defaultProps = {
  bottom: false,
  shortTitleText: '',
  customIsActiveMatcher: null,
};
export default SecondNavSubListItem;
