import React from 'react';
import {Typography} from '@mui/material';

const DescriptionText = ({typographyDef, classes}) => {
  const {text, component, ...typoProps} = typographyDef;
  return (
    <Typography className={classes.formField} {...typoProps}>
      {text}
    </Typography>
  );
};

export default DescriptionText;
