import React, {forwardRef} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  IconButton,
  TextField,
  Typography,
  MenuItem,
  Chip,
} from '@mui/material';
import {components} from 'react-select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classNames from 'classnames';

import Tooltip from '../tooltip';

export const ClearIndicator = (props) => {
  const {
    children = <CloseIcon />,
    innerProps: {ref, ...restInnerProps},
  } = props;
  return (
    <IconButton
      {...restInnerProps}
      ref={ref}
      className={props.selectProps.classes.clearIndicator}
      id="clear-value-cta"
    >
      {children}
    </IconButton>
  );
};

const InputComponent = forwardRef((props, ref) => <div ref={ref} {...props} />);
InputComponent.displayName = 'InputComponent';

export const Control = (props) => {
  const hasPlaceholder = !!props.selectProps.placeholder;
  const shrink = hasPlaceholder || props.hasValue ? true : undefined;
  return (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      error={props.selectProps.error}
      helperText={props.selectProps.helperText}
      required={props.selectProps.required}
      label={props.selectProps.label}
      InputLabelProps={{
        shrink,
      }}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: {
          className: classNames(props.selectProps.classes.input, {
            [props.selectProps.classes.inputMultiValue]:
              props.selectProps.isMulti &&
              !props.selectProps.truncateMultiValue,
          }),
          ref: props.innerRef,
          children: props.children,
          id: props.selectProps.id,
          name: props.selectProps.id,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.TextFieldProps}
    />
  );
};

export const DropdownIndicator = (props) => (
  <div className={props.selectProps.classes.drowdownIndicator}>
    <ArrowDropDownIcon />
  </div>
);

export const IndicatorsContainer = (props) => (
  <div className={props.selectProps.classes.indicatorsContainer}>
    {props.children}
  </div>
);

export const IndicatorSeparator = (props) => (
  <Divider className={props.selectProps.classes.indicatorSeparator} />
);

export const Menu = (props) => (
  <div name="select-list-menu">
    <components.Menu {...props}>{props.children}</components.Menu>
  </div>
);

export const MultiValue = (props) => {
  const {getValue, selectProps, removeProps, data} = props;
  const {getOptionLabel, classes, truncateMultiValue} = selectProps;
  if (truncateMultiValue) {
    const index = getValue().indexOf(data);
    if (index > 0) return null;
    const fullMultiValue = getValue()
      .map((v) => getOptionLabel(v))
      .join(', ');
    let truncatedMultiValue = fullMultiValue;
    if (fullMultiValue.length >= 10) {
      truncatedMultiValue = `${fullMultiValue.substr(0, 9)}...`;
    }
    return (
      <Tooltip title={fullMultiValue}>
        <Typography className={classes.multiValueTruncate} color="inherit">
          {truncatedMultiValue}
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Chip
      label={getOptionLabel(data)}
      className={classes.multiValue}
      onMouseDown={removeProps.onMouseDown}
      onDelete={removeProps.onClick}
    />
  );
};

export const NoOptionsMessage = (props) => (
  <Typography
    color="textSecondary"
    name="no-options-message"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

export const Option = (props) => (
  <MenuItem
    className={props.selectProps.classes.option}
    ref={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected
        ? props.theme.typography.fontWeightMedium
        : props.theme.typography.fontWeightRegular,
      color: props.isSelected
        ? props.theme.palette.text.hint
        : props.theme.palette.text.primary,
    }}
    {...props.innerProps}
    data-value={props.data.id}
  >
    {props.children}
  </MenuItem>
);

export const Placeholder = (props) => (
  <Typography
    color="textSecondary"
    className={classNames(props.selectProps.classes.placeholder, {
      [props.selectProps.classes.placeholderDisabled]:
        props.selectProps.isDisabled,
    })}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

export const SingleValue = (props) => (
  <Typography
    className={props.selectProps.classes.singleValue}
    color="inherit"
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

export const ValueContainer = ({selectProps, isDisabled, children}) => {
  const {classes, truncateMultiValue, isMulti} = selectProps;
  return (
    <div
      className={classNames(classes.valueContainer, {
        [classes.valueContainerMultiValueTruncate]: truncateMultiValue,
        [classes.valueDisabled]: isDisabled,
        [classes.valueContainerMultiValue]: isMulti,
      })}
    >
      {children}
    </div>
  );
};

export const GroupHeading = (props) => (
  <Typography
    className={props.selectProps.classes.groupHeading}
    color="textSecondary"
    variant="subtitle2"
  >
    {props.children}
  </Typography>
);
