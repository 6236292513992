import React from 'react';
import CurrentUserContext from '../../contexts/currentUserContext';
import {wrapDisplayName} from '../../util/hocSupport';

export const LoggerContext = React.createContext();

/* eslint-disable-next-line react/display-name */
export const withLogger = (Component) => {
  const WithLogger = (props) => (
    <CurrentUserContext.Consumer>
      {(userContext) => (
        <LoggerContext.Consumer>
          {(loggerContext) => {
            // eslint-disable-next-line no-param-reassign
            if (
              loggerContext &&
              !loggerContext.user &&
              userContext &&
              userContext.currentUser
            ) {
              loggerContext.setUserContext(userContext.currentUser);
            }
            return <Component {...props} logger={loggerContext} />;
          }}
        </LoggerContext.Consumer>
      )}
    </CurrentUserContext.Consumer>
  );
  WithLogger.displayName = wrapDisplayName('WithLogger', Component);
  return WithLogger;
};
