import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {Button, Typography} from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  prerequisite: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    textTransform: 'none',
  },
  icon: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  prerequisiteText: {
    textDecoration: 'underline',
  },
}));

const AlarmModePrerequisite = ({children, state, onClick, ...restProps}) => {
  const classes = useStyles();

  return (
    <Button
      data-cy="prerequisite"
      disableRipple
      className={classNames(
        {
          [classes.success]: state,
          [classes.error]: !state,
        },
        classes.prerequisite,
      )}
      onClick={onClick}
      {...restProps}
    >
      <div className={classes.icon}>
        {state ? (
          <CheckIcon data-cy="prerequisite-met" />
        ) : (
          <CloseIcon data-cy="prerequisite-not-met" />
        )}
      </div>
      <Typography className={classes.prerequisiteText}>{children}</Typography>
    </Button>
  );
};

AlarmModePrerequisite.propTypes = {
  state: PropTypes.bool,
  onClick: PropTypes.func,
};

AlarmModePrerequisite.defaultProps = {
  state: false,
  onClick: () => {},
};

export default AlarmModePrerequisite;

export const CameraEnabledPrerequisite = ({
  state,
  onCamerasConfigurationOpen,
}) => (
  <AlarmModePrerequisite
    data-cy="camera-enabled-prerequisite"
    state={state}
    onClick={onCamerasConfigurationOpen}
  >
    Camera Enabled
  </AlarmModePrerequisite>
);

export const ScheduleSetPrerequisite = ({
  state,
  onSchedulesConfigurationOpen,
}) => (
  <AlarmModePrerequisite
    data-cy="schedule-set-prerequisite"
    state={state}
    onClick={onSchedulesConfigurationOpen}
  >
    Schedule Set
  </AlarmModePrerequisite>
);

export const EnvysionMonitoringSubscriberPrerequisite = ({
  state,
  onNotificationsConfigurationOpen,
}) => (
  <AlarmModePrerequisite
    data-cy="envysion-monitoring-subscriber-prerequisite"
    state={state}
    onClick={onNotificationsConfigurationOpen}
  >
    Envysion Monitoring Subscriber
  </AlarmModePrerequisite>
);

export const NotificationSubscriberPrerequisite = ({
  state,
  onNotificationsConfigurationOpen,
}) => (
  <AlarmModePrerequisite
    data-cy="notification-subscriber-prerequisite"
    state={state}
    onClick={onNotificationsConfigurationOpen}
  >
    Notification Subscriber
  </AlarmModePrerequisite>
);
