/* eslint-disable */
import React, {Component} from 'react';
import classnames from 'classnames';
import 'font-awesome/css/font-awesome.css';
import withStyles from '@mui/styles/withStyles';

import * as users from '../../api/users';
import {hideGlobalMenu} from '../../shared/util/general';
import logo from './envysion-logo.png'; // TODO: move to shared/common location pending team design
import './Menu.css';

const styles = (theme) => ({
  navHeader: theme.mixins.toolbar,
});

const getWidth = (id) => {
  const elem = document.getElementById(id);
  return elem ? elem.clientWidth : 0;
};

class Menu extends Component {
  state = {
    primaryWidth: 0,
    secondaryWidth: 0,
    wholeWidth: window.screen ? window.screen.width : 1024, // default to a size which will show the menu so we can compute render sizes
  };

  async componentDidMount() {
    try {
      // Load the menus
      const response = await users.menu();
      this.setState({
        menuData: response,
      });
    } catch (e) {
      // swallow 401's causing uncaught exceptions
      if (e.response && e.response.status !== 401) throw e;
    }

    // Allow clicking anywhere (except in menu item with subitems) to collapse the open menu
    if (document.documentElement) {
      document.documentElement.addEventListener(String('click'), (e) => {
        if (
          !e.target ||
          !e.target.dataset ||
          e.target.dataset.noCloseOnClick !== 'true'
        ) {
          this.setState({expanded: undefined, subExpanded: undefined});
        }
      }, true);
    }

    if (window && window.addEventListener) {
      window.addEventListener('resize', this.setResponsiveDisplay);
      const wholeWidth = getWidth('envysion-common-menu');
      const primaryWidth = getWidth('primary') + getWidth('logo');
      const secondaryWidth = getWidth('secondary');
      this.setState(
        {primaryWidth, secondaryWidth, wholeWidth},
        this.setResponsiveDisplay,
      );
    }

    // Only initialize the Chat button if we have menu data, otherwise the menu won't render and there is no chat button.
    if (this.state.menuData && !hideGlobalMenu()) {
      try {
        const userResponse = await users.getFullUserById();
        this.setState({currentUser: userResponse});

        // enable new LiveChat
        let el = document.getElementById('livechat_button_envysion');
        el.style.display = 'inline';
        el = document.getElementById('livechat_button_small_envysion');
        el.style.display = 'inline';
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.log('Live Chat integration error', e);
      }
    }
  }

  componentWillUnmount() {
    if (window && window.removeEventListener) {
      window.removeEventListener('resize', this.setResponsiveDisplay);
    }
  }

  getSubMenu(items, isExpanded, menuClass = 'ecm-dropdown', subExpanded) {
    return (
      <ul className={`${menuClass} ${isExpanded ? '' : 'hide'}`}>
        {items.map(
          (c) =>
            c && (
              <li
                key={
                  c.liveChat
                    ? 'secondary9999'
                    : (c.separator && c.title) || c.href + c.icon
                }
                className={`${c.items ? 'current-responsive' : ''}`}
                name={c.title || c.src}
              >
                <a
                  href={c.liveChat ? null : c.href}
                  onClick={
                    c.liveChat
                      ? this.openLiveChat
                      : () => c.items && this.toggle(c.title, true)
                  }
                  target={c.target}
                >
                  {!c.src && c.icon && <i className={`fa ${c.icon}`} />}
                  {!c.src && (
                    <span data-no-close-on-click={!!c.items}>{c.title}</span>
                  )}
                  {!!c.src && <img src={c.src} />}
                </a>
                {c.items &&
                  this.getSubMenu(
                    c.items,
                    subExpanded === c.title,
                    'responsive-sub-menu',
                  )}
              </li>
            ),
        )}
      </ul>
    );
  }

  toggle = (key, toggleSubExpanded = false) => {
    if (toggleSubExpanded) {
      this.setState((prevState) => ({
        subExpanded: prevState.subExpanded !== key ? key : '',
      }));
    } else {
      this.setState((prevState) => ({
        expanded: prevState.expanded !== key ? key : '',
      }));
    }
  };

  learnMore = () => {
    window.open('https://learning.envysion.com/html5-player/', '_blank');
  };

  openLiveChat = () => {
    window.open(
      'https://home-c35.nice-incontact.com/incontact/chatclient/chatclient.aspx?poc=a85ec919-6992-44dc-85ce-edf1982b35ff&bu=4601639',
      'popup',
      'width=550,height=500',
    );
  };

  checkIfCurrent = (menuItem) => {
    let isCurrent = false;
    if (menuItem.matches && menuItem.matches.length > 0) {
      menuItem.matches.forEach((regexStr) => {
        const regex = new RegExp(regexStr);
        const matchesLocation = regex.test(window.location);
        const matchesHash = regex.test(window.location.hash);

        isCurrent = matchesLocation || matchesHash;
      });
    }
    return isCurrent;
  };

  setResponsiveDisplay = () => {
    const checkWidth = () => {
      const wholeWidth = getWidth('envysion-common-menu');
      this.setState({wholeWidth});
    };
    if (window.requestAnimationFrame) {
      window.requestAnimationFrame(checkWidth);
    } else {
      checkWidth();
    }
  };

  render() {
    const {
      menuData,
      expanded,
      subExpanded,
      primaryWidth,
      secondaryWidth,
      wholeWidth,
    } = this.state;
    const {classes} = this.props;

    if (hideGlobalMenu()) {
      return null;
    }

    if (!menuData) {
      return (
        <header className={classes.navHeader}>
          <div id="envysion-common-menu">
            <img id="logo" className="logo" src={logo} />
          </div>
        </header>
      );
    }

    const {primary, secondary, logoOptions} = menuData;
    const trigger = wholeWidth - primaryWidth - secondaryWidth - 215;
    const hidePrimary = trigger <= 0;

    const smallMenu = this.getSmallMenu(
      menuData,
      expanded,
      hidePrimary,
      subExpanded,
    );

    return (
      <header className={classes.navHeader}>
        <div id="envysion-common-menu">
          <a href={menuData.landingPage}>
            <img id="logo" className="logo" src={logo} />
          </a>
          <ul
            id="primary"
            className={classnames({hide: hidePrimary}, 'primary-menu')}
          >
            {primary.map((i, index) => (
              <li
                key={'primary' + index}
                className={this.checkIfCurrent(i) ? 'current' : ''}
                name={i.title || i.src}
              >
                {!i.src && (
                  <a
                    href={i.href}
                    onClick={() => this.toggle(i.icon)}
                    target={i.target}
                  >
                    <i className={`fa ${i.icon}`} />
                    {i.title}
                  </a>
                )}
                {!!i.src && (
                  <a href={i.href} target={i.target}>
                    <img src={i.src} />
                    {!!i.title && i.title}
                  </a>
                )}
                {i.items && this.getSubMenu(i.items, expanded === i.icon)}
              </li>
            ))}
          </ul>

          <ul
            id="secondary"
            className={classnames({hide: hidePrimary}, 'secondary-menu')}
          >
            {secondary.map((i, index) => (
              <li key={`secondary${index}`}>
                <a
                  href={i.href}
                  onClick={() => this.toggle(i.icon)}
                  target={i.target}
                >
                  <i className={`fa ${i.icon}`} />
                  {i.src ? <img src={i.src} /> : <span>{i.title}</span>}
                </a>
                {i.items && this.getSubMenu(i.items, expanded === i.icon)}
              </li>
            ))}
          </ul>
          {smallMenu}
        </div>
      </header>
    );
  }

  /**
   * Returns a small, responsive, hamburger menu
   * which will be visible when the viewport is too small for the primary menu
   */
  getSmallMenu = (menuData, expanded, hidePrimary, subExpanded) => {
    const newResponsiveDropdown = menuData.primary.concat(
      menuData.secondary[0].items,
    );
    const secondary = menuData.secondary.slice(1);
    const items = newResponsiveDropdown;
    return (
      <ul
        id="responsive-secondary"
        className={classnames({hide: !hidePrimary}, 'secondary-menu')}
      >
        {secondary.map((i, index) => (
          <li key={`secondary${index}`}>
            <a
              href={i.href}
              onClick={() => this.toggle(i.icon)}
              target={i.target}
            >
              <i className={`fa ${i.icon}`} />
              <span>{i.title}</span>
            </a>
            {i.items && this.getSubMenu(i.items, expanded === i.icon)}
          </li>
        ))}
        <li className="responsive-nav">
          <a onClick={() => this.toggle('nested-primary')}>
            <i className="fa fa-bars" />
          </a>
          {items &&
            this.getSubMenu(
              items,
              expanded === 'nested-primary',
              'ecm-dropdown nested-primary',
              subExpanded,
            )}
        </li>
      </ul>
    );
  };
}

export default withStyles(styles)(Menu);
