import React from 'react';
import {Grid} from '@mui/material';
import {PropTypes} from 'prop-types';

const DashboardComponent = ({children, ...rest}) => {
  return (
    <Grid
      xs={12}
      item
      // nested grid container to keep dashboard sections separated from each other on the responsive page layout
      height="min-content"
      {...rest}
    >
      <Grid
        container
        columnSpacing={{xs: 0.5, sm: 2, md: 2, lg: 3, xl: 3}}
        rowSpacing={{xs: 1, sm: 2, md: 2, lg: 3, xl: 3}}
        marginBottom={(theme) => theme.spacing(2)}
      >
        {children}
      </Grid>
    </Grid>
  );
};

DashboardComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default DashboardComponent;
