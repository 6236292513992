import React, {useEffect, useState, useRef, useCallback} from 'react';
import {DateTime} from 'luxon';

import {Box, Typography} from '@mui/material';
import LoadingScreen from '../../../shared/components/loadingScreen';
import LoadingComponent from '../../../shared/components/loadingComponent';
import GlobalErrorPage from '../../globalErrorPage';

import MobileGridSearch from './MobileGridSearch';
import MobileGridFilter from './MobileGridFilter';
import MobileGridSort from './MobileGridSort';
import MobileSiteCard from './MobileSiteCard';
import {getHardwareHealth} from '../../../api/hardwareHealth';

const formatDateTime = (time, zone) => {
  const luxonDateTime = DateTime.fromISO(time).setZone(zone);
  if (!luxonDateTime.isValid) {
    return null;
  }
  const absoluteDate = luxonDateTime.toFormat('DDD');
  return [absoluteDate];
};

const validationSchema = {
  status: ['online', 'offline', 'impaired'],
  cameraStatus: ['online', 'offline'],
  caStatus: ['error', 'warning', 'good'],
  bitrateQuality: ['offline', 'poor', 'good', 'excellent'],
  impairedCameras: ['impaired', 'unimpaired'],
};

const validateUrlState = (urlState) => {
  const validEntries = {};
  Object.keys(urlState).forEach((key) => {
    if (validationSchema[key]) {
      const statuses = urlState[key]
        ? urlState[key].split(',').map((string) => string.trim())
        : [];
      const validStatuses = statuses.filter((status) => {
        return validationSchema[key].includes(status);
      });
      validEntries[key] = validStatuses.join(',');
    }
  });
  return validEntries;
};

const MobileGrid = (props) => {
  const {logger, setUrlState, urlState, caStatusAllowed} = props;
  // Hard coded to display 10 cards at a time
  const [globalError, setGlobalError] = useState(false);
  const [cards, setCards] = useState();
  const [searchParam, setSearchParam] = useState('');
  const [sortValue, setSortValue] = useState();
  const [initialSort, setInitialSort] = useState({
    sortingOrder: null,
    sortingColumn: null,
  });
  const [filterValues, setFilterValues] = useState(validateUrlState(urlState));
  const [loading, setLoading] = useState(false);
  const [fetchingMoreCards, setFetchingMoreCards] = useState(false);
  const [siteCount, setSiteCount] = useState();
  const pageCount = useRef(1);
  const observer = useRef();

  const handleSortChange = (sort) => {
    setInitialSort(sort);
    const order = sort.sortingOrder === 'Ascending' ? 'asc' : 'desc';
    const column = sort.sortingColumn;
    const columnMap = {
      'Site Name': 'name',
      'Appliance Status': 'status',
      'Camera Status': 'offline_cameras',
      'Cloud Archive Status': 'ca_status',
      'Upload Speed': 'bitrate_quality',
      'Image Health': 'impaired_cameras',
    };
    setSortValue({order, column: columnMap[column]});
  };

  const handleFilterChange = (filters) => {
    const formatFilters = Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        key,
        value.join(',').toLowerCase(),
      ]),
    );
    setUrlState(formatFilters);
    setFilterValues(validateUrlState(formatFilters));
  };

  // This callback loads the next "page" of results using an observer
  // to watch for the last card to enter view
  const lastCardElementRef = useCallback(
    (node) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      const totalPages = Math.floor((siteCount - 1) / 10);
      const anyAdditionalPages = totalPages >= pageCount.current;
      observer.current = new IntersectionObserver((entries) => {
        if (
          !fetchingMoreCards &&
          anyAdditionalPages &&
          entries[0].isIntersecting
        ) {
          const fetchAdditionalCards = async () => {
            setFetchingMoreCards(true);
            try {
              const data = await getHardwareHealth(
                pageCount.current,
                10,
                sortValue?.column,
                sortValue?.order,
                searchParam,
                filterValues?.status,
                filterValues?.bitrateQuality,
                filterValues?.cameraStatus,
                filterValues?.caStatus,
                filterValues?.impairedCameras,
              );
              if (data.results) {
                const formattedRows = [];
                data.results.forEach((site) => {
                  formattedRows.push({
                    ...site,
                    earliestVideo: formatDateTime(
                      site.earliestVideo,
                      site.timezoneName,
                    ),
                  });
                });
                pageCount.current += 1;
                setCards((prevCards) => [...prevCards, ...formattedRows]);
              }
            } catch (error) {
              logger.error('Failed to load system health data', {}, error);
            }
            setFetchingMoreCards(false);
          };
          fetchAdditionalCards();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [
      siteCount,
      fetchingMoreCards,
      sortValue,
      searchParam,
      filterValues,
      logger,
    ],
  );

  useEffect(
    () => {
      const fetchInitialCards = async () => {
        setLoading(true);
        try {
          const data = await getHardwareHealth(
            0,
            10,
            sortValue?.column,
            sortValue?.order,
            searchParam,
            filterValues?.status,
            filterValues?.bitrateQuality,
            filterValues?.cameraStatus,
            filterValues?.caStatus,
            filterValues?.impairedCameras,
          );
          if (data.results) {
            setSiteCount(data.count);
            const formattedRows = [];
            data.results.forEach((site) => {
              formattedRows.push({
                ...site,
                earliestVideo: formatDateTime(
                  site.earliestVideo,
                  site.timezoneName,
                ),
              });
            });
            setUrlState(filterValues);
            setCards(formattedRows);
            setLoading(false);
          }
        } catch (error) {
          logger.error('Failed to load system health data', {}, error);
          setGlobalError(true);
          setLoading(false);
        }
      };
      fetchInitialCards();
      pageCount.current = 1;
      // This is precautionary to prevent the lazy loading of additional
      // cards on the fetching of initial cards.
      if (observer.current) {
        observer.current.disconnect();
      }
    },
    [filterValues, logger, searchParam, setUrlState, sortValue],
  );

  if (globalError) {
    return <GlobalErrorPage />;
  }

  return (
    <React.Fragment>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box
          className="top-nav"
          sx={{
            width: '88vw',
            position: 'sticky',
            top: 0,
            bgcolor: '#fafafa',
            zIndex: 10,
            py: 1,
          }}
        >
          <MobileGridSearch
            className="main-search-field"
            searchParam={searchParam}
            setSearchParam={setSearchParam}
          />
          <Box sx={{display: 'flex', justifyContent: 'end'}}>
            <MobileGridSort
              caStatusAllowed={caStatusAllowed}
              initialSort={initialSort}
              onSortChange={handleSortChange}
            />
            <MobileGridFilter
              caStatusAllowed={caStatusAllowed}
              filterValues={filterValues}
              onFilterChange={handleFilterChange}
            />
          </Box>
        </Box>
        {siteCount && (
          <Typography className="main-site-count">
            {siteCount > 1 ? `${siteCount} Sites` : `${siteCount} Site`}
          </Typography>
        )}
        {loading ? (
          <LoadingScreen />
        ) : (
          <Box>
            {siteCount > 0 ? (
              cards?.map((site, idx) => {
                if (cards.length === idx + 1) {
                  return (
                    <div key={site.siteId} ref={lastCardElementRef}>
                      <MobileSiteCard data={{...site, ...props}} />
                    </div>
                  );
                }
                return (
                  <MobileSiteCard
                    key={site.siteId}
                    data={{...site, ...props}}
                    className="main-card"
                  />
                );
              })
            ) : (
              <Typography variant="h4" sx={{mt: 10, textAlign: 'center'}}>
                No results found.
              </Typography>
            )}
            {fetchingMoreCards && <LoadingComponent />}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default MobileGrid;
