import axios from 'axios';

// Only init GA once
let initialized = false;

export default axios.interceptors.response.use((response) => {
  const ignoredHosts = [/.*local\.envysion\.com/i];

  const isLowerEnv = ignoredHosts.filter((h) => h.test(window.location.href));

  const {request, data} = response;

  if (
    !isLowerEnv.length &&
    !initialized &&
    window.gtag &&
    request &&
    data &&
    !window.Cypress
  ) {
    const url = request.url || request.responseURL;
    if (/api\/v3\/users\/me\/extensive/i.test(url)) {
      const env = window.location.host.split('.')[0];

      const userId = `${data.username}@${data.domain_name}`;

      window.gtag('set', 'user_properties', {
        userId,
        domain: data.domain_name,
        role: data.role_name,
        silo: env,
        persona: data.persona_name,
      });
      window.gtag('config', 'G-SJ3LKMN7KB', {
        user_id: userId,
      });

      initialized = true;
    }
  }
  return response;
});
