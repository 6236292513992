import React from 'react';

import GlobalErrorPage from '../globalErrorPage';
import PageLoader from '../../shared/components/pageLoader';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {getOrderStatus} from '../../api/orderStatus';
import {ENVR_ADMIN} from '../../shared/util/allowed';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';

function OrderStatus(props) {
  if (props.currentUser.settings.enableWorkOrderStatus) {
    return (
      <CapabilitiesGuard
        allowed={[ENVR_ADMIN]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <PageLoader
          page={() => import('./List')}
          resources={{
            orders: getOrderStatus,
          }}
          {...props}
        />
      </CapabilitiesGuard>
    );
  }
  return <GlobalErrorPage />;
}

export default withCurrentUser(OrderStatus);
