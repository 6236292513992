import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Helmet} from 'react-helmet';
import {Drawer} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PageHeader from '../pageHeader';

const styles = (theme) => {
  const height = `calc(100vh - ${theme.mixins.toolbar.height * 2}px)`;

  return {
    root: {
      padding: theme.spacing(3),
      ...theme.mixins.scrollable(),
    },
    rootWithDrawer: {
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden',
      padding: 'unset',
      position: 'relative',
      width: '100%',
      zIndex: 1,
    },
    content: {},
    drawerPaper: {
      ...theme.mixins.drawer,
      height,
    },
    drawer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    drawerOpen: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerCollapse: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    contentWithDrawer: {
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      height,
      overflow: 'auto',
      padding: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0),
      },
    },
  };
};

class PageTemplate extends Component {
  state = {
    drawerCollapsed: window.innerWidth < this.props.theme.breakpoints.values.sm,
    actions: this.props.actions,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  collapseDrawer = () => {
    this.setState({drawerCollapsed: true});
  };

  expandDrawer = () => {
    this.setState({drawerCollapsed: false});
  };

  setActions = (actions) => {
    this.setState({actions});
  };

  handleResize = () =>
    this.setState({
      drawerCollapsed:
        window.innerWidth < this.props.theme.breakpoints.values.sm,
    });

  render() {
    const {drawerCollapsed, actions} = this.state;

    const {
      category,
      children,
      classes,
      className,
      drawerContent,
      drawerId,
      PageHeaderProps,
      showHeader,
      title,
      withDrawer,
    } = this.props;

    const pageTitle = `${category ? `${category} | ` : ''}${title} | Envysion`;

    const drawer =
      typeof drawerContent === 'function'
        ? drawerContent(drawerCollapsed)
        : drawerContent;

    return (
      <Fragment>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {showHeader && <PageHeader {...PageHeaderProps} actions={actions} />}
        <section
          className={classNames(
            classes.root,
            {
              [classes.rootWithDrawer]: withDrawer,
            },
            className,
          )}
        >
          {withDrawer && (
            <Drawer
              id={drawerId}
              variant="permanent"
              className={classNames(classes.drawer, {
                [classes.drawerCollapse]: drawerCollapsed,
                [classes.drawerOpen]: !drawerCollapsed,
              })}
              classes={{paper: classes.drawerPaper}}
            >
              <div className={classes.drawer}>{drawer}</div>
            </Drawer>
          )}
          <div
            className={classNames(classes.content, {
              [classes.contentWithDrawer]: withDrawer,
            })}
          >
            {typeof children === 'function'
              ? children(
                  this.collapseDrawer,
                  this.expandDrawer,
                  this.setActions,
                )
              : children}
          </div>
        </section>
      </Fragment>
    );
  }
}

PageTemplate.propTypes = {
  actions: PropTypes.node,
  category: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  className: PropTypes.string,
  drawerContent: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  drawerId: PropTypes.string,
  PageHeaderProps: PropTypes.shape({}),
  showHeader: PropTypes.bool,
  title: PropTypes.string.isRequired,
  withDrawer: PropTypes.bool,
};

PageTemplate.defaultProps = {
  actions: null,
  category: undefined,
  className: undefined,
  drawerContent: null,
  drawerId: '',
  PageHeaderProps: {},
  showHeader: true,
  withDrawer: false,
};

export default withStyles(styles, {withTheme: true})(PageTemplate);
