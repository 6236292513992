import _ from 'lodash';

export const sortByNullableField = (
  data,
  field,
  sortDescending = false,
  inNormalizeSortValue,
) => {
  const normalizeSortValue =
    inNormalizeSortValue ||
    ((val) => (val && val.toLowerCase ? val.toLowerCase() : val));

  const fieldSort = (a, b) => {
    const compA = normalizeSortValue(_.get(a, field));
    const compB = normalizeSortValue(_.get(b, field));
    if (sortDescending)
      return !!compB - !!compA || -(compA > compB) || +(compA < compB);
    return !!compA - !!compB || +(compA > compB) || -(compA < compB);
  };

  return data.sort(fieldSort);
};

export default {sortByNullableField};
