import PropTypes from 'prop-types';

export const componentPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
  PropTypes.object,
  PropTypes.node,
]);

export const lazyTypeFn = (f, _lazyCheckerHasSeen) => (...rest) => {
  if (_lazyCheckerHasSeen.indexOf(rest[0]) !== -1) {
    return true;
  }

  _lazyCheckerHasSeen.push(rest[0]);
  return f().apply(this, rest);
};

export const cameraType = {
  id: PropTypes.string.isRequired,
  applianceName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isOnline: PropTypes.bool,
  lastOnlineDatetime: PropTypes.string,
};

export const overrideComponent = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
  PropTypes.object,
]);

export const applianceInterfaceType = {
  interface: PropTypes.string.isRequired,
  current_status: PropTypes.shape({
    ipv4Address: PropTypes.string,
  }),
  configuration: PropTypes.shape({
    dhcpEnabled: PropTypes.bool.isRequired,
    ipv4Address: PropTypes.string,
    gatewayIpv4Address: PropTypes.string,
    netmask: PropTypes.string,
  }),
};

// More minimal appliance list scheme
export const applianceListType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const applianceType = {
  ...applianceListType,
  networkConfig: PropTypes.arrayOf(PropTypes.shape(applianceInterfaceType)),
};

export const siteType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  applianceIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
};

export const categoryType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  numSites: PropTypes.number.isRequired,
  parentId: PropTypes.string.isRequired,
});
