import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Alert,
} from '@mui/material';
import Spinner from '../spinner';

const ConfirmationDialog = ({
  children,
  title,
  confirmLabel,
  onClose,
  onConfirm,
  destructive,
  open,
  errorText,
  ...dialogProps
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleConfirm = async () => {
    setError(false);
    setLoading(true);

    try {
      await onConfirm();
      onClose();
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      name="confirmation-dialog"
      {...dialogProps}
    >
      <DialogTitle name="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {children}
        {error && (
          <Alert
            data-cy="confirmation-dialog-alert"
            sx={{marginTop: 1}}
            severity="error"
          >
            {errorText}
          </Alert>
        )}
      </DialogContent>
      {loading && <Spinner name="loading-spinner" color="primary" size={30} />}
      <DialogActions>
        <Button
          name="confirmation-dialog-button-confirm"
          onClick={handleConfirm}
          autoFocus
          variant="contained"
          color={destructive ? 'error' : 'primary'}
          disabled={loading}
        >
          {confirmLabel}
        </Button>
        <Button
          variant="text"
          name="confirmation-dialog-button-cancel"
          onClick={() => {
            onClose();
            setError(false);
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  confirmLabel: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  destructive: PropTypes.bool,
  errorText: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
  title: '',
  children: null,
  destructive: false,
  errorText: 'Something went wrong. Please try again.',
};

export default ConfirmationDialog;
