import axios from 'axios';

export const signin = async () => {
  await axios.post('/sso/login', {
    username: 'cypress@devsample.com',
    password: 'cypress',
  });
};

export const signout = async () => {
  await axios.get('/sso/logout');
};

export const changePasswordWithToken = async (
  newPassword,
  token,
  userIds = [],
) => axios.put(`/sso/tokens/${token}/password`, {newPassword, userIds});

export const validatePasswordResetToken = async (token) => {
  try {
    // multi returns an array of validations
    return (await axios.get(`/sso/tokens/${token}/validate/multi`)).data;
  } catch (e) {
    return undefined;
  }
};

export const getPasswordPolicy = async (userName, domainName) =>
  (await axios.get(`/sso/users/${userName}@${domainName}/passwordPolicy`)).data;

export const forgotPassword = async (usernameOrEmail, type = 'username') =>
  axios.post(`/sso/users/${usernameOrEmail}/forgotPassword?login_type=${type}`);

export const getLoginPolicy = async (userName) =>
  (await axios.get(`sso/users/${userName}/method`)).data;

export const login = async (username, password, params) =>
  (await axios.post(
    '/sso/login',
    {
      username,
      password,
    },
    {params},
  )).data;

export const getAuthConfiguration = async (domainId) =>
  (await axios.get(`sso/saml2/domain/${domainId}/configuration`)).data;

export const updateSamlConfiguration = async (domainId, config) =>
  (await axios.put(`sso/saml2/domain/${domainId}/configuration/saml2`, config))
    .data;

export const updateCloudConfiguration = async (domainId, config) =>
  (await axios.put(`sso/saml2/domain/${domainId}/configuration/cloud`, config))
    .data;

export const regenerateSPCertificates = async (domainId) =>
  axios.post(`sso/saml2/domain/${domainId}/configuration/saml2/regenerate`);
