// Handles Utils for the salesSummary widget

import {DateTime} from 'luxon';

export const formatData = (element) => {
  const date = DateTime.fromFormat(String(element.calendarId), 'yyyyMMddHH');

  return {
    ...element,
    name: date.toFormat('MMMM d'),
    id: element.calendarId,
    y: element.netAmt,
    url: element?.reportUrl?.reportUrl,
  };
};

export const formatDataDualAxis = (results, theme) => {
  return {
    type: 'spline',
    yAxis: 1,
    color: theme.palette.accent.main,
    data: results.map((element) => {
      const date = DateTime.fromFormat(
        String(element.calendarId),
        'yyyyMMddHH',
      );

      return {
        ...element,
        name: date.toFormat('MMMM d'),
        y: element.avgSaleAmt,
      };
    }),
  };
};

export const extraOptions = (theme) => {
  return {
    yAxis: [
      {
        title: {
          text: 'Total Sales',
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          formatter() {
            const {value} = this;

            if (value >= 1000000) {
              return `$${(value / 1000000).toFixed(1)}M`;
            }
            if (value >= 1000) {
              return `$${(value / 1000).toFixed(1)}k`;
            }
            return value;
          },
        },
      },
      {
        title: {
          text: 'Avg Dollar Per Transaction',
          style: {
            color: theme.palette.accent.dark,
          },
        },
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${value}',
          style: {
            color: theme.palette.accent.dark,
          },
        },
        opposite: true,
      },
    ],
  };
};

export const formatTooltip = (element, tipText) => {
  const {
    point: {
      options: {y, avgSaleAmt, count},
    },
    key,
  } = element;

  const avgAmount = parseFloat(avgSaleAmt.toFixed(2));
  const totalAmount = Number(parseFloat(y).toFixed(2));

  const withSales = `
      <div>
        <span>${key}</span><br>
        <span id="tooltip-dollars">
          Avg Sale: $${avgAmount}
        </span><br>
        <span id="tooltip-amount">${tipText} $${totalAmount}</span><br>
        <span id="tooltip-trans-count">Transaction Count: ${count}</span>
      </div>
      `;

  const withoutSales = `
      <div>
        <span>${key}</span><br>
        <span id="tooltip-dollars">
          Avg Sale: $${avgAmount}
        </span>
      </div>
      `;

  return avgAmount === totalAmount ? withoutSales : withSales;
};
