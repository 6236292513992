import React, {
  useReducer,
  useCallback,
  useState,
  useMemo,
  Fragment,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Grid,
  InputAdornment,
  Alert,
} from '@mui/material';
import {PropTypes} from 'prop-types';
import {MobileDatePicker, TimePicker} from '@mui/x-date-pickers';
import {CalendarTodayOutlined, AccessTimeOutlined} from '@mui/icons-material';
import {DateTime} from 'luxon';
import LabeledGroupBox from '../../../../shared/components/labeledGroupBox';
import EditTemplateInfoSection from './EditTemplateInfoSection';
import Spinner from '../../../../shared/components/spinner';
import {createAlarmSchedule, updateAlarmSchedule} from '../../../../api/alarms';
import {
  generateTemplateEntriesFromDateTimes,
  isSameDay,
  mergeDayAndTime,
} from './utils';

const textFieldProps = (maxLength) => ({
  required: true,
  fullWidth: true,
  type: 'text',
  variant: 'outlined',
  size: 'small',
  InputProps: {
    style: {color: 'black'},
  },
  inputProps: {
    maxLength,
  },
  InputLabelProps: {
    shrink: true,
  },
});
const dateTimePickersProps = (endIcon) => {
  return {
    sx: {
      backgroundColor: 'white',
    },
    slotProps: {
      textField: {
        variant: 'outlined',
        size: 'small',
        InputProps: {
          endAdornment: endIcon && (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ),
        },
      },
    },
  };
};
const dateDisplayFormat = 'MMM/dd/yyyy';
const minAllowedTimeSpan = 15; // minutes

const validateForm = (state) => {
  try {
    return (
      state.name.trim().length > 0 &&
      state.name.trim().length <= 128 &&
      state.description.trim().length > 0 &&
      state.description.trim().length <= 255 &&
      !state.errorResponse &&
      mergeDayAndTime(state.endDay, state.endTime) >=
        mergeDayAndTime(state.startDay, state.startTime).plus({
          minutes: minAllowedTimeSpan,
        })
    );
  } catch {
    return false;
  }
};

const formStateReducer = (currState, {type = '', name, value}) => {
  let state = currState;

  switch (type) {
    case 'SET': {
      state = {...currState, [name]: value};
      return {...state, errorResponse: false, isFormValid: validateForm(state)};
    }
    default: {
      break;
    }
  }

  return {...state, isFormValid: validateForm(state)};
};

const EditTemplateDialog = (props) => {
  const {open, handleClose, snackbar, onSaveSuccess, selectedSchedule} = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState();
  const [conflictData, setConflictData] = useState(null);

  const defaultDates = useMemo(
    () => ({
      start: DateTime.now().startOf('minute'),
      end: DateTime.now()
        .plus({days: 1})
        .startOf('minute'),
    }),
    [],
  );

  const [
    {name, description, startDay, startTime, endDay, endTime, isFormValid},
    dispatch,
  ] = useReducer(formStateReducer, {
    name: selectedSchedule?.name || '',
    description: selectedSchedule?.notes || '',
    startDay: selectedSchedule?.startDate || defaultDates.start,
    startTime: selectedSchedule?.startDate || defaultDates.start,
    endDay: selectedSchedule?.endDate || defaultDates.end,
    endTime: selectedSchedule?.endDate || defaultDates.end,
    errorResponse: false,
    isFormValid: false,
  });

  const handleSubmitForm = useCallback(
    async () => {
      try {
        setIsSubmitting(true);
        const prepareAndSaveSchedule = async () => {
          const prepareRequestData = () => {
            const entries = generateTemplateEntriesFromDateTimes(
              mergeDayAndTime(startDay, startTime),
              mergeDayAndTime(endDay, endTime),
            );

            return {
              name,
              notes: description,
              schedule: {
                once: entries,
              },
            };
          };

          const scheduleData = prepareRequestData();
          return selectedSchedule
            ? updateAlarmSchedule(selectedSchedule.id, scheduleData)
            : createAlarmSchedule(scheduleData);
        };

        await prepareAndSaveSchedule();
        snackbar.success(
          `Exception template has been successfully ${
            selectedSchedule ? 'updated' : 'saved'
          }`,
        );
        onSaveSuccess((prev) => !prev);
        handleClose();
      } catch (e) {
        dispatch({type: 'SET', name: 'errorResponse', value: true});
        setConflictData(e.response.data?.error?.conflictData);
        setError(e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      description,
      endDay,
      endTime,
      handleClose,
      name,
      onSaveSuccess,
      selectedSchedule,
      snackbar,
      startDay,
      startTime,
    ],
  );
  const startDayBeforeNow =
    startDay < DateTime.now() ? startDay : DateTime.now();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      data-cy="edit-template-dialog"
    >
      <DialogTitle>
        {selectedSchedule ? 'Edit' : 'Create'} Exception Template
      </DialogTitle>

      <DialogContent sx={{height: '100%', overflow: 'auto'}}>
        <Grid container spacing={{xs: 2}} sx={{pt: 0.5}}>
          <Grid item xs={12}>
            <TextField
              {...textFieldProps(128)}
              data-cy="template-name-field"
              disabled={isSubmitting}
              label="Name"
              placeholder="i.e. Christmas Override"
              value={name}
              onChange={(event) =>
                dispatch({
                  type: 'SET',
                  name: 'name',
                  value: event.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...textFieldProps(255)}
              data-cy="template-description-field"
              disabled={isSubmitting}
              label="Description"
              placeholder="i.e. Region or City Name"
              value={description}
              onChange={(event) =>
                dispatch({
                  type: 'SET',
                  name: 'description',
                  value: event.target.value,
                })
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LabeledGroupBox
              data-cy="template-start-group-box"
              label="Start*"
              disabled={isSubmitting}
            >
              <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                <Box
                  data-cy="template-start-day-picker"
                  sx={{display: 'contents'}}
                >
                  <MobileDatePicker
                    {...dateTimePickersProps(<CalendarTodayOutlined />)}
                    id="start-day-picker"
                    disablePast={!selectedSchedule}
                    minDate={startDayBeforeNow}
                    disabled={isSubmitting}
                    format={dateDisplayFormat}
                    value={startDay}
                    onAccept={(day) =>
                      dispatch({type: 'SET', name: 'startDay', value: day})
                    }
                    DialogProps={{unmountOnExit: true}}
                  />
                </Box>

                <Box
                  data-cy="template-start-time-picker"
                  sx={{display: 'contents'}}
                >
                  <TimePicker
                    {...dateTimePickersProps(<AccessTimeOutlined />)}
                    id="start-time-picker"
                    disabled={isSubmitting}
                    value={startTime}
                    onChange={(time) =>
                      dispatch({type: 'SET', name: 'startTime', value: time})
                    }
                  />
                </Box>
              </Box>
            </LabeledGroupBox>
          </Grid>

          <Grid item xs={12} md={6}>
            <LabeledGroupBox
              data-cy="template-end-group-box"
              label="End*"
              disabled={isSubmitting}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Box
                  data-cy="template-end-day-picker"
                  sx={{display: 'contents'}}
                >
                  <MobileDatePicker
                    {...dateTimePickersProps(<CalendarTodayOutlined />)}
                    id="end-day-picker"
                    disablePast
                    disabled={isSubmitting}
                    format={dateDisplayFormat}
                    minDate={startDay}
                    value={endDay}
                    onAccept={(day) =>
                      dispatch({type: 'SET', name: 'endDay', value: day})
                    }
                  />
                </Box>

                <Box
                  data-cy="template-end-time-picker"
                  sx={{display: 'contents'}}
                >
                  <TimePicker
                    {...dateTimePickersProps(<AccessTimeOutlined />)}
                    id="end-time-picker"
                    disabled={isSubmitting}
                    minTime={
                      isSameDay(startDay, endDay)
                        ? startTime.plus({minutes: minAllowedTimeSpan})
                        : undefined
                    }
                    value={endTime}
                    onChange={(time) =>
                      dispatch({type: 'SET', name: 'endTime', value: time})
                    }
                  />
                </Box>
              </Box>
            </LabeledGroupBox>
          </Grid>

          <Grid item xs={12}>
            {!isSubmitting && error ? (
              <Fragment>
                {conflictData ? (
                  <EditTemplateInfoSection conflicts={conflictData} />
                ) : (
                  <Alert data-cy="template-save-error-alert" severity="error">
                    Template saving failed. Please try again.
                  </Alert>
                )}
              </Fragment>
            ) : (
              <EditTemplateInfoSection />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{p: 2, px: 2}}>
        <Button
          variant="text"
          autoFocus
          disabled={isSubmitting}
          onClick={handleClose}
          data-cy="cancel-template-dialog-button"
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          autoFocus
          disabled={!isFormValid || isSubmitting}
          onClick={handleSubmitForm}
          data-cy="save-template-dialog-button"
        >
          SAVE
          {isSubmitting && <Spinner style={{marginLeft: '10px'}} size={15} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  snackbar: PropTypes.shape({}).isRequired,
  selectedSchedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    notes: PropTypes.string,
    startDate: PropTypes.shape({}),
    endDate: PropTypes.shape({}),
  }),
  onSaveSuccess: PropTypes.func.isRequired,
};

EditTemplateDialog.defaultProps = {
  selectedSchedule: null,
};

export default EditTemplateDialog;
