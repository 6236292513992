import React from 'react';
import compose from 'lodash/flowRight';
import {useTheme} from '@mui/material';
import {DateTime} from 'luxon';
import HighChartsWidget from '../../../../shared/components/highcharts';
import {getSiteByDayDataRemoved} from '../../../../api/reports';
import * as removedItems from './widgetUtils/removedItemsUtils';
import {withCurrentUser} from '../../../../shared/contexts/currentUserContext';
import DashboardWidget from '../../../../shared/components/dashboardComponent/dashboardWidget';

const RemovedItemsWidget = ({currentUser, widgetConfig}) => {
  const currentUserDate = DateTime.local()
    .toUTC()
    .toFormat('yyyyMMdd23');
  const reportLookBackDays = currentUser.settings.reportsDefaultDay || 1;
  const theme = useTheme();

  const getContent = () => {
    return (
      <HighChartsWidget
        id="highcharts-widget-removed"
        getData={getSiteByDayDataRemoved}
        reportLookBackDays={reportLookBackDays}
        currentUserDate={currentUserDate}
        getDrilldownData={getSiteByDayDataRemoved}
        formatData={removedItems.formatData}
        tooltipProvider={removedItems.formatTooltip}
        dualAxes={false}
        graphSettings={{
          chartType: 'column',
          seriesName: 'Voids',
          title:
            widgetConfig.name ||
            'Removed Item Dollars as a Percentage of Sales',
          subtitle:
            widgetConfig.description ||
            'Removed items dollars as a percentage of sales over the last seven days',
          yAxisTitle: 'Sales %',
          plotLinesLabel: 'avg removed items % sale',
          tipText: 'Removed Dollars:',
          columnColor: theme.palette.primary.dark,
          hoverColor: theme.palette.primary.main,
          widgetName: 'removed-items-widget',
        }}
        extraOptions={removedItems.extraOptions}
        currentUser={currentUser}
      />
    );
  };

  return (
    <DashboardWidget
      id={widgetConfig.widgetReference}
      title={widgetConfig.title}
      content={getContent()}
      {...widgetConfig.properties}
    />
  );
};

export default compose(withCurrentUser)(RemovedItemsWidget);
