import axios from 'axios';
import {Interval, DateTime} from 'luxon';

export const instance = axios.create();

export const getClips = async (folderId = 'root', foldersOnly = false) =>
  (await axios.get(
    `/clips_folders2/${folderId}/contents?foldersOnly=${foldersOnly}`,
  )).data;

export const searchClips = async (searchString, limit = 100, order = 'asc') =>
  (await axios.get(
    `/clips_folders2?q=${searchString}&limit=${limit}&order=${order}`,
  )).data;

export const getBreadcrumb = async (folderId = 'root') =>
  (await axios.get(`/clips_folders2/${folderId}/breadcrumb`)).data;

export const create = async (requestBody) => {
  const newClip = requestBody;

  const duration = Interval.fromDateTimes(
    DateTime.fromISO(newClip.startTime),
    DateTime.fromISO(newClip.endTime),
  )
    .toDuration()
    .as('seconds');

  newClip.sensorId = requestBody.sensorId.map((sensor) =>
    sensor === 'null' ? null : sensor,
  );

  let res;
  if (duration > 3600) {
    res = await axios.post(`/clips2/batch`, newClip);
  } else {
    res = await axios.post(`/clips2`, newClip);
  }

  const {data} = res;

  return data;
};

export const shareGroups = async (uuid, groupIds, noEmail = false) => {
  instance.interceptors.request.eject(
    instance.interceptorIds?.requestCasingInterceptor,
  );
  const {data} = await instance.post(
    `/clips2/${uuid}/shares/groups`,
    {groups: groupIds},
    {
      params: {noEmail},
    },
  );
  return data;
};

export const shareUsers = async (uuid, userIds, noEmail = false) => {
  instance.interceptors.request.eject(
    instance.interceptorIds?.requestCasingInterceptor,
  );
  const {data} = await instance.post(
    `/clips2/${uuid}/shares/users`,
    {users: userIds},
    {
      params: {noEmail},
    },
  );
  return data;
};

export const restoreArchivedFootage = async (siteId, body) => {
  const {data} = await axios.post(`/cloud_archive/site/${siteId}/clip`, body);
  return data;
};

export const getQueuedClips = async (siteId, lookbackDays = 7) => {
  const {data} = await axios.get(
    `/clips2/queue?lookbackCreated=${lookbackDays}&ignoreShares=true&site=${siteId}`,
  );
  return data;
};

export const cancelExport = async (uuid) => {
  const {data} = await axios.delete(`/clips2/${uuid}/cancel`);
  return data;
};

export const retryExport = async (uuid) => {
  const {data} = await axios.post(`/clips2/${uuid}/retry`);
  return data;
};

export default {create, shareGroups, shareUsers, getQueuedClips};
