import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import {TextField} from '@mui/material';
import toString from 'lodash/toString';
import get from 'lodash/get';
import LabelWithTooltip from '../labelWithTooltip';

const FormTextField = ({fieldDef, formProps, classes, focused}) => {
  const {
    name,
    label,
    multiline,
    helperText,
    tooltip,
    additional,
    onChange,
    ComponentProps = {},
    validation,
    format,
  } = fieldDef;

  const {handleChange, errors, setFieldTouched, touched, values} = formProps;
  const required = get(validation, '_exclusive.required', false);

  let suffix;
  let {shrink} = ComponentProps;
  if (additional) {
    suffix = additional;
  } else {
    suffix = '';
  }
  if (tooltip) {
    shrink = true;
  }

  return (
    <TextField
      name={name}
      label={
        tooltip ? <LabelWithTooltip label={label} tooltip={tooltip} /> : label
      }
      defaultValue={
        typeof format === 'function'
          ? format(values[name])
          : toString(values[name])
      }
      helperText={
        ((touched && touched[name]) || touched === undefined) && errors[name]
          ? errors[name]
          : helperText
      }
      onChange={(value) => {
        if (typeof setFieldTouched === 'function') {
          setFieldTouched(name);
        }
        handleChange(value);
        if (typeof onChange === 'function') {
          onChange(value, name, formProps);
        }
      }}
      className={classes.formField}
      required={required}
      error={
        ((touched && touched[name]) || touched === undefined) &&
        Boolean(errors[name])
      }
      disabled={fieldDef.disabled}
      autoFocus={focused}
      multiline={multiline}
      InputLabelProps={{
        shrink,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment className={classes.inputAdornment} position="end">
            {suffix}
          </InputAdornment>
        ),
      }}
      {...ComponentProps}
    />
  );
};

export default FormTextField;
