import {DateTime} from 'luxon';
import Url from 'url-parse';
import {coerce, lt} from 'semver';
import {isMobile} from './browser';
import {getCookie} from './storage';

export const isMobileApp = () =>
  getCookie('is_mobile_app') === 'true' || !!getCookie('mobile_app_version');

export const checkMinMobileVersion = (minVersion) => {
  const mobileApp = getCookie('is_mobile_app') === 'true';
  const version = getCookie('mobile_app_version');

  if (mobileApp && !version) {
    return !lt('3.32.9', minVersion);
  }
  if (version) {
    return !lt(coerce(version), minVersion);
  }
  return false;
};

export const checkPlatformSupport = ({supportsDesktop, minMobileVersion}) => {
  if (!isMobileApp()) {
    return supportsDesktop;
  }
  return checkMinMobileVersion(minMobileVersion);
};

export const hideGlobalMenu = () =>
  /\/auth\/login/.test(window.location.href) ||
  /\/ui\/reset-password*/.test(window.location.href) ||
  /\/forgot-password*/.test(window.location.href) ||
  /\/ui\/live-monitor\/view*/.test(window.location.href) ||
  /\/partner-embed*/.test(window.location.href) ||
  isMobileApp();

export const hideFooter = () =>
  /\/partner-embed*/.test(window.location.href) ||
  /\/ui\/live-monitor\/view*/.test(window.location.href) ||
  !hideGlobalMenu() ||
  isMobile ||
  isMobileApp();

export const appPaths = ['#/partnerEmbed', '^#/reports', '/external-clips'];

export const getBuildInfo = () => {
  return {
    branch: import.meta.env.BRANCH ?? 'local',
    version: import.meta.env.VERSION ?? 'mine',
    date: import.meta.env.DATE ?? null,
  };
};

export const getHostEnv = () => {
  const prodNames = [
    'insight',
    'video',
    'admin',
    'partner',
    'audits',
    'beta',
    'share',
    'dr316fhxca0k',
    'prod',
  ];
  const hostEnv = window.location.host.match(
    new RegExp(`(${prodNames.join('|')}|dev|stage|uat|test)`, 'i'),
  );
  if (!hostEnv) return 'unknown';
  return hostEnv && prodNames.indexOf(hostEnv[0]) !== -1
    ? 'prod'
    : hostEnv[0].toLowerCase();
};

export const getEnvironmentName = (branch) => {
  if (branch === 'HEAD' || branch === 'heads') {
    return getHostEnv();
  }
  return branch.toLowerCase();
};

export const getBuildDisplay = () => {
  const buildInfo = getBuildInfo();
  return `${buildInfo.branch}/${buildInfo.version} built on ${
    buildInfo.date
      ? DateTime.fromMillis(parseInt(buildInfo.date, 10) * 1000)
          .toISO()
          .split('.')[0]
      : ''
  }`;
};

export const isEnvysionRoute = (target) => {
  const parsed = new Url(target);
  return (
    target &&
    parsed.hostname &&
    parsed.protocol === 'https:' &&
    new RegExp(/^.*envysion\.com$/).test(parsed.hostname)
  );
};

export const isEnvysionAppPath = (target) =>
  target && appPaths.some((fds) => new RegExp(fds).test(target));

// paths of pages that hide appBar when implemented in mobile app
const appBarExcludedPaths = ['ssp', 'hardware-health'];

export const toolbarMultiplier = (pathname, title) => {
  if (isMobileApp()) {
    // for pages where we hide nav bar -  deduct 1 toolbarHeight, if we additionally hide appBar - 0
    return appBarExcludedPaths.some((path) => pathname.includes(path)) ? 0 : 1;
  }
  if (!title) {
    return 1;
  }
  // when not inside mobile app - deduct 2 * toolbarHeight (navBar and appBar)
  return 2;
};
