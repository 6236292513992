import querystring from 'querystring';
import React from 'react';

import PageLoader from '../../shared/components/pageLoader';
import {validatePasswordResetToken, getPasswordPolicy} from '../../api/auth';

const Loader = ({location}) => {
  const query = querystring.parse(location.search.substr(1));
  const {t: token, expired} = query;

  return (
    <PageLoader
      page={() => import('./Page')}
      resources={{
        tokenAndPolicy: async () => {
          const validTokens = await validatePasswordResetToken(token);
          if (validTokens) {
            const passwordPolicy = await getPasswordPolicy(
              validTokens[0].userName,
              validTokens[0].domainName,
            );
            return {validTokens, passwordPolicy};
          }
          return {};
        },
      }}
      loadedToPageProps={(loaded) => ({
        token,
        expired,
        ...loaded.tokenAndPolicy,
      })}
    />
  );
};

export default Loader;
