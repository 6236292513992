import makeStyles from '@mui/styles/makeStyles';
import {Checkbox} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ApiCheckbox = (props) => {
  const classes = useStyles();

  const {
    controlChecked,
    promoteChecked,
    apiPromise,
    checkedColor,
    icon,
    checkedIcon,
    disabledIcon,
    auxCallback,
    confirmationCallback,
    isDisabled,
    onSuccess,
    onError,
    displayError,
    ...checkboxProps
  } = props;

  let color;
  let pending;

  const [isChecked, setChecked] = React.useState(controlChecked);
  const [state, changeState] = React.useState('IDLE'); // IDLE, PENDING, RESOLVED, ERROR

  React.useEffect(
    () => {
      setChecked(controlChecked);
    },
    [controlChecked],
  );

  React.useEffect(
    () => {
      if (promoteChecked) setChecked(true); // set to true when promotion bit is set
    },
    [promoteChecked],
  );

  const handleChange = (stateAction) => {
    changeState('PENDING');
    apiPromise(stateAction)
      .then(() => {
        changeState('RESOLVED');

        setChecked(stateAction); // update the 'checked' value when promise is resolved
        auxCallback(stateAction);
        onSuccess();
      })
      .catch(() => {
        onError();
        changeState('ERROR');
      });
  };

  const handler = (event) => {
    const stateAction = event.target.checked;
    if (typeof confirmationCallback === 'function') {
      confirmationCallback(() => handleChange(stateAction));
    } else {
      handleChange(stateAction);
    }
  };

  // state conditions
  if (state === 'ERROR') color = 'default';
  else color = checkedColor;

  if (state === 'PENDING') pending = true;
  else pending = false;

  return (
    <div className={classes.flexContainer}>
      <Checkbox
        onChange={handler}
        color={color}
        disabled={pending || isDisabled}
        checked={pending ? !isChecked : isChecked} // proper state is re-applied when API promise resolves
        icon={pending ? disabledIcon : icon}
        checkedIcon={pending ? disabledIcon : checkedIcon}
        inputProps={{'aria-label': 'checkbox'}}
        {...checkboxProps}
      />
      {state === 'ERROR' && displayError && (
        <ErrorOutlineOutlinedIcon color="error" />
      )}
    </div>
  );
};

ApiCheckbox.defaultProps = {
  controlChecked: false,
  promoteChecked: false,
  checkedColor: 'primary',
  auxCallback: () => {},
  isDisabled: false,
  displayError: false,
  onError: () => {},
  onSuccess: () => {},
};

ApiCheckbox.propTypes = {
  apiPromise: PropTypes.func.isRequired,
  controlChecked: PropTypes.bool,
  promoteChecked: PropTypes.bool,
  checkedColor: PropTypes.string,
  auxCallback: PropTypes.func,
  isDisabled: PropTypes.bool,
  displayError: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default ApiCheckbox;
