import React from 'react';
import {Box} from '@mui/material';

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
