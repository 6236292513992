import React from 'react';

import {MY_CLIPS} from '../../shared/util/allowed';
import CapabilitiesGuard from '../../shared/components/capabilitiesGuard';
import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import ClipsPageResponsive from './ClipsPageResponsive';

const Clips = (props) => {
  if (props.currentUser.settings.newNavigationWeb) {
    return (
      <CapabilitiesGuard
        allowed={[MY_CLIPS]}
        renderDefault={() => <GlobalErrorPage error="NoAuth" />}
      >
        <ClipsPageResponsive />
      </CapabilitiesGuard>
    );
  }
  return <GlobalErrorPage />;
};

export default withCurrentUser(Clips);
