import React from 'react';
import {DateTimePicker as MuiDateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';

const DateTimePicker = (props) => {
  const {onChange, initialValue, timezone, ...forwardProps} = props;
  const {id, name, helperText, error, actionBar = ['accept']} = forwardProps;

  let inputDateTime;
  if (initialValue) {
    inputDateTime =
      initialValue instanceof DateTime
        ? initialValue
        : DateTime.fromISO(initialValue, {zone: timezone});
  }

  const slotProps = {
    field: {
      id,
      name,
    },
    textField: {
      helperText,
      error,
    },
    actionBar: {
      actions: actionBar,
    },
  };

  return (
    <MuiDateTimePicker
      {...forwardProps}
      slotProps={slotProps}
      value={inputDateTime}
      onChange={onChange}
      timeSteps={{hours: 1, minutes: 1, seconds: 1}}
      className={`${name}-field`}
    />
  );
};

DateTimePicker.propTypes = {
  /**
   * Initial value for the date
   */
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(DateTime),
  ]),
  /**
   * Format for the date and time
   */
  format: PropTypes.string,
  /**
   * Error message when given incorrect format
   */
  invalidDateMessage: PropTypes.string,
  /**
   * Action when change occurs
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Timezone
   */
  timezone: PropTypes.string,
};

DateTimePicker.defaultProps = {
  initialValue: null,
  format: 'MM/dd/yyyy hh:mm a',
  invalidDateMessage:
    "Please enter date in the format: 'MM/DD/YYYY HH:MM AM/PM'",
  timezone: undefined,
};

export default DateTimePicker;
