import React from 'react';

import PageLoader from '../../../shared/components/pageLoader';
import {getCookie, setLocalStorageItem} from '../../../shared/util/storage';
import {getLoginMessage} from '../../../api/loginMessage';
import {getLoginPolicy} from '../../../api/auth';

const Loader = () => {
  const storedUsername = getCookie('username');
  setLocalStorageItem('dismissBandwidthBanner', null);

  return (
    <PageLoader
      page={() => import('./Page')}
      resources={{
        loginMessage: getLoginMessage,
        loginPolicy: async () => {
          if (storedUsername) {
            const loginPolicy = await getLoginPolicy(storedUsername);
            const {method, interactiveUrl} = loginPolicy;
            if (method === 'saml2') {
              window.location = `${interactiveUrl}${window.location.search.replace(
                'target',
                'r',
              )}`;
            }
            return Promise.resolve(loginPolicy);
          }
          return Promise.resolve({method: 'local'});
        },
      }}
      loadedToPageProps={(props) => ({
        username: storedUsername || '',
        loginMethod: props.loginPolicy.method,
        interactiveUrl: props.loginPolicy.interactiveUrl,
        ...props,
      })}
    />
  );
};

export default Loader;
