export const filterReducer = (state, {type = '', value = ''}) => {
  switch (type) {
    case 'UPDATE_SEARCH': {
      const isValid = value.length > 2;
      return isValid
        ? {...state, searchValue: value, isSearchValueValid: true}
        : {...state, isSearchValueValid: false};
    }
    case 'RESET_SEARCH':
      return {
        ...state,
        searchValue: '',
        isSearchValueValid: true,
      };
    case 'UPDATE_ALARM_MODES_FILTER':
      return {
        ...state,
        alarmModesFilter: value,
      };
    default:
      return state;
  }
};

export const handleFilterChange = (dispatch, value) => {
  if (value.length) {
    dispatch({type: 'UPDATE_SEARCH', value});
  } else {
    dispatch({type: 'RESET_SEARCH'});
  }
};
