import React from 'react';

import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import {hasRequiredCapabilityWithMatchingPreference} from '../../shared/components/navigationBar/utils';
import Dashboard from '../dashboard';
import Sites from '../sites';
import HardwareHealth from '../hardwareHealth';
import LiveMonitor from '../liveMonitor';
import {LIVE_VIDEO, MY_CLIPS} from '../../shared/util/allowed';
import Clips from '../clips';

const dashboard = (props) => <Dashboard {...props} />;
const sites = (props) => <Sites {...props} />;
const systemHealth = (props) => <HardwareHealth {...props} />;
const liveMonitor = (props) => <LiveMonitor {...props} />;
const myClips = (props) => <Clips {...props} />;

export const getTabs = (currentUser) =>
  [
    {
      key: 'dashboard',
      path: 'dashboard',
      primaryTitleText: 'Dashboard',
      component: dashboard,
      icon: <DashboardOutlinedIcon />,
      capabilities: [],
      preferences: [{newDashboard: true}],
      displayOnSmallScreen: true,
    },
    {
      key: 'sites',
      path: 'sites',
      primaryTitleText: 'Sites',
      component: sites,
      icon: <LanguageOutlinedIcon />,
      capabilities: [],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'systemHealth',
      path: 'system-health',
      primaryTitleText: 'System Health',
      component: systemHealth,
      icon: <MonitorHeartOutlinedIcon />,
      capabilities: [],
      preferences: [],
      displayOnSmallScreen: true,
    },
    {
      key: 'liveMonitor',
      path: 'live-monitor',
      primaryTitleText: 'Live Monitor',
      component: liveMonitor,
      icon: <OndemandVideoOutlinedIcon />,
      capabilities: [LIVE_VIDEO],
      preferences: [],
      displayOnSmallScreen: false,
    },
    {
      key: 'myClips',
      path: 'my-clips',
      primaryTitleText: 'My Clips',
      component: myClips,
      icon: <MovieOutlinedIcon />,
      capabilities: [MY_CLIPS],
      preferences: [],
      displayOnSmallScreen: false,
    },
  ].filter((item) =>
    hasRequiredCapabilityWithMatchingPreference(item, currentUser),
  );
