import React from 'react';
import {Typography, Box} from '@mui/material';
import {PropTypes} from 'prop-types';

const LabeledGroupBox = (props) => {
  const {label, children, disabled, sx, ...rest} = props;

  return (
    <Box
      component="fieldset"
      disabled={disabled}
      sx={{
        borderRadius: '4px',
        borderColor: 'rgba(0,0,0,.23)',
        backgroundColor: 'background.default',
        borderWidth: '1px',
        p: 2,
        px: 1,
        ...sx,
      }}
      {...rest}
    >
      {label && (
        <legend>
          <Typography
            component="legend"
            variant="caption"
            sx={{
              color: 'background.transparent',
            }}
          >
            {label}
          </Typography>
        </legend>
      )}
      {children}
    </Box>
  );
};

LabeledGroupBox.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  sx: PropTypes.shape({}),
};

LabeledGroupBox.defaultProps = {
  label: undefined,
  children: undefined,
  disabled: false,
  sx: {},
};

export default LabeledGroupBox;
