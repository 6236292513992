import React, {useState} from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  styled,
} from '@mui/material';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const style = {
  position: 'absolute',
  width: '90vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '4px',
  p: 2,
  marginLeft: '5%',
  marginTop: '20%',
};

const StyledAutoComplete = styled(Autocomplete)(({theme}) => ({
  margin: theme.spacing(3, 0),
  width: '80vw',
}));

const MobileGridSort = ({initialSort, onSortChange, caStatusAllowed}) => {
  const [open, setOpen] = useState(false);
  const [sortModel, setSortModel] = useState(initialSort);

  const sortsortingOrder = ['Ascending', 'Descending'];
  const sortCategory = [
    'Site Name',
    'Appliance Status',
    'Camera Status',
    'Upload Speed',
    'Image Health',
  ];

  if (caStatusAllowed) sortCategory.splice(4, 0, 'Cloud Archive Status');

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setSortModel(initialSort);
    setOpen(false);
  };

  const handleApply = () => {
    onSortChange(sortModel);
    setOpen(false);
  };

  const handleClear = () => {
    setSortModel({sortingOrder: null, sortingColumn: null});
  };

  return (
    <Box sx={{display: 'flex', justifyContent: 'end', mr: 1}}>
      <Button className="main-sort-button" onClick={handleOpen}>
        <SwapVertIcon />
        SORT BY
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography id="sort-modal-title" variant="h6" component="h2">
            Sort
          </Typography>
          <StyledAutoComplete
            disablePortal
            id="sort-sortingOrder"
            options={sortsortingOrder}
            value={sortModel?.sortingOrder}
            onChange={(e, newValues) =>
              setSortModel((prev) => ({...prev, sortingOrder: newValues}))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Ascending/Descending"
                placeholder="Select..."
              />
            )}
          />
          <StyledAutoComplete
            disablePortal
            id="sort-order"
            options={sortCategory}
            value={sortModel?.sortingColumn}
            onChange={(e, newValues) =>
              setSortModel((prev) => ({...prev, sortingColumn: newValues}))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Sort Category"
                placeholder="Select..."
              />
            )}
          />
          <Box sx={{mt: 5, display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              className="sort-clear-button"
              variant="text"
              sx={{mr: 2}}
              onClick={handleClear}
            >
              Clear
            </Button>
            <Button
              className="sort-cancel-button"
              variant="text"
              sx={{mr: 2}}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              className="sort-apply-button"
              variant="contained"
              color="primary"
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};
export default MobileGridSort;
