export function bitrateFormat(bandwidth, offline) {
  let label = `${bandwidth} bps`;
  if (offline) {
    label = 'Site is offline';
  } else if (bandwidth > 1000000) {
    label = `${Math.round((bandwidth / 1000000) * 10) / 10} Mbps`;
  } else if (bandwidth > 1000) {
    label = `${Math.round((bandwidth / 1000) * 10) / 10} Kbps`;
  }
  return label;
}

export default {bitrateFormat};
