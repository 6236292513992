import semver from 'semver';
import {getStatusFromAppliance} from '../../api/appliances';

export const getApplianceStatuses = async (appliances) => {
  const applianceStatuses = await Promise.all(
    appliances.map((a) => {
      if (!a.online) return Promise.resolve({offline: true});

      return getStatusFromAppliance(a.id).catch((e) => ({error: e}));
    }),
  );

  return applianceStatuses.reduce(
    (acc, v, idx) => ({
      ...acc,
      [appliances[idx].id]: v,
    }),
    {},
  );
};

export const versionsMeetMinimum = (
  applianceStatuses,
  minVersion,
  checkOfflineAppliances,
) =>
  Object.values(applianceStatuses).every((a) => {
    if (!checkOfflineAppliances && a.offline) return true;

    if (a.error || !a.version) return false;
    if (!semver.valid(a.version)) return false;

    return semver.satisfies(a.version, `>=${minVersion}`);
  });

export const pendingAppliancesUpdate = (
  applianceStatuses,
  appliancePreferences,
) => {
  const parseVersion = (versionString) => {
    return semver.coerce(versionString.replace('p', '.'));
  };
  return Object.entries(applianceStatuses).some(
    ([applianceId, applianceStatus]) => {
      const {value: puppetEnv} =
        appliancePreferences[applianceId]?.find(
          ({name}) => name === 'puppetEnv',
        ) ?? {};
      const applianceReleaseVersion = applianceStatus.appliance_release_version;

      // not returned from appliance, no way to know if pending update
      if (!applianceReleaseVersion) return false;
      if (!puppetEnv) return false;

      if (
        applianceReleaseVersion.startsWith('ar') &&
        puppetEnv.startsWith('ar')
      ) {
        const parsedApplianceReleaseVersion = parseVersion(
          applianceReleaseVersion,
        );
        const parsedPuppetEnv = parseVersion(puppetEnv);

        // returns true if parsedApplianceReleaseVersion < parsedPuppetEnv
        return semver.lt(parsedApplianceReleaseVersion, parsedPuppetEnv);
      }
      return applianceReleaseVersion.toLowerCase() !== puppetEnv.toLowerCase();
    },
  );
};
