import React from 'react';

import {Switch, Route, withRouter, Redirect} from 'react-router-dom';
import GlobalErrorPage from '../globalErrorPage';
import {withCurrentUser} from '../../shared/contexts/currentUserContext';
import {isNewNavigationWebEnabled} from '../../shared/util/user';
import PageTemplateResponsive from '../../shared/components/pageTemplateResponsive';
import {getNavigationItems} from '../../shared/components/getNavigationItems';
import {getTabs} from './homePillarRoutes';
import {RedirectWithQueryParams} from '../../shared/components/redirectWithQueryParams';

const Home = ({currentUser}) => {
  if (isNewNavigationWebEnabled(currentUser)) {
    const tabs = getTabs(currentUser);
    const navigationProps = getNavigationItems(tabs, currentUser);

    return (
      <PageTemplateResponsive
        PageHeaderProps={{title: 'Home'}}
        {...navigationProps}
      >
        <Route exact path="/home">
          {currentUser.settings.newDashboard ? (
            <RedirectWithQueryParams to="/home/dashboard" />
          ) : (
            <Redirect to="/home/sites" />
          )}
        </Route>
        <Switch>
          {tabs.map((tab) => (
            <Route
              exact={tab.key !== 'sites'}
              key={tab.key}
              path={`/home/${tab.path}`}
              component={tab.component}
            />
          ))}
        </Switch>
      </PageTemplateResponsive>
    );
  }
  return <GlobalErrorPage />;
};

export default withRouter(withCurrentUser(Home));
