import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Paper,
  Box,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import SmokeDetectorAlertIcon from '../../../../shared/images/SmokeDetectorAlertIcon';

/** This component visualizes basic data about the safety button */
const SafetyButtonCard = ({
  buttonData,
  mappedCameras,
  children,
  camerasVisible,
  fullWidth,
  buttonName,
  handleChangeName,
  editMode,
}) => {
  const displayCameras = (cameras) => {
    if (!Array.isArray(cameras)) return '---';
    return cameras.length
      ? cameras.map((cam) => cam.cameraName).join(', ')
      : 'No Cameras Assigned';
  };

  return (
    <Paper
      variant="outlined"
      name="card"
      sx={{
        marginBottom: 2,
        overflow: 'auto',
      }}
      data-cy="safety-button-card"
    >
      <Box
        display="flex"
        alignItems={editMode ? 'flex-start' : 'center'}
        justifyContent="center"
        flexWrap={fullWidth ? 'wrap' : undefined}
        sx={{padding: 2, gap: 2}}
      >
        <SmokeDetectorAlertIcon
          sx={(theme) => ({
            fill: theme.palette.primary.main,
            alignSelf: 'center',
          })}
        />
        <Box flexGrow={1} overflow="hidden">
          {!editMode ? (
            <Typography
              variant="body1"
              data-cy="safety-button-name"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {buttonData.name}
            </Typography>
          ) : (
            <FormControl sx={{marginBottom: 1, width: 1}}>
              <TextField
                value={buttonName}
                onChange={handleChangeName}
                label="Button Name"
                data-cy="input-safety-button-name"
                variant="standard"
                error={buttonName.length < 3}
                helperText={
                  buttonName.length < 3
                    ? 'At least 3 characters required'
                    : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CreateIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )}

          <Typography
            variant="body2"
            sx={(theme) => ({color: theme.palette.grey[500]})}
          >
            ID: {buttonData.deviceId}
          </Typography>
          {camerasVisible && (
            <Typography
              data-cy="panic-button-camera-mapping"
              variant="body2"
              sx={{
                marginTop: 1,
                whiteSpace: 'break-spaces',
              }}
            >
              {displayCameras(mappedCameras)}
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            ...(editMode && {width: fullWidth ? 1 : 0.5}),
          }}
          data-cy="safety-button-card-content"
          data-id={buttonData.integrationId}
          data-mode={buttonData.mode}
        >
          {children}
        </Box>
      </Box>
    </Paper>
  );
};

export default SafetyButtonCard;

SafetyButtonCard.propTypes = {
  buttonData: PropTypes.shape({
    deviceId: PropTypes.string,
    name: PropTypes.string,
    cameras: PropTypes.arrayOf(
      PropTypes.shape({id: PropTypes.string, name: PropTypes.string}),
    ),
  }).isRequired,
  mappedCameras: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  camerasVisible: PropTypes.bool,
  fullWidth: PropTypes.bool,
  buttonName: PropTypes.string,
  handleChangeName: PropTypes.func,
  editMode: PropTypes.bool,
};

SafetyButtonCard.defaultProps = {
  camerasVisible: false,
  mappedCameras: undefined,
  fullWidth: undefined,
  buttonName: '',
  handleChangeName: () => {},
  editMode: false,
};
