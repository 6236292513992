import React from 'react';

import PageLoader from '../../shared/components/pageLoader';
import {getSharedClips} from '../../api/clipsSharing';

const Loader = (loaderProps) => {
  const {shareToken} = loaderProps.match.params;
  return (
    <PageLoader
      page={() => import('./Page')}
      resources={{
        shareProperties: async () => {
          let requiresAuth = false;
          let fetchResponse = {};
          let responseCode;
          try {
            fetchResponse = await getSharedClips(shareToken);
          } catch (e) {
            const {status, data} = e.response;
            if (status === 403 || status === 401) {
              requiresAuth = true;
            } else if (status > 401) {
              responseCode = status;
            } else {
              if (data && data.error) {
                throw data.error.message;
              }
              throw e;
            }
          }
          return {
            sharedClips: fetchResponse.clips || [],
            responseCode,
            requiresAuth,
            shareToken,
            expirationDate: fetchResponse.expirationDate,
          };
        },
      }}
      {...loaderProps}
    />
  );
};

export default Loader;
